import {
	BasicPermissionedProperty,
	BasicProperty,
	VersionedModel,
} from '@angular-helpers/frontend-api';
import {inject} from '@angular/core';
import {
	AnyModel,
	combineLatestSafe,
	ModelHelper,
	notNull,
	sum,
} from '@app/main';
import {
	combineLatest,
	Observable,
	ObservedValueOf,
	of,
} from 'rxjs';
import {
	map,
	switchMap,
} from 'rxjs/operators';
import {HourlyRateService} from '../../services/hourly-rate.service';
import {PriceSchema} from '../common/price-schema';
import {
	AID_IDENTIFIER_LEASE,
	AidIdentifier,
	HourlyRate,
	VatRate,
} from '../common/types';
import {FixedPriceModel} from '../fixedPrice/fixed-price.model';
import {MaterialModel} from '../material/material.model';
import {ProductModel} from '../product/product.model';
import {TaskTimeModel} from '../task-time/task-time.model';
import {
	TaskScope,
	TaskType,
} from '../task/task.model';
import {CalculationService} from './calculation.service';

export const CALCULATION_UNITS = [
	'PIECE',
	'PAIR',
] as const;
export type CalculationUnit = typeof CALCULATION_UNITS[number];

type CalculationModelCalculationPriceProperty = { [K in keyof CalculationModel]: CalculationModel[K] extends BasicPermissionedProperty<CalculationPricedAmount<infer T>[]> ? K : never }[keyof CalculationModel];

export class CalculationModel extends VersionedModel<CalculationService> {
	readonly identifierExtension = new BasicPermissionedProperty<string>('identifierExtension', this);
	readonly productSpecialityNumber = new BasicPermissionedProperty<string | null>('productSpecialityNumber', this);
	readonly aidIdentifiers = new BasicPermissionedProperty<AidIdentifier[]>('aidIdentifier', this);
	readonly careScope = new BasicPermissionedProperty<string | null>('careScope', this);
	readonly name = new BasicPermissionedProperty<string>('name', this);
	readonly serviceScope = new BasicPermissionedProperty<string>('serviceScope', this);
	readonly characteristic = new BasicPermissionedProperty<string | null>('characteristic', this);
	readonly possibleExtensions = new BasicPermissionedProperty<CalculationModel[]>('possibleExtensions', this);
	readonly fixedPrice = new BasicPermissionedProperty<FixedPriceModel>('fixedPrice', this);
	readonly products = new BasicPermissionedProperty<CalculationProduct[]>('products', this);
	readonly materials = new BasicPermissionedProperty<CalculationMaterial[]>('materials', this);
	readonly calculations = new BasicPermissionedProperty<CalculationCalculation[]>('calculations', this);
	readonly taskTimes = new BasicPermissionedProperty<CalculationTask[]>('taskTimes', this);
	readonly addition = new BasicPermissionedProperty<number>('addition', this);
	readonly price = new BasicPermissionedProperty<number>('price', this);
	readonly priceSchema = new BasicPermissionedProperty<PriceSchema>('priceSchema', this);
	readonly hourlyBillingRateBracket = new BasicPermissionedProperty<HourlyRate>('hourlyBillingRateBracket', this);
	readonly vatRates = new BasicPermissionedProperty<VatRate[]>('vatRates', this);
	readonly unit = new BasicPermissionedProperty<CalculationUnit>('unit', this);
	readonly hint = new BasicPermissionedProperty<string>('hint', this);
	readonly dynamicFields = new BasicPermissionedProperty<unknown>('dynamicFields', this);
	readonly isLeasing = this.aidIdentifiers.value.pipe(
		map(aidIdentifiers => {
			if(aidIdentifiers == null)
				return aidIdentifiers;

			if(aidIdentifiers.includes(AID_IDENTIFIER_LEASE) === false)
				return false;

			if(aidIdentifiers.length !== 1)
				throw new Error(`Undecidable constellation of aidIdentifiers found: ${JSON.stringify(aidIdentifiers)}. Expected only ${AID_IDENTIFIER_LEASE} or array without it.`);

			return true;
		}),
	);
	protected readonly hourlyRateService = inject(HourlyRateService);

	getTotalPrice<PropertyNameType extends CalculationModelCalculationPriceProperty>(fieldName: PropertyNameType): Observable<number | null | undefined> {
		const field: Observable<undefined | CalculationPricedAmount<PriceModel | LeasePriceModel>[]> = this[fieldName].value;

		const getPrice$ = (isLeasing: ObservedValueOf<CalculationModel['isLeasing']>, {model}: NonNullable<ObservedValueOf<typeof field>>[number]) => {
			if('price' in model)
				return model.price.value;

			switch(isLeasing) {
				case true:
					return model.priceLease.value;

				case false:
					return model.priceBuy.value;

				case undefined:
					return of(undefined);

				case null:
					throw new Error('Unknown leasing state');
			}
		};

		return combineLatest([
			this.isLeasing,
			field,
		]).pipe(
			switchMap(([isLeasing, data]) => {
				if(data === undefined)
					return of(undefined);

				if(data.length === 0)
					return of(null);

				const parts$ = data.map(entry => {
					const price$ = getPrice$(isLeasing, entry);
					return price$.pipe(map(price => price === undefined ? undefined : entry.amount * price));
				});

				return combineLatestSafe(parts$).pipe(
					map(parts => {
						if(parts.includes(undefined))
							return undefined;

						const partsSave = parts.filter(notNull); // for type hinting

						return partsSave.reduce((a, b) => a + b, 0);
					}),
				);
			}),
		);
	}

	calculateTotalMaterialPrice(): Observable<number | null | undefined> {
		return this.getTotalPrice('materials');
	}

	calculateTotalIncludedCalculationsPrice(): Observable<number | null | undefined> {
		return this.getTotalPrice('calculations');
	}

	calculateTotalProductPrice(): Observable<number | null | undefined> {
		const sum = this.getTotalPrice('products');
		const amounts = this.products.value.pipe(
			map(materials => materials?.reduce((a, b) => a + b.amount, 0)),
		);

		return combineLatest([sum, amounts]).pipe(
			map(([sum, amounts]) => {
				if(sum === undefined || amounts === undefined)
					return undefined;

				if(sum === null)
					return null;

				if(amounts === 0)
					return 0;

				return sum / amounts;
			}),
		);
	}

	calculateLabourTimes(includedScope?: TaskScope | TaskType, excludedTypes: TaskType[] = []): Observable<number | null | undefined> {
		return this.taskTimes.value.pipe(
			switchMap(taskTimes => {
				if(taskTimes === undefined)
					return of(undefined);

				return of(taskTimes).pipe(
					map(tasksTimes => tasksTimes.map(time => time.model.task.value.pipe(
						map(task => ModelHelper.getPropertyValues(task, ['type', 'scope'])),
						switchMap(x => combineLatest(x)),
						map(x => ({
							...x[0],
							time,
						})),
					))),
					switchMap(x => combineLatestSafe(x)),
				);
			}),
			map(data => {
				if(data === undefined)
					return undefined;

				if(data.length === 0)
					return null;

				return data
					.filter(notNull)
					.filter(x => x.type === undefined || excludedTypes.includes(x.type) === false)
					.filter(x => includedScope === undefined || x.scope === includedScope || x.type === includedScope)
					.map(x => x.time);
			}),
			map(tasks => {
				if(tasks == null)
					return tasks;

				const amounts = tasks.map(task => task.amount);
				return sum(amounts);
			}),
		);
	}

	calculateLabourCosts(includedScope?: TaskScope | TaskType, excludedTypes: TaskType[] = ['VERWALTUNGSZEITEN']): Observable<number | null | undefined> {
		return this.calculateLabourTimes(includedScope, excludedTypes).pipe(
			switchMap(sum => {
				if(sum == null)
					return of(sum);

				return this.hourlyRateService.getRatePerMinute(this).pipe(
					map(rate => {
						if(rate === undefined)
							return undefined;

						return sum * rate;
					}),
				);
			}),
		);
	}

	calculatePriceStandardSchema(): Observable<undefined | { labourCosts: number | null | false; listPrice: number | null | false; addition: number | false; hourlyBillingRateBracket: number | false }> {
		return this.priceSchema.value.pipe(
			map(priceSchema => {
				if(priceSchema?.schema !== 'STANDARD')
					return undefined;

				const listPrice$ = combineLatest([
					this.calculateTotalMaterialPrice(), this.calculateTotalProductPrice(),
				]).pipe(map(values => {
					if(values.every(x => x === undefined))
						return undefined;

					if(values.every(x => x === null))
						return null;

					return sum(values.filter(notNull));
				}));
				const hourlyBillingRateBracket$ = this.hourlyBillingRateBracket.value.pipe(
					switchMap(rate => {
						if(rate === undefined)
							return of(undefined);

						return this.hourlyRateService.getRatePerMinute(rate);
					}),
				);

				const false$ = of(false as const);
				return combineLatest([
					priceSchema.parameters.listPrice ? listPrice$ : false$,
					priceSchema.parameters.addition ? this.addition.value : false$,
					priceSchema.parameters.labourCosts ? this.calculateLabourCosts() : false$,
					priceSchema.parameters.labourCosts ? hourlyBillingRateBracket$ : false$,
				]).pipe(
					map(([
						     listPrice,
						     addition,
						     labourCosts,
						     hourlyBillingRateBracket,
					     ]) => {
						if(listPrice === undefined)
							return undefined;

						if(addition === undefined)
							return undefined;

						if(labourCosts === undefined)
							return undefined;

						if(hourlyBillingRateBracket === undefined)
							return undefined;

						return {
							listPrice,
							addition,
							labourCosts,
							hourlyBillingRateBracket,
						};
					}),
				);
			}),
			switchMap(x => x == null ? of(x) : x),
		);
	}
}

export type CalculationMaterial = CalculationPricedAmount<MaterialModel>;
export type CalculationProduct = CalculationPricedAmount<ProductModel>;
export type CalculationCalculation = CalculationPricedAmount<CalculationModel>;
export type CalculationTask = CalculationAmount<TaskTimeModel>;

export interface PriceModel {
	readonly price: BasicProperty<number>;
}

export interface LeasePriceModel {
	readonly priceBuy: BasicProperty<number>;
	readonly priceLease: BasicProperty<number>;
}

export interface CalculationPricedAmount<T extends PriceModel | LeasePriceModel> {
	model: T;
	amount: number;
}

export interface CalculationAmount<T extends AnyModel> {
	model: T;
	amount: number;
}