import {
	Pipe,
	PipeTransform,
} from '@angular/core';
import {ElementEntryStateInterface} from '@app/contracts';
import {MedicalStoreModel} from '@contracts/frontend-api';
import {Subject} from 'rxjs';

@Pipe({
	name: 'storeToState',
})
export class StoreToStatePipe implements PipeTransform {

	transform(store: MedicalStoreModel, library: Map<string, ElementEntryStateInterface>, processor?: Subject<MedicalStoreModel>): ElementEntryStateInterface | undefined {
		const state = library.get(store.id);
		if(processor != null && state == null)
			processor.next(store);

		return state;
	}
}
