import {
	Component,
	inject,
	Input,
} from '@angular/core';
import {environment} from '@app/environment';
import {
	ClientSearchMinimalColumns,
	PhonePipe,
	SearchFilter,
} from '@app/main';
import {LandesinnungModel} from '@contracts/frontend-api';

@Component({
	selector:    'portal-landesinnung-list',
	templateUrl: './landesinnung-list.component.html',
	styleUrls:   ['./landesinnung-list.component.scss'],
})
export class LandesinnungListComponent {
	@Input({required: true}) data: LandesinnungModel[] | undefined | null;
	@Input() pageSize: number | undefined;
	environment = environment;
	readonly searchFilter = new CSearchFilter();
	readonly tableHeaders: ClientSearchMinimalColumns<LandesinnungModel> = {
		name:    {
			label: 'common.name',
			index: 0,
		},
		phone:   {
			label: 'common.phone',
			index: 1,
		},
		fax:     {
			label: 'common.fax',
			index: 2,
		},
		email:   {
			label: 'common.email',
			index: 3,
		},
		website: {
			label: 'common.website',
			index: 4,
			isVisible: false,
		},
	};
}

class CSearchFilter extends SearchFilter<LandesinnungModel> {
	protected readonly phonePipe = inject(PhonePipe);

	protected async getModelValue(field: string, model: LandesinnungModel): Promise<unknown> {
		switch(field) {
			case 'phone':
				return this.phonePipe.transform(await model.phone.firstValue);

			case 'fax':
				return this.phonePipe.transform(await model.fax.firstValue);

			default:
				return super.getModelValue(field, model);
		}
	}
}
