import {AbstractParser} from '@angular-helpers/frontend-api';
import {FixedPriceModel} from './fixed-price.model';
import {FixedPriceService} from './fixed-price.service';

export class FixedPriceParser extends AbstractParser<FixedPriceModel, FixedPriceService> {
	protected sanitizeInput(propertyDto: string, property: string, value: unknown): unknown {
		switch(property) {
			case 'predecessor':
			case 'successor':
				if(value != null && typeof value !== 'string')
					throw new Error(`Property ${property} must be a string`);

				return this.castToModelOptional(value, this.service);

			default:
				return super.sanitizeInput(propertyDto, property, value);
		}
	}
}