import {
	Component,
	Input,
} from '@angular/core';
import {TooltipPosition} from '@angular/material/tooltip';
import {IconService} from '@app/main';

@Component({
	selector:    'portal-info',
	templateUrl: './info.component.html',
	styleUrls:   ['./info.component.scss'],
})
export class InfoComponent {
	@Input({required: true}) description!: string;
	@Input() position: TooltipPosition = 'above';
	@Input({transform: (value: '' | boolean) => value !== false}) inline = false;

	constructor(
			protected readonly iconService: IconService,
	) { }
}
