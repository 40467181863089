import {
	DtoEditFormHelper,
	DtoFormHelper,
} from '@angular-helpers/frontend-api';
import {
	Component,
	DestroyRef,
	Inject,
	OnInit,
} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {
	UntypedFormControl,
	Validators,
} from '@angular/forms';
import {
	MAT_DIALOG_DATA,
	MatDialogRef,
} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {environment} from '@app/environment';
import {
	ConfirmDialogAnswer,
	ConfirmDialogComponent,
	ConfirmDialogConfig,
	DialogService,
	IconService,
	RelativeDateService,
} from '@app/main';
import {
	ContractAccessionDtoModel,
	ContractAccessionModel,
	ContractAccessionService,
	ContractSectionModel,
	InstitutionskennzeichenModel,
} from '@contracts/frontend-api';
import {
	Observable,
	timer,
} from 'rxjs';
import {
	catchError,
	map,
	mapTo,
	mergeMap,
	retry,
	withLatestFrom,
} from 'rxjs/operators';

export interface WithdrawalDataInterface {
	dialogTypeDelete: boolean;
	contractAccession: ContractAccessionModel;
}

@Component({
	selector:    'portal-withdrawal',
	templateUrl: './withdrawal.component.html',
	styleUrls:   ['./withdrawal.component.scss'],
})
export class WithdrawalComponent implements OnInit {
	protected isLoading             = false;
	protected institutionsKennzeichen$?: Observable<InstitutionskennzeichenModel | undefined>;
	protected contractNotificationPeriod$?: Observable<string>;
	protected contractSection$?: Observable<ContractSectionModel | null | undefined>;
	protected minResignDate$?: Observable<Date>;
	protected confirmationDialog?: MatDialogRef<ConfirmDialogComponent, unknown>;
	protected readonly accessionEnd = new UntypedFormControl('', Validators.required);
	private helper?: DtoFormHelper<ContractAccessionModel, ContractAccessionService>;

	constructor(
		protected dialogRef: MatDialogRef<WithdrawalComponent>,
		protected dialogService: DialogService,
		protected iconService: IconService,
		protected relativeDateService: RelativeDateService,
		protected contractAccessionService: ContractAccessionService,
		protected router: Router,
		@Inject(MAT_DIALOG_DATA) protected data: WithdrawalDataInterface,
		protected readonly destroyRef: DestroyRef,
	) {
	}

	ngOnInit(): void {
		this.contractSection$ = this.data.contractAccession.contractSection.value;

		this.contractNotificationPeriod$ = this.data.contractAccession.contractSection.value.pipe(
			takeUntilDestroyed(this.destroyRef),
			mergeMap(contractSection => {
				if(contractSection == null)
					throw new Error('contractSection is not loaded');

				return contractSection.noticePeriodDelay.withParent.value;
			}),
			map((noticePeriodDelay) => {
				if(noticePeriodDelay == null)
					throw new Error('noticePeriodDelay is not loaded');

				return noticePeriodDelay;
			}),
			catchError((error, caught$) => {
				console.warn(error.message);
				return caught$;
			}),
		);

		this.minResignDate$ = timer(0, 50_000).pipe(
			mapTo(new Date()),
			withLatestFrom(this.contractNotificationPeriod$),
			map(([selectedDate, resignDelay]) => this.relativeDateService.calcNewDate(selectedDate, resignDelay)),
			retry(5),
		);

		this.institutionsKennzeichen$ = this.data.contractAccession.institutionskennzeichen.value;

		this.helper = DtoEditFormHelper.create(
			ContractAccessionDtoModel,
			this.data.contractAccession,
			this.contractAccessionService,
		);
	}

	accept(): void {
		const selectedDate = this.accessionEnd.value;

		if(this.accessionEnd.valid === false)
			return;


		this.setResignDate(selectedDate);
	}

	decline(): void {
		if(this.accessionEnd.touched || this.accessionEnd.dirty) {
			const confirmDialogData: ConfirmDialogConfig = {
				labelPositiv:  'system.abortChangesDialog.labelPositiv',
				labelNegative: 'system.abortChangesDialog.labelNegative',
				title:         'system.abortChangesDialog.title',
				message:       'system.abortChangesDialog.message',
				icon:          this.iconService.DIALOG_ATTENTION,
			};

			this.confirmationDialog = this.dialogService.openConfirmDialog(confirmDialogData);
			this.confirmationDialog.afterClosed().toPromise().then((value) => {
				if(ConfirmDialogAnswer.negative === value)
					this.dialogRef.close();

				this.confirmationDialog?.close();
			});
		} else
			this.dialogRef.close();

	}

	delete(): void {
		if(this.helper == null)
			throw new Error('helper is not loaded');


		this.isLoading = true;
		this.contractAccessionService.delete(this.data.contractAccession).then(() => {
			this.dialogRef.close();

			this.router.navigate([
				environment.contractsModuleBaseUrl,
				environment.contractAccessionBaseUrl,
			]);
		}).catch((error) => {
			console.warn(error.message);
		}).finally(() => {
			this.isLoading = false;
		});
	}

	setResignDate(selectedDate: moment.Moment): void {
		if(this.helper == null)
			return;
		const fillOperation = this.helper.fill({
			accessionEndAt: selectedDate.toDate().toISOString(),
		});

		this.isLoading = true;
		this.processOperation(fillOperation).finally(() => {
			this.isLoading = false;
		});
	}

	processOperation(fillOperation: Promise<void>): Promise<void> {
		return fillOperation.then(() => {
			                    if(this.helper == null)
				                    throw new Error('helper not loaded');

			                    return this.helper.save();
		                    })
		                    .then((accessionSaved) => {
			                    if(accessionSaved == null)
				                    throw new Error('could not save');

			                    this.dialogRef.close();
		                    }).catch((error) => {
				console.warn(error.message);
			});
	}
}
