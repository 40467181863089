import {
	Component,
	Input,
	OnChanges,
} from '@angular/core';
import {ModelHelper} from '@app/main';
import {
	ExternalContractNumberModel,
	ExternalContractNumberType,
} from '@contracts/frontend-api';
import {
	Observable,
	of,
} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
	selector:    'portal-external-contract-number-token-list',
	templateUrl: './external-contract-number-token-list.component.html',
	styleUrls:   ['./external-contract-number-token-list.component.scss'],
})
export class ExternalContractNumberTokenListComponent implements OnChanges {
	@Input({required: true}) numbers?: ExternalContractNumberModel[] | null = [];
	@Input() type?: ExternalContractNumberType;
	filtered$?: Observable<ExternalContractNumberModel[]>;

	ngOnChanges(): void {
		if(this.type != null && this.numbers != null)
			this.filtered$ = this.setFilter(this.numbers, this.type);
		else if(this.numbers != null)
			this.filtered$ = of(this.numbers);

	}

	setFilter(numbers: ExternalContractNumberModel[], type: ExternalContractNumberType): Observable<ExternalContractNumberModel[]> {
		return ModelHelper.getModelPropertyValues(numbers, ['type', 'number'], 'model').pipe(
			map(ecnList => ecnList
				.filter((ecnNumber) => ecnNumber.type === type)
				.map((ecnNumber) => ecnNumber.model)),
		);
	}
}
