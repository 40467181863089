import {
	Component,
	inject,
} from '@angular/core';
import {
	FormBuilder,
	FormControl,
	ReactiveFormsModule,
	Validators,
} from '@angular/forms';
import {
	MatOptgroup,
	MatOption,
} from '@angular/material/autocomplete';
import {
	MAT_DIALOG_DATA,
	MatDialogConfig,
	MatDialogRef,
} from '@angular/material/dialog';
import {
	MatFormField,
	MatLabel,
} from '@angular/material/form-field';
import {
	MatRadioButton,
	MatRadioGroup,
} from '@angular/material/radio';
import {
	CALCULATION_EXPORT_FILE_TYPES,
	CalculationModel,
	CalculationService,
	HourlyRateService,
} from '@app/calculations';
import {
	DialogService,
	IconService,
	MainModule,
	SaveFile,
} from '@app/main';
import {TranslateModule} from '@ngx-translate/core';
import {first} from 'rxjs/operators';

export interface GenerateCalculationExportDialogData {
	calculation: CalculationModel;
}

@Component({
	standalone:  true,
	imports:     [
		MatFormField,
		MatLabel,
		MatOptgroup,
		MatOption,
		ReactiveFormsModule,
		TranslateModule,
		MainModule,
		MatRadioGroup,
		MatRadioButton,
	],
	templateUrl: './generate-calculation-export-dialog.component.html',
	styleUrl:    './generate-calculation-export-dialog.component.scss',
})
export class GenerateCalculationExportDialogComponent {
	protected readonly iconService = inject(IconService);
	protected readonly formGroup = (new FormBuilder()).nonNullable.group(
		{
			fileType: new FormControl<GenerateCalculationExportDialogComponent['possibleCalculationFileTypes'][number]>('pdf', {
				nonNullable: true,
				validators:  [Validators.required],
			}),
			extended: new FormControl<boolean>(false, {
				nonNullable: true,
				validators:  [Validators.required],
			}),
		},
	);
	protected readonly dialogRef = inject(MatDialogRef<this>);
	protected readonly possibleCalculationFileTypes = CALCULATION_EXPORT_FILE_TYPES;
	private readonly data = inject<GenerateCalculationExportDialogData>(MAT_DIALOG_DATA);
	private readonly hourlyRateService = inject(HourlyRateService);
	private readonly calculationService = inject(CalculationService);

	get calculation(): CalculationModel {
		return this.data.calculation;
	}

	static open(dialogService: DialogService, calculation: CalculationModel): MatDialogRef<GenerateCalculationExportDialogComponent> {
		const config = new MatDialogConfig<void>();
		config.autoFocus = false;
		config.minWidth = '25rem';

		return dialogService.openCustomDialog(GenerateCalculationExportDialogComponent, {calculation}, config);
	}

	protected async save(): Promise<void> {
		await this.generateExport();
		this.dialogRef.close();
	}

	protected async generateExport(): Promise<void> {
		const billingRate = await this.hourlyRateService.getRate(this.calculation).pipe(first()).toPromise();
		if(billingRate == null)
			throw new Error('Billing rate not found');

		const fileType = this.formGroup.controls.fileType.value;
		const extended = this.formGroup.controls.extended.value;

		const export$ = this.calculationService.generateExport(this.calculation, billingRate, fileType, extended);

		const data = await export$.pipe(first()).toPromise();

		const fileName = await this.calculation.identifierExtension.firstValue;
		const fileNameExtended = `${fileName}.${fileType}`;

		SaveFile.save(false, fileNameExtended, data);
	}
}
