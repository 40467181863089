import {Level8Error} from '@angular-helpers/frontend-api';
import {
	Component,
	ContentChildren,
	ElementRef,
	inject,
	Input,
	OnInit,
	QueryList,
	TemplateRef,
	ViewChildren,
} from '@angular/core';
import {
	ActivatedRoute,
	Router,
} from '@angular/router';
import {
	Column,
	SearchOptions,
	StringHelper,
	TableColumnData,
	TableColumnTemplateDirective,
	TableHeaderData,
	TableHeaderTemplateDirective,
	TableSearchHeaderData,
	TableSearchHeaderTemplateDirective,
} from '@app/main';

export interface MinimalColumn<DataType extends object, RawType = unknown> extends Omit<Partial<Column<DataType, RawType>>, 'onSearch' | 'filter'> {
	label: string;
	prepareSearch?: ((value: RawType, searchOptions: SearchOptions) => unknown | Promise<unknown>);
	isSearchable?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type MinimalColumns<DataType extends object> = Record<string, MinimalColumn<DataType, any> | undefined>;

@Component({
	template: '',
})
export abstract class AbstractTableComponent<TableEntry extends object> implements OnInit {
	protected static readonly DEFAULT_PAGE_SIZE: number = 5;
	protected static readonly PAGE_PARAMETER = '_page';
	protected static readonly SORT_BY_PARAMETER = '_sort';
	protected static readonly SORT_ASCENDING_PARAMETER = '_asc';
	@Input() storeSearch = true;
	@Input() sortBy?: string;
	@Input() sortAscending?: boolean;
	@ContentChildren(TableColumnTemplateDirective) columnContentContentTemplates?: QueryList<TableColumnTemplateDirective<TableEntry>>;
	@ViewChildren(TableColumnTemplateDirective) columnContentViewTemplates?: QueryList<TableColumnTemplateDirective<TableEntry>>;
	@ContentChildren(TableHeaderTemplateDirective) columnHeaderContentTemplates?: QueryList<TableHeaderTemplateDirective<TableEntry>>;
	@ViewChildren(TableHeaderTemplateDirective) columnHeaderViewTemplates?: QueryList<TableHeaderTemplateDirective<TableEntry>>;
	@ContentChildren(TableSearchHeaderTemplateDirective) columnSearchHeaderContentTemplates?: QueryList<TableSearchHeaderTemplateDirective<TableEntry, unknown>>;
	@ViewChildren(TableSearchHeaderTemplateDirective) columnSearchHeaderViewTemplates?: QueryList<TableSearchHeaderTemplateDirective<TableEntry, unknown>>;
	protected readonly router = inject(Router);
	protected readonly activatedRoute = inject(ActivatedRoute);
	protected tableHeaders: Record<string, Column<TableEntry> | undefined> = {};
	protected searchingData: Record<string, unknown> = {};
	protected elementRef = inject(ElementRef);
	protected _displayPage = 0;
	protected sortByStartValue: string | undefined;
	protected sortAscendingStartValue: boolean | undefined;
	protected isInitialized = false;
	private _tableId?: string;

	get baseLink(): string | undefined {
		if(typeof this.columnLink === 'string')
			return this.columnLink;

		return undefined;
	}

	get getBaseLinkFunction(): ((entry: TableEntry) => string | undefined) | undefined {
		if(typeof this.baseLink === 'function')
			return this.baseLink;

		return undefined;
	}

	protected get displayPage(): number {
		return this._displayPage;
	}

	protected set displayPage(value: number) {
		if(this._displayPage === value)
			return;

		this._displayPage = value;
		this.search((value > 0) ? value : undefined, {id: AbstractTableComponent.PAGE_PARAMETER});
	}

	protected get tableId(): string {
		if(this._tableId == null)
			this._tableId = this.getTableId();

		return this._tableId;
	}

	protected abstract get columnLink(): string | ((entry: TableEntry) => string | undefined) | undefined;

	ngOnInit(): void {
		this.sortByStartValue = this.sortBy;
		this.sortAscendingStartValue = this.sortAscending;
		this.isInitialized = true;

		if(this.storeSearch) {
			const queryMap = this.activatedRoute.snapshot.queryParamMap;
			let changed = false;

			const pageKey = `${this.tableId}.${AbstractTableComponent.PAGE_PARAMETER}`;
			const page = Number.parseInt(queryMap.get(pageKey) ?? '0');
			if(page !== this.displayPage) {
				this._displayPage = page;
				changed = true;
			}

			const sortByKey = `${this.tableId}.${AbstractTableComponent.SORT_BY_PARAMETER}`;
			if(queryMap.has(sortByKey))
				this.sortBy = queryMap.get(sortByKey) ?? undefined;

			const sortAscendingKey = `${this.tableId}.${AbstractTableComponent.SORT_ASCENDING_PARAMETER}`;
			if(queryMap.has(sortAscendingKey))
				this.sortAscending = queryMap.get(sortAscendingKey) === 'true';

			for(const [key, column] of Object.entries(this.tableHeaders)) {
				if(column == null)
					continue;

				const id = `${this.tableId}.${key}`;

				let value = queryMap.get(id);
				if(value == null) {
					if(column.filter == null || column.filter === '')
						continue;
					else
						value ??= '';
				}

				const realValue = (column.deserialize != null) ? column.deserialize(value) : value;

				// eslint-disable-next-line eqeqeq
				if(column.filter != realValue) {
					column.onSearch?.(realValue, {
						id: key,
						page,
					});
					changed = true;
				}
			}

			if(changed)
				this.reload();
		}
	}

	async search(value: unknown, options: SearchOptions): Promise<void> {
		const column = this.tableHeaders[options.id];
		if(column != null)
			column.filter = options.rawSearchValue ?? value;

		if(options.id !== AbstractTableComponent.PAGE_PARAMETER)
			this.executeSearch(this._prepareSearch(value, options), options);
		else {
			const page = value ?? 0;
			if(typeof page !== 'number')
				throw new Level8Error(`unexpected page format. Expected "number" got "${typeof page}"`);

			this.goToPage(page);
		}

		if(this.isInitialized && this.storeSearch) {
			const queryParams = {...this.activatedRoute.snapshot.queryParams};
			const id = `${this.tableId}.${options.id}`;
			value = options.rawSearchValue ?? value;
			if(value === '' || value == null) {
				delete this.searchingData[id];
				delete queryParams[id];
			} else {
				value = column?.serialize?.(value) ?? value;
				this.searchingData[id] = value;
				queryParams[id] = value;
			}

			const SORT_BY_KEY = `${this.tableId}.${AbstractTableComponent.SORT_BY_PARAMETER}`;
			const SORT_ASCENDING_KEY = `${this.tableId}.${AbstractTableComponent.SORT_ASCENDING_PARAMETER}`;

			delete queryParams[SORT_ASCENDING_KEY];
			delete queryParams[SORT_BY_KEY];

			if(this.sortBy != null) {
				if(this.sortBy !== this.sortByStartValue)
					queryParams[SORT_BY_KEY] = this.sortBy;

				if(this.sortAscending === false)
					queryParams[SORT_ASCENDING_KEY] = this.sortAscending;
			}

			const pageId = `${this.tableId}.${AbstractTableComponent.PAGE_PARAMETER}`;
			if(options.page !== undefined && queryParams[pageId] !== options.page)
				queryParams[pageId] = options.page;

			if(queryParams[pageId] === 0)
				delete queryParams[pageId];

			await this.router.navigate(
				[],
				{
					relativeTo: this.activatedRoute,
					replaceUrl: true,
					queryParams,
				},
			);
		}
	}

	getHeaderTemplate(id: string): TemplateRef<TableHeaderData<TableEntry>> | undefined {
		let template = this.columnHeaderViewTemplates?.find(e => e.header === id)?.template;
		template ??= this.columnHeaderContentTemplates?.find(e => e.header === id)?.template;

		return template;
	}

	getSearchHeaderTemplate(id: string): TemplateRef<TableSearchHeaderData<TableEntry, unknown>> | undefined {
		let template = this.columnSearchHeaderViewTemplates?.find(e => e.searchHeader === id)?.template;
		template ??= this.columnSearchHeaderContentTemplates?.find(e => e.searchHeader === id)?.template;

		return template;
	}

	getContentTemplate(id: string): TemplateRef<TableColumnData<TableEntry>> | undefined {
		let template = this.columnContentViewTemplates?.find(e => e.column === id)?.template;
		template ??= this.columnContentContentTemplates?.find(e => e.column === id)?.template;

		return template;
	}

	protected getTableId(): string {
		const element = this.elementRef.nativeElement.parentNode;
		const name = ((element.id != null && element.id !== '') ? element.id : element.tagName).toLowerCase();

		return StringHelper.fromKebabCase(name).toCamelCase();
	}

	protected parseHeaders(headers: MinimalColumns<TableEntry>): Record<string, Column<TableEntry>> {
		const headersOut: Record<string, Column<TableEntry>> = {};

		for(const [id, column] of Object.entries(headers)) {
			if(column == null)
				continue;

			const defaultValues = {
				inMenu:               true,
				isVisible:            true,
				contentTemplate:      (() => this.getContentTemplate(id)),
				headerTemplate:       (() => this.getHeaderTemplate(id)),
				searchHeaderTemplate: (() => this.getSearchHeaderTemplate(id)),
			};
			const out: Column<TableEntry> = {...defaultValues, ...column};
			if(column.isSearchable ?? true) {
				out.onSearch = async (value, options) => {
					options.rawSearchValue = value;
					value = (column.prepareSearch != null) ? await column.prepareSearch(value, options) : value;
					return this.search(value, options);
				};
			}

			headersOut[id] = out;
		}

		return headersOut;
	}

	protected reload(): void | Promise<void> {
		return this.search(this._displayPage, {id: AbstractTableComponent.PAGE_PARAMETER});
	}

	protected abstract goToPage(page: number): void | Promise<void>;

	protected abstract _prepareSearch(value: unknown, options: SearchOptions): unknown;

	protected abstract executeSearch(value: unknown, options: SearchOptions): void | Promise<void>;
}
