import {PercentPipe} from '@angular/common';
import {
	inject,
	Pipe,
	PipeTransform,
} from '@angular/core';
import {ShortCurrencyPipe} from '@app/main';
import {TranslateService} from '@ngx-translate/core';
import {ObservedValueOf} from 'rxjs';
import {CalculationModel} from '../models/calculation/calculation.model';

type InputType = ObservedValueOf<ReturnType<CalculationModel['calculatePriceStandardSchema']>>;

@Pipe({
	name:       'formatCalculationStandardSchema',
	standalone: true,
})
export class FormatCalculationStandardSchemaPipe implements PipeTransform {
	protected readonly currencyPipe = inject(ShortCurrencyPipe);
	protected readonly percentPipe = inject(PercentPipe);
	protected readonly translate = inject(TranslateService);

	transform(value: InputType | null): string | null {
		if(value == null)
			return null;


		const listPrice = this.getListPrice(value);
		const labourCosts = this.getLabourCosts(value);

		if(typeof listPrice === 'number') {
			if(typeof labourCosts === 'number')
				return this.currencyPipe.transform(listPrice + labourCosts);

			if(listPrice === 0)
				return labourCosts ?? '';


			let output = this.currencyPipe.transform(listPrice);
			if(labourCosts != null)
				output += ` + ${labourCosts}`;

			return output;
		}

		const addition = this.getAddition(value);
		const result = [
			listPrice,
			addition != null ? this.percentPipe.transform(addition) : null,
			typeof labourCosts === 'number' ? this.currencyPipe.transform(labourCosts) : labourCosts,
		].filter(item => item !== null);

		return result.join(' + ');
	}

	protected getListPrice(value: NonNullable<InputType>): string | null | number {
		if(value.listPrice === null)
			return this.translate.instant('calculation.listPriceShort');

		if(value.listPrice === false)
			return null;

		if(typeof value.addition === 'number')
			return value.listPrice * (1 + value.addition);

		return value.listPrice;
	}

	protected getAddition(value: NonNullable<InputType>): number | null {
		if(value.addition === false)
			return null;

		return value.addition;
	}

	protected getLabourCosts(value: NonNullable<InputType>): number | null | string {
		if(value.labourCosts === false)
			return null;

		if(value.labourCosts === 0)
			value.labourCosts = null;

		if(typeof value.labourCosts === 'number')
			return value.labourCosts;

		let hourlyBillingRate = (value.hourlyBillingRateBracket === false) ? null : this.currencyPipe.transform(value.hourlyBillingRateBracket * 60);
		if(hourlyBillingRate !== null)
			hourlyBillingRate += '/h';

		const stringLabourCosts = this.translate.instant('calculation.task');
		return `${stringLabourCosts} * ${hourlyBillingRate}`;
	}

}
