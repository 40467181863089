import {
	animate,
	AUTO_STYLE,
	state,
	style,
	transition,
	trigger,
} from '@angular/animations';
import {
	NgIf,
	NgTemplateOutlet,
} from '@angular/common';
import {
	Component,
	ContentChild,
	ElementRef,
	EventEmitter,
	inject,
	Input,
	Output,
	ViewChild,
} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {
	MatCard,
	MatCardContent,
	MatCardHeader,
} from '@angular/material/card';
import {
	MatAccordion,
	MatExpansionPanel,
	MatExpansionPanelHeader,
	MatExpansionPanelTitle,
} from '@angular/material/expansion';
import {IconService} from '@app/main';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';
import {TranslateModule} from '@ngx-translate/core';
import {CalculationSectionHeaderTemplateDirective} from './calculation-section-header-template.directive';
import {CalculationSectionHeaderTitleTemplateDirective} from './calculation-section-header-title-template.directive';

@Component({
	selector:    'portal-calculation-show-section',
	standalone:  true,
	imports: [
		MatCard,
		MatCardContent,
		MatCardHeader,
		TranslateModule,
		FaIconComponent,
		MatAccordion,
		MatExpansionPanel,
		MatExpansionPanelHeader,
		MatExpansionPanelTitle,
		NgTemplateOutlet,
		NgIf,
	],
	animations:  [
		trigger('collapse', [
			state('open', style({
				height: AUTO_STYLE,
			})),
			state('closed', style({
				height: '0',
			})),
			transition('open <=> closed', animate(`300ms ease-in`)),
		]),
	],
	templateUrl: './calculation-show-section.component.html',
	styleUrl:    './calculation-show-section.component.scss',
})
export class CalculationShowSectionComponent {
	@Input({required: true}) headline!: string;
	@Input({transform: (value: '' | boolean) => value !== false}) stickyHeader = false;
	@Input({transform: (value: '' | boolean) => value !== false}) collapsed = false;
	@Output() collapsedChange = new EventEmitter<boolean>();
	@ContentChild(CalculationSectionHeaderTemplateDirective) protected readonly headerTemplate?: CalculationSectionHeaderTemplateDirective;
	@ContentChild(CalculationSectionHeaderTitleTemplateDirective) protected readonly headerTitleTemplate?: CalculationSectionHeaderTitleTemplateDirective;
	@ViewChild('ngContents', {static: false}) protected readonly ngContent?: ElementRef<HTMLElement>;
	protected readonly iconService = inject(IconService);

	constructor() {
		this.collapsedChange.pipe(
			takeUntilDestroyed(),
		).subscribe(collapsed => this.collapsed = collapsed);
	}

	protected _isCollapsable?: boolean;
	get isCollapsable(): boolean {
		if(this._isCollapsable != null)
			return this._isCollapsable;

		if(this.ngContent)
			return this.ngContent.nativeElement.children.length > 0;

		return true;
	}

	@Input()
	set isCollapsable(collapsable: boolean) {
		this._isCollapsable = collapsable;
	}
}
