<div
	mat-dialog-title
	class="dialog-title logo"
>
	<fa-icon
		class="dialog-icon"
		[icon]="icon"
	></fa-icon>
	<div class="title-text">{{ headline | translate }}</div>
</div>

<mat-dialog-content class="scroll-shadows dialog-content">
	<div
		class="content-wrapper"
		[class.grid-2]="enableContentGrid"
	>
		<ng-container
			[ngTemplateOutlet]="content"
			[ngTemplateOutletContext]="{$implicit: control}"
		/>
	</div>
</mat-dialog-content>

<mat-dialog-actions class="actionbar">
	<div class="actions">
		@if (cancelText) {
			<button
          #cancelButton
					class="left"
					mat-stroked-button
					(document:keydown.escape)="cancelButton.focus();$event.preventDefault();"
					(click)="cancel()"
			>
				{{ cancelText | translate }}
			</button>
		}
		
		<portal-spinning-button
        #acceptButton
				[processing]="isSaving"
				class="right"
				(document:keydown.control.enter)="acceptButton.focus();$event.preventDefault();"
				(document:keydown.meta.enter)="acceptButton.focus();$event.preventDefault();"
				(clicked)="accept()"
		>
			{{ acceptText | translate }}
		</portal-spinning-button>
	</div>
	
	@if ((error?.() ?? errorHasOccurred); as error) {
		<portal-form-error
			[error]="error"
			[control]="control"
			(retrySave)="onErrorResolved($event)"
		/>
	}
</mat-dialog-actions>
