import {
	Component,
	Input,
	OnChanges,
	SimpleChanges,
} from '@angular/core';
import {ModelHelper} from '@app/main';
import {
	ContractModel,
	ContractSectionModel,
	ExternalContractNumberModel,
	ExternalContractNumberType,
	MasterContractModel,
} from '@contracts/frontend-api';
import {Observable} from 'rxjs';
import {
	map,
	mergeMap,
} from 'rxjs/operators';
import {BaseContractListComponent} from 'src/app/contracts/components/base-contract/list/base-contract-list.component';


@Component({
	selector:    'portal-base-contract-show-external-contract-number',
	templateUrl: './base-contract-show-external-contract-number.component.html',
	styleUrls:   ['./base-contract-show-external-contract-number.component.scss'],
})
export class BaseContractShowExternalContractNumberComponent implements OnChanges {
	@Input({required: true}) model!: MasterContractModel | ContractModel | ContractSectionModel;
	@Input({required: true}) type!: ExternalContractNumberType;
	externalContractNumbers!: Observable<ExternalContractNumberModel[] | undefined>;

	ngOnChanges(changes: SimpleChanges): void {
		if(this.type === ExternalContractNumberType.legs){
			this.externalContractNumbers = BaseContractListComponent.getExternalContractNumbers$(this.model, this.type).pipe(
				mergeMap(numbers => ModelHelper.getModelPropertyValues(numbers, 'type', 'number')),
				map(x => x?.filter(n => n.type === this.type)),
				map(x => x?.map(n => n.number)),
			);
		}

		if(this.type === ExternalContractNumberType.mip){
			let ecnList;
			if(this.model instanceof MasterContractModel)
				ecnList = this.model.externalContractNumbers.value;
			else
				ecnList = this.model.externalContractNumbers.withParent.value;

			this.externalContractNumbers = ModelHelper.getModelPropertyValues(ecnList, 'type', 'number').pipe(
				map(x => x?.filter(n => n.type === this.type)),
				map(x => x?.map(n => n.number)),
			);
		}
	}
}
