<mat-card>
	@if (asReadMarkable) {
		<a class="did-read clickable" (click)="markedAsRead ? hide() : markAsRead()">
			@if (!markedAsRead) {
				<fa-icon class="not-hover" [icon]="iconService.VISIBLE"></fa-icon>
				<fa-icon class="hover" [icon]="iconService.HIDDEN"></fa-icon>
			}
			@if (markedAsRead) {
				<fa-icon [icon]="iconService.CLOSE"></fa-icon>
			}
		</a>
	}

	@if (!markedAsRead) {
		<mat-card-header class="logo" [class.highlight]="(news.importance.value | async) === 'high'">
			<mat-card-title class="mat-body">{{ news.title.value | async }}</mat-card-title>
			@if ((news.displayStartAt.value | async);as displayStartAt) {
				<span class="date-container">{{ displayStartAt | date }}</span>
			}
		</mat-card-header>
	}
	<mat-card-content class="mat-body-2">
		@if (!markedAsRead) {
			<div [innerHTML]="news.text.value | async | bypassSecurityTrustResource"></div>
		}
		@if (markedAsRead) {
			<p>
				{{ 'news.markedAsRead' | translate }}
				<a (click)="markAsUnread()" class="clickable">{{ 'news.markedAsReadUndo' | translate }}</a>
			</p>
		}
	</mat-card-content>
</mat-card>
