export function deepEquals(obj1: unknown, obj2: unknown): boolean {
	if(obj1 === obj2)
		return true;

	if(typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null)
		return false;

	const keys1 = Object.keys(obj1);
	const keys2 = Object.keys(obj2);

	if(keys1.length !== keys2.length)
		return false;

	for(const key of keys1) {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		if(!obj2.hasOwnProperty(key) || !deepEquals(obj1[key], obj2[key]))
			return false;
	}

	return true;
}


export function empty(value: unknown[] | object | null | undefined | string): boolean {
	if(value == null)
		return true;

	if(Array.isArray(value))
		return value.length === 0;

	if(typeof value === 'object')
		return Object.keys(value).length === 0;

	if(typeof value === 'string')
		return value === '';

	return false;
}