import {Level8Error} from '@angular-helpers/frontend-api';
import {
	Component,
	inject,
	Input,
	OnInit,
} from '@angular/core';
import {environment} from '@app/environment';
import {
	IconService,
	MinimalColumns,
} from '@app/main';
import {NewsModel} from '@contracts/frontend-api';
import {UserNewsService} from './news.service';

@Component({
	selector:    'portal-news-list-page',
	templateUrl: './news-list-page.component.html',
	styleUrls:   ['./news-list-page.component.scss'],
	providers: [UserNewsService],
})
export class NewsListPageComponent implements OnInit {
	@Input() textPreviewLength = 80;
	headers: MinimalColumns<NewsModel> = {
		displayStartAt: {
			label:     'news.displayStartAt',
			index:     1,
			isVisible: true,
		},
		displayEndAt:   {
			label:     'news.displayEndAt',
			index:     2,
			isVisible: false,
		},
		// todo this column is way to big
		title: {
			label: 'news.title',
			index: 3,
		},
		// todo this column is way to small
		text:       {
			label:         'news.text',
			index:         4,
			prepareSearch: (value) => getText(value),
			isSortable: false,
		},
		importance: {
			label:        'news.importance',
			index:        5,
			isVisible:    false,
			isSearchable: false,
		},
	};
	protected readonly environment = environment;
	protected canCreate = false;
	protected readonly newsService = inject(UserNewsService);
	protected readonly iconService = inject(IconService);


	ngOnInit(): void {
		NewsModel.permissionsClass.canCreate({}).then((canCreate) => {
			this.canCreate = canCreate;
		});
	}
}

function getText(value: unknown): string {
	if(typeof value === 'string') {
		const tempDivElement = document.createElement('div');
		tempDivElement.innerHTML = value;
		return tempDivElement.textContent ?? tempDivElement.innerText;
	}

	throw new Level8Error(`Argument of unexpected type (${typeof value}): ${value}`);
}