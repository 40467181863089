<portal-headline
	label="model.calculation"
	[menu]="(isMultiView$ | async) ? undefined : menu"
	[backlink]="true"
>
	<mat-menu #menu>
		<button
			mat-menu-item
			(click)="showExportDialog()"
		>
			<fa-icon
				matMenuItemIcon
				[icon]="iconService.EXPORT"
			/>
			<span>{{ 'calculation.export' | translate }}</span>
		</button>
	</mat-menu>
</portal-headline>

<mat-card
	id="root"
	appearance="raised"
	[class.isCompareMode]="isMultiView$ | async"
	*ngIf="calculations$ | async as calculations"
>
	<mat-card-content
		class="wrapper"
		[style.--columns]="calculations.length"
		[style.--height-calculation-header-row]="headerHeight +'px'"
	>
		<section
			cdkDropList
			cdkDropListOrientation="horizontal"
			(cdkDropListDropped)="setPosition($event.previousIndex, $event.currentIndex)"
			class="headers"
			id="head"
      #head
		>
			<div class="col-0 cell onlyCompare"></div>
			
			@for (calculation of calculations; track calculation.id) {
				<header
					[cdkDragDisabled]="!(isMultiView$ | async)"
					cdkDragBoundary=".headers"
					cdkDrag
					cdkDragLockAxis="x"
					cdkDragPreviewContainer="parent"
					class="cell multiView"
				>
					<mat-card appearance="outlined">
						<mat-card-content class="head">
							<fa-icon
								matRipple
								[icon]="iconService.PIN"
								class="clickable onlyCompare"
							/>
							<span cdkDragHandle>{{ calculation.name.value | async }}</span>
							<fa-icon
								matRipple
								[icon]="iconService.CLOSE"
								class="clickable onlyCompare"
								(click)="remove(calculation)"
							/>
						</mat-card-content>
					</mat-card>
					
					<div class="baseInfos">
						@if (calculation.identifierExtension.value | async; as identifierExtension) {
							<mat-card
								appearance="outlined"
								class="identifierExtension"
							>
								<mat-card-content>
									{{ identifierExtension | formatIdentifierExtension }}
								</mat-card-content>
							</mat-card>
						}
						
						@if (calculation.productSpecialityNumber.value | async; as productSpecialityNumber) {
							<mat-card
								appearance="outlined"
								class="productSpecialityNumber"
							>
								<mat-card-content>
									{{ productSpecialityNumber | formatProductSpecialityNumber }}
								</mat-card-content>
							</mat-card>
						}
						
						@if (calculation.aidIdentifiers.value | async; as aidIdentifiers) {
							<mat-card
								appearance="outlined"
								class="aidIdentifier"
							>
								<mat-card-content>
									@for (aidIdentifier of aidIdentifiers; track aidIdentifier; let isLast = $last) {
										<span>
											{{ aidIdentifier.toString().padStart(2, '0') }}                  |                  {{ aidIdentifier | translatePrefixed: 'calculation.aidIdentifierValues' }}
										</span>
										<br *ngIf="!isLast"/>
									}
								</mat-card-content>
							</mat-card>
						}
						
						@if (calculation.unit.value | async; as unit) {
							<mat-card
								appearance="outlined"
								class="unit"
							>
								<mat-card-content>
									{{ unit | translatePrefixed: 'calculation.unitValues' }}
								</mat-card-content>
							</mat-card>
						}
						
						@if (calculation.careScope.value | async) {
							<mat-card
								appearance="outlined"
								class="careScope"
							>
								<mat-card-content>{{ calculation.careScope.value | async }}</mat-card-content>
							</mat-card>
						}
					</div>
				</header>
			}
			
			<div class="cell add-calculation">
				<button
					mat-stroked-button
					color="primary"
					(click)="openSelectCalculationsDialog()"
				>
					<fa-icon [icon]="iconService.ADD"></fa-icon>
				</button>
			</div>
		</section>
		
		@for (section of sections; track section) {
			@switch (section) {
				@case ("indication") {
					<portal-calculation-show-section
						headline="hmv.indication"
						id="indication"
						stickyHeader
						collapsed
						*ngIf="(hasIndications$ | async) !== false"
					>
						<div class="col-0 cell onlyCompare"></div>
						@for (calculation of calculations; track calculation.id; let i = $index) {
							<span
								class="textbox cell multiView"
								[class.col-0]="!(isMultiView$ | async)"
							>
								<portal-skeleton-field [content]="findIndication(calculation)">
									<ng-template
                      let-indication
											skeletonContent
									>{{ indication | trim }}</ng-template>
								</portal-skeleton-field>
			        </span>
						}
						<div class="hover cell"></div>
					</portal-calculation-show-section>
				}
				@case ("characteristic") {
					<portal-calculation-show-section
						headline="hmv.characteristic"
						id="characteristic"
						stickyHeader
						collapsed
						*ngIf="(hasCharacteristics$ | async) !== false"
					>
						<div class="col-0 cell onlyCompare"></div>
						@for (calculation of calculations; track calculation.id) {
							<span
								class="textbox cell multiView"
								[class.col-0]="!(isMultiView$ | async)"
							>
								<portal-skeleton-field [content]="findCharacteristic(calculation)">
                  <ng-template
                      let-characteristic
		                  skeletonContent
                  >{{ characteristic | trim }}</ng-template>
                </portal-skeleton-field>
              </span>
						}
						<div class="hover cell"></div>
					</portal-calculation-show-section>
				}
				@case ("serviceScope") {
					<portal-calculation-show-section
						headline="calculation.serviceScope"
						id="serviceScope"
						stickyHeader
						*ngIf="(hasServiceScopes$ | async) !== false"
					>
						<div class="col-0 cell onlyCompare"></div>
						@for (calculation of calculations; track calculation.id) {
							<span
								class="textbox cell multiView"
								[class.col-0]="!(isMultiView$ | async)"
							>{{ calculation.serviceScope.value | async }}</span>
						}
						<div class="hover cell"></div>
					</portal-calculation-show-section>
				}
				@case ("hint") {
					<portal-calculation-show-section
						headline="calculation.hint"
						id="hint"
						stickyHeader
						collapsed
						*ngIf="(hasHint$ | async) !== false"
					>
						<div class="col-0 cell onlyCompare"></div>
						@for (calculation of calculations; track calculation.id) {
							<div
								class="textbox cell multiView"
								[class.col-0]="!(isMultiView$ | async)"
								[innerHTML]="calculation.hint.value | async | bypassSecurityTrustResource"
							></div>
						}
						<div class="hover cell"></div>
					</portal-calculation-show-section>
				}
				@case ("possibleExtensions") {
					<portal-calculation-show-section
						headline="calculation.possibleExtensions"
						id="possibleExtensions"
						stickyHeader
						collapsed
						*ngIf="(possibleExtensions$ | async)?.length ?? -1 > 0"
					>
						@if ((possibleExtensions$ | async); as possibleExtensions) {
							@for (possibleExtension of possibleExtensions; track possibleExtension.id) {
								<div class="cell flex-row col-0 multiView">
									<div class="shrink-column">
										<span
											class="ellipsis"
											*ngLet="(possibleExtension.name.value | async) as name"
											[title]="name"
										>{{ name }}</span>
										<span class="additionalInfo">{{ possibleExtension.identifierExtension.value | async }}</span>
									</div>
								</div>
								@for (calculation of calculations; track calculation.id) {
									<div class="cell data center onlyCompare">
										@if ((calculation.possibleExtensions.value | async)?.includes(possibleExtension)) {
											<fa-icon [icon]="ICON_POSSIBLE_EXTENSION_EXISTS"/>
										} @else {
											<span></span>
										}
									</div>
								}
								
								<div class="hover cell"></div>
							}
						}
					</portal-calculation-show-section>
				}
				@case ("fixedPrice") {
					<portal-calculation-show-section
						headline="calculation.fixedPrice"
						id="fixedPrice"
						stickyHeader
						*ngIf="((fixedPrices$ | async)?.length ?? 0) > 0"
					>
						<ng-template CalculationSectionHeaderTitle>
							<a
								class="headerAdditionalInfo"
								href="https://www.gkv-spitzenverband.de/krankenversicherung/hilfsmittel/festbetraege_3/festbetraege.jsp"
							>
							<fa-icon
								[icon]="iconService.EXTERNAL_LINK"
								size="2xs"
							/>
							</a>
						</ng-template>
						<ng-template CalculationSectionHeader>
							@for (calculation of calculations; track calculation.id) {
								<portal-skeleton-field [content]="(calculation.fixedPrice.value | async)?.price?.value">
									<ng-template
										skeletonContent
                    let-fixedPrice
									>
										<div
											class="header-cell span-content"
											*ngIf="fixedPrice"
										>
											<span class="price">{{ fixedPrice | shortCurrency }}</span>
										</div>
									</ng-template>
								</portal-skeleton-field>
							}
						</ng-template>
					</portal-calculation-show-section>
				}
				@case ("price") {
					<portal-calculation-show-section
						headline="calculation.price"
						id="price"
						stickyHeader
						collapsed
					>
						<ng-template CalculationSectionHeader>
							@for (calculation of calculations; track calculation.id) {
								<div
									class="header-cell span-content column"
									*ngLet="(calculation.priceSchema.value | async) as schema"
								>
									@switch (schema?.schema) {
										@case (null) {
											<portal-loading/>
										}
										@case ('COST_ESTIMATE') {
											<span>{{ 'calculation.schema.COST_ESTIMATE' | translate }}</span>
										}
										@case ('STANDARD') {
											<span>{{ calculation.calculatePriceStandardSchema() | async | formatCalculationStandardSchema }}</span>
										}
										@case ('STAGGERED') {
											<div class="flex-column">
												<!-- todo: sort entries -->
												@for (entry of schema.parameters; track entry) {
													<span *ngLet="{from: entry.lowerLimit | shortCurrency, to:entry.upperLimit | shortCurrency} as format">
														@if (entry.lowerLimit != null) {
															@if (entry.upperLimit != null) {
																{{ 'calculation.calculationSchema.staggered.range'  | translate: format }}
															} @else {
																{{ 'calculation.calculationSchema.staggered.startingFrom'  | translate: format }}
															}
														} @else {
															{{ 'calculation.calculationSchema.staggered.upTo'  | translate: format }}
														}
														@if (entry.type === 'EURO') {
															{{ entry.value | shortCurrency }}
														} @else {
															{{ entry.value | percent }}
														}
												</span>
												}
											</div>
										}
										@case ('FIXED') {
											<span>{{ schema.parameters.value | shortCurrency }}</span>
										}
										@case ('DISCOUNT') {
											<span>VK - {{ schema.parameters | percent }}</span>
										}
										@default {
											<span [style.background-color]="'red'">MISSING CALCULATION SCHEMA "{{ schema?.type | json }}"</span>
										}
									}
								</div>
							}
						</ng-template>
					</portal-calculation-show-section>
				}
				@case ("materials") {
					<portal-calculation-show-section
						headline="calculation.materials"
						id="materials"
						stickyHeader
						collapsed
						*ngIf="((materials$ | async)?.length ?? -1) !== 0"
					>
						<ng-template CalculationSectionHeader>
							@for (calculation of calculations; track calculation.id) {
								<div class="span-2 header-cell cell-left"></div>
								<div class="header-cell cell-center align-right">
									{{ calculation.calculateTotalMaterialPrice() | async | shortCurrency }}
								</div>
								<div class="header-cell cell-right"></div>
							}
						</ng-template>
						@if ((materials$ | async); as materials) {
							@for (material of materials; track material.id) {
								<div class="cell col-0">
									<div class="shrink-column">
										<span
											class="ellipsis"
											*ngLet="(material.name.value | async) as name"
											[title]="name"
										>{{ name }}</span>
									</div>
									<span class="price additionalInfo">{{ material.price.value | async | currency }} /</span>
									<span class="unit additionalInfo">{{ material.unit.value | async | translatePrefixed: 'material.unitShort' }}</span>
								</div>
								@for (calculation of calculations; track calculation.id) {
									<ng-container
										class="cell data"
										*ngLet="(calculation.materials.value | async) as materials"
									>
										@if (findModel(materials, material); as entry) {
											<ng-container *ngIf="getFactor(entry.amount, material.unit) | async as factor">
												<span class="cell cell-center align-right amount">{{ factor.amount | number }}</span>
												<span class="cell cell-center unit">{{ factor.unit | translatePrefixed: 'material.unitShort' }}</span>
												<span class="cell cell-center align-right price additionalInfo">{{ (((material.price.value | async) ?? 0) * entry.amount) | currency }}</span>
												<span class="cell cell-right"></span>
											</ng-container>
										} @else {
											<span class="cell span-content"></span>
										}
									</ng-container>
								}
								
								<div class="hover cell"></div>
							}
						}
					</portal-calculation-show-section>
				}
				@case ("products") {
					<portal-calculation-show-section
						headline="calculation.products"
						id="products"
						stickyHeader
						collapsed
						*ngIf="((products$ | async)?.length ?? -1) !== 0"
					>
						<ng-template CalculationSectionHeader>
							@for (calculation of calculations; track calculation.id) {
								<div class="header-cell span-content">
									{{ calculation.calculateTotalProductPrice() | async | shortCurrency }}
								</div>
							}
						</ng-template>
						@if ((products$ | async); as products) {
							@for (product of products; track product.id; let $index = $index) {
								<ng-container
									*ngLet="{
										name: product.name.value | async,
										unit: product.unit.value | async,
										identifierExtension: product.identifierExtension.value | async
									} as data"
								>
									
									<div class="cell col-0">
										<div class="shrink-column">
											<span
												class="ellipsis"
												[title]="data.name"
											>{{ data.name }}</span>
											<span class="additionalInfo">{{ data.identifierExtension | formatIdentifierExtension }}</span>
										</div>
									</div>
									@for (calculation of calculations; track calculation.id; let $index = $index) {
										<ng-container
											*ngLet="(calculation.products.value | async) as products"
										>
											@if (findModel(products, product); as entry) {
												<span class="cell cell-center align-right amount">{{ entry.amount | number }}</span>
												<span class="cell cell-center unit">{{ data.unit | translatePrefixed: 'material.unitShort' }}</span>
												<portal-skeleton-field
													*ngLet="(calculations[$index].isLeasing | async) ? (product.priceLease.value | async) : (product.priceBuy.value | async) as price"
													[loading]="price == null"
												>
													<span class="cell cell-center align-right price additionalInfo">{{ (price * entry.amount) | currency }}</span>
												</portal-skeleton-field>
												<span class="cell cell-right"></span>
											} @else {
												<span class="cell span-content"></span>
											}
										</ng-container>
									}
									
									<div class="hover cell"></div>
								</ng-container>
							}
						}
					</portal-calculation-show-section>
				}
				@case ("calculations") {
					<portal-calculation-show-section
						headline="calculation.calculations"
						id="calculations"
						stickyHeader
						collapsed
						*ngIf="((includedCalculations$ | async)?.length ?? -1) !== 0"
					>
						<ng-template CalculationSectionHeader>
							@for (calculation of calculations; track calculation.id) {
								<div class="header-cell span-content">
									{{ calculation.calculateTotalIncludedCalculationsPrice() | async | shortCurrency }}
								</div>
							}
						</ng-template>
						@if ((includedCalculations$ | async); as allIncludedCalculations) {
							@for (includedCalculation of allIncludedCalculations; track includedCalculation.id) {
								<ng-container
									*ngLet="{
										name: includedCalculation.name.value | async,
										price: includedCalculation.price.value | async,
										identifierExtension: includedCalculation.identifierExtension.value | async
									} as data"
								>
									
									<div class="cell flex-row col-0">
										<div class="shrink-column">
											<span
												class="ellipsis"
												[title]="data.name"
											>{{ data.name }}</span>
											<span class="additionalInfo">{{ data.identifierExtension | formatIdentifierExtension }}</span>
										</div>
										<div class="additionalInfo">
											<span class="price">{{ data.price | currency }}</span>
										</div>
									</div>
									@for (calculation of calculations; track calculation.id) {
										<div
											class="cell data"
											*ngLet="(calculation.calculations.value | async) as includedCalculations"
										>
											@if (findModel(includedCalculations, includedCalculation); as entry) {
												<span class="amount">{{ entry.amount | number }}</span>
												<span class="price additionalInfo">{{ (data.price * entry.amount) | currency }}</span>
											}
										</div>
									}
									
									<div class="hover cell"></div>
								</ng-container>
							}
						}
					</portal-calculation-show-section>
				}
				@case ("addition") {
					<portal-calculation-show-section
						headline="calculation.addition"
						id="addition"
						stickyHeader
						*ngIf="((additions$ | async)?.length ?? -1) !== 0"
					>
						<ng-template CalculationSectionHeader>
							@for (calculation of calculations; track calculation.id) {
								<div
									class="header-cell span-content column"
									*ngLet="(calculation.addition.value | async) as addition"
								>
									<ng-container
										*ngLet="
											addition * (
												((calculation.calculateTotalMaterialPrice() | async) ?? 0) +
												((calculation.calculateTotalProductPrice() | async) ?? 0)
											) as additionPrice
											"
									>
									<span
										class="price"
										*ngIf="additionPrice !== 0"
									>
										{{ additionPrice | shortCurrency }}
									</span>
										<span
											[class.additionalInfo]="additionPrice !== 0"
											class="amount"
										>
									{{ addition | percent }}
									</span>
									</ng-container>
								</div>
							}
						</ng-template>
					</portal-calculation-show-section>
				}
				@case ("tasks") {
					<portal-calculation-show-section
						headline="calculation.tasks"
						id="tasks"
						stickyHeader
						*ngIf="((tasks$ | async)?.length ?? -1) !== 0"
					>
						<ng-template CalculationSectionHeader>
							@for (calculation of calculations; track calculation.id) {
								<div class="header-cell span-content column">
									<span class="price">
										{{ calculation.calculateLabourCosts() | async | shortCurrency }}
									</span>
									<span class="amount additionalInfo">
										{{ calculation.calculateLabourTimes(undefined, ['VERWALTUNGSZEITEN']) | async | number }} {{ 'common.durations.minute' | translate }}                    á
										<span
											(click)="openPreferences()"
											class="clickable"
											[matTooltip]="'calculation.toSystemPreferences' | translate"
											matTooltipPosition="above"
										>{{ (calculation.hourlyBillingRateBracket.value | async | hourlyRate: true | async) | shortCurrency }}/h</span>
									</span>
								</div>
							}
						</ng-template>
						@if (groupTasksBy$ | async; as groupTasksBy) {
							@if (tasksGrouped$ | async; as tasksGrouped) {
								@for (taskGrouped of (tasksGrouped | keyvalue | sortCustom: sortTaskGroups); track taskGrouped.key) {
									<div
										class="scope"
										*ngIf="taskGrouped.value.length > 0"
									>
										<portal-calculation-show-section
											[headline]="taskGrouped.key | translatePrefixed: ('task.'+groupTasksBy)"
											collapsed
										>
											<ng-template CalculationSectionHeader>
												@for (calculation of calculations; track calculation.id) {
													<div class="cell data column span-content header-padding">
														@if (taskGrouped.value.length > 0 && (taskGrouped.value[0].type.value | async) === 'VERWALTUNGSZEITEN') {
															<span
																class="price"
																[matTooltip]="'calculation.ignoredTypes' | translate"
																matTooltipPosition="above"
															>
															{{ 0 | currency }}
														</span>
														} @else {
															<span class="price">
															{{ calculation.calculateLabourCosts(taskGrouped.key) | async | shortCurrency }}
														</span>
														}
														<span class="amount additionalInfo">
															{{ calculation.calculateLabourTimes(taskGrouped.key) | async }} {{ 'common.durations.minute' | translate }}
														</span>
													</div>
												}
											</ng-template>
											
											@for (task of taskGrouped.value; track task.id) {
												<ng-container
													*ngLet="{
													name: task.name.value | async,
													scope: task.scope.value | async,
													type: task.type.value | async,
												} as data"
												>
													<div class="cell flex-row col-0">
														<div class="shrink-column">
															<span>{{ data.name }}</span>
															@if (groupTasksBy === 'scope') {
																<span class="additionalInfo">{{ data.type | translatePrefixed: 'task.type' }}</span>
															} @else {
																<span class="additionalInfo">{{ data.scope | translatePrefixed: 'task.scope' }}</span>
															}
														</div>
													</div>
													
													@for (calculation of calculations; track calculation.id) {
														<div
															class="cell data column span-content header-padding"
															*ngLet="(calculation.taskTimes.value | async) as tasksTimes"
														>
															@if (findTaskTimeModel(tasksTimes, task); as entry) {
																<span class="amount">{{ entry.amount | number }} {{ 'common.durations.minute' | translate }}</span>
															}
														</div>
													}
													
													<div class="hover cell"></div>
												</ng-container>
											}
										</portal-calculation-show-section>
									</div>
								}
							}
						}
					
					</portal-calculation-show-section>
				}
				@default {
					<span [style.background-color]="'red'">MISSING SECTION {{ section }}</span>
				}
			}
		}
	
	</mat-card-content>
</mat-card>

<div class="padding-bottom"></div>

<ng-template
    #vatRate
    let-vatRates
>
	<span
		class="vat"
		*ngIf="vatRates"
	>
		{{ vatRates | VatRatesString }}
	</span>
</ng-template>