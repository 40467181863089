import {
	Pipe,
	PipeTransform,
} from '@angular/core';

@Pipe({
	name: 'sortCustom',
})
export class SortCustomPipe implements PipeTransform {

	transform<T>(value: T[] | undefined, sortingFunction: (a: T, b: T) => number): T[] | undefined {
		if(value == null)
			return value;

		return value.sort(sortingFunction);
	}

}
