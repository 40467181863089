import {
	Component,
	ContentChild,
	Input,
} from '@angular/core';
import {
	from,
	Observable,
	of,
} from 'rxjs';
import {tap} from 'rxjs/operators';
import {SkeletonContentDirective} from './skeleton-content.directive';

@Component({
	selector:    'portal-skeleton-field',
	templateUrl: './skeleton-field.component.html',
	styleUrls:   ['./skeleton-field.component.scss'],
})
export class SkeletonFieldComponent {
	@Input() loading        = true;
	@Input() loadingContent = 'placeholder'; // placeholder for loading size
	@ContentChild(SkeletonContentDirective) protected contentTemplate?: SkeletonContentDirective;
	protected _content$?: Observable<unknown>;
	// eslint-disable-next-line @typescript-eslint/naming-convention
	protected readonly Array = Array;
	private _content: unknown;

	@Input() set content(value: unknown | Promise<unknown> | Observable<unknown>) {
		if(this._content === value)
			return;

		this._content = value;

		this.loading   = true;
		this._content$ = this.asObservable(value)
							 .pipe(tap(() => this.loading = false));
	}

	private asObservable(value: unknown): Observable<unknown> {
		if(value == null)
			return new Observable();

		if(value instanceof Promise)
			return from(value);

		if(value instanceof Observable)
			return value;

		return of(value);
	}

}
