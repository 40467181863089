export class SaveFile {
	private objectUrl?: string;

	constructor(
		private readonly forceDownload: boolean,
		private readonly fileName: string,
		private readonly content: Blob,
	) {
	}

	static save(forceDownload: boolean, fileName: string, content: Blob): void {
		new SaveFile(forceDownload, fileName, content).save();
	}

	save(): void {
		this.cleanObjectUrl();

		const file = new File([this.content], this.fileName, {type: this.content.type});

		this.objectUrl = URL.createObjectURL(file);

		const link = document.createElement('a');
		link.href = this.objectUrl;
		link.target = '_blank';
		link.type = 'hidden';
		if(this.forceDownload)
			link.download = file.name;

		link.click();
		link.remove();

		// todo clean up after download
	}

	cancel(): void {
		this.cleanObjectUrl();
	}

	private cleanObjectUrl(): void {
		if(this.objectUrl == null)
			return;

		URL.revokeObjectURL(this.objectUrl);
		this.objectUrl = undefined;
	}
}