export const PRICE_SCHEMA_TYPES = [
	'COST_ESTIMATE',
	'STANDARD',
	'STAGGERED',
	'FIXED',
	'DISCOUNT',
] as const;
export type PriceSchemaType = typeof PRICE_SCHEMA_TYPES[number];

export type PriceSchema = PriceSchemaCostEstimate | PriceSchemaStandard | PriceSchemaStaggered | PriceSchemaFixed | PriceSchemaDiscount;

export interface PriceSchemaCostEstimate {
	schema: 'COST_ESTIMATE';
	parameters: null,
}

export interface PriceSchemaStandard {
	schema: 'STANDARD';
	parameters: {
		listPrice: boolean;
		addition: boolean;
		labourCosts: boolean;
	},
}

export interface PriceSchemaStaggeredEntry {
	lowerLimit: number | null;
	upperLimit: number | null;
	value: number;
	type: 'EURO' | 'PERCENT';
}

export interface PriceSchemaStaggered {
	schema: 'STAGGERED';
	parameters: PriceSchemaStaggeredEntry[],
}

export interface PriceSchemaFixed {
	schema: 'FIXED';
	parameters: {
		value: number;
	};
}

export interface PriceSchemaDiscount {
	schema: 'DISCOUNT';
	parameters: number;
}