import {
	BasicPermissionedProperty,
	VersionedModel,
} from '@angular-helpers/frontend-api';
import {MaterialService} from './material.service';

export const MATERIAL_UNITS = [
	'SET',
	'METER',
	'MILLILITER',
	'KILOGRAM',
	'PIECE',
	'PAIR',
	'ROLL',
	'METER_SQUARED',
] as const;
export type MaterialUnit = typeof MATERIAL_UNITS[number];

export const MATERIAL_EXTENDED_UNITS = [
	...MATERIAL_UNITS,
	'MILLIMETER',
	'CENTIMETER',
	'LITER',
	'GRAM',
	'CENTIMETER_SQUARED',
	'MILLIMETER_SQUARED',
] as const;
export type MaterialExtendedUnit = typeof MATERIAL_EXTENDED_UNITS[number];

export const MATERIAL_STATUS = [
	'AVAILABLE',
	'NOT_AVAILABLE',
] as const;
export type MaterialStatus = typeof MATERIAL_STATUS[number];

export class MaterialModel extends VersionedModel<MaterialService> {
	readonly name                = new BasicPermissionedProperty<string>('name', this);
	readonly identifierExtension = new BasicPermissionedProperty<string>('identifierExtension', this);
	readonly dimension           = new BasicPermissionedProperty<string>('dimension', this);
	readonly comment             = new BasicPermissionedProperty<string>('comment', this);
	readonly articleNumber       = new BasicPermissionedProperty<string>('articleNumber', this);
	readonly ean                 = new BasicPermissionedProperty<string>('ean', this);
	readonly manufacturer        = new BasicPermissionedProperty<string>('manufacturer', this);
	readonly unit                = new BasicPermissionedProperty<MaterialUnit>('unit', this);
	readonly price               = new BasicPermissionedProperty<number>('price', this);
	readonly value               = new BasicPermissionedProperty<string>('value', this);
	readonly availability        = new BasicPermissionedProperty<MaterialStatus>('availability', this);
	readonly hint                = new BasicPermissionedProperty<string>('hint', this);
	readonly predecessor         = new BasicPermissionedProperty<MaterialModel | null>('predecessor', this);
}