import {Level8Error} from '@angular-helpers/frontend-api';
import {
	Component,
	EventEmitter,
	inject,
	Input,
	Output,
} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {FormControl} from '@angular/forms';
import {
	combineLatestSafe,
	FullColumn,
	IconService,
} from '@app/main';
import {TranslateService} from '@ngx-translate/core';
import {
	combineLatest,
	from,
	Observable,
	of,
	ReplaySubject,
} from 'rxjs';
import {
	filter,
	last,
	map,
	startWith,
	switchMap,
	toArray,
} from 'rxjs/operators';

export interface MultiSelectEntry {
	label: string;
	info: string;
}

@Component({
	selector:    'portal-table-search-multiselect',
	templateUrl: './table-search-multiselect.component.html',
	styleUrls:   ['./table-search-multiselect.component.scss'],
})
export class TableSearchMultiselectComponent<T> {
	@Output() valueChanged            = new EventEmitter<T[]>();
	protected control                 = new FormControl<T[]>([]);
	protected readonly filterControl  = new FormControl('');
	protected readonly filteredOptions$: Observable<TableSearchMultiselectComponent<T>['possibleValues']>;
	protected readonly options$       = new ReplaySubject<TableSearchMultiselectComponent<T>['possibleValues']>(1);
	protected readonly last           = last;
	private readonly translateService = inject(TranslateService);

	constructor(
		protected readonly iconService: IconService,
	) {
		this.control.valueChanges
		    .pipe(takeUntilDestroyed())
		    .subscribe(value => {
			    if(value?.length === 0)
				    value = null;

			    this.valueChanged.next(value ?? undefined);
		    });

		this.filteredOptions$ = combineLatest([
			this.filterControl.valueChanges.pipe(
				startWith(this.filterControl.value),
				map(start => start ?? this.filterControl.value ?? ''),
				map(x => x.toLowerCase()),
			),
			this.options$,
		]).pipe(
			switchMap(([input, options]) => {
				return from(Array.from(options.entries())).pipe(
					switchMap(([key, value]) => {
						const search$ = (typeof key === 'string') ?
						                this.translateService.get(key) :
						                combineLatest([
							                this.translateService.get(key.label),
							                this.translateService.get(key.info),
						                ]).pipe(map(labels => labels.join('\n')));

						return combineLatestSafe([search$, of(key)]);
					}),
					filter(([search, key]) => {
						if(typeof search !== 'string')
							throw new Error(`Expected string, got ${typeof search}`);

						return search.toLowerCase().includes(input);
					}),
					map(([, key]) => key),
					toArray(),
					map(includedKeys => new Map(Array.from(options.entries()).filter(([key]) => includedKeys.includes(key)))),
				);
			}),
		);
	}

	private _column?: FullColumn<unknown & object, T[]>;

	get column(): FullColumn<unknown & object, T[]> | undefined {
		return this._column;
	}

	@Input()
	set column(value: FullColumn<unknown & object, T[]>) {
		this._column = value;
		if(value.filter)
			this.currentValue = value.filter;
	}

	@Input({required: true})
	set possibleValues(possibleValues: ReadonlyMap<string | MultiSelectEntry, T>) {
		this.options$.next(possibleValues);
	}

	@Input() set currentValue(value: T[]) {
		this.control.setValue(
			value,
			{
				onlySelf:  true,
				emitEvent: false,
			},
		);
	}

	getKey(value: T): string {
		const entries = Array.from(this.possibleValues.entries());
		const entry   = entries.find(([key, entryValue]) => entryValue === value);
		if(entry == null)
			throw new Level8Error(`Could not find key for value ${JSON.stringify(value)}`);

		const label = entry[0];
		if(typeof label === 'string')
			return label;

		return label.label;
	}

	getLabel(value: string | MultiSelectEntry): string {
		if(typeof value === 'string')
			return value;

		return value.label;
	}

	getInfo(value: string | MultiSelectEntry): string | undefined {
		if(typeof value === 'string')
			return undefined;

		return value.info;
	}
}
