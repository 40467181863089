import {SearchEntry} from '@angular-helpers/frontend-api';
import {PageRequestOptions} from '@angular-helpers/frontend-api/lib/models/abstract-model/abstract.api-service';
import {
	CdkDrag,
	CdkDragHandle,
	CdkDropList,
	moveItemInArray,
} from '@angular/cdk/drag-drop';
import {
	AsyncPipe,
	JsonPipe,
	KeyValuePipe,
	NgIf,
} from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	inject,
} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {
	FormControl,
	FormsModule,
	ReactiveFormsModule,
	Validators,
} from '@angular/forms';
import {MatCheckbox} from '@angular/material/checkbox';
import {
	MatChip,
	MatChipInput,
	MatChipRemove,
	MatChipRow,
	MatChipsModule,
} from '@angular/material/chips';
import {
	MAT_DIALOG_DATA,
	MatDialogConfig,
	MatDialogRef,
} from '@angular/material/dialog';
import {MatDivider} from '@angular/material/divider';
import {
	MatError,
	MatLabel,
	MatSuffix,
} from '@angular/material/form-field';
import {
	MatRadioButton,
	MatRadioGroup,
} from '@angular/material/radio';
import {
	MatFormField,
	MatOption,
	MatSelect,
} from '@angular/material/select';
import {
	CALCULATION_EXPORT_FILE_TYPES,
	CalculationService,
} from '@app/calculations';
import {
	DialogService,
	empty,
	FormHelperService,
	IconService,
	MainModule,
	SaveFile,
	unique,
} from '@app/main';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';
import {
	TranslateModule,
	TranslateService,
} from '@ngx-translate/core';
import {first} from 'rxjs/operators';

export interface CalculationListExportDialogData {
	search: PageRequestOptions['search'];
}

const POSSIBLE_COLUMNS = [
	'identifierExtension',
	'productSpecialityNumber',
	'name',
	'careScope',
	'serviceScope',
	'characteristic',
	'possibleExtensions',
	'aidIdentifiers',
	'unit',
	'materials',
	'addition',
	'taskTimes.PATIENTENZEITEN',
	'taskTimes.MAS_UND_PLANUNGSZEITEN',
	'taskTimes.FERTIGUNGSZEITEN',
	'taskTimes.ANPROBE_KORREKTURZEITEN',
	'taskTimes',
	'hourlyBillingRateBracket',
	'taskTimes.price',
	'price',
	'fixedPrice',
	'vatRates',
] as const;
type PossibleColumns = typeof POSSIBLE_COLUMNS[number];

const POSSIBLE_CALCULATIONS_OPTIONS = [
	'search',
	'selected',
	'all',
] as const;
type PossibleCalculationsOptions = typeof POSSIBLE_CALCULATIONS_OPTIONS[number];

@Component({
	standalone:      true,
	imports:         [
		MainModule, MatCheckbox, CdkDropList, CdkDrag, TranslateModule, FaIconComponent, CdkDragHandle, FormsModule, AsyncPipe, JsonPipe, MatRadioGroup, MatSelect, MatOption, MatDivider, MatFormField, MatChipRow, MatChipInput, MatLabel, NgIf, ReactiveFormsModule, MatChipRemove, KeyValuePipe, MatError, MatSuffix, MatChip, MatChipsModule, MatRadioButton,
	],
	templateUrl:     './calculation-list-export-dialog.component.html',
	styleUrl:        './calculation-list-export-dialog.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalculationListExportDialogComponent {
	protected readonly translateService = inject(TranslateService);
	protected readonly calculationService = inject(CalculationService);
	protected readonly iconService = inject(IconService);
	protected readonly dialogRef = inject(MatDialogRef<this>);
	protected readonly formHelperService = inject(FormHelperService);
	protected readonly POSSIBLE_COLUMNS = [...POSSIBLE_COLUMNS]; // todo add only permitted fields
	protected possibleCalculationOptions: PossibleCalculationsOptions[];
	protected selectedCalculations: PossibleCalculationsOptions;
	protected selectedColumns = [...POSSIBLE_COLUMNS];
	protected selectedProductGroups: string[] = [];
	protected readonly selectedCalculationsInput = new FormControl('', {
		nonNullable: true,
		validators:  [Validators.required],
	});
	protected fileType: 'xlsx' | 'pdf' = 'xlsx';
	protected readonly possibleCalculationFileTypes = CALCULATION_EXPORT_FILE_TYPES;
	private readonly data = inject<CalculationListExportDialogData>(MAT_DIALOG_DATA);

	constructor() {
		this.selectedCalculationsInput.valueChanges.pipe(takeUntilDestroyed()).subscribe(value => this.parseSelectProductGroup(value));
		const hasSearch = !empty(this.data.search);
		if(!hasSearch) {
			this.possibleCalculationOptions = POSSIBLE_CALCULATIONS_OPTIONS.filter(value => value !== 'search');
			this.selectedCalculations = 'all';
		} else {
			this.selectedCalculations = 'search';
			this.possibleCalculationOptions = [...POSSIBLE_CALCULATIONS_OPTIONS];
		}
	}

	static open(dialogService: DialogService, search: CalculationListExportDialogData['search']): MatDialogRef<CalculationListExportDialogComponent> {
		const config = new MatDialogConfig<void>();
		config.autoFocus = false;
		config.minWidth = '25rem';

		return dialogService.openCustomDialog(CalculationListExportDialogComponent, {search}, config);
	}

	parseSelectProductGroup(value: string, forceAdd = false): void {
		const valueIn = value;
		value = value.replace(/[^0-9,]/g, '');
		if(value === '')
			return;

		const SEPARATOR_REGEX = /[\s,]+/;
		if(SEPARATOR_REGEX.test(value)) {
			const entries = value.split(SEPARATOR_REGEX);
			value = entries.pop() ?? '';
			entries.forEach(entry => this.selectProductGroup(entry));
		}

		while(value.length >= 2) {
			this.selectProductGroup(value.slice(0, 2));
			value = value.slice(2);
		}

		if(forceAdd) {
			this.selectProductGroup(value);
			value = '';
		}

		if(value === valueIn)
			return;

		this.selectedCalculationsInput.setValue(value);
	}

	selectProductGroup(productGroup: string): void {
		if(productGroup === '')
			return;

		if(productGroup.length < 2)
			productGroup = productGroup.padStart(2, '0');

		if(productGroup.length > 2)
			productGroup = productGroup.slice(0, 2);

		if(this.selectedProductGroups.includes(productGroup))
			return;

		this.selectedProductGroups.push(productGroup);
		this.selectedProductGroups = this.selectedProductGroups
		                                 .filter(unique)
		                                 .sort();
	}

	removeProductGroup(productGroup: string): void {
		this.selectedProductGroups = this.selectedProductGroups.filter(value => value !== productGroup);
	}

	isSelected(column: PossibleColumns): boolean {
		return this.selectedColumns.includes(column);
	}

	selectColumn(column: PossibleColumns, select?: boolean): void {
		const isSelected = this.selectedColumns.includes(column);
		select ??= !isSelected;

		if(isSelected === select) return;

		if(select)
			this.selectedColumns = [...this.selectedColumns, column];
		else
			this.selectedColumns = this.selectedColumns.filter(value => value !== column);
	}

	setColumnListPosition(previousIndex: number, currentIndex: number): void {
		moveItemInArray(this.POSSIBLE_COLUMNS, previousIndex, currentIndex);
	}

	allColumnsSelected(): boolean | null {
		if(this.selectedColumns.length === this.POSSIBLE_COLUMNS.length)
			return true;

		if(this.selectedColumns.length === 0)
			return false;

		return null;
	}

	selectAllColumns(all: boolean): void {
		if(all)
			this.selectedColumns = [...this.POSSIBLE_COLUMNS];
		else
			this.selectedColumns = [];
	}

	protected async generate(): Promise<void> {
		const getSearch = () => {
			switch(this.selectedCalculations) {
				case 'all':
					return undefined;

				case 'search':
					return this.data.search;

				case 'selected':
					return {
						column:     'identifierExtension',
						comparator: 'similar to',
						value:      `(${this.selectedProductGroups.join('|')})%`,
					} satisfies SearchEntry;
			}
		};

		const export$ = this.calculationService.generateListExport(this.selectedColumns, getSearch(), this.fileType);
		const data = await export$.pipe(first()).toPromise();

		const fileName = await this.translateService.getTranslation('model.calculations').toPromise();
		const fileNameExtended = `${fileName}.${this.fileType}`;

		SaveFile.save(false, fileNameExtended, data);
	}
}
