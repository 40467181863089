import {
	inject,
	Pipe,
	PipeTransform,
} from '@angular/core';
import {TranslatePipe} from '@ngx-translate/core';

@Pipe({
	name:       'translatePrefixed',
	standalone: true,
})
export class TranslatePrefixedPipe implements PipeTransform {
	protected readonly translatePipe = inject(TranslatePipe);

	transform<T>(value: T, prefix?: string, suffix?: string, ...args: unknown[]): string {
		if(value == null)
			return '';

		const translateString = this.prefixIt(value, prefix, suffix);
		return this.translatePipe.transform(translateString, ...args);
	}

	protected prefixIt(value: unknown, prefix?: string, suffix?: string): string {
		const data = [];
		if(prefix != null)
			data.push(prefix);

		data.push(value);

		if(suffix != null)
			data.push(suffix);

		return data.join('.');
	}

}