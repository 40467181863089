/* eslint-disable @typescript-eslint/member-ordering */
import {Injectable} from '@angular/core';
import {
	faClock,
	faCopy,
	faEnvelope,
	faEye,
	faEyeSlash,
	faFilePdf,
	faHandshake,
	faMoon,
	faQuestionCircle,
	faSquare,
	faSquareCheck,
	faSun,
	faUser,
} from '@fortawesome/free-regular-svg-icons';
import {
	faAngleDoubleLeft,
	faAngleDoubleRight,
	faAngleDown,
	faAngleLeft,
	faAngleRight,
	faAngleUp,
	faArrowDownShortWide,
	faArrowDownWideShort,
	faAsterisk,
	faBoxOpen,
	faBug,
	faCalendarAlt,
	faCertificate,
	faCheck,
	faCircleHalfStroke,
	faCloudUploadAlt,
	faCogs,
	faColumns,
	faComment,
	faDiamond,
	faDownload,
	faEllipsisV,
	faEraser,
	faExclamation,
	faExclamationTriangle,
	faExternalLink,
	faFax,
	faFile,
	faFileContract,
	faFileExport,
	faFileInvoiceDollar,
	faFolderOpen,
	faGlobe,
	faGripLines,
	faGripLinesVertical,
	faHandHoldingDollar,
	faHandshakeSlash,
	faHome,
	faHouseUser,
	faInfoCircle,
	faKey,
	faMapMarked,
	faMapMarkedAlt,
	faMapMarkerAlt,
	faNewspaper,
	faPen,
	faPencilAlt,
	faPhoneAlt,
	faPlus,
	faReceipt,
	faRotateLeft,
	faSearch,
	faSignInAlt,
	faSignOutAlt,
	faSpinner,
	faStamp,
	faSync,
	faTableCells,
	faThumbtack,
	faTimes,
	faTimesCircle,
	faTrash,
	faTrashRestore,
} from '@fortawesome/free-solid-svg-icons';

@Injectable({
	providedIn: 'root',
})
export class IconService {
	readonly SEARCH = faSearch;
	readonly SYNC = faSync;
	readonly ICON_ALERT = faExclamationTriangle;
	readonly MASTER_CONTRACT = faFileContract;
	readonly CONTRACT = faFileContract;
	readonly CONTRACT_SECTION = faFileContract;
	readonly CONTACT = faComment;
	readonly MEDICAL_STORE = faHome;
	readonly LOCATION = faMapMarkerAlt;
	readonly MAIL = faEnvelope;
	readonly FAX = faFax;
	readonly PHONE = faPhoneAlt;
	readonly WEBSITE = faGlobe;
	readonly QUESTION = faQuestionCircle;
	readonly LOADING_SPINNER = faSpinner;
	readonly EDITED_AT = faClock;
	readonly CREATE = faPlus;
	readonly EDIT = faPen;
	readonly DELETE = faTrash;
	readonly STOP_DELETE = faTrashRestore;
	readonly CANCEL = faTimesCircle;
	readonly MENU_SHOW = faEye;
	readonly COLUMN_CONFIG = faColumns;
	readonly MENU_HIDDEN = faEyeSlash;
	readonly MENU = faEllipsisV;
	readonly BOOLEAN_TRUE = faCheck;
	readonly HELP = faQuestionCircle;
	readonly DIALOG_ATTENTION = faExclamationTriangle;
	readonly DIALOG_UPDATE = faSync;
	readonly LOGIN = faSignInAlt;
	readonly LOGOUT = faSignOutAlt;
	readonly STEPPER_EDIT = faPencilAlt;
	readonly STEPPER_DONE_ALL = faCheck;
	readonly REMOVE = faTimesCircle;
	readonly FILE_SHOW = faEye;
	readonly FILE_DOWNLOAD = faDownload;
	readonly FILE_SELECT = faFolderOpen;
	readonly FILE_PDF = faFilePdf;
	readonly EMPLOYEE = faHouseUser;
	readonly CONTACT_ACCESSION = faHandshake;
	readonly CONTACT_ACCESSION_TIMINGS = faCalendarAlt;
	readonly CONTRACTING_PARTY = faFileInvoiceDollar;
	readonly PRE_QUALIFICATION_CERTIFICATE = faCertificate;
	readonly LANDESINNUNG = faMapMarked;
	readonly PROFESSIONAL_ASSOCIATION = faMapMarkedAlt;
	readonly INPUT_ERROR = faExclamation;
	readonly INPUT_VALID = faCheck;
	readonly INPUT_REQUIRED = faAsterisk;
	readonly NO_DATA = faBoxOpen;
	readonly INSTITUTIONSKENNZEICHEN = faReceipt;
	readonly ERROR = faBug;
	readonly ANGLE_RIGHT = faAngleRight;
	readonly ANGLE_DOWN = faAngleDown;
	readonly ANGLE_UP = faAngleUp;
	readonly BACK = faAngleLeft;
	readonly VISIBLE = faEye;
	readonly HIDDEN = faEyeSlash;
	readonly UPLOAD = faCloudUploadAlt;
	readonly RESIGN = faHandshakeSlash;
	readonly RESCIND = faEraser;
	readonly FIELD_DESCRIPTION = faInfoCircle;
	readonly CALCULATION_HANDBOOK = faTableCells;
	readonly MEMBER_INFO = faInfoCircle;
	readonly NEWS = faNewspaper;
	readonly HOME = faHome;
	readonly USER = faUser;
	readonly CLOSE = faTimes;
	readonly COPY = faCopy;
	readonly FILES = faFile;
	readonly CHECKBOX = faSquare;
	readonly CHECKBOX_CHECKED = faSquareCheck;
	readonly IQZ_PARTICIPATION = faStamp;
	readonly REQUIREMENTS = faCircleHalfStroke;
	readonly DARK_THEME = faMoon;
	readonly LIGHT_THEME = faSun;
	readonly GRIP_HORIZONTAL = faGripLines;
	readonly GRIP_VERTICAL = faGripLinesVertical;
	readonly PIN = faThumbtack;
	readonly ADD = faPlus;
	readonly PREFERENCES = faCogs;
	readonly PASSWORD = faKey;
	readonly SORTED_ASC = faArrowDownShortWide;
	readonly SORTED_DESC = faArrowDownWideShort;
	readonly PAGE_PREVIOUS = faAngleLeft;
	readonly PAGE_FIRST = faAngleDoubleLeft;
	readonly PAGE_LAST = faAngleDoubleRight;
	readonly PAGE_NEXT = faAngleRight;
	readonly EXPORT = faFileExport;
	readonly HOURLY_BILLING_RATE_BRACKETS = faHandHoldingDollar;
	readonly RESET = faRotateLeft;
	readonly EXTERNAL_LINK = faExternalLink;
	protected readonly PLACEHOLDER = faDiamond;
}
