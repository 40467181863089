import {
	PageRequestOptions,
	ResultPageModel,
	SearchEntry,
} from '@angular-helpers/frontend-api';
import {Injectable} from '@angular/core';
import {
	NewsModel,
	NewsService,
} from '@contracts/frontend-api';

@Injectable()
export class UserNewsService extends NewsService {
	getPage(number: number, options?: PageRequestOptions): Promise<ResultPageModel<NewsModel>> {
		const searchEntry: SearchEntry = {
			column:     'importance',
			comparator: '!=',
			value:      'system',
		};


		const isSearchEntry = (search: unknown): search is SearchEntry => {
			if(search == null)
				return false;

			if(typeof (search) !== 'object')
				return false;

			if('column' in search && 'comparator' in search && 'value' in search)
				return true;

			return false;
		};

		if(options == null)
			options = {};

		if(options.search == null)
			options.search = [searchEntry];
		else if(isSearchEntry(options.search))
			options.search = [options.search, searchEntry];
		else if(Array.isArray(options.search))
			options.search.push(searchEntry);
		else
			throw new Error('Invalid search options'); // not used on this position


		return super.getPage(number, options);
	}
}