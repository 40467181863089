import {
	AbstractPermissionedService,
	DtoInterface,
	ModelId,
} from '@angular-helpers/frontend-api';
import {
	Injectable,
	Injector,
} from '@angular/core';
import {FixedPriceApiService} from './fixed-price.api-service';
import {FixedPriceModel} from './fixed-price.model';
import {FixedPriceParser} from './fixed-price.parser';


@Injectable({
	providedIn: 'root',
})
export class FixedPriceService extends AbstractPermissionedService<FixedPriceApiService, FixedPriceModel> {
	constructor(apiService: FixedPriceApiService, injector: Injector) {
		super(apiService, injector);
	}

	protected createNewModel(id: ModelId): FixedPriceModel {
		return new FixedPriceModel(id, this);
	}

	protected fillModelFromDto(dto: DtoInterface, model: FixedPriceModel): void {
		return FixedPriceParser.fillModelFromDto(model, dto, this);
	}
}