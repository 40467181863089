export interface JoinedPartiesData {
	name: string;
	institutionskennzeichen: string[];
	contractor: string;
	productGroups: string[];
	terminatedAt?: Date;
	joinedRegions: string[];
}

export const joinedParties: JoinedPartiesData[] = [
	{
		name:                    'audi BKK',
		institutionskennzeichen: [
			'108534160',
		],
		contractor:              'BKK Landesverband Mitte und Bayern',
		productGroups:           [
			'01',
			'02',
			'03',
			'05',
			'08',
			'10',
			'11',
			'14',
			'15',
			'17',
			'19',
			'20',
			'21',
			'23',
			'24',
			'37',
			'38',
		],
		terminatedAt:            new Date('2024-12-31 00:00:00'),
		joinedRegions:           [
			'BY',
			'BE',
			'BB',
			'HB',
			'HH',
			'MV',
			'NI',
			'NW',
			'RP',
			'SL',
			'SN',
			'ST',
			'SH',
			'TH',
		],
	},
	{
		name:                    'Bertelsmann BKK',
		institutionskennzeichen: [
			'103725342',
		],
		contractor:              'GWQ ServicePlus AG',
		productGroups:           [
			'05',
			'08',
			'17',
			'23',
			'24',
			'31',
			'37',
		],
		joinedRegions:           [],
	},
	{
		name:                    'BIG direkt gesund',
		institutionskennzeichen: [
			'103501080',
		],
		contractor:              'GWQ ServicePlus AG',
		productGroups:           [
			'08',
			'31',
		],
		joinedRegions:           [],
	},
	{
		name:                    'BIG direkt gesund',
		institutionskennzeichen: [
			'103501080',
		],
		contractor:              'SpectrumK',
		productGroups:           [
			'05',
			'17',
			'23',
			'24',
			'37',
			'38',
		],
		joinedRegions:           [],
	},
	{
		name:                    'BKK Aesculap Braun',
		institutionskennzeichen: [
			'107536171',
		],
		contractor:              'BKK Landesverband Mitte und Bayern',
		productGroups:           [
			'01',
			'02',
			'03',
			'05',
			'08',
			'10',
			'11',
			'14',
			'15',
			'17',
			'19',
			'20',
			'21',
			'23',
			'24',
			'26',
			'37',
			'38',
		],
		joinedRegions:           [
			'BY',
			'BE',
			'BB',
			'HB',
			'HH',
			'MV',
			'NI',
			'NW',
			'RP',
			'SL',
			'SN',
			'ST',
			'SH',
			'TH',
		],
	},
	{
		name:                    'BKK Aesculap Braun',
		institutionskennzeichen: [
			'107536171',
		],
		contractor:              'BKK Landesverband S\u00fcd',
		productGroups:           [
			'01',
			'02',
			'05',
			'08',
			'10',
			'11',
			'17',
			'20',
			'21',
			'23',
			'24',
			'37',
			'38',
		],
		joinedRegions:           [],
	},
	{
		name:                    'BKK Aesculap Braun',
		institutionskennzeichen: [
			'107536171',
		],
		contractor:              'GWQ ServicePlus AG',
		productGroups:           [
			'08',
			'17',
		],
		joinedRegions:           [],
	},
	{
		name:                    'BKK Akzo Nobel Bayern',
		institutionskennzeichen: [
			'108833355',
		],
		contractor:              'BKK Landesverband Mitte und Bayern',
		productGroups:           [
			'01',
			'02',
			'03',
			'05',
			'08',
			'10',
			'11',
			'14',
			'15',
			'17',
			'19',
			'20',
			'21',
			'23',
			'24',
			'26',
			'37',
			'38',
		],
		joinedRegions:           [
			'BY',
			'BE',
			'BB',
			'HB',
			'HH',
			'MV',
			'NI',
			'NW',
			'RP',
			'SL',
			'SN',
			'ST',
			'SH',
			'TH',
		],
	},
	{
		name:                    'BKK Akzo Nobel Bayern',
		institutionskennzeichen: [
			'108833355',
		],
		contractor:              'BKK Landesverband S\u00fcd',
		productGroups:           [
			'01',
			'02',
			'05',
			'08',
			'10',
			'11',
			'17',
			'20',
			'21',
			'23',
			'24',
			'37',
			'38',
		],
		joinedRegions:           [],
	},
	{
		name:                    'BKK BMW',
		institutionskennzeichen: [
			'109028524',
		],
		contractor:              'GWQ ServicePlus AG',
		productGroups:           [
			'05',
			'08',
			'17',
			'23',
			'24',
			'31',
			'37',
		],
		joinedRegions:           [],
	},
	{
		name:                    'BKK Deutsche Bank AG',
		institutionskennzeichen: [
			'104224634\n104220878',
		],
		contractor:              'GWQ ServicePlus AG',
		productGroups:           [
			'05',
			'08',
			'17',
			'23',
			'24',
			'31',
			'37',
		],
		joinedRegions:           [],
	},
	{
		name:                    'BKK Diakonie',
		institutionskennzeichen: [
			'103724294\n103729701',
		],
		contractor:              'GWQ ServicePlus AG',
		productGroups:           [
			'05',
			'08',
			'17',
			'23',
			'24',
			'31',
			'37',
		],
		joinedRegions:           [],
	},
	{
		name:                    'BKK D\u00fcrkopp Adler',
		institutionskennzeichen: [
			'103724249',
		],
		contractor:              'GWQ ServicePlus AG',
		productGroups:           [
			'05',
			'08',
			'17',
			'23',
			'24',
			'31',
			'37',
		],
		joinedRegions:           [],
	},
	{
		name:                    'BKK EUREGIO',
		institutionskennzeichen: [
			'104125509',
		],
		contractor:              'BKK Landesverband Mitte und Bayern',
		productGroups:           [
			'01',
			'02',
			'03',
			'05',
			'08',
			'10',
			'11',
			'14',
			'15',
			'17',
			'19',
			'20',
			'21',
			'23',
			'24',
			'26',
			'37',
			'38',
		],
		joinedRegions:           [
			'BY',
			'BE',
			'BB',
			'HB',
			'HH',
			'MV',
			'NI',
			'NW',
			'RP',
			'SL',
			'SN',
			'ST',
			'SH',
			'TH',
		],
	},
	{
		name:                    'BKK EWE',
		institutionskennzeichen: [
			'102429648',
		],
		contractor:              'GWQ ServicePlus AG',
		productGroups:           [
			'05',
			'23',
			'24',
			'37',
		],
		joinedRegions:           [],
	},
	{
		name:                    'BKK Faber-Castell & Partner',
		institutionskennzeichen: [
			'109033393',
		],
		contractor:              'BKK Landesverband Mitte und Bayern',
		productGroups:           [
			'01',
			'02',
			'05',
			'08',
			'10',
			'11',
			'14',
			'15',
			'17',
			'19',
			'20',
			'21',
			'23',
			'24',
			'26',
			'37',
			'38',
		],
		joinedRegions:           [
			'BY',
			'BE',
			'BB',
			'HB',
			'HH',
			'MV',
			'NI',
			'NW',
			'RP',
			'SL',
			'SN',
			'ST',
			'SH',
			'TH',
		],
	},
	{
		name:                    'BKK Finanzen & Wirtschaft',
		institutionskennzeichen: [
			'105734543',
		],
		contractor:              'BKK Landesverband Mitte und Bayern',
		productGroups:           [
			'01',
			'02',
			'03',
			'05',
			'08',
			'10',
			'11',
			'14',
			'15',
			'17',
			'19',
			'20',
			'21',
			'23',
			'24',
			'26',
			'37',
			'38',
		],
		joinedRegions:           [
			'BY',
			'BE',
			'BB',
			'HB',
			'HH',
			'MV',
			'NI',
			'NW',
			'RP',
			'SL',
			'SN',
			'ST',
			'SH',
			'TH',
		],
	},
	{
		name:                    'BKK Finanzen & Wirtschaft',
		institutionskennzeichen: [
			'105734543',
		],
		contractor:              'BKK Landesverband S\u00fcd',
		productGroups:           [
			'01',
			'02',
			'05',
			'08',
			'10',
			'11',
			'17',
			'20',
			'21',
			'23',
			'24',
			'37',
			'38',
		],
		joinedRegions:           [],
	},
	{
		name:                    'BKK Finanzen & Wirtschaft',
		institutionskennzeichen: [
			'105734543',
		],
		contractor:              'SpectrumK',
		productGroups:           [
			'05',
			'23',
			'24',
			'37',
			'38',
		],
		joinedRegions:           [],
	},
	{
		name:                    'BKK firmus',
		institutionskennzeichen: [
			'103121137\n102529638',
		],
		contractor:              'GWQ ServicePlus AG',
		productGroups:           [
			'05',
			'08',
			'17',
			'23',
			'24',
			'31',
			'37',
		],
		joinedRegions:           [],
	},
	{
		name:                    'BKK Freudenberg',
		institutionskennzeichen: [
			'107036370',
		],
		contractor:              'BKK Landesverband Mitte und Bayern',
		productGroups:           [
			'01',
			'03',
			'05',
			'08',
			'14',
			'15',
			'17',
			'19',
			'20',
			'21',
			'23',
			'24',
			'37',
			'38',
		],
		joinedRegions:           [
			'BY',
			'HB',
			'BE',
			'BB',
			'HH',
			'MV',
			'NW',
			'NI',
			'RP',
			'SL',
			'SN',
			'ST',
			'SH',
			'TH',
		],
	},
	{
		name:                    'BKK Freudenberg',
		institutionskennzeichen: [
			'107036370',
		],
		contractor:              'BKK Landesverband S\u00fcd',
		productGroups:           [
			'01',
			'02',
			'05',
			'08',
			'10',
			'11',
			'17',
			'20',
			'21',
			'23',
			'24',
			'37',
			'38',
		],
		joinedRegions:           [],
	},
	{
		name:                    'BKK Gildemeister Seidensticker',
		institutionskennzeichen: [
			'103724272',
		],
		contractor:              'GWQ ServicePlus AG',
		productGroups:           [
			'31',
		],
		joinedRegions:           [],
	},
	{
		name:                    'BKK Gildemeister Seidensticker',
		institutionskennzeichen: [
			'103724272',
		],
		contractor:              'SpectrumK',
		productGroups:           [
			'05',
			'17',
			'23',
			'24',
			'37',
			'38',
		],
		joinedRegions:           [],
	},
	{
		name:                    'BKK Groz-Beckert',
		institutionskennzeichen: [
			'107835071',
		],
		contractor:              'GWQ ServicePlus AG',
		productGroups:           [
			'05',
			'08',
			'17',
			'23',
			'24',
			'31',
			'37',
		],
		joinedRegions:           [],
	},
	{
		name:                    'BKK K\u00f6nig & Bauer (KBA)',
		institutionskennzeichen: [
			'108833674',
		],
		contractor:              'BKK Landesverband Mitte und Bayern',
		productGroups:           [
			'01',
			'02',
			'03',
			'05',
			'08',
			'10',
			'11',
			'14',
			'15',
			'17',
			'19',
			'20',
			'21',
			'23',
			'24',
			'26',
			'37',
			'38',
		],
		joinedRegions:           [
			'BY',
			'HB',
			'BE',
			'BB',
			'HH',
			'MV',
			'NW',
			'NI',
			'RP',
			'SL',
			'SN',
			'ST',
			'SH',
			'TH',
		],
	},
	{
		name:                    'BKK Krones',
		institutionskennzeichen: [
			'108934142',
		],
		contractor:              'GWQ ServicePlus AG',
		productGroups:           [
			'05',
			'08',
			'17',
			'23',
			'24',
			'31',
			'37',
		],
		joinedRegions:           [],
	},
	{
		name:                    'BKK Linde',
		institutionskennzeichen: [
			'105821242\n105830517',
		],
		contractor:              'BKK Landesverband S\u00fcd',
		productGroups:           [
			'38',
		],
		joinedRegions:           [],
	},
	{
		name:                    'BKK Linde',
		institutionskennzeichen: [
			'105821242\n105830517',
		],
		contractor:              'GWQ ServicePlus AG',
		productGroups:           [
			'05',
			'08',
			'17',
			'23',
			'24',
			'37',
		],
		joinedRegions:           [],
	},
	{
		name:                    'BKK Linde',
		institutionskennzeichen: [
			'105821242\n105830517',
		],
		contractor:              'SpectrumK',
		productGroups:           [
			'08',
			'31',
		],
		joinedRegions:           [],
	},
	{
		name:                    'BKK Mahle',
		institutionskennzeichen: [
			'108036145',
		],
		contractor:              'BKK Landesverband S\u00fcd',
		productGroups:           [
			'01',
			'02',
			'05',
			'08',
			'10',
			'11',
			'17',
			'20',
			'21',
			'23',
			'24',
			'37',
			'38',
		],
		joinedRegions:           [],
	},
	{
		name:                    'BKK Melitta HMR \n',
		institutionskennzeichen: [
			'103726081',
		],
		contractor:              'BKK Landesverband Mitte und Bayern',
		productGroups:           [
			'15',
			'19',
			'20',
		],
		joinedRegions:           [
			'BY',
			'BE',
			'BB',
			'HB',
			'HH',
			'MV',
			'NI',
			'NW',
			'RP',
			'SL',
			'SN',
			'ST',
			'SH',
			'TH',
		],
	},
	{
		name:                    'BKK Melitta HMR \n',
		institutionskennzeichen: [
			'103726081',
		],
		contractor:              'GWQ ServicePlus AG',
		productGroups:           [
			'05',
			'17',
			'23',
			'24',
			'31',
			'37',
		],
		joinedRegions:           [],
	},
	{
		name:                    'BKK Melitta HMR \n',
		institutionskennzeichen: [
			'103726081',
		],
		contractor:              'SpectrumK',
		productGroups:           [
			'08',
			'31',
		],
		joinedRegions:           [],
	},
	{
		name:                    'BKK Miele',
		institutionskennzeichen: [
			'103725364',
		],
		contractor:              'BKK Landesverband Mitte und Bayern',
		productGroups:           [
			'01',
			'02',
			'03',
			'05',
			'08',
			'10',
			'11',
			'14',
			'15',
			'17',
			'19',
			'20',
			'21',
			'23',
			'24',
			'26',
			'37',
			'38',
		],
		joinedRegions:           [
			'BY',
			'BE',
			'BB',
			'HB',
			'HH',
			'MV',
			'NI',
			'NW',
			'RP',
			'SL',
			'SN',
			'ST',
			'SH',
			'TH',
		],
	},
	{
		name:                    'BKK Miele',
		institutionskennzeichen: [
			'103725364',
		],
		contractor:              'BKK Landesverband S\u00fcd',
		productGroups:           [
			'01',
			'02',
			'05',
			'08',
			'10',
			'11',
			'20',
			'21',
			'23',
			'24',
			'37',
			'38',
		],
		joinedRegions:           [],
	},
	{
		name:                    'BKK Miele',
		institutionskennzeichen: [
			'103725364',
		],
		contractor:              'GWQ ServicePlus AG',
		productGroups:           [
			'05',
			'08',
			'17',
			'23',
			'24',
			'31',
			'37',
		],
		joinedRegions:           [],
	},
	{
		name:                    'BKK mkk - meine Krankenkasse',
		institutionskennzeichen: [
			'109723913',
		],
		contractor:              'BKK Landesverband Mitte und Bayern',
		productGroups:           [
			'01',
			'02',
			'03',
			'05',
			'08',
			'10',
			'11',
			'14',
			'15',
			'17',
			'19',
			'20',
			'21',
			'23',
			'24',
			'26',
			'37',
			'38',
		],
		joinedRegions:           [
			'BY',
			'BE',
			'BB',
			'HB',
			'HH',
			'MV',
			'NI',
			'NW',
			'RP',
			'SL',
			'SN',
			'ST',
			'SH',
			'TH',
		],
	},
	{
		name:                    'BKK mkk - meine Krankenkasse',
		institutionskennzeichen: [
			'109723913',
		],
		contractor:              'BKK Landesverband S\u00fcd',
		productGroups:           [
			'01',
			'02',
			'05',
			'08',
			'10',
			'11',
			'17',
			'20',
			'21',
			'23',
			'24',
			'37',
			'38',
		],
		joinedRegions:           [],
	},
	{
		name:                    'BKK MTU',
		institutionskennzeichen: [
			'107835333',
		],
		contractor:              'GWQ ServicePlus AG',
		productGroups:           [
			'05',
			'08',
			'17',
			'23',
			'24',
			'31',
			'37',
		],
		joinedRegions:           [],
	},
	{
		name:                    'BKK Pfaff',
		institutionskennzeichen: [
			'106431572',
		],
		contractor:              'BKK Landesverband Mitte und Bayern',
		productGroups:           [
			'01',
			'02',
			'03',
			'05',
			'08',
			'10',
			'11',
			'14',
			'15',
			'17',
			'19',
			'20',
			'21',
			'23',
			'24',
			'26',
			'37',
			'38',
		],
		joinedRegions:           [
			'BY',
			'BE',
			'BB',
			'HB',
			'HH',
			'MV',
			'NI',
			'NW',
			'RP',
			'SL',
			'SN',
			'ST',
			'SH',
			'TH',
		],
	},
	{
		name:                    'BKK Pfaff',
		institutionskennzeichen: [
			'106431572',
		],
		contractor:              'BKK Landesverband S\u00fcd',
		productGroups:           [
			'01',
			'02',
			'05',
			'08',
			'10',
			'11',
			'17',
			'20',
			'21',
			'23',
			'24',
			'37',
			'38',
		],
		joinedRegions:           [],
	},
	{
		name:                    'BKK Pfalz',
		institutionskennzeichen: [
			'106431652',
		],
		contractor:              'BKK Landesverband Mitte und Bayern',
		productGroups:           [
			'01',
			'02',
			'03',
			'05',
			'08',
			'10',
			'11',
			'14',
			'15',
			'17',
			'19',
			'20',
			'21',
			'23',
			'24',
			'26',
			'37',
			'38',
		],
		joinedRegions:           [
			'BY',
			'BE',
			'BB',
			'HB',
			'HH',
			'MV',
			'NI',
			'NW',
			'RP',
			'SL',
			'SN',
			'ST',
			'SH',
			'TH',
		],
	},
	{
		name:                    'BKK Pfalz',
		institutionskennzeichen: [
			'106431652',
		],
		contractor:              'BKK Landesverband S\u00fcd',
		productGroups:           [
			'01',
			'02',
			'05',
			'08',
			'10',
			'11',
			'17',
			'20',
			'21',
			'23',
			'24',
			'37',
			'38',
		],
		joinedRegions:           [],
	},
	{
		name:                    'BKK ProVita',
		institutionskennzeichen: [
			'108591499',
		],
		contractor:              'BKK Landesverband Mitte und Bayern',
		productGroups:           [
			'01',
			'02',
			'03',
			'08',
			'10',
			'11',
			'14',
			'15',
			'17',
			'19',
			'20',
			'21',
			'24',
			'26',
			'37',
			'38',
		],
		joinedRegions:           [
			'BY',
			'BE',
			'BB',
			'HB',
			'HH',
			'MV',
			'NI',
			'NW',
			'RP',
			'SL',
			'SN',
			'ST',
			'SH',
			'TH',
		],
	},
	{
		name:                    'BKK ProVita',
		institutionskennzeichen: [
			'108591499',
		],
		contractor:              'BKK Landesverband S\u00fcd',
		productGroups:           [
			'01',
			'02',
			'05',
			'08',
			'10',
			'11',
			'17',
			'20',
			'21',
			'23',
			'24',
			'37',
			'38',
		],
		joinedRegions:           [],
	},
	{
		name:                    'BKK ProVita',
		institutionskennzeichen: [
			'108591499',
		],
		contractor:              'SpectrumK',
		productGroups:           [
			'05',
			'08',
			'17',
			'23',
			'24',
			'31',
			'37',
			'38',
		],
		joinedRegions:           [],
	},
	{
		name:                    'BKK Public',
		institutionskennzeichen: [
			'101931440',
		],
		contractor:              'SpectrumK',
		productGroups:           [
			'05',
			'17',
			'23',
			'24',
			'37',
			'38',
		],
		joinedRegions:           [],
	},
	{
		name:                    'BKK PwC',
		institutionskennzeichen: [
			'105723301',
		],
		contractor:              'BKK Landesverband S\u00fcd',
		productGroups:           [
			'01',
			'02',
			'05',
			'08',
			'10',
			'11',
			'17',
			'20',
			'21',
			'23',
			'24',
			'37',
			'38',
		],
		joinedRegions:           [],
	},
	{
		name:                    'BKK PwC',
		institutionskennzeichen: [
			'105723301',
		],
		contractor:              'SpectrumK',
		productGroups:           [
			'05',
			'08',
			'17',
			'23',
			'24',
			'31',
			'37',
			'38',
		],
		joinedRegions:           [],
	},
	{
		name:                    'BKK Rieker RICOSTA Weisser',
		institutionskennzeichen: [
			'107532042',
		],
		contractor:              'GWQ ServicePlus AG',
		productGroups:           [
			'05',
			'08',
			'17',
			'23',
			'24',
			'31',
			'37',
		],
		joinedRegions:           [],
	},
	{
		name:                    'BKK Salzgitter',
		institutionskennzeichen: [
			'101922757',
		],
		contractor:              'SpectrumK',
		productGroups:           [
			'05',
			'17',
			'23',
			'24',
			'37',
			'38',
		],
		joinedRegions:           [],
	},
	{
		name:                    'BKK Scheufelen',
		institutionskennzeichen: [
			'108035576',
		],
		contractor:              'BKK Landesverband Mitte und Bayern',
		productGroups:           [
			'01',
			'02',
			'03',
			'05',
			'08',
			'10',
			'11',
			'14',
			'15',
			'17',
			'19',
			'20',
			'21',
			'23',
			'24',
			'26',
			'37',
			'38',
		],
		joinedRegions:           [
			'BY',
			'BE',
			'BB',
			'HB',
			'HH',
			'MV',
			'NI',
			'NW',
			'SN',
			'ST',
			'SH',
			'TH',
		],
	},
	{
		name:                    'BKK Scheufelen',
		institutionskennzeichen: [
			'108035576',
		],
		contractor:              'BKK Landesverband S\u00fcd',
		productGroups:           [
			'01',
			'02',
			'05',
			'08',
			'10',
			'11',
			'17',
			'20',
			'21',
			'23',
			'24',
			'37',
			'38',
		],
		joinedRegions:           [],
	},
	{
		name:                    'BKK Schwarzwald-Baar-Heuberg',
		institutionskennzeichen: [
			'107531187',
		],
		contractor:              'BKK Landesverband S\u00fcd',
		productGroups:           [
			'01',
			'02',
			'05',
			'08',
			'10',
			'11',
			'17',
			'20',
			'21',
			'23',
			'24',
			'37',
			'38',
		],
		joinedRegions:           [],
	},
	{
		name:                    'BKK Schwarzwald-Baar-Heuberg',
		institutionskennzeichen: [
			'107531187',
		],
		contractor:              'GWQ ServicePlus AG',
		productGroups:           [
			'05',
			'08',
			'17',
			'23',
			'24',
			'31',
			'37',
		],
		joinedRegions:           [],
	},
	{
		name:                    'BKK Technoform',
		institutionskennzeichen: [
			'102031410',
		],
		contractor:              'BKK Landesverband Mitte und Bayern',
		productGroups:           [
			'01',
			'02',
			'03',
			'05',
			'08',
			'10',
			'11',
			'14',
			'15',
			'17',
			'19',
			'20',
			'21',
			'23',
			'24',
			'26',
			'37',
			'38',
		],
		joinedRegions:           [
			'RP',
			'SL',
		],
	},
	{
		name:                    'BKK Technoform',
		institutionskennzeichen: [
			'102031410',
		],
		contractor:              'SpectrumK',
		productGroups:           [
			'05',
			'17',
			'23',
			'24',
			'37',
			'38',
		],
		joinedRegions:           [],
	},
	{
		name:                    'BKK Textilgruppe HOF',
		institutionskennzeichen: [
			'108632900',
		],
		contractor:              'BKK Landesverband Mitte und Bayern',
		productGroups:           [
			'01',
			'02',
			'03',
			'05',
			'08',
			'10',
			'11',
			'14',
			'15',
			'17',
			'19',
			'20',
			'21',
			'23',
			'24',
			'26',
			'37',
			'38',
		],
		joinedRegions:           [
			'BY',
			'BE',
			'BB',
			'HB',
			'HH',
			'MV',
			'NI',
			'NW',
			'RP',
			'SL',
			'SN',
			'ST',
			'SH',
			'TH',
		],
	},
	{
		name:                    'BKK VDN',
		institutionskennzeichen: [
			'103526615',
		],
		contractor:              'SpectrumK',
		productGroups:           [
			'05',
			'08',
			'17',
			'23',
			'24',
			'31',
			'37',
			'38',
		],
		joinedRegions:           [],
	},
	{
		name:                    'BKK VerbundPlus',
		institutionskennzeichen: [
			'107832012',
		],
		contractor:              'SpectrumK',
		productGroups:           [
			'05',
			'08',
			'17',
			'23',
			'24',
			'31',
			'37',
			'38',
		],
		joinedRegions:           [],
	},
	{
		name:                    'BKK Voralb Heller',
		institutionskennzeichen: [
			'108031424',
		],
		contractor:              'GWQ ServicePlus AG',
		productGroups:           [
			'05',
			'08',
			'17',
			'23',
			'24',
			'31',
			'37',
		],
		joinedRegions:           [],
	},
	{
		name:                    'BKK Werra Meissner',
		institutionskennzeichen: [
			'105530126',
		],
		contractor:              'BKK Landesverband Mitte und Bayern',
		productGroups:           [
			'01',
			'02',
			'03',
			'05',
			'08',
			'10',
			'11',
			'17',
			'19',
			'20',
			'21',
			'23',
			'24',
			'26',
			'37',
			'38',
		],
		joinedRegions:           [
			'BY',
			'BE',
			'BB',
			'HB',
			'HH',
			'MV',
			'NI',
			'NW',
			'RP',
			'SL',
			'SN',
			'ST',
			'SH',
		],
	},
	{
		name:                    'BKK W\u00fcrth',
		institutionskennzeichen: [
			'108036577',
		],
		contractor:              'GWQ ServicePlus AG',
		productGroups:           [
			'05',
			'17',
			'23',
			'24',
			'31',
			'37',
		],
		joinedRegions:           [],
	},
	{
		name:                    'BKK ZF & Partner',
		institutionskennzeichen: [
			'107829563',
		],
		contractor:              'SpectrumK',
		productGroups:           [
			'05',
			'08',
			'17',
			'23',
			'24',
			'31',
			'37',
			'38',
		],
		joinedRegions:           [],
	},
	{
		name:                    'BKK24',
		institutionskennzeichen: [
			'102122660',
		],
		contractor:              'BKK Landesverband Mitte und Bayern',
		productGroups:           [
			'01',
			'02',
			'03',
			'08',
			'10',
			'11',
			'14',
			'15',
			'19',
			'20',
			'21',
			'26',
		],
		joinedRegions:           [
			'BY',
			'BE',
			'BB',
			'HB',
			'HH',
			'MV',
			'NI',
			'NW',
			'SN',
			'ST',
			'SH',
			'TH',
		],
	},
	{
		name:                    'BKK24',
		institutionskennzeichen: [
			'102122660',
		],
		contractor:              'BKK Landesverband S\u00fcd',
		productGroups:           [
			'01',
			'02',
			'05',
			'08',
			'10',
			'11',
			'17',
			'20',
			'21',
			'23',
			'24',
			'37',
			'38',
		],
		joinedRegions:           [],
	},
	{
		name:                    'BKK24',
		institutionskennzeichen: [
			'102122660',
		],
		contractor:              'SpectrumK',
		productGroups:           [
			'05',
			'17',
			'23',
			'24',
			'31',
		],
		joinedRegions:           [],
	},
	{
		name:                    'Bosch BKK',
		institutionskennzeichen: [
			'108036123',
		],
		contractor:              'SpectrumK',
		productGroups:           [
			'05',
			'17',
			'23',
			'24',
			'37',
			'38',
		],
		joinedRegions:           [],
	},
	{
		name:                    'Continentale BKK',
		institutionskennzeichen: [
			'103523440',
		],
		contractor:              'GWQ ServicePlus AG',
		productGroups:           [
			'05',
			'08',
			'17',
			'23',
			'24',
			'31',
			'37',
		],
		joinedRegions:           [],
	},
	{
		name:                    'DAK',
		institutionskennzeichen: [
			'105830016',
		],
		contractor:              'GWQ ServicePlus AG',
		productGroups:           [
			'05',
			'17',
			'23',
			'24',
		],
		joinedRegions:           [],
	},
	{
		name:                    'Debeka BKK',
		institutionskennzeichen: [
			'106329225',
		],
		contractor:              'BKK Landesverband Mitte und Bayern',
		productGroups:           [
			'01',
			'02',
			'03',
			'05',
			'08',
			'10',
			'11',
			'14',
			'15',
			'17',
			'19',
			'20',
			'21',
			'23',
			'24',
			'26',
			'37',
			'38',
		],
		joinedRegions:           [
			'BY',
			'BE',
			'BB',
			'HB',
			'HH',
			'MV',
			'NI',
			'NW',
			'RP',
			'SL',
			'SN',
			'ST',
			'SH',
			'TH',
		],
	},
	{
		name:                    'Debeka BKK',
		institutionskennzeichen: [
			'106329225',
		],
		contractor:              'BKK Landesverband S\u00fcd',
		productGroups:           [
			'01',
			'02',
			'05',
			'08',
			'10',
			'11',
			'17',
			'20',
			'21',
			'23',
			'24',
			'37',
			'38',
		],
		joinedRegions:           [],
	},
	{
		name:                    'Die BERGISCHE Krankenkasse',
		institutionskennzeichen: [
			'104926702',
		],
		contractor:              'GWQ ServicePlus AG',
		productGroups:           [
			'05',
			'08',
			'17',
			'23',
			'24',
			'31',
			'37',
		],
		joinedRegions:           [],
	},
	{
		name:                    'Die BERGISCHE Krankenkasse',
		institutionskennzeichen: [
			'104926702',
		],
		contractor:              'SpectrumK',
		productGroups:           [
			'37',
			'38',
		],
		joinedRegions:           [],
	},
	{
		name:                    'energie-BKK',
		institutionskennzeichen: [
			'102129930',
		],
		contractor:              'GWQ ServicePlus AG',
		productGroups:           [
			'05',
			'08',
			'17',
			'23',
			'24',
			'31',
			'37',
		],
		joinedRegions:           [],
	},
	{
		name:                    'Ernst & Young BKK',
		institutionskennzeichen: [
			'105732324',
		],
		contractor:              'GWQ ServicePlus AG',
		productGroups:           [
			'05',
			'17',
			'23',
			'24',
			'31',
			'37',
		],
		joinedRegions:           [],
	},
	{
		name:                    'Heimat Krankenkasse',
		institutionskennzeichen: [
			'103724238\n',
		],
		contractor:              'GWQ ServicePlus AG',
		productGroups:           [
			'05',
			'17',
			'23',
			'24',
			'31',
			'37',
		],
		joinedRegions:           [],
	},
	{
		name:                    'HEK-Hanseatische Krankenkasse',
		institutionskennzeichen: [
			'101570638',
		],
		contractor:              'SpectrumK',
		productGroups:           [
			'05',
			'23',
			'24',
			'37',
			'38',
		],
		joinedRegions:           [],
	},
	{
		name:                    'IKK Brandenburg und Berlin',
		institutionskennzeichen: [
			'100602360',
		],
		contractor:              'GWQ ServicePlus AG',
		productGroups:           [
			'23',
		],
		joinedRegions:           [],
	},
	{
		name:                    'IKK Classic',
		institutionskennzeichen: [
			'107202793',
		],
		contractor:              'SpectrumK',
		productGroups:           [
			'31',
		],
		joinedRegions:           [],
	},
	{
		name:                    'IKK gesund plus',
		institutionskennzeichen: [
			'101202961',
		],
		contractor:              'GWQ ServicePlus AG',
		productGroups:           [
			'08',
			'17',
			'24',
		],
		joinedRegions:           [],
	},
	{
		name:                    'IKK  S\u00fcdwest',
		institutionskennzeichen: [
			'109303301',
		],
		contractor:              'GWQ ServicePlus AG',
		productGroups:           [
			'05',
			'17',
			'23',
			'24',
			'37',
		],
		joinedRegions:           [],
	},
	{
		name:                    'KKH - Kaufm\u00e4nnische Krankenkasse',
		institutionskennzeichen: [
			'102171012',
		],
		contractor:              'SpectrumK',
		productGroups:           [
			'05',
			'23',
			'24',
			'37',
			'38',
		],
		joinedRegions:           [],
	},
	{
		name:                    'Merck BKK ',
		institutionskennzeichen: [
			'105230076',
		],
		contractor:              'GWQ ServicePlus AG',
		productGroups:           [
			'05',
			'08',
			'17',
			'23',
			'24',
			'31',
			'37',
		],
		joinedRegions:           [],
	},
	{
		name:                    'Mercedes-Benz Betriebskrankenkasse',
		institutionskennzeichen: [
			'108030775',
		],
		contractor:              'GWQ ServicePlus AG',
		productGroups:           [
			'05',
			'08',
			'17',
			'23',
			'24',
			'31',
			'37',
		],
		joinedRegions:           [],
	},
	{
		name:                    'mhplus BKK',
		institutionskennzeichen: [
			'108035612',
		],
		contractor:              'SpectrumK',
		productGroups:           [
			'05',
			'17',
			'23',
			'24',
			'37',
			'38',
		],
		joinedRegions:           [],
	},
	{
		name:                    'Novitas BKK',
		institutionskennzeichen: [
			'104491707',
		],
		contractor:              'BKK Landesverband Mitte und Bayern',
		productGroups:           [
			'01',
			'02',
			'08',
			'10',
			'11',
			'15',
			'19',
			'20',
			'21',
			'24',
			'37',
			'38',
		],
		joinedRegions:           [
			'BY',
			'BE',
			'BB',
			'HB',
			'HH',
			'MV',
			'NI',
			'NW',
			'RP',
			'SL',
			'SN',
			'ST',
			'SH',
			'TH',
		],
	},
	{
		name:                    'pronova BKK',
		institutionskennzeichen: [
			'106492393',
		],
		contractor:              'SpectrumK',
		productGroups:           [
			'05',
			'23',
			'24',
			'37',
			'38',
		],
		joinedRegions:           [],
	},
	{
		name:                    'R+V BKK',
		institutionskennzeichen: [
			'105823040',
		],
		contractor:              'BKK Landesverband Mitte und Bayern',
		productGroups:           [
			'01',
			'03',
			'05',
			'08',
			'11',
			'15',
			'17',
			'19',
			'21',
			'23',
			'24',
			'26',
			'37',
			'38',
		],
		joinedRegions:           [
			'BY',
			'BE',
			'BB',
			'HB',
			'HH',
			'MV',
			'NI',
			'NW',
			'RP',
			'SL',
			'SN',
			'ST',
			'SH',
			'TH',
		],
	},
	{
		name:                    'R+V BKK',
		institutionskennzeichen: [
			'105823040',
		],
		contractor:              'BKK Landesverband S\u00fcd',
		productGroups:           [
			'01',
			'05',
			'08',
			'11',
			'17',
			'21',
			'23',
			'24',
			'37',
			'38',
		],
		joinedRegions:           [],
	},
	{
		name:                    'Salus BKK',
		institutionskennzeichen: [
			'105330168\n105321269',
		],
		contractor:              'GWQ ServicePlus AG',
		productGroups:           [
			'05',
			'08',
			'17',
			'23',
			'24',
			'31',
			'37',
		],
		joinedRegions:           [],
	},
	{
		name:                    'SBK BKK (ehem. Siemens BKK)',
		institutionskennzeichen: [
			'108433248',
		],
		contractor:              'BKK Landesverband S\u00fcd',
		productGroups:           [
			'01',
			'03',
			'14',
			'15',
			'17',
			'21',
		],
		joinedRegions:           [],
	},
	{
		name:                    'SBK BKK (ehem. Siemens BKK)',
		institutionskennzeichen: [
			'108433248',
		],
		contractor:              'GWQ ServicePlus AG',
		productGroups:           [
			'05',
			'08',
			'17',
			'23',
			'24',
			'31',
			'37',
		],
		joinedRegions:           [],
	},
	{
		name:                    'Securvita BKK',
		institutionskennzeichen: [
			'101320032',
		],
		contractor:              'GWQ ServicePlus AG',
		productGroups:           [
			'05',
			'08',
			'17',
			'23',
			'24',
			'31',
			'37',
		],
		joinedRegions:           [],
	},
	{
		name:                    'SKD BKK',
		institutionskennzeichen: [
			'108833505',
		],
		contractor:              'GWQ ServicePlus AG',
		productGroups:           [
			'05',
			'08',
			'17',
			'23',
			'24',
			'31',
			'37',
		],
		joinedRegions:           [],
	},
	{
		name:                    'S\u00fcdzucker BKK',
		institutionskennzeichen: [
			'106936311',
		],
		contractor:              'GWQ ServicePlus AG',
		productGroups:           [
			'05',
			'08',
			'17',
			'23',
			'24',
			'31',
			'37',
		],
		joinedRegions:           [],
	},
	{
		name:                    'TUI BKK',
		institutionskennzeichen: [
			'102137985',
		],
		contractor:              'SpectrumK',
		productGroups:           [
			'05',
			'17',
			'23',
			'24',
			'37',
			'38',
		],
		joinedRegions:           [],
	},
	{
		name:                    'VIACTIV Krankenkasse',
		institutionskennzeichen: [
			'104526376\n104529255',
		],
		contractor:              'GWQ ServicePlus AG',
		productGroups:           [
			'05',
			'08',
			'17',
			'23',
			'24',
			'31',
			'37',
		],
		joinedRegions:           [],
	},
	{
		name:                    'Vivida BKK',
		institutionskennzeichen: [
			'107536262',
		],
		contractor:              'GWQ ServicePlus AG',
		productGroups:           [
			'05',
			'08',
			'17',
			'23',
			'24',
			'31',
			'37',
		],
		joinedRegions:           [],
	},
	{
		name:                    'WMF Betriebskrankenkasse',
		institutionskennzeichen: [
			'108036441',
		],
		contractor:              'BKK Landesverband Mitte und Bayern',
		productGroups:           [
			'01',
			'02',
			'03',
			'10',
			'11',
			'15',
			'19',
			'20',
			'21',
			'24',
			'26',
			'37',
			'38',
		],
		joinedRegions:           [
			'BY',
			'BE',
			'BB',
			'HB',
			'HH',
			'MV',
			'NI',
			'NW',
			'RP',
			'SL',
			'SN',
			'ST',
			'SH',
			'TH',
		],
	},
	{
		name:                    'WMF Betriebskrankenkasse',
		institutionskennzeichen: [
			'108036441',
		],
		contractor:              'SpectrumK',
		productGroups:           [
			'05',
			'08',
			'17',
			'23',
			'24',
			'31',
			'37',
			'38',
		],
		joinedRegions:           [],
	},
];