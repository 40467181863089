<table [dataSource]="(data ?? [])" [trackBy]="trackBy" mat-table>
	@if (withMenu) {
		<ng-container [matColumnDef]="MENU_COLUMN_ID">
			<mat-menu #menu="matMenu">
				<button [matMenuTriggerFor]="columnConfigMenu" mat-menu-item>
					<fa-icon matMenuItemIcon [icon]="iconService.COLUMN_CONFIG"></fa-icon>
					<span>{{ 'actions.columnConfig' | translate }}</span>
				</button>
				@if (customMenu) {
					<ng-container
						[ngTemplateOutlet]="customMenu"
					/>
				}
			</mat-menu>
			<mat-menu #columnConfigMenu="matMenu">
				@for (column of columns; track column) {
					@if (column.inMenu) {
						<button (click)="column.isVisible = !column.isVisible;" mat-menu-item>
							<fa-icon matMenuItemIcon [icon]="column.isVisible ? iconService.MENU_SHOW : iconService.MENU_HIDDEN"></fa-icon>
							<span>{{ column.label | translate }}</span>
						</button>
					}
				}
			</mat-menu>
			<th class="clickable" *matHeaderCellDef [matMenuTriggerFor]="customMenu ? menu : columnConfigMenu" mat-header-cell>
				<fa-icon [icon]="iconService.MENU"></fa-icon>
			</th>
			<td *matCellDef="let entry" mat-cell>
				@if (_getRowLink(entry); as link) {
					<a class="row-link no-link" [routerLink]="link"></a>
				}
			</td>
		</ng-container>
	}
	
	@for (column of (headers | keyvalue); track column) {
		@if (column.value != null) {
			<ng-container [matColumnDef]="column.key">
				<th
					*matHeaderCellDef
					mat-header-cell
					[class.clickable]="column.value.isSortable !== false"
					
					(click)="sortByColumn(column.key)"
				>
					@if (column.value.headerTemplate(); as template) {
						<ng-container
							[ngTemplateOutlet]="template"
							[ngTemplateOutletContext]="{column: column.value}"
						></ng-container>
					} @else {
						<fa-icon
							*ngIf="sortBy === column.key"
							class="sort-icon sortable"
							[icon]="sortAscending ? iconService.SORTED_ASC : iconService.SORTED_DESC"
						/>
						<span
							class="mat-header-cell left"
						>{{ column.value.label | translate }}</span>
					}
				</th>
				<td *matCellDef="let entry" mat-cell [attr.colspan]="isLastVisibleColumn(column.key) ? 2 : 1">
					@if (_getRowLink(entry); as link) {
						<a class="row-link no-link" [routerLink]="link"></a>
					}
					@if (column.value.contentTemplate(); as template) {
						<ng-container
							[ngTemplateOutlet]="template"
							[ngTemplateOutletContext]="{
              model: entry,
              columnId: column.key
              }"
						></ng-container>
					} @else {
						<portal-skeleton-field [content]="getValue(column.key, entry)"/>
					}
				</td>
			</ng-container>
		}
		@if (column.value != null) {
			<ng-container [matColumnDef]="column.key + SEARCH_COLUMN_SUFFIX">
				<th class="search-header-cell" *matHeaderCellDef [attr.colspan]="isLastVisibleColumn(column.key) ? 2 : 1" mat-header-cell>
					@if (column.value.searchHeaderTemplate?.(); as template) {
						<ng-container
							[ngTemplateOutlet]="template"
							[ngTemplateOutletContext]="{column: asFullColumn(column.value, column.key), search: search.bind(this)}"
						></ng-container>
					} @else {
						@if (column.value.onSearch) {
							<mat-form-field subscriptSizing="dynamic">
								@if (!column.value.filter) {
									<fa-icon [icon]="iconService.SEARCH" matPrefix/>
								} @else {
									<fa-icon matPrefix [icon]="iconService.CANCEL" class="clickable" (click)="search(column.key, column.value, '', true)"/>
								}
								<input
									[ngModel]="column.value.filter"
									matInput type="text"
									(ngModelChange)="search(column.key, column.value, $event)"
									(keyup.enter)="search(column.key, column.value, column.value.filter, true)"/>
							</mat-form-field>
						}
					}
				</th>
			</ng-container>
		}
	}
	
	<tr *matHeaderRowDef="visibleHeaderColumnIds; sticky: true" mat-header-row></tr>
	<tr class="search-header" *matHeaderRowDef="visibleSearchColumnIds" mat-header-row></tr>
	<tr *matRowDef="let entry; columns: visibleColumnIds" mat-row></tr>
</table>
<portal-loading [loading]="isLoading"/>

@if (hasNextPage || currentPage > 0) {
	<div class="paginator">
		<div class="padding"></div>
		<fa-icon [icon]="iconService.PAGE_FIRST" (click)="currentPage = 0" [class.disabled]="currentPage <= 0"/>
		<fa-icon [icon]="iconService.PAGE_PREVIOUS" (click)="displayPreviousPage()" [class.disabled]="currentPage <= 0"/>
		<small>{{ 'general.paging.currentPage' | translate : {page: currentPage + 1} }} </small>
		<fa-icon [icon]="iconService.PAGE_NEXT" (click)="displayNextPage()" [class.disabled]="!hasNextPage"/>
		<fa-icon
			[class.hidden]="lastPage == null"
			[icon]="iconService.PAGE_LAST"
			(click)="currentPage = lastPage ?? 0"
			[class.disabled]="!hasNextPage"
		/>
	</div>
}
