export const HOURLY_RATES = [
	'RATE_01',
	'RATE_02',
	'RATE_03',
	'RATE_04',
] as const;
export type HourlyRate = typeof HOURLY_RATES[number];

export const VAT_RATES = [
	'FULL',
	'REDUCED',
] as const;
export type VatRate = typeof VAT_RATES[number];

export const AID_IDENTIFIER_LEASE = 3;
export const AID_IDENTIFIERS = [
	0,
	1,
	2,
	AID_IDENTIFIER_LEASE,
	4,
	5,
	6,
	7,
	8,
	9,
	10,
	11,
	12,
	13,
	14,
	15,
	16,
	17,
	18,
	19,
	20,
] as const;
export type AidIdentifier = typeof AID_IDENTIFIERS[number];

