<portal-headline
	label="hourlyRates.preferences.title"
/>

<mat-card>
	<mat-card-content>
		<p>Hier finden Sie eine Auflistung der derzeit gültigen Stundenverrechnungssätze, die der BIV-OT in seinen Kalkulationsbeispielen verwendet und in den abgeschlossenen Verträgen verhandelt hat. Um individuell kalkulieren zu können, haben Sie
			<a
				routerLink="."
				(click)="openPreferences()"
			>hier</a>
		   die Möglichkeit, den Stundenverrechnungssatz anzupassen. Optional können Sie in der oberen rechten Ecke auf Ihren Namen klicken und das Menü zwecks Anpassung öffnen.
		</p>
	</mat-card-content>
</mat-card>

<mat-card class="scroll">
	<mat-card-content id="rates">
		<portal-table-client-side-searchable
			[headers]="tableHeaders"
			[columnLink]="undefined"
			[data]="data"
			[pageSize]="50"
		/>
	</mat-card-content>
</mat-card>
