import {
	PermissionedPropertyInterface,
	PropertyInterface,
} from '@angular-helpers/frontend-api';
import {
	Observable,
	of,
} from 'rxjs';
import {
	map,
	mergeMap,
} from 'rxjs/operators';
import {combineLatestSafe} from './combine-latest-empty-safe';


export type BasicPropertyType<Model> = Model extends PropertyInterface<infer V> ? V : never;
export type BasicPropertyObject<Model, Fields extends keyof Model> = { [P in Fields]: Model[P] extends PropertyInterface<infer V> ? V : never };

export class ModelHelper {

	static async findEditableProperty(properties: PermissionedPropertyInterface<unknown>[]): Promise<boolean> {
		const permissions$ = properties.map(property => property.permissions.canUpdate.catch(error => false));
		const permissions  = await Promise.all(permissions$);
		return permissions.includes(true);
	}


	static getPropertyValues<Model, Property extends keyof Model>(
		models: Model,
		fields: Property | Property[],
	): Observable<BasicPropertyObject<Model, Property>>;
	static getPropertyValues<Model, Property extends keyof Model>(
		models: Model[],
		fields: Property | Property[],
	): Observable<BasicPropertyObject<Model, Property>[]>;
	static getPropertyValues<Model, Property extends keyof Model>(
		models: Model | undefined,
		fields: Property | Property[],
	): Observable<BasicPropertyObject<Model, Property> | undefined>;
	static getPropertyValues<Model, Property extends keyof Model>(
		models: Model[] | undefined,
		fields: Property | Property[],
	): Observable<BasicPropertyObject<Model, Property>[] | undefined>;
	static getPropertyValues<Model, Property extends keyof Model>(
		models: Model | Model[] | undefined,
		fields: Property | Property[],
	): Observable<BasicPropertyObject<Model, Property> | BasicPropertyObject<Model, Property>[] | undefined>;
	static getPropertyValues<Model, Property extends keyof Model>(
		models: Model | Model[] | undefined,
		fields: Property | Property[],
	): Observable<BasicPropertyObject<Model, Property> | BasicPropertyObject<Model, Property>[] | undefined> {
		if(models === undefined)
			return of(undefined);

		const isArray = Array.isArray(models);
		if(!Array.isArray(models))
			models = [models];

		const models$ = models.map(model => {
			if(!Array.isArray(fields))
				fields = [fields];

			const fieldValues = fields.map(field => {
				const property = model[field];
				if(!(typeof property === 'object' && property != null && 'value' in property && property.value instanceof Observable))
					throw new Error(`field ${String(field)} is not correctly typed`);

				return property.value.pipe(
					map(value => ({[field]: value})),
				);
			});
			return combineLatestSafe(fieldValues).pipe(
				map(values => values.reduce((prev, curr) => ({...prev, ...curr}))),
			);
		});

		if(isArray)
			return combineLatestSafe(models$) as Observable<BasicPropertyObject<Model, Property>[]>;
		else
			return combineLatestSafe(models$).pipe(map(models => models[0])) as Observable<BasicPropertyObject<Model, Property>>;
	}

	static getModelPropertyValues<MFN extends PropertyKey, Model, Property extends keyof Model>(
		models: Model | Observable<Model>,
		fields: Property | Property[],
		modelFieldName: MFN,
	): Observable<BasicPropertyObject<Model, Property> & { [P in MFN]: Model }>;
	static getModelPropertyValues<MFN extends PropertyKey, Model, Property extends keyof Model>(
		models: Model[] | Observable<Model[]>,
		fields: Property | Property[],
		modelFieldName: MFN,
	): Observable<(BasicPropertyObject<Model, Property> & { [P in MFN]: Model })[]>;
	static getModelPropertyValues<MFN extends PropertyKey, Model, Property extends keyof Model>(
		models: Model[] | undefined | Observable<Model[] | undefined>,
		fields: Property | Property[],
		modelFieldName: MFN,
	): Observable<(BasicPropertyObject<Model, Property> & { [P in MFN]: Model })[] | undefined>;
	static getModelPropertyValues<MFN extends PropertyKey, Model, Property extends keyof Model>(
		models: Model | Model[] | undefined | Observable<Model | Model[] | undefined>,
		fields: Property | Property[],
		modelFieldName: MFN,
	): Observable<(BasicPropertyObject<Model, Property> & { [P in MFN]: Model }) | (BasicPropertyObject<Model, Property> & { [P in MFN]: Model })[] | undefined>;
	static getModelPropertyValues<MFN extends PropertyKey, Model, Property extends keyof Model>(
		models: Model | Model[] | undefined | Observable<Model | Model[] | undefined>,
		fields: Property | Property[],
		modelFieldName: MFN,
	): Observable<(BasicPropertyObject<Model, Property> & { [P in MFN]: Model }) | (BasicPropertyObject<Model, Property> & { [P in MFN]: Model })[] | undefined> {
		if(models === undefined)
			return of(undefined);

		if(models instanceof Observable) {
			return models.pipe(
				mergeMap(models => ModelHelper.getModelPropertyValues(models, fields, modelFieldName)),
			);
		}

		if(Array.isArray(models)) {
			// @ts-expect-error doesn't get type matching correct
			return this.getPropertyValues(models, fields).pipe(
				map(values => values.map((value, index) => ({
					...value,
					[modelFieldName]: models[index],
				}))),
			);
		}

		// @ts-expect-error doesn't get type matching correct
		return this.getPropertyValues(models, fields).pipe(
			map(value => ({
				...value,
				[modelFieldName]: models,
			})),
		);
	}
}
