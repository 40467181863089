<portal-headline
	label="general.modelList"
	[labelArgs]="{model: 'calculations'}"
	[menu]="menu"
>
	<mat-menu #menu>
		<button
			mat-menu-item
			(click)="showExportDialog()"
		>
			<fa-icon
				matMenuItemIcon
				[icon]="iconService.EXPORT"
			/>
			<span>{{ 'calculation.export' | translate }}</span>
		</button>
	</mat-menu>
</portal-headline>

<mat-accordion>
	<mat-expansion-panel>
		<mat-expansion-panel-header>
			<mat-panel-title>{{ 'calculation.infobox.title' | translate }}</mat-panel-title>
		</mat-expansion-panel-header>
		@for (text of ('calculation.infobox.text' | translate); track text) {
			<p [innerText]="text"></p>
		}
	</mat-expansion-panel>
</mat-accordion>

<portal-calculation-card-list
	(searchEvent)="search=$event"
	[label]="null"
/>