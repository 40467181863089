import {Enum} from '@angular-helpers/frontend-api';
import {
	Component,
	EventEmitter,
	inject,
	Input,
	Output,
} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {LabelSelectFieldEntry} from '../edit-field-select/edit-field-select.component';

type EnumKeys<Enum> = Exclude<keyof Enum, number>
const enumObject = <Enum extends Record<string, number | string>>(e: Enum) => {
	const copy = {...e} satisfies { [K in EnumKeys<Enum>]: Enum[K] };
	Object.values(e).forEach(value => typeof value === 'number' && delete copy[value]);
	return copy;
};

const enumKeys = <Enum extends Record<string, number | string>>(e: Enum) => {
	return Object.keys(enumObject(e)) as EnumKeys<Enum>[];
};

@Component({
	selector:    'portal-edit-field-enum',
	templateUrl: './edit-field-enum.component.html',
	styleUrls:   ['./edit-field-enum.component.scss'],
})
export class EditFieldEnumComponent {
	@Input({required: true}) label!: string;
	@Input({required: true}) valueLabelPrefix!: string;
	@Input({required: true}) enum!: Enum;
	@Input({required: true}) validator!: UntypedFormControl;
	@Output() change                    = new EventEmitter<boolean>();
	protected readonly translateService = inject(TranslateService);

	get options(): LabelSelectFieldEntry<string | number>[] {
		return enumKeys(this.enum).reduce((last, curr) => {
			last.push({
				label: this.translateService.instant(`${this.valueLabelPrefix}.${curr}`),
				value: this.enum[curr],
			});

			return last;
		}, [] as LabelSelectFieldEntry<string | number>[]);
	}
}
