<portal-base-dialog-content
	[content]="data.content"
	[headline]="data.headline"
	[icon]="data.icon"
	[cancelText]="data.cancelButtonText"
	[control]="data.control"
	[acceptText]="data.acceptText"
	[dialogRef]="dialogRef"
	[enableContentGrid]="data.enableContentGrid"
	[error]="data.error"
	[onAccept]="data.onAccept"
	[onCancel]="data.onCancel"
/>