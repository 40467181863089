import {ResultPageModel} from '@angular-helpers/frontend-api';
import {
	Component,
	OnInit,
	TemplateRef,
	ViewChild,
} from '@angular/core';
import {
	MatDialogConfig,
	MatDialogRef,
} from '@angular/material/dialog';
import {environment} from '@app/environment';
import {
	BaseDialogData,
	DialogService,
	IconService,
	InstitutionskennzeichenDownloadService,
} from '@app/main';
import {
	ContractAccessionModel,
	ContractAccessionService,
	InstitutionskennzeichenModel,
	InstitutionskennzeichenService,
} from '@contracts/frontend-api';

@Component({
	templateUrl: './contract-accession-list-page.component.html',
	styleUrls:   ['./contract-accession-list-page.component.scss'],
})
export class ContractAccessionListPageComponent implements OnInit {
	contractAccessions?: Promise<ResultPageModel<ContractAccessionModel>>;
	readonly environment                                                           = environment;
	_canCreateContractAccession?: Promise<boolean>;
	@ViewChild('exportAccessionsDialog') dialog?: TemplateRef<unknown>;
	protected selectedInstitutionsKennzeichen: InstitutionskennzeichenModel | null = null;
	protected errorOccurred?: Error;
	protected isCreatingFile                                                       = false;
	private dialogRef?: MatDialogRef<unknown>;

	constructor(
			protected readonly contractAccessionService: ContractAccessionService,
			private readonly institutionskennzeichenService: InstitutionskennzeichenService,
			public readonly iconService: IconService,
			public readonly dialogService: DialogService,
			private readonly institutionskennzeichenDownloadService: InstitutionskennzeichenDownloadService,
	) { }

	get canCreateContractAccession(): Promise<boolean> {
		if(this._canCreateContractAccession == null)
			this._canCreateContractAccession = this.calculateCanCreateContractAccession();

		return this._canCreateContractAccession;
	}

	ngOnInit(): void {
		this.contractAccessions = this.contractAccessionService.getPage(0, {pageSize: 25});
	}

	openDialog(): void {
		if(this.dialog == null)
			return;

		const config    = new MatDialogConfig<BaseDialogData>();
		config.minWidth = '40em';
		config.width    = 'max-content';
		config.data     = {
			headline:          'contractAccession.exportHeader',
			icon:              this.iconService.FILE_DOWNLOAD,
			acceptText:        'actions.export',
			cancelButtonText:  'actions.abort',
			content:           this.dialog,
			enableContentGrid: true,
			onAccept:          this.createFile.bind(this),
			onCancel:          async () => {
				this.dialogRef?.close();
			},
		};
		this.dialogRef  = this.dialogService.openBaseDialog(config);
	}

	createFile(): Promise<void> {
		const value = this.selectedInstitutionsKennzeichen;
		if(value == null)
			return Promise.resolve();

		this.isCreatingFile = true;
		return this.institutionskennzeichenDownloadService.downloadExport(value)
				   .catch(error => {
					   this.errorOccurred = error;
					   throw error;
				   }).finally(() => {
					this.isCreatingFile = false;
					this.dialogRef?.close();
				});
	}

	private async calculateCanCreateContractAccession(): Promise<boolean> {
		let page = await this.institutionskennzeichenService.getPage(0);

		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
		while(true) {
			for(const ik of page.data) {
				if(await ik.contractAccessions.permissions.canCreate)
					return true;
			}

			if(page.hasNextPage())
				page = await page.loadNextPage();
			else
				return false;
		}
	}
}
