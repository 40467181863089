import {CurrencyPipe as AngularCurrencyPipe} from '@angular/common';
import {
	Pipe,
	PipeTransform,
} from '@angular/core';

@Pipe({
	name:       'shortCurrency',
	standalone: true,
})
export class ShortCurrencyPipe extends AngularCurrencyPipe implements PipeTransform {
	transform(value: number | string, currencyCode?: string, display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean, digitsInfo?: string, locale?: string): string | null;
	transform(value: null | undefined, currencyCode?: string, display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean, digitsInfo?: string, locale?: string): null;
	transform(value: number | string | null | undefined, currencyCode?: string, display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean, digitsInfo?: string, locale?: string): string | null;
	transform(value: number | string | null | undefined, currencyCode?: string, display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean, digitsInfo?: string, locale?: string): string | null {
		digitsInfo ??= Number.isInteger(value) ? '1.0-2' : undefined;
		return super.transform(value, currencyCode, display, digitsInfo, locale);
	}

}
