import {PageRequestOptions} from '@angular-helpers/frontend-api/lib/models/abstract-model/abstract.api-service';
import {
	AsyncPipe,
	CurrencyPipe,
	JsonPipe,
	NgIf,
	PercentPipe,
} from '@angular/common';
import {
	Component,
	EventEmitter,
	inject,
	Output,
} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {
	MatFormField,
	MatSuffix,
} from '@angular/material/form-field';
import {MatInput} from '@angular/material/input';
import {environment} from '@app/environment';
import {
	DoubleTokenComponent,
	FormatIdentifierExtensionPipe,
	FormatProductSpecialityNumberPipe,
	IconService,
	MainModule,
	MinimalColumn,
	MinimalColumns,
	MultiSelectEntry,
	ShortCurrencyPipe,
	TranslatePrefixedPipe,
} from '@app/main';
import {TranslateModule} from '@ngx-translate/core';
import {
	Observable,
	of,
} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {
	CALCULATION_UNITS,
	CalculationModel,
	CalculationUnit,
} from '../../../models/calculation/calculation.model';
import {CalculationService} from '../../../models/calculation/calculation.service';
import {
	AID_IDENTIFIERS,
	HOURLY_RATES,
	HourlyRate,
	VAT_RATES,
	VatRate,
} from '../../../models/common/types';
import {FormatCalculationStandardSchemaPipe} from '../../../pipes/format-calculation-standart-schema.pipe';
import {HourlyRatePipe} from '../../../pipes/hourly-rate.pipe';

@Component({
	selector:    'portal-calculation-list',
	standalone:  true,
	imports:     [
		MainModule,
		FormatIdentifierExtensionPipe,
		FormatProductSpecialityNumberPipe,
		FormatCalculationStandardSchemaPipe,
		TranslatePrefixedPipe,
		NgIf,
		DoubleTokenComponent,
		AsyncPipe,
		JsonPipe,
		PercentPipe,
		ShortCurrencyPipe,
		TranslateModule,
		CurrencyPipe,
		HourlyRatePipe,
		FormsModule,
		MatFormField,
		MatInput,
		MatSuffix,
	],
	templateUrl: './calculation-list.component.html',
	styleUrl:    './calculation-list.component.scss',
})
export class CalculationListComponent {
	@Output() readonly searchEvent = new EventEmitter<PageRequestOptions['search']>;
	protected readonly calculationService = inject(CalculationService);
	protected readonly iconService = inject(IconService);
	protected readonly environment = environment;
	protected readonly tableHeaders: MinimalColumns<CalculationModel> = {
		identifierExtension:      {
			label:         'calculation.identifierExtension',
			index:         0,
			sortBy: [
				'identifierExtension',
				'productSpecialityNumber',
			],
			prepareSearch: (value, options) => {
				if(typeof value !== 'string')
					throw new Error(`Search value for 'calculations.identifierExtension' must be a string`);

				return value.replace(/[^0-9]+/g, '');
			},
			prepareSearchOutput: value => ({
				value:      `${value}%`,
				comparator: 'ilike',
				column:     'identifierExtension',
			}),
		},
		name:                     {
			label: 'calculation.name',
			index: 2,
		},
		aidIdentifiers: {
			                label: 'calculation.aidIdentifiers',
			                          index:       3,
			                          serialize:   value => value.join(','),
			                          deserialize: value => {
				                          if(value === '')
					                          return undefined;

				                          return value.split(',').map(x => Number.parseInt(x));
			                          },
		                          } satisfies MinimalColumn<CalculationModel, number[]>,
		careScope:                {
			label:        'calculation.careScope',
			isVisible:    false,
			index:        4,
			isSearchable: false,
		},
		fixedPrice:               {
			label:        'calculation.fixedPrice',
			isVisible:    false,
			index:        6,
			isSearchable: false,
			isSortable: false,
		},
		addition:                 {
			label:       'calculation.addition',
			isVisible:   false,
			index:       11,
			serialize:   value => {
				if(typeof value !== 'string')
					throw new Error(`Search value for 'calculations.addition' must be a string`);

				const inputNumber = Number.parseFloat(value.replace(',', '.'));
				if(Number.isNaN(inputNumber))
					return '';

				return (inputNumber / 100).toString();
			},
			deserialize: value => (Number.parseFloat(value) * 100).toString().replace('.', ','),
		},
		price:                    {
			label:        'calculation.price',
			index:        12,
			isSearchable: false,
			isSortable: false,
			isVisible: false,
		},
		hourlyBillingRateBracket: {
			                          label:        'calculation.hourlyBillingRateBracket',
			                          isVisible:    false,
			                          isSearchable: false, // todo backend currently does not support this
			                          isSortable: false,
			                          index:        13,
			                          serialize:    value => value.join(','),
			                          deserialize:  value => {
				                          if(value === '')
					                          return undefined;

				                          return value
					                          .split(',')
					                          // @ts-expect-error to narrow type
					                          .filter((x): x is HourlyRate => HOURLY_RATES.includes(x));
			                          },
		                          } satisfies MinimalColumn<CalculationModel, HourlyRate[]>,
		// currently disabled till fixed (https://git.biv.to/calculations/public/-/issues/98)
		/*vatRates:                 {
		 label:       'common.vatRate',
		 isVisible:   false,
		 index:       14,
		 serialize:   value => value.join(','),
		 deserialize: value => {
		 if(value === '')
		 return undefined;

		 return value
		 .split(',')
		 // @ts-expect-error to narrow type
		 .filter((x): x is VatRate => VAT_RATES.includes(x));
		 },
		 } satisfies MinimalColumn<CalculationModel, VatRate[]>,*/
		unit: {
			      label:       'calculation.unit',
			      isVisible:   false,
			      index:       15,
			      serialize:   value => value.join(','),
			      deserialize: value => {
				      if(value === '')
					      return undefined;

				      return value
					      .split(',')
					      // @ts-expect-error to narrow type
					      .filter((x): x is CalculationUnit => CALCULATION_UNITS.includes(x));
			      },
		      } satisfies MinimalColumn<CalculationModel, CalculationUnit[]>,
	};

	private _aidIdentifierValues?: Map<MultiSelectEntry, number>;
	private _vatRatesValues?: Map<MultiSelectEntry, VatRate>;
	private _unitValues?: Map<string, CalculationUnit>;

	protected get aidIdentifierValues(): Map<MultiSelectEntry, number> {
		if(this._aidIdentifierValues === undefined) {
			this._aidIdentifierValues = new Map();
			// todo show only used aidIdentifiers (load from backend?)
			for(const identifier of AID_IDENTIFIERS) {
				this._aidIdentifierValues.set({
					label: identifier.toString().padStart(2, '0'),
					info:  `calculation.aidIdentifierValues.${identifier}`,
				}, identifier);
			}
		}

		return this._aidIdentifierValues;
	}

	protected get vatRatesValues(): Map<MultiSelectEntry, VatRate> {
		if(this._vatRatesValues === undefined) {
			this._vatRatesValues = new Map();
			for(const rate of VAT_RATES) {
				this._vatRatesValues.set({
					label: `common.vatRateNames.${rate}`,
					info:  `common.vatRateValuesLong.${rate}`,
				}, rate);
			}
		}

		return this._vatRatesValues;
	}

	protected get unitValues(): Map<string, CalculationUnit> {
		if(this._unitValues === undefined) {
			this._unitValues = new Map();
			for(const unit of CALCULATION_UNITS)
				this._unitValues.set(`material.unit.${unit}`, unit);
		}

		return this._unitValues;
	}

	protected getFixedPrice(calculation: CalculationModel): Observable<number | null | undefined> {
		return calculation.fixedPrice.value.pipe(
			switchMap(fixedPrice => fixedPrice == null ? of(fixedPrice) : fixedPrice.price.value),
		);
	}
}
