import {
	Pipe,
	PipeTransform,
} from '@angular/core';

@Pipe({
	name:       'formatIdentifierExtension',
	standalone: true,
})
export class FormatIdentifierExtensionPipe implements PipeTransform {

	transform<T extends (string | undefined | null)>(value: T): T;
	transform(value: string | undefined | null): string | undefined | null {
		if(typeof value !== 'string')
			return value;

		const parts = [];

		const cuts = [2, 2, 2];
		for(const cut of cuts) {
			if(value.length < cut)
				break;

			parts.push(value.slice(0, cut));
			value = value.slice(cut);
		}

		if(value.length > 0)
			parts.push(value);

		return parts.join('.');
	}

}
