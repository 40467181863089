<portal-editable
	[validator]="validator"
	[icon]="icon"
	[label]="label"
	[showAlways]="showAlways"
>
	<mat-form-field>
		<fa-icon
			*ngIf="resetValue !== undefined && validator.value !== resetValue"
			matPrefix
			[icon]="iconService.RESET"
			class="clickable reset"
			(click)="resetToDefault()"
			[matTooltip]="resetTooltip == null ? undefined : resetTooltip | translate"
			matTooltipPosition="above"
		/>
		<div
			class="suffix"
			matTextSuffix
			*ngIf="suffix"
		>{{ suffix }}
		</div>
		<div matSuffix>
			<portal-input-status-icon
				[validator]="validator"
			/>
		</div>
		<input
			type="number"
			[placeholder]="placeholder ?? ''"
			(change)="change.emit(validator.value)"
			[step]="stepSize"
			matInput
			[formControl]="validator"
		/>
		@for (error of formHelperService.getErrors(validator) | keyvalue; track error) {
			<mat-error>
				{{ 'input.error.' + error.key | translate: error.value }}
			</mat-error>
		}
	</mat-form-field>
</portal-editable>
