import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {
	FormsModule,
	ReactiveFormsModule,
} from '@angular/forms';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatOptionModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatStepperModule} from '@angular/material/stepper';
import {MatTableModule} from '@angular/material/table';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatTreeModule} from '@angular/material/tree';
import {
	BypassSecurityTrustResourcePipe,
	DoubleTokenComponent,
	EditFieldNumberComponent,
	MainModule,
} from '@app/main';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {AngularEditorModule} from '@kolkov/angular-editor';
import {TranslateModule} from '@ngx-translate/core';
import {AddressCreateComponent} from './components/address/create/address-create.component';
import {AddressEditModelComponent} from './components/address/model/edit/address-edit-model.component';
import {AddressShowModelComponent} from './components/address/model/show/address-show-model.component';
import {BaseContractCardFilesComponent} from './components/base-contract/cards/files/base-contract-card-files.component';
import {ContractCardJoinedPartiesComponent} from './components/base-contract/cards/joined-parties/joined-parties.component';
import {BaseContactRequirementsCardComponent} from './components/base-contract/cards/requirements-card/base-contact-requirements-card.component';
import {BaseContractListComponent} from './components/base-contract/list/base-contract-list.component';
import {BaseContractEditMandateGrantorComponent} from './components/base-contract/properties/awarded-mandates/edit/base-contract-edit-mandate-grantor.component';
import {BaseContractShowMandateGrantorComponent} from './components/base-contract/properties/awarded-mandates/show/base-contract-show-mandate-grantor.component';
import {BaseContractEditBillingDelayComponent} from './components/base-contract/properties/billing-date/edit/base-contract-edit-billing-delay.component';
import {BaseContractShowBillingDelayComponent} from './components/base-contract/properties/billing-date/show/base-contract-show-billing-delay.component';
import {BaseContractEditInitialSignerComponent} from './components/base-contract/properties/initial-signer/edit/base-contract-edit-initial-signer.component';
import {BaseContractShowInitialSignerComponent} from './components/base-contract/properties/initial-signer/show/base-contract-show-initial-signer.component';
import {BaseContractEditJoinedPartiesComponent} from './components/base-contract/properties/joined-parties/edit/base-contract-edit-joined-parties.component';
import {BaseContractEditJoiningDelayComponent} from './components/base-contract/properties/joining-delay/edit/base-contract-edit-joining-delay.component';
import {BaseContractShowJoiningDelayComponent} from './components/base-contract/properties/joining-delay/show/base-contract-show-joining-delay.component';
import {BaseContractEditMaximumBackDatingDelayComponent} from './components/base-contract/properties/maximum-backdating-delay/edit/base-contract-edit-maximum-back-dating-delay.component';
import {BaseContractShowMaximumBackDatingDelayComponent} from './components/base-contract/properties/maximum-backdating-delay/show/base-contract-show-maximum-back-dating-delay.component';
import {BaseContractEditMinimumTermComponent} from './components/base-contract/properties/minimum-term/edit/base-contract-edit-minimum-term.component';
import {BaseContractShowMinimumTermAtComponent} from './components/base-contract/properties/minimum-term/show/base-contract-show-minimum-term-at.component';
import {BaseContractEditNameComponent} from './components/base-contract/properties/name/edit/base-contract-edit-name.component';
import {BaseContractShowNameComponent} from './components/base-contract/properties/name/show/base-contract-show-name.component';
import {BaseContractEditNegotiatorsComponent} from './components/base-contract/properties/negotiant/edit/base-contract-edit-negotiators.component';
import {BaseContractShowNegotiatorsComponent} from './components/base-contract/properties/negotiant/show/base-contract-show-negotiators.component';
import {BaseContractEditNoticePeriodNegotiationsComponent} from './components/base-contract/properties/notice-period-negotiations/edit/base-contract-edit-notice-period-negotiations.component';
import {BaseContractShowNoticePeriodNegotiationDelayComponent} from './components/base-contract/properties/notice-period-negotiations/show/base-contract-show-notice-period-negotiation-delay.component';
import {BaseContractEditNoticePeriodComponent} from './components/base-contract/properties/notice-period/edit/base-contract-edit-notice-period.component';
import {BaseContractShowNoticePeriodDelayComponent} from './components/base-contract/properties/notice-period/show/base-contract-show-notice-period-delay.component';
import {BaseContractEditPeriodOfValidityComponent} from './components/base-contract/properties/period-of-validity/edit/base-contract-edit-period-of-validity.component';
import {BaseContractShowPeriodOfValidityComponent} from './components/base-contract/properties/period-of-validity/show/base-contract-show-period-of-validity.component';
import {BaseContractStatusComponent} from './components/base-contract/properties/status/base-contract-status.component';
import {BaseContractEditTerminatedAtComponent} from './components/base-contract/properties/terminated-at/edit/base-contract-edit-terminated-at.component';
import {BaseContractShowTerminatedAtComponent} from './components/base-contract/properties/terminated-at/show/base-contract-show-terminated-at.component';
import {BaseContractEditValidityStartComponent} from './components/base-contract/properties/validity-start/edit/base-contract-edit-validity-start.component';
import {BaseContractShowValidityStartComponent} from './components/base-contract/properties/validity-start/show/base-contract-show-validity-start.component';
import {CommonEditContactComponent} from './components/common/properties/contact/edit/common-edit-contact.component';
import {CommonShowContactComponent} from './components/common/properties/contact/show/common-show-contact.component';
import {CommonEditEmailComponent} from './components/common/properties/email/edit/common-edit-email.component';
import {CommonShowEmailComponent} from './components/common/properties/email/show/common-show-email.component';
import {CommonEditFaxComponent} from './components/common/properties/fax/edit/common-edit-fax.component';
import {CommonShowFaxComponent} from './components/common/properties/fax/show/common-show-fax.component';
import {CommonEditNameComponent} from './components/common/properties/name/edit/common-edit-name.component';
import {CommonShowNameComponent} from './components/common/properties/name/show/common-show-name.component';
import {CommonEditPhoneComponent} from './components/common/properties/phone/edit/common-edit-phone.component';
import {CommonShowPhoneComponent} from './components/common/properties/phone/show/common-show-phone.component';
import {CommonEditWebsiteComponent} from './components/common/properties/website/edit/common-edit-website.component';
import {CommonShowWebsiteComponent} from './components/common/properties/website/show/common-show-website.component';
import {ContractAccessionCardListComponent} from './components/contract-accession/cards/list/contract-accession-card-list.component';
import {ContractAccessionCardMasterDataComponent} from './components/contract-accession/cards/master-data/contract-accession-card-master-data.component';
import {ContractAccessionCardTimingsComponent} from './components/contract-accession/cards/timings/contract-accession-card-timings.component';
import {WithdrawalComponent} from './components/contract-accession/dialogs/withdrawel/withdrawal.component';
import {ContractAccessionListComponent} from './components/contract-accession/list/contract-accession-list.component';
import {ContractAccessionEditAccessionEndAtComponent} from './components/contract-accession/properties/accession-end-at/edit/contract-accession-edit-accession-end-at.component';
import {ContractAccessionShowAccessionEndAtComponent} from './components/contract-accession/properties/accession-end-at/show/contract-accession-show-accession-end-at.component';
import {ContractAccessionEditAccessionStartAtComponent} from './components/contract-accession/properties/accession-start-at/edit/contract-accession-edit-accession-start-at.component';
import {ContractAccessionShowAccessionStartAtComponent} from './components/contract-accession/properties/accession-start-at/show/contract-accession-show-accession-start-at.component';
import {BaseContractShowExportsComponent} from './components/contract-accession/properties/base-contract-show-export-data/base-contract-show-exports.component';
import {ContractAccessionContractComponent} from './components/contract-accession/properties/contract-accession-contract/contract-accession-contract.component';
import {ContractAccessionDateBillingDelayComponent} from './components/contract-accession/properties/contract-accession-date-billing-delay/contract-accession-date-billing-delay.component';
import {ContractAccessionJoinedPartyComponent} from './components/contract-accession/properties/contract-accession-joined-party/contract-accession-joined-party.component';
import {ContractAccessionJoiningDelayComponent} from './components/contract-accession/properties/contract-accession-joineding-delay/contract-accession-joining-delay.component';
import {ContractAccessionMasterContractComponent} from './components/contract-accession/properties/contract-accession-master-contract/contract-accession-master-contract.component';
import {ContractSectionCardContractingComponent} from './components/contract-section/cards/contracting/contract-section-card-contracting.component';
import {ContractSectionCardListComponent} from './components/contract-section/cards/list/contract-section-card-list.component';
import {ContractSectionCardMasterDataComponent} from './components/contract-section/cards/master-data/contract-section-card-master-data.component';
import {ContractSectionShowComponent} from './components/contract-section/self/contract-section-show/contract-section-show.component';
import {ContractCardContractingComponent} from './components/contract/cards/contracting/contract-card-contracting.component';
import {ContractCardListComponent} from './components/contract/cards/list/contract-card-list.component';
import {ContractCardMasterDataComponent} from './components/contract/cards/master-data/contract-card-master-data.component';
import {BaseContractShowExternalContractNumberComponent} from './components/contract/properties/contract-external-contract-number/base-contract-show-externalcontractnumber/base-contract-show-external-contract-number.component';
import {ExternalContractNumberTokenListComponent} from './components/contract/properties/contract-external-contract-number/external-contract-number-token-list/external-contract-number-token-list.component';
import {ExternalContractNumberTokenComponent} from './components/contract/properties/contract-external-contract-number/external-contract-number-token/external-contract-number-token.component';
import {ContractExternalContractNumberListEditComponent} from './components/contract/properties/contract-external-contract-number/list-edit/contract-external-contract-number-list-edit.component';
import {PseudoVersorgungsbereichEditComponent} from './components/contract/properties/pseudo-versorgungsbereich-edit/pseudo-versorgungsbereich-edit.component';
import {ContractSectionEditVersorgungsbereichComponent} from './components/contract/properties/versorgungsbereich/edit/contract-section-edit-versorgungsbereich.component';
import {ContractShowVersorgungsbereichComponent} from './components/contract/properties/versorgungsbereich/show/contract-show-versorgungsbereich.component';
import {ContractShowComponent} from './components/contract/self/show/contract-show.component';
import {ContractingPartyCardMasterDataComponent} from './components/contracting-party/cards/master-data/contracting-party-card-master-data.component';
import {ContractingPartyListComponent} from './components/contracting-party/list/contracting-party-list.component';
import {ContractingPartyEditContactComponent} from './components/contracting-party/properties/contact/edit/contracting-party-edit-contact.component';
import {ContractingPartyShowContactComponent} from './components/contracting-party/properties/contact/show/contracting-party-show-contact.component';
import {ContractingPartyEditInsuranceCountComponent} from './components/contracting-party/properties/insurance-count/edit/contracting-party-edit-insurance-count.component';
import {ContractingPartyShowInsuranceCountComponent} from './components/contracting-party/properties/insurance-count/show/contracting-party-show-insurance-count.component';
import {ContractingPartyEditNameComponent} from './components/contracting-party/properties/name/edit/contracting-party-edit-name.component';
import {ContractingPartyShowNameComponent} from './components/contracting-party/properties/name/show/contracting-party-show-name.component';
import {EmployeeListShortDataCardComponent} from './components/employee/cards/list-short/employee-list-short-data-card.component';
import {EmployeeMasterDataCardComponent} from './components/employee/cards/master-data/employee-master-data-card.component';
import {EmployeeCreateFormComponent} from './components/employee/create-form/employee-create-form.component';
import {EmployeeShowNameComponent} from './components/employee/properties/name/show/employee-show-name.component';
import {EmployeeEditRolesComponent} from './components/employee/properties/roles/edit/employee-edit-roles.component';
import {EmployeeShowRolesComponent} from './components/employee/properties/roles/show/employee-show-roles.component';
import {EmployeeEditSalutationComponent} from './components/employee/properties/salutation/edit/employee-edit-salutation.component';
import {EmployeeSelectComponent} from './components/employee/select/employee-select.component';
import {EmployeeShowComponent} from './components/employee/show/employee-show.component';
import {ExportCardMasterDataComponent} from './components/export/cards/export-card-master-data/export-card-master-data.component';
import {ExportEditNextAtComponent} from './components/export/properties/next-at/edit/export-edit-next-at.component';
import {ExportShowNextAtComponent} from './components/export/properties/next-at/show/export-show-next-at.component';
import {ExportEditRecipientsComponent} from './components/export/properties/recipients/edit/export-edit-recipients.component';
import {ExportShowRecipientsComponent} from './components/export/properties/recipients/show/export-show-recipients.component';
import {ExportEditStandardDelayComponent} from './components/export/properties/standard-delay/edit/export-edit-standard-delay.component';
import {ExportShowStandardDelayComponent} from './components/export/properties/standard-delay/show/export-show-standard-delay.component';
import {FileCreateUploadComponent} from './components/file/file-create-upload/file-create-upload.component';
import {FileCreateComponent} from './components/file/file-create/file-create.component';
import {ModelFilesCardComponent} from './components/file/files-card/model-files-card.component';
import {FileEditDescriptionComponent} from './components/file/properties/description/edit/file-edit-description.component';
import {FileEditIsPublishableComponent} from './components/file/properties/is-publishable/edit/file-edit-is-publishable.component';
import {FileEditNameComponent} from './components/file/properties/name/edit/file-edit-name.component';
import {FileEditValidityStartAtComponent} from './components/file/properties/validity-start-at/edit/file-edit-validity-start-at.component';
import {ShowFileComponent} from './components/file/show/show-file.component';
import {InstitutionskennzeichenCardMasterDataComponent} from './components/institutionskennzeichen/cards/master-data/institutionskennzeichen-card-master-data.component';
import {InstitutionskennzeichenEditNumberComponent} from './components/institutionskennzeichen/properties/number/edit/institutionskennzeichen-edit-number.component';
import {InstitutionskennzeichenShowNumberComponent} from './components/institutionskennzeichen/properties/number/show/institutionskennzeichen-show-number.component';
import {InstitutionskennzeichenSelectComponent} from './components/institutionskennzeichen/select/institutionskennzeichen-select.component';
import {InstitutionskennzeichenShowComponent} from './components/institutionskennzeichen/show/institutionskennzeichen-show.component';
import {IqzParticipationListComponent} from './components/iqz-participation/iqz-participation-list/iqz-participation-list.component';
import {IqzTrainingCourseEditAttendanceComponent} from './components/iqz-training-course/properties/attendance/edit/iqz-training-course-edit-attendance.component';
import {IqzTrainingCourseShowAttendanceComponent} from './components/iqz-training-course/properties/attendance/show/iqz-training-course-show-attendance.component';
import {IqzTrainingCourseEditCategoryComponent} from './components/iqz-training-course/properties/category/edit/iqz-training-course-edit-category.component';
import {IqzTrainingCourseShowCategoryComponent} from './components/iqz-training-course/properties/category/show/iqz-training-course-show-category.component';
import {IqzTrainingCourseEditEndAtComponent} from './components/iqz-training-course/properties/end-at/edit/iqz-training-course-edit-end-at.component';
import {IqzTrainingCourseShowEndAtComponent} from './components/iqz-training-course/properties/end-at/show/iqz-training-course-show-end-at.component';
import {IqzTrainingCourseEditExternalIdComponent} from './components/iqz-training-course/properties/external-id/edit/iqz-training-course-edit-external-id.component';
import {IqzTrainingCourseShowExternalIdComponent} from './components/iqz-training-course/properties/external-id/show/iqz-training-course-show-external-id.component';
import {IqzTrainingCourseEditIqzTrainingCoursePointsExamComponent} from './components/iqz-training-course/properties/iqz-training-course-points-exam/edit/iqz-training-course-edit-iqz-training-course-points-exam.component';
import {IqzTrainingCourseShowIqzTrainingCoursePointsExamComponent} from './components/iqz-training-course/properties/iqz-training-course-points-exam/show/iqz-training-course-show-iqz-training-course-points-exam.component';
import {IqzTrainingCourseEditIqzTrainingCoursePointsComponent} from './components/iqz-training-course/properties/iqz-training-course-points/edit/iqz-training-course-edit-iqz-training-course-points.component';
import {IqzTrainingCourseShowIqzTrainingCoursePointsComponent} from './components/iqz-training-course/properties/iqz-training-course-points/show/iqz-training-course-show-iqz-training-course-points.component';
import {IqzTrainingCourseEditIqzWorkshopPointsComponent} from './components/iqz-training-course/properties/iqz-workshop-points/edit/iqz-training-course-edit-iqz-workshop-points.component';
import {IqzTrainingCourseShowIqzWorkshopPointsComponent} from './components/iqz-training-course/properties/iqz-workshop-points/show/iqz-training-course-show-iqz-workshop-points.component';
import {IqzTrainingCourseEditIsCanceledComponent} from './components/iqz-training-course/properties/is-canceled/edit/iqz-training-course-edit-is-canceled.component';
import {IqzTrainingCourseShowIsCanceledComponent} from './components/iqz-training-course/properties/is-canceled/show/iqz-training-course-show-is-canceled.component';
import {IqzTrainingCourseEditShouldBeCanceledComponent} from './components/iqz-training-course/properties/should-be-canceled/edit/iqz-training-course-edit-should-be-canceled.component';
import {IqzTrainingCourseShowShouldBeCanceledComponent} from './components/iqz-training-course/properties/should-be-canceled/show/iqz-training-course-show-should-be-canceled.component';
import {IqzTrainingCourseEditStartAtComponent} from './components/iqz-training-course/properties/start-at/edit/iqz-training-course-edit-start-at.component';
import {IqzTrainingCourseShowStartAtComponent} from './components/iqz-training-course/properties/start-at/show/iqz-training-course-show-start-at.component';
import {IqzTrainingCourseEditTitleComponent} from './components/iqz-training-course/properties/title/edit/iqz-training-course-edit-title.component';
import {IqzTrainingCourseShowTitleComponent} from './components/iqz-training-course/properties/title/show/iqz-training-course-show-title.component';
import {LandesinnungCardMasterDataComponent} from './components/landesinnung/cards/master-data/landesinnung-card-master-data.component';
import {LandesinnungEditComponent} from './components/landesinnung/edit/landesinnung-edit.component';
import {LandesinnungListComponent} from './components/landesinnung/list/landesinnung-list.component';
import {LandesinnungShowComponent} from './components/landesinnung/show/landesinnung-show.component';
import {MasterContractCardContractingComponent} from './components/master-contract/cards/contracting/master-contract-card-contracting.component';
import {MasterContractCardListComponent} from './components/master-contract/cards/list/master-contract-card-list.component';
import {MasterContractCardMasterDataComponent} from './components/master-contract/cards/master-data/master-contract-card-master-data.component';
import {MasterContractShowComponent} from './components/master-contract/self/show/master-contract-show.component';
import {MedicalStoreContactsCardComponent} from './components/medical-store/cards/contacts-card/medical-store-contacts-card.component';
import {MedicalStoreContractsCardComponent} from './components/medical-store/cards/contracts-card/medical-store-contracts-card.component';
import {MedicalStoreCardListComponent} from './components/medical-store/cards/list/medical-store-card-list.component';
import {MedicalStoreMasterDataCardComponent} from './components/medical-store/cards/master-data-card/medical-store-master-data-card.component';
import {MedicalStoreParticipationsCardComponent} from './components/medical-store/cards/participations-card/medical-store-participations-card.component';
import {MedicalStoreShortCardComponent} from './components/medical-store/cards/short-card/medical-store-short-card.component';
import {MedicalStoreListComponent} from './components/medical-store/list/medical-store-list.component';
import {MedicalStoreEditBivNumberComponent} from './components/medical-store/properties/biv-number/edit/medical-store-edit-biv-number.component';
import {MedicalStoreShowBivNumberComponent} from './components/medical-store/properties/biv-number/show/medical-store-show-biv-number.component';
import {MedicalStoreEditHmmParticipationComponent} from './components/medical-store/properties/hmm-participation/edit/medical-store-edit-hmm-participation.component';
import {MedicalStoreShowHmmParticipationComponent} from './components/medical-store/properties/hmm-participation/show/medical-store-show-hmm-participation.component';
import {MedicalStoreShowInstitutionskennzeichensComponent} from './components/medical-store/properties/institutionskennzeichens/show/medical-store-show-institutionskennzeichens.component';
import {MedicalStoreEditIqzCategoriesComponent} from './components/medical-store/properties/iqz-categories/edit/medical-store-edit-iqz-categories.component';
import {MedicalStoreShowIqzCategoriesComponent} from './components/medical-store/properties/iqz-categories/show/medical-store-show-iqz-categories.component';
import {MedicalStoreEditIso13485Component} from './components/medical-store/properties/iso13485/edit/medical-store-edit-iso13485.component';
import {MedicalStoreShowIso13485Component} from './components/medical-store/properties/iso13485/show/medical-store-show-iso13485.component';
import {MedicalStoreEditIso9001Component} from './components/medical-store/properties/iso9001/edit/medical-store-edit-iso9001.component';
import {MedicalStoreShowIso9001Component} from './components/medical-store/properties/iso9001/show/medical-store-show-iso9001.component';
import {MedicalStoreEditLegalFormComponent} from './components/medical-store/properties/legal-form/edit/medical-store-edit-legal-form.component';
import {MedicalStoreShowLegalFormComponent} from './components/medical-store/properties/legal-form/show/medical-store-show-legal-form.component';
import {MedicalStoreUserEditComponent} from './components/medical-store/properties/medical-store-user/edit/medical-store-user-edit.component';
import {MedicalStoreUserShowComponent} from './components/medical-store/properties/medical-store-user/show/medical-store-user-show.component';
import {MedicalStoreEditMipComponent} from './components/medical-store/properties/mip/medical-store-edit-mip/medical-store-edit-mip.component';
import {MedicalStoreShowMipComponent} from './components/medical-store/properties/mip/medical-store-mip-show/medical-store-show-mip.component';
import {MedicalStoreEditProprioceptiveDepositsComponent} from './components/medical-store/properties/proprioceptive-deposits/medical-store-edit-proprioceptive-deposits/medical-store-edit-proprioceptive-deposits.component';
import {MedicalStoreShowProprioceptiveDepositsComponent} from './components/medical-store/properties/proprioceptive-deposits/medical-store-show-proprioceptive-deposits/medical-store-show-proprioceptive-deposits.component';
import {MedicalStoreEditPsaComponent} from './components/medical-store/properties/psa/medical-store-edit-psa/medical-store-edit-psa.component';
import {MedicalStoreShowPsaComponent} from './components/medical-store/properties/psa/medical-store-show-psa/medical-store-show-psa.component';
import {MedicalStoreEditSupplyAreaComponent} from './components/medical-store/properties/supply-area/medical-store-edit-supply-area/medical-store-edit-supply-area.component';
import {MedicalStoreShowSupplyAreaComponent} from './components/medical-store/properties/supply-area/medical-store-show-supply-area/medical-store-show-supply-area.component';
import {MedicalStoreShowComponent} from './components/medical-store/show/medical-store-show.component';
import {NewsCardMasterDataComponent} from './components/news/cards/master-data/news-card-master-data.component';
import {NewsEditDateFieldComponent} from './components/news/properties/date/edit/news-edit-date-field.component';
import {NewsEditDisplayEndAtComponent} from './components/news/properties/display-end-at/edit/news-edit-display-end-at.component';
import {NewsEditDisplayStartAtComponent} from './components/news/properties/display-start-at/edit/news-edit-display-start-at.component';
import {NewsEditImportanceComponent} from './components/news/properties/importance/edit/news-edit-importance.component';
import {NewsEditSummaryComponent} from './components/news/properties/summary/edit/news-edit-summary.component';
import {NewsEditTextComponent} from './components/news/properties/text/edit/news-edit-text.component';
import {NewsShowFieldTextComponent} from './components/news/properties/text/show/news-show-field-text.component';
import {NewsEditTitleComponent} from './components/news/properties/title/edit/news-edit-title.component';
import {NewsShowComponent} from './components/news/show/news-show.component';
import {PreQualificationCertificateListCardComponent} from './components/pre-qualification-certificate/cards/list/pre-qualification-certificate-list-card.component';
import {PreQualificationCertificateMasterDataComponent} from './components/pre-qualification-certificate/cards/master-data/pre-qualification-certificate-master-data.component';
import {PreQualificationCertificateOwnerComponent} from './components/pre-qualification-certificate/cards/owner/pre-qualification-certificate-owner.component';
import {PreQualificationCertificateValidityComponent} from './components/pre-qualification-certificate/cards/validity/pre-qualification-certificate-validity.component';
import {PreQualificationCertificateListComponent} from './components/pre-qualification-certificate/list/pre-qualification-certificate-list.component';
import {PreQualificationCertificateEditAccreditationBodyComponent} from './components/pre-qualification-certificate/properties/accreditation-body/edit/pre-qualification-certificate-edit-accreditation-body.component';
import {PreQualificationCertificateShowAccreditationBodyComponent} from './components/pre-qualification-certificate/properties/accreditation-body/show/pre-qualification-certificate-show-accreditation-body.component';
import {PreQualificationCertificateEditCertificateIdentifierComponent} from './components/pre-qualification-certificate/properties/certificate-identifier/edit/pre-qualification-certificate-edit-certificate-identifier.component';
import {PreQualificationCertificateShowCertificateIdentifierComponent} from './components/pre-qualification-certificate/properties/certificate-identifier/show/pre-qualification-certificate-show-certificate-identifier.component';
import {PreQualificationCertificateEditEmployeeComponent} from './components/pre-qualification-certificate/properties/employee/edit/pre-qualification-certificate-edit-employee.component';
import {PreQualificationCertificateEditFileComponent} from './components/pre-qualification-certificate/properties/file/edit/pre-qualification-certificate-edit-file.component';
import {PreQualificationCertificateEditInstitutionskennzeichenComponent} from './components/pre-qualification-certificate/properties/institutionskennzeichen/edit/pre-qualification-certificate-edit-institutionskennzeichen.component';
import {PreQualificationCertificateEditValidityEndAtComponent} from './components/pre-qualification-certificate/properties/validity-end-at/edit/pre-qualification-certificate-edit-validity-end-at.component';
import {PreQualificationCertificateShowValidityEndAtComponent} from './components/pre-qualification-certificate/properties/validity-end-at/show/pre-qualification-certificate-show-validity-end-at.component';
import {PreQualificationCertificateEditValidityStartAtComponent} from './components/pre-qualification-certificate/properties/validity-start-at/edit/pre-qualification-certificate-edit-validity-start-at.component';
import {PreQualificationCertificateShowValidityStartAtComponent} from './components/pre-qualification-certificate/properties/validity-start-at/show/pre-qualification-certificate-show-validity-start-at.component';
import {PreQualificationCertificateEditVersorgungsbereichsComponent} from './components/pre-qualification-certificate/properties/versorgungsbereichs/edit/pre-qualification-certificate-edit-versorgungsbereichs.component';
import {PreQualificationCertificateShowVersorgungsbereichsComponent} from './components/pre-qualification-certificate/properties/versorgungsbereichs/show/pre-qualification-certificate-show-versorgungsbereichs.component';
import {ProfessionalAssociationCardMasterDataComponent} from './components/professional-association/cards/master-data/professional-association-card-master-data.component';
import {ProfessionalAssociationEditComponent} from './components/professional-association/edit/professional-association-edit.component';
import {ProfessionalAssociationListComponent} from './components/professional-association/list/professional-association-list.component';
import {ProfessionalAssociationShowComponent} from './components/professional-association/show/professional-association-show.component';
import {RequirementsShowComponent} from './components/requirement/show/requirements-show.component';
import {VersionErrorDialogComponent} from './components/version-error-dialog/version-error-dialog.component';
import {ContractsRoutingModule} from './contracts-routing.module';
import {ContractsComponent} from './contracts.component';
import {BaseContractsCreateComponent} from './pages/base-contracts/base-contracts-create.component';
import {ContractAccessingPageComponent} from './pages/contract-accessing/contract-accessing-page.component';
import {ContractAccessionListPageComponent} from './pages/contract-accession/list/contract-accession-list-page.component';
import {ContractAccessionShowPageComponent} from './pages/contract-accession/show/contract-accession-show-page.component';
import {ContractSectionsPageCreateComponent} from './pages/contract-sections/create/contract-sections-page-create.component';
import {ContractSectionsPageShowComponent} from './pages/contract-sections/show/contract-sections-page-show.component';
import {ContractingPartyPageCreateComponent} from './pages/contracting-party/create/contracting-party-page-create.component';
import {ContractingPartyPageListComponent} from './pages/contracting-party/list/contracting-party-page-list.component';
import {ContractingPartyPageShowComponent} from './pages/contracting-party/show/contracting-party-page-show.component';
import {ContractsPageCreateComponent} from './pages/contracts/create/contracts-page-create.component';
import {RequirementCreateRequirementComponent} from './pages/contracts/create/requirement-create-component/requirement-create-requirement.component';
import {ContractsPageShowComponent} from './pages/contracts/show/contracts-page-show.component';
import {DownloadSectionPageComponent} from './pages/download-section-page/download-section-page.component';
import {EmployeePageShowComponent} from './pages/employee/show/employee-page-show.component';
import {ExportShowPageComponent} from './pages/export-show-page/export-show-page.component';
import {InstitutionskennzeichenPageCreateComponent} from './pages/institutionskennzeichen/create/institutionskennzeichen-page-create.component';
import {InstitutionskennzeichenPageShowComponent} from './pages/institutionskennzeichen/show/institutionskennzeichen-page-show.component';
import {IqzParticipationPageCreateComponent} from './pages/iqz-participation/create/iqz-participation-page-create.component';
import {LandesinnungPageListComponent} from './pages/landesinnung/list/landesinnung-page-list.component';
import {LandesinnungPageShowComponent} from './pages/landesinnung/show/landesinnung-page-show.component';
import {MasterContractsPageCreateComponent} from './pages/master-contracts/create/master-contracts-page-create.component';
import {MasterContractsPageListComponent} from './pages/master-contracts/list/master-contracts-page-list.component';
import {MasterContractsPageShowComponent} from './pages/master-contracts/show/master-contracts-page-show.component';
import {MedicalStoreUserEditCreatePageComponent} from './pages/medical-store-user/edit-create/medical-store-user-edit-create-page.component';
import {MedicalStoreUsersListPageComponent} from './pages/medical-store-user/list/medical-store-users-list-page.component';
import {MedicalStoreUserDeleteDialogComponent} from './pages/medical-store-user/medical-store-user-delete-dialog/medical-store-user-delete-dialog.component';
import {MedicalStorePageCreateComponent} from './pages/medical-store/create/medical-store-page-create.component';
import {MedicalStoreListPageComponent} from './pages/medical-store/list/medical-store-list-page.component';
import {MedicalStoreShowPageComponent} from './pages/medical-store/show/medical-store-show-page.component';
import {NewsCreatePageComponent} from './pages/news/create/news-create-page.component';
import {NewsListPageComponent} from './pages/news/list/news-list-page.component';
import {NewsShowPageComponent} from './pages/news/show/news-show-page.component';
import {NewsletterPageComponent} from './pages/newsletter/newsletter-page.component';
import {PreQualificationCertificatePageCreateComponent} from './pages/pre-qualification-certificate/create/pre-qualification-certificate-page-create.component';
import {PreQualificationCertificateShowPageComponent} from './pages/pre-qualification-certificate/show/pre-qualification-certificate-show-page.component';
import {ProfessionalAssociationPageListComponent} from './pages/professional-association/list/professional-association-page-list.component';
import {ProfessionalAssociationPageShowComponent} from './pages/professional-association/show/professional-association-page-show.component';

@NgModule({
	declarations: [
		ContractsComponent,
		AddressCreateComponent,
		AddressEditModelComponent,
		AddressShowModelComponent,
		BaseContactRequirementsCardComponent,
		BaseContractCardFilesComponent,
		BaseContractEditBillingDelayComponent,
		BaseContractEditInitialSignerComponent,
		BaseContractEditJoinedPartiesComponent,
		BaseContractEditJoiningDelayComponent,
		BaseContractEditMandateGrantorComponent,
		BaseContractEditMaximumBackDatingDelayComponent,
		BaseContractEditMinimumTermComponent,
		BaseContractEditNameComponent,
		BaseContractEditNegotiatorsComponent,
		BaseContractEditNoticePeriodComponent,
		BaseContractEditNoticePeriodNegotiationsComponent,
		BaseContractEditPeriodOfValidityComponent,
		BaseContractEditTerminatedAtComponent,
		BaseContractEditValidityStartComponent,
		BaseContractListComponent,
		BaseContractShowBillingDelayComponent,
		BaseContractShowExportsComponent,
		BaseContractShowExternalContractNumberComponent,
		BaseContractShowInitialSignerComponent,
		BaseContractShowJoiningDelayComponent,
		BaseContractShowMandateGrantorComponent,
		BaseContractShowMaximumBackDatingDelayComponent,
		BaseContractShowMinimumTermAtComponent,
		BaseContractShowNameComponent,
		BaseContractShowNegotiatorsComponent,
		BaseContractShowNoticePeriodDelayComponent,
		BaseContractShowNoticePeriodNegotiationDelayComponent,
		BaseContractShowPeriodOfValidityComponent,
		BaseContractShowTerminatedAtComponent,
		BaseContractShowValidityStartComponent,
		BaseContractStatusComponent,
		CommonShowPhoneComponent,
		CommonEditContactComponent,
		CommonEditEmailComponent,
		CommonEditFaxComponent,
		CommonEditNameComponent,
		CommonEditPhoneComponent,
		CommonEditWebsiteComponent,
		CommonShowContactComponent,
		CommonShowEmailComponent,
		CommonShowFaxComponent,
		CommonShowNameComponent,
		CommonShowWebsiteComponent,
		ContractAccessingPageComponent,
		ContractAccessionCardListComponent,
		ContractAccessionCardListComponent,
		ContractAccessionCardMasterDataComponent,
		ContractAccessionCardTimingsComponent,
		ContractAccessionContractComponent,
		ContractAccessionDateBillingDelayComponent,
		ContractAccessionEditAccessionEndAtComponent,
		ContractAccessionEditAccessionStartAtComponent,
		ContractAccessionJoinedPartyComponent,
		ContractAccessionJoiningDelayComponent,
		ContractAccessionListComponent,
		ContractAccessionListPageComponent,
		ContractAccessionMasterContractComponent,
		ContractAccessionShowAccessionEndAtComponent,
		ContractAccessionShowAccessionStartAtComponent,
		ContractAccessionShowPageComponent,
		ContractCardContractingComponent,
		ContractCardJoinedPartiesComponent,
		ContractCardListComponent,
		ContractCardMasterDataComponent,
		ContractSectionEditVersorgungsbereichComponent,
		ContractExternalContractNumberListEditComponent,
		ContractingPartyCardMasterDataComponent,
		ContractingPartyEditContactComponent,
		ContractingPartyEditInsuranceCountComponent,
		ContractingPartyEditNameComponent,
		ContractingPartyListComponent,
		ContractingPartyPageCreateComponent,
		ContractingPartyPageListComponent,
		ContractingPartyPageShowComponent,
		ContractingPartyShowContactComponent,
		ContractingPartyShowInsuranceCountComponent,
		ContractingPartyShowNameComponent,
		ContractSectionCardContractingComponent,
		ContractSectionCardListComponent,
		ContractSectionCardMasterDataComponent,
		ContractSectionShowComponent,
		ContractSectionsPageCreateComponent,
		ContractSectionsPageShowComponent,
		ContractShowComponent,
		ContractShowVersorgungsbereichComponent,
		ContractsPageCreateComponent,
		ContractsPageShowComponent,
		DownloadSectionPageComponent,
		EmployeeCreateFormComponent,
		EmployeeEditRolesComponent,
		EmployeeEditSalutationComponent,
		EmployeeListShortDataCardComponent,
		EmployeeMasterDataCardComponent,
		EmployeePageShowComponent,
		EmployeeSelectComponent,
		EmployeeShowComponent,
		EmployeeShowNameComponent,
		EmployeeShowRolesComponent,
		ExportCardMasterDataComponent,
		ExportEditNextAtComponent,
		ExportEditRecipientsComponent,
		ExportEditStandardDelayComponent,
		ExportShowNextAtComponent,
		ExportShowPageComponent,
		ExportShowRecipientsComponent,
		ExportShowStandardDelayComponent,
		FileCreateComponent,
		FileCreateUploadComponent,
		FileEditDescriptionComponent,
		FileEditIsPublishableComponent,
		FileEditNameComponent,
		FileEditValidityStartAtComponent,
		InstitutionskennzeichenCardMasterDataComponent,
		InstitutionskennzeichenEditNumberComponent,
		InstitutionskennzeichenPageCreateComponent,
		InstitutionskennzeichenPageShowComponent,
		InstitutionskennzeichenSelectComponent,
		InstitutionskennzeichenShowComponent,
		InstitutionskennzeichenShowNumberComponent,
		LandesinnungCardMasterDataComponent,
		LandesinnungEditComponent,
		LandesinnungListComponent,
		LandesinnungPageListComponent,
		LandesinnungPageShowComponent,
		LandesinnungShowComponent,
		MasterContractCardContractingComponent,
		MasterContractCardListComponent,
		MasterContractCardMasterDataComponent,
		MasterContractShowComponent,
		MasterContractsPageCreateComponent,
		BaseContractsCreateComponent,
		MasterContractsPageListComponent,
		MasterContractsPageShowComponent,
		MedicalStoreCardListComponent,
		MedicalStoreContactsCardComponent,
		MedicalStoreContractsCardComponent,
		MedicalStoreEditBivNumberComponent,
		MedicalStoreEditIso13485Component,
		MedicalStoreEditIso9001Component,
		MedicalStoreEditLegalFormComponent,
		MedicalStoreEditMipComponent,
		MedicalStoreEditProprioceptiveDepositsComponent,
		MedicalStoreEditPsaComponent,
		MedicalStoreEditSupplyAreaComponent,
		MedicalStoreListComponent,
		MedicalStoreListPageComponent,
		MedicalStoreMasterDataCardComponent,
		MedicalStorePageCreateComponent,
		MedicalStoreShortCardComponent,
		MedicalStoreShowBivNumberComponent,
		MedicalStoreShowComponent,
		MedicalStoreShowInstitutionskennzeichensComponent,
		MedicalStoreShowIso13485Component,
		MedicalStoreShowIso9001Component,
		MedicalStoreShowLegalFormComponent,
		MedicalStoreShowMipComponent,
		MedicalStoreShowPageComponent,
		MedicalStoreShowProprioceptiveDepositsComponent,
		MedicalStoreShowPsaComponent,
		MedicalStoreShowSupplyAreaComponent,
		MedicalStoreUserDeleteDialogComponent,
		MedicalStoreUserEditCreatePageComponent,
		MedicalStoreUserEditComponent,
		MedicalStoreUserShowComponent,
		MedicalStoreUserShowComponent,
		MedicalStoreUsersListPageComponent,
		ModelFilesCardComponent,
		NewsCardMasterDataComponent,
		NewsCreatePageComponent,
		NewsEditDateFieldComponent,
		NewsEditDisplayEndAtComponent,
		NewsEditDisplayStartAtComponent,
		NewsEditImportanceComponent,
		NewsEditSummaryComponent,
		NewsEditTitleComponent,
		NewsletterPageComponent,
		NewsListPageComponent,
		NewsShowComponent,
		NewsShowFieldTextComponent,
		NewsShowPageComponent,
		PreQualificationCertificateEditAccreditationBodyComponent,
		PreQualificationCertificateEditCertificateIdentifierComponent,
		PreQualificationCertificateEditEmployeeComponent,
		PreQualificationCertificateEditFileComponent,
		PreQualificationCertificateEditInstitutionskennzeichenComponent,
		PreQualificationCertificateEditValidityEndAtComponent,
		PreQualificationCertificateEditValidityStartAtComponent,
		PreQualificationCertificateEditVersorgungsbereichsComponent,
		PreQualificationCertificateListCardComponent,
		PreQualificationCertificateListComponent,
		PreQualificationCertificateMasterDataComponent,
		PreQualificationCertificateOwnerComponent,
		PreQualificationCertificatePageCreateComponent,
		PreQualificationCertificateShowAccreditationBodyComponent,
		PreQualificationCertificateShowCertificateIdentifierComponent,
		PreQualificationCertificateShowPageComponent,
		PreQualificationCertificateShowValidityEndAtComponent,
		PreQualificationCertificateShowValidityStartAtComponent,
		PreQualificationCertificateShowVersorgungsbereichsComponent,
		PreQualificationCertificateValidityComponent,
		ProfessionalAssociationCardMasterDataComponent,
		ProfessionalAssociationEditComponent,
		ProfessionalAssociationListComponent,
		ProfessionalAssociationPageListComponent,
		ProfessionalAssociationPageShowComponent,
		ProfessionalAssociationShowComponent,
		PseudoVersorgungsbereichEditComponent,
		RequirementCreateRequirementComponent,
		RequirementsShowComponent,
		ShowFileComponent,
		NewsEditTextComponent,
		VersionErrorDialogComponent,
		VersionErrorDialogComponent,
		WithdrawalComponent,
		ExternalContractNumberTokenComponent,
		ExternalContractNumberTokenListComponent,
		IqzParticipationPageCreateComponent,
		IqzTrainingCourseShowExternalIdComponent,
		IqzTrainingCourseEditExternalIdComponent,
		IqzTrainingCourseShowTitleComponent,
		IqzTrainingCourseEditTitleComponent,
		IqzTrainingCourseShowStartAtComponent,
		IqzTrainingCourseEditStartAtComponent,
		IqzTrainingCourseShowEndAtComponent,
		IqzTrainingCourseEditEndAtComponent,
		IqzTrainingCourseShowCategoryComponent,
		IqzTrainingCourseEditCategoryComponent,
		IqzTrainingCourseShowAttendanceComponent,
		IqzTrainingCourseEditAttendanceComponent,
		IqzTrainingCourseShowIqzTrainingCoursePointsComponent,
		IqzTrainingCourseEditIqzTrainingCoursePointsComponent,
		IqzTrainingCourseShowIqzTrainingCoursePointsExamComponent,
		IqzTrainingCourseEditIqzTrainingCoursePointsExamComponent,
		IqzTrainingCourseShowIqzWorkshopPointsComponent,
		IqzTrainingCourseEditIqzWorkshopPointsComponent,
		IqzTrainingCourseShowShouldBeCanceledComponent,
		IqzTrainingCourseEditShouldBeCanceledComponent,
		IqzTrainingCourseShowIsCanceledComponent,
		IqzTrainingCourseEditIsCanceledComponent,
		MedicalStoreParticipationsCardComponent,
		IqzParticipationListComponent,
		MedicalStoreShowIqzCategoriesComponent,
		MedicalStoreEditIqzCategoriesComponent,
		MedicalStoreShowHmmParticipationComponent,
		MedicalStoreEditHmmParticipationComponent,
	],
	imports: [
		CommonModule,
		ContractsRoutingModule,
		MainModule,
		MatCardModule,
		MatTableModule,
		FontAwesomeModule,
		MatMenuModule,
		MatButtonModule,
		MatIconModule,
		FormsModule,
		MatFormFieldModule,
		MatInputModule,
		MatProgressSpinnerModule,
		MatListModule,
		MatTooltipModule,
		MatDialogModule,
		ReactiveFormsModule,
		TranslateModule,
		MatSlideToggleModule,
		MatDatepickerModule,
		MatMomentDateModule,
		MatAutocompleteModule,
		MatOptionModule,
		MatChipsModule,
		MatSelectModule,
		MatCheckboxModule,
		MatStepperModule,
		MatTreeModule,
		MatExpansionModule,
		AngularEditorModule,
		MatRadioModule,
		DoubleTokenComponent,
		EditFieldNumberComponent,
		BypassSecurityTrustResourcePipe,
	],
	exports: [
		ShowFileComponent,
		FileCreateUploadComponent,
		NewsListPageComponent,
		NewsShowComponent,
		NewsShowPageComponent,
		NewsListPageComponent,
		NewsCreatePageComponent,
		ExternalContractNumberTokenComponent,
	],
	providers:    [],
})
export class ContractsModule {
}
