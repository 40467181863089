import {Routes} from '@angular/router';
import {environment} from '@app/environment';
import {
	HAS_PERMISSION_GUARD_USE_ROUTE_ID,
	hasPermissionGuard,
	LoginGuard,
	TermsAndConditionsAcceptedGuard,
	UrlMatchers,
} from '@app/main';
import {MasterContractModel} from '@contracts/frontend-api';
import {CalculationInfoGuard} from '../main/guards/calculation-info.guard';
import {CalculationModel} from './models/calculation/calculation.model';
import {CalculationInfoAcceptPageComponent} from './pages/calculation-info-accept-page/calculation-info-accept-page.component';
import {CalculationListPageComponent} from './pages/calculations/list/calculation-list-page.component';
import {CalculationShowPageComponent} from './pages/calculations/show/calculation-show-page.component';
import {HourlyBillingRateBracketPageComponent} from './pages/hourly-billing-rate-bracket-page/hourly-billing-rate-bracket-page.component';
import {JoinedPartiesPageComponent} from './pages/joined-parties-page/joined-parties-page.component';

const routes: Routes = [
	{
		path:             '',
		canActivateChild: [
			LoginGuard,
			TermsAndConditionsAcceptedGuard,
			hasPermissionGuard(CalculationModel),
		],
		children:         [
			{
				path:        environment.calculationBaseUrl,
				canActivate: [
					CalculationInfoGuard,
				],
				children:    [
					{
						path:      '',
						component: CalculationListPageComponent,
					},
					{
						matcher:     UrlMatchers.uuid(),
						component:   CalculationShowPageComponent,
						canActivate: [
							hasPermissionGuard(CalculationModel, HAS_PERMISSION_GUARD_USE_ROUTE_ID),
						],
					},
				],
			},
			{
				path:        environment.hourlyBillingRateBracketsBaseUrl,
				component:   HourlyBillingRateBracketPageComponent,
				canActivate: [
					hasPermissionGuard(MasterContractModel),
				],
			},
			{
				path:        environment.joinedPartiesBaseUrl,
				component:   JoinedPartiesPageComponent,
				canActivate: [
					hasPermissionGuard(MasterContractModel),
				],
			},
		],
	},
	{
		path:      environment.calculationInfoAcceptPageBaseUrl,
		component: CalculationInfoAcceptPageComponent,
	},
];
export default routes;
