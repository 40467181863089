import {AbstractParser} from '@angular-helpers/frontend-api';
import {TaskModel} from './task.model';
import {TaskService} from './task.service';

export class TaskParser extends AbstractParser<TaskModel, TaskService> {
	protected sanitizeInput(propertyDto: string, property: string, value: unknown): unknown {
		switch(property) {
			case 'times':
				if(Array.isArray(value) === false)
					throw new Error(`Invalid parameter '${property}'. Expected array got: ${JSON.stringify(value)}`);

				return this.castToModelArray(value, this.service.taskService);

			// todo remove - workaround for backend
			case 'position':
				if(typeof value === 'string')
					value = Number.parseInt(value);

				return value;

			// todo remove - workaround for backend
			case 'scope':
				if(typeof value !== 'string')
					throw new Error(`Invalid parameter '${property}'. Expected string got: ${JSON.stringify(value)}`);

				switch(value) {
					default:
						return value;

					case 'Anamnese (Beratung, Evaluierung, Anamnesbogen)\'':
						return 'ANAMNESE';

					case 'Anpassung des Exopulse Mollii Suit':
						return 'ANPASSUNG';

					case 'Herstellung bis zur 1. Anprobe':
						return 'HERSTELLUNG';

					case 'Übertragung d. Testprothese':
						return 'TESTPROTHESE_UBERTRAGUNG';

					case 'Definitiv-Schaft/Prothese Schafttechnik':
						return 'PROTHESE_SCHAFTTECHNIK';

					case 'Modell':
						return 'MODELL';

					case 'Testschaft Herstellung bis zur Anprobe':
						return 'TESTSCHAFT_HERSTELLUNG';

					case 'Testprothese Herstellung bis zur Anprobe':
						return 'TESTPROTHESE_HERSTELLUNG';

					case 'Definitiv-Schaft/Prothese Herstellung bi zur Anprobe\'':
						return 'PROTHESE_HERSTELLUNG';

					case 'Testprothese stat. u. dyn. Anprobe':
						return 'TESTPROTHESE_ANPROB';

					case 'Prothesengebrauchsschulung':
						return 'PROTHESENGEBRAUCHSSCHULUN';

					case 'Liner und Linerverschlusssystem':
						return 'LINER_UND_LINERVERSCHLUSSSYSTE';

					case 'Testschaft Anprobe':
						return 'TESTSCHAFT_ANPROB';

					case 'Definitiv-Schaft/Prothese Anprobe':
						return 'PROTHESE_ANPROBE';

					case 'Änderung, Passformgarantie':
						return 'PASSFORMGARANTI';

					case '1. Änderung und Kontrolle':
						return 'AENDERUNG_1';

					case '2. Änderung und Kontrolle':
						return 'AENDERUNG_2';

					case 'T0 Initialer Test zum Patient Reportet Otcome (PRO)\'':
						return 'REPORTED_OUTCOME';

					case 'Stimulation unter Supervision':
						return 'STIMULATION';

					case 'T1 Test nach Stimulation':
						return 'TEST_1';

					case 'T2 Test nach 4 Wochen':
						return 'TEST_2';

					case 'Auslieferung und Einweisung in Funktion  Handhabung\'':
						return 'AUSLIEFERUNG';

					case 'Aufklärung zu verpflichtenden Wartungsinervallen\'':
						return 'AUFKLARUNG_WARTUNGSINERVALLEN';

					case '1. Service und Kontrolle nach 4-6 Wochen':
						return 'SERVICE_1';

					case '2. Service und Kontrolle nach 3 Monaten':
						return 'SERVICE_2';

					case '3. Service und Kontrolle nach 6 Monaten':
						return 'SERVICE_3';

					case '4. Service und Kontrolle nach 12 Monaten':
						return 'SERVICE_4';

				}

			default:
				return super.sanitizeInput(propertyDto, property, value);
		}
	}
}