<mat-form-field>
	@if (control.value == null) {
		<fa-icon [icon]="iconService.SEARCH" matPrefix/>
	} @else {
		<fa-icon
			[icon]="iconService.CANCEL"
			(click)="control.setValue(undefined); $event.preventDefault(); $event.stopPropagation();"
			class="clickable"
			matPrefix/>
	}

	<mat-select [formControl]="control">
		<!-- todo add search -->
		@for (entry of possibleValues | keyvalue;track entry) {
			<mat-option
				[value]="entry.value">
				{{ entry.key | translate }}
			</mat-option>
		}
	</mat-select>
</mat-form-field>