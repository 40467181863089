import {AbstractApiService} from '@angular-helpers/frontend-api';
import {PageRequestOptions} from '@angular-helpers/frontend-api/lib/models/abstract-model/abstract.api-service';
import {Injectable} from '@angular/core';
import {SystemPreferences} from '@app/main';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {CalculationsModule} from '../../calculations.module';
import {CalculationModel} from './calculation.model';

export const CALCULATION_EXPORT_FILE_TYPES = [
	'xlsx',
	'pdf',
] as const;
export type CalculationExportFileType = typeof CALCULATION_EXPORT_FILE_TYPES[number];

@Injectable({
	providedIn: 'root',
})
export class CalculationApiService extends AbstractApiService {
	constructor() {
		super(CalculationsModule.injector);
	}

	generateExport(calculation: CalculationModel, hourlyBillingRate: number, fileType: CalculationExportFileType, extended: boolean): Observable<Blob> {
		const url = `${this.getUrl()}/${calculation.id}/export${extended ? '/extended' : ''}`;
		const body = {
			hourlyBillingRate,
			fileType,
		};
		return this.httpClient.post(url, body, {responseType: 'blob'}).pipe(
			map(response => {
				if((response as unknown) instanceof Blob)
					return response;

				throw new Error('Invalid response type');
			}),
		);
	}

	generateListExport(includedColumns: string[], search: PageRequestOptions['search'], hourlyBillingRates: SystemPreferences['hourlyRates'], fileType: CalculationExportFileType): Observable<Blob> {
		const url = `${this.getUrl()}/export`;
		const body: Record<string, unknown> = {
			fileType,
			hourlyBillingRates,
			includedColumns,
		};

		if(search != null)
			body.search = search;

		return this.httpClient.post(url, body, {responseType: 'blob'}).pipe(
			map(response => {
				if((response as unknown) instanceof Blob)
					return response;

				throw new Error('Invalid response type');
			}),
		);
	}

	protected getBaseUrl(): string {
		return '/v1/calculation';
	}
}