@if (data !== undefined) {
	<portal-table-client-side-searchable
		[headers]="clientSideSearchTableHeaders"
		[searchFilter]="searchFilter"
		[columnLink]="environment.contractAccessionFullUrl"
		[data]="data"
	>
		<ng-template column="contractSection" let-model="model" [data]="data">
			<portal-skeleton-field [content]="getContractSection$(model)"/>
		</ng-template>
		<ng-template column="contract" let-model="model" [data]="data">
			<portal-skeleton-field [content]="getContract$(model)"/>
		</ng-template>
		<ng-template column="masterContract" let-model="model" [data]="data">
			<portal-skeleton-field [content]="getMasterContract$(model)"/>
		</ng-template>
		<ng-template column="joinedParties" let-model="model" [data]="data">
			<portal-skeleton-field [content]="getJoinedParties$(model)">
				<ng-template let-joinedParties skeletonContent>
					@for (joinedParty of joinedParties;track joinedParty) {
						<span class="line">{{ joinedParty }}</span>
					}
				</ng-template>
			</portal-skeleton-field>
		</ng-template>
		<ng-template column="institutionskennzeichen" let-model="model" [data]="data">
			<portal-skeleton-field [content]="getInstitutionskennzeichen$(model) | formatInstitutionskennzeichen"/>
		</ng-template>
	</portal-table-client-side-searchable>
}

@if (data === undefined) {
	<portal-table-server-side-searchable
		[headers]="serverSideSearchHeaders"
		[columnLink]="environment.contractAccessionFullUrl"
		[service]="contractAccessionService"
		sortBy="accessionStartAt"
		[sortAscending]="false"
	>
		<ng-template column="contractSection" let-model="model" [service]="contractAccessionService">
			<portal-skeleton-field [content]="getContractSection$(model)"/>
		</ng-template>
		<ng-template column="contract" let-model="model" [service]="contractAccessionService">
			<portal-skeleton-field [content]="getContract$(model)"/>
		</ng-template>
		<ng-template column="masterContract" let-model="model" [service]="contractAccessionService">
			<portal-skeleton-field [content]="getMasterContract$(model)"/>
		</ng-template>
		<ng-template column="joinedParties" let-model="model" [service]="contractAccessionService">
			<portal-skeleton-field [content]="getJoinedParties$(model)">
				<ng-template let-joinedParties skeletonContent>
					@for (joinedParty of joinedParties;track joinedParty) {
						<span class="line">{{ joinedParty }}</span>
					}
				</ng-template>
			</portal-skeleton-field>
		</ng-template>
		<ng-template column="institutionskennzeichen" let-model="model" [service]="contractAccessionService">
			<portal-skeleton-field [content]="getInstitutionskennzeichen$(model) | formatInstitutionskennzeichen"/>
		</ng-template>
		<ng-template column="externalContractNumbers" let-model="model" [service]="contractAccessionService">
			<portal-skeleton-field *ngLet="(getExternalContractNumbers$(model) | async) as model" [loading]="model==null">
				<portal-external-contract-number-token-list [numbers]="model" [type]="externalContractNumberType.legs"/>
			</portal-skeleton-field>
		</ng-template>
	</portal-table-server-side-searchable>
}
