import {
	AbstractApiService,
	AbstractService,
	ResultPageModel,
} from '@angular-helpers/frontend-api';
import {
	Directive,
	Input,
	TemplateRef,
} from '@angular/core';
import {AnyModel} from '../../helper/any';
import {Column} from './table.component';

export interface TableHeaderData<ModelType extends object> {
	column: Column<ModelType>;
}

@Directive({
	selector: 'ng-template[header][data],ng-template[header][service]',
})
export class TableHeaderTemplateDirective<ModelType extends object> {
	@Input({required: true}) header!: string;
	@Input() data?: ModelType[] | ResultPageModel<ModelType> | undefined | null;
	@Input() service?: AbstractService<AbstractApiService, ModelType & AnyModel>;

	constructor(public readonly template: TemplateRef<TableHeaderData<ModelType>>) {
	}

	static ngTemplateContextGuard<ModelType extends object>(dir: TableHeaderTemplateDirective<ModelType>, ctx: unknown): ctx is TableHeaderData<ModelType> {
		return true;
	}
}
