import {AngularEditorConfig} from '@kolkov/angular-editor';

export declare type Stage = 'LOCAL' | 'DEVELOPMENT' | 'BETA' | 'PRODUCTION';

export class Environment {
	readonly homeBaseUrl                               = '/';
	readonly homeFullUrl                               = `/${this.homeBaseUrl}`;
	readonly loginBaseUrl                              = 'login';
	readonly loginFullUrl                              = `/${this.loginBaseUrl}`;
	readonly logoutBaseUrl                             = 'logout';
	readonly logoutFullUrl                             = `/${this.logoutBaseUrl}`;
	readonly dashboardBaseUrl                          = 'dashboard';
	readonly dashboardFullUrl                          = `/${this.dashboardBaseUrl}`;
	readonly termsAndConditionsBaseUrl                 = 'terms-and-conditions';
	readonly termsAndConditionsFullUrl                 = `/${this.termsAndConditionsBaseUrl}`;
	readonly acceptTermsAndConditionsBaseUrl           = 'accept-terms-and-conditions';
	readonly acceptTermsAndConditionsFullUrl           = `/${this.acceptTermsAndConditionsBaseUrl}`;
	readonly impressumBaseUrl                          = 'impressum';
	readonly impressumFullUrl                          = `/${this.impressumBaseUrl}`;
	readonly privacyBaseUrl                            = 'privacy';
	readonly privacyFullUrl                            = `/${this.privacyBaseUrl}`;
	readonly dependenciesBaseUrl                       = 'dependencies';
	readonly dependenciesFullUrl                       = `/${this.dependenciesBaseUrl}`;
	readonly contractsModuleBaseUrl             = 'contracts';
	readonly medicalStoreUsersUrl                      = 'medical-store-users';
	readonly medicalStoreUsersFullUrl           = `/${this.contractsModuleBaseUrl}/${this.medicalStoreUsersUrl}`;
	readonly medicalStoresBaseUrl                      = 'medical-store';
	readonly medicalStoresFullUrl               = `/${this.contractsModuleBaseUrl}/${this.medicalStoresBaseUrl}`;
	readonly masterContractsBaseUrl                    = 'master-contract';
	readonly masterContractsFullUrl             = `/${this.contractsModuleBaseUrl}/${this.masterContractsBaseUrl}`;
	readonly contractsBaseUrl                          = 'contract';
	readonly contractsFullUrl                   = `/${this.contractsModuleBaseUrl}/${this.contractsBaseUrl}`;
	readonly contractSectionsBaseUrl                   = 'contract-section';
	readonly contractSectionsFullUrl            = `/${this.contractsModuleBaseUrl}/${this.contractSectionsBaseUrl}`;
	readonly contractingPartyBaseUrl            = 'contracting-party';
	readonly contractingPartyFullUrl            = `/${this.contractsModuleBaseUrl}/${this.contractingPartyBaseUrl}`;
	readonly contractAccessionBaseUrl           = 'contract-accession';
	readonly contractAccessionFullUrl           = `/${this.contractsModuleBaseUrl}/${this.contractAccessionBaseUrl}`;
	readonly landesinnungBaseUrl                = 'landesinnung';
	readonly landesinnungFullUrl                = `/${this.contractsModuleBaseUrl}/${this.landesinnungBaseUrl}`;
	readonly professionalAssociationBaseUrl            = 'professional-association';
	readonly professionalAssociationFullUrl     = `/${this.contractsModuleBaseUrl}/${this.professionalAssociationBaseUrl}`;
	readonly contractAccessingBaseUrl                  = `${this.contractAccessionBaseUrl}/accessing`;
	readonly contractAccessingFullUrl           = `/${this.contractsModuleBaseUrl}/${this.contractAccessingBaseUrl}`;
	readonly employeeBaseUrl                           = 'employee';
	readonly employeeFullUrl                    = `/${this.contractsModuleBaseUrl}/${this.employeeBaseUrl}`;
	readonly preQualificationCertificateBaseUrl        = 'pre-qualification-certificate';
	readonly preQualificationCertificateFullUrl = `/${this.contractsModuleBaseUrl}/${this.preQualificationCertificateBaseUrl}`;
	readonly institutionskennzeichenBaseUrl            = 'institutionskennzeichen';
	readonly institutionskennzeichenFullUrl     = `/${this.contractsModuleBaseUrl}/${this.institutionskennzeichenBaseUrl}`;
	readonly downloadSectionBaseUrl                    = 'download-section';
	readonly downloadSectionFullUrl             = `/${this.contractsModuleBaseUrl}/${this.downloadSectionBaseUrl}`;
	readonly newsletterBaseUrl                         = 'newsletter';
	readonly newsletterFullUrl                         = `/${this.newsletterBaseUrl}`;
	readonly newsBaseUrl                               = 'news';
	readonly newsFullUrl                        = `/${this.contractsModuleBaseUrl}/${this.newsBaseUrl}`;
	readonly exportBaseUrl                             = 'export';
	readonly exportFullUrl                      = `/${this.contractsModuleBaseUrl}/${this.exportBaseUrl}`;
	readonly infoPageMdrBaseUrl                        = 'member-information/mdr';
	readonly infoPageMdrFullUrl                        = `/${this.infoPageMdrBaseUrl}`;
	readonly infoPageImageCampaignCraftsmanshipBaseUrl = 'member-information/image-campaign-craftsmanship';
	readonly infoPageImageCampaignCraftsmanshipFullUrl = `/${this.infoPageImageCampaignCraftsmanshipBaseUrl}`;
	readonly maintenanceUrlBaseUrl                     = `maintenance`;
	readonly maintenanceUrlFullUrl                     = `/${this.maintenanceUrlBaseUrl}`;

	readonly calculationsModuleBaseUrl = '/calculations';
	readonly calculationBaseUrl        = 'calculation';
	readonly calculationFullUrl        = `${this.calculationsModuleBaseUrl}/${this.calculationBaseUrl}`;
	readonly hourlyBillingRateBracketsBaseUrl = 'hourly-billing-rate-brackets';
	readonly hourlyBillingRateBracketsFullUrl = `${this.calculationsModuleBaseUrl}/${this.hourlyBillingRateBracketsBaseUrl}`;
	readonly joinedPartiesBaseUrl = 'joined-parties';
	readonly joinedPartiesFullUrl = `${this.calculationsModuleBaseUrl}/${this.joinedPartiesBaseUrl}`;

	readonly calculationInfoAcceptPageBaseUrl = 'calculation-info-accept';
	readonly calculationInfoAcceptPageFullUrl = `${this.calculationsModuleBaseUrl}/${this.calculationInfoAcceptPageBaseUrl}`;

	readonly downloadRawContentId             = '271401d8-86d2-438e-8832-d6714996e673';
	readonly newsletterRawContentId           = '1b24d184-833e-4fde-bc4b-a41a617d205e';
	readonly termsAndConditionsNewsId = 'd295f20b-56e1-425b-95c1-5936da32fd63';
	readonly calculationsInfoNewsId = '57e17d70-f616-49fa-858c-d97501e09615';
	readonly employeeMedicalStoreLeaderRoleId = '2a456309-71c7-4b74-8b3a-60c3d9f5ae99';
	readonly maintenanceNewsId                = 'c28b965f-6640-4325-9d99-720849f1790b';
	readonly memberInformationPageRawContentId = '058b53d5-dc04-4889-855b-56e4519a5c8b';
	readonly iqzCategoryBasicId               = 'aec6ede2-f70e-4421-b0a7-9e465d5ea607';
	readonly IQZ_POINTS_VALID_PERIOD_YEARS    = 3;
	readonly IQZ_REQUIRED_POINTS_PER_CATEGORY = 20;
	readonly externalContactUrl               = 'https://biv-ot.org/vertraege/kontakt/';
	// eslint-disable-next-line @typescript-eslint/no-var-requires
	readonly version: string                  = require('../../package.json').version;
	// eslint-disable-next-line @typescript-eslint/no-var-requires
	readonly name: string                     = require('../../package.json').name;
	readonly config: AngularEditorConfig      = {
		editable:                  true,
		translate:                 'yes',
		spellcheck:                true,
		minHeight:                 '10em',
		maxHeight:                 '50vh',
		defaultParagraphSeparator: 'p',
		toolbarHiddenButtons:      [
			[
				'fontName',
				'fontSize',
				'textColor',
				'backgroundColor',
				'customClasses',
				'insertVideo',
				'insertHorizontalRule',
				'toggleEditorMode',
				'insertImage',
			],
		],
	};

	constructor(
		readonly stage: Stage,
		readonly authServer: string,
		readonly authClientId: string,
		readonly authRedirectUrl: string,
		readonly contractsApiUrl: string,
		readonly calculationsApiUrl: string,
	) {
	}

	get isOnServer(): boolean {
		return this.stage !== 'LOCAL';
	}

	get redirectUrl(): string {
		return `${document.location.protocol}//${document.location.host}${this.authRedirectUrl}`;
	}
}
