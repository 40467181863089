import {Level8Error} from '@angular-helpers/frontend-api';
import {
	Component,
	DestroyRef,
	EventEmitter,
	inject,
	Input,
	OnInit,
	Output,
} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {UntypedFormGroup} from '@angular/forms';
import {MedicalStoreListComponent} from '@app/contracts';
import {
	combineLatestSafe,
	IconService,
	MinimalColumns,
	notNull,
	SearchFilter,
} from '@app/main';
import {
	AddressInterface,
	MedicalStoreModel,
	MedicalStoreService,
	MedicalStoreUserModel,
} from '@contracts/frontend-api';
import {TranslateService} from '@ngx-translate/core';
import {
	combineLatest,
	Observable,
	of,
	Subject,
	throwError,
	timer,
} from 'rxjs';
import {
	catchError,
	debounceTime,
	map,
	mergeMap,
	tap,
	throttle,
} from 'rxjs/operators';

export interface ElementOperationDataInterface {
	updated: boolean;
	removed: boolean;
	created: boolean;
	model: MedicalStoreModel;
	state: ElementState;
}

export interface ElementData {
	permissionToEdit: boolean;
	permissionToView: boolean;
	model: MedicalStoreModel;
	address: AddressInterface | undefined;
	name: string | undefined;
	ikNumbers: string[] | undefined;
}

export interface ElementState {
	canView: boolean;
	canEdit: boolean;
	inheritedView: boolean;
	inheritedEdit: boolean;
	touchedByUser: boolean;
	touchedBySystem: boolean;
}

export interface ElementEntryStateInterface {
	data: ElementData;
	oldState: ElementState;
	state: ElementState;
}

class ElementEntryState implements ElementEntryStateInterface {
	data: ElementData;
	oldState: ElementState;
	state: ElementState;
	parent?: ElementEntryState;
	children?: ElementEntryState[];

	getData?: (data: ElementData) => string;

	constructor(data: ElementData, oldState: ElementState, state: ElementState) {
		this.data  = data;
		this.oldState = oldState;
		this.state = state;
	}

	checkView(status: boolean, system = false): void {
		if(status === true)
			this.state.canView = true;
		else {
			this.state.canView = false;
			this.state.canEdit = false;
		}
		if(system)
			this.state.touchedBySystem = true;
		else
			this.state.touchedByUser = true;

		this.applyInheritanceToChildren();
	}

	checkEdit(status: boolean, system = false): void {
		if(status) {
			this.state.canEdit = true;
			this.state.canView = this.parent == null ? true : !this.parent.state.canView;
		} else
			this.state.canEdit = false;

		if(system)
			this.state.touchedBySystem = true;
		else
			this.state.touchedByUser = true;

		this.applyInheritanceToChildren();
	}

	setParent(parent: ElementEntryState): void {
		this.parent = parent;

		if(parent.children == null)
			parent.children = [this];
		else
			parent.addChild(this);
	}

	addChild(child: ElementEntryState): void {
		if(this.children == null)
			this.children = [child];
		else {
			const hasAlready = this.children.some((childL) => childL === child);
			if(!hasAlready)
				this.children.push(child);
		}

		child.parent = this;
	}

	childAlreadyPresent(child: ElementEntryState) {
		let hasChild = false;
		if(this.children != null) {
			hasChild = this.children.some((local) => {
				if(this.getData != null)
					return this.getData(local.data).includes(this.getData(child.data));

				return child === local;
			});
		}
		return hasChild;
	}

	removeChild(child: ElementEntryState): void {
		if(this.children == null || this.children.length < 1)
			return;

		const index = this.children.findIndex((value) => value === child);
		this.children.splice(index, 1);
	}

	reapplyChangesToState(state: ElementState, changes: ElementOperationDataInterface): ElementState {
		if(!changes.updated)
			return state;

		return state;
	}

	applyInheritanceFromParent(parent: ElementEntryState): void {
		const state = this.getInheritedStateFromParent(parent);

		if(this.state.touchedBySystem)
			state.touchedBySystem = true;

		state.touchedByUser = this.state.touchedByUser || parent.state.touchedByUser;
		if(state.touchedByUser) {
			if(!parent.state.canEdit && this.state.canEdit && parent.state.canView)
				state.canEdit = this.state.canEdit;
		} else {
			if(this.state.touchedBySystem) {
				if(this.oldState.canView && !state.inheritedView)
					state.canView = true;

				if(this.oldState.canEdit)
					state.canEdit = true;
			}
		}
		this.state = state;
	}

	applyInheritanceToChildren(): void {
		if(this.children == null || this.children.length < 1)
			return;

		for(const child of this.children) child.applyInheritanceFromParent(this);
	}

	getInheritedStateFromParent(parent: ElementEntryState): ElementState {
		const newState: ElementState = {
			canEdit:       false,
			canView:       false,
			inheritedEdit: false,
			inheritedView: false,
			touchedBySystem: false,
			touchedByUser: false,
		};

		if(parent.state.canEdit && parent.state.canView) {
			newState.inheritedEdit = true;
			newState.inheritedView = true;
			newState.canView = false;
			newState.canEdit = false;
			return newState;
		}

		if(!parent.state.canEdit && parent.state.canView) {
			newState.inheritedView = true;
			newState.inheritedEdit = false;
			newState.canView = false;
			newState.canEdit = false;
			return newState;
		}

		return newState;
	}

	getChanges(): ElementOperationDataInterface {
		let created = false;
		let updated = false;
		let removed = false;

		if(!this.isRemovable(this.oldState) && this.isRemovable(this.state)) {
			removed = true;
			updated = true;
		}

		if(!this.isCreatable(this.oldState) && this.isCreatable(this.state)) {
			created = true;
			updated = true;
		}

		if(this.state.canEdit !== this.oldState.canEdit)
			updated = true;

		return {
			model: this.data.model,
			state: {...this.state},
			removed,
			updated,
			created,
		};
	}

	isCreatable(state: ElementState) {
		return state.canView || (state.inheritedView && state.canEdit);
	}

	isRemovable(state: ElementState) {
		return !state.canView && !state.canEdit;
	}

	applyState(state?: ElementState): void {
		if(state == null) {
			this.oldState = {...this.state};
			return;
		}
		this.oldState = state;
		this.state = state;

		this.state.touchedBySystem = true;
		this.oldState.touchedBySystem = true;
	}

	resetState(): void {
		this.state = {...this.oldState};
	}
}

class CSearchFilter extends SearchFilter<ElementEntryState> {
	protected readonly translateService = inject(TranslateService);


	protected async getModelValue(
		field: string,
		model: ElementEntryState,
	): Promise<unknown> {
		switch(field) {
			case 'name':
				return model.data.name;

			case 'institutionskennzeichens':
				if(model.data.ikNumbers == null)
					return '';

				return `${model.data.ikNumbers.join(', ')}`;

			case 'address':
				return MedicalStoreListComponent.formatAddress(model.data.address);

			case 'canEdit':
				return model.state.canEdit || model.state.inheritedEdit;

			case 'canView':
				return model.state.canView || model.state.inheritedView;

			case 'isParent':
				return await this.translateService
				                 .get((await model.data.model.parent.firstValue) == null ? 'medicalStore.mainHouse' : 'medicalStore.branchOffice')
				                 .toPromise();

			default:
				throw new Error('field not found, can not search field');
		}
	}
}

class TablePageController {
	readonly pageSize                                        = 25;
	readonly searchFilter                                    = new CSearchFilter();
	readonly translateService                                = inject(TranslateService);
	readonly tableHeaders: MinimalColumns<MedicalStoreModel> = {
		isParent:                 {
			label:       'medicalStore.create.title.isParent',
			index:       0,
			isSortable: false,
			deserialize: x => JSON.parse(x),
		},
		institutionskennzeichens: {
			label:         'model.institutionskennzeichens',
			index:         1,
			isSortable: false,
			prepareSearch: (value) => {
				if(typeof value === 'string') {
					const REGEX_ALL_SPACES = /\s/g;
					return value.replace(REGEX_ALL_SPACES, '');
				}

				return value;
			},
		},
		name:                     {
			label: 'common.name',
			index: 2,
		},
		address:                  {
			label: 'common.address',
			isVisible:  false,
			isSortable: false,
			index: 3,
		},
		email:                    {
			label: 'common.email',
			isVisible: false,
			index: 4,
		},
		phone:                    {
			label: 'common.phone',
			isVisible: false,
			index: 5,
		},
		fax:                      {
			label: 'common.fax',
			isVisible: false,
			index:     6,
		},
		landesinnung:             {
			label:     'model.landesinnung',
			isSortable: false,
			isVisible:  false,
			index:     7,
		},
		userCanRead:              {
			label: 'medicalStoreUser.canView',
			index: 8,
			isSortable: false,
			serialize:   value => 'true',
			deserialize: value => value ? this.userId : undefined,
		},
		userCanEdit:              {
			label: 'medicalStoreUser.canEdit',
			index: 8,
			isSortable: false,
			serialize:   value => 'true',
			deserialize: value => value ? this.userId : undefined,
		},
	};
	readonly isParentFilter                                  = new Map([
		[
			'medicalStore.mainHouse',
			true,
		],
		[
			'medicalStore.branchOffice',
			false,
		],
	]);
	readonly hasPermissionFilter                             = new Map([
		[
			'medicalStoreUser.permitted',
			this.userId,
		],
	]);
	protected _userId: string | undefined;

	get userId(): string {
		return this._userId ?? '';
	}

	set userId(userId: string | undefined) {
		this._userId = userId;
		this.hasPermissionFilter.set('medicalStoreUser.permitted', userId ?? '');
	}
}

@Component({
	selector:    'portal-medical-store-edit-user-permission',
	templateUrl: './medical-store-user-edit.component.html',
	styleUrls:   ['./medical-store-user-edit.component.scss'],
})
export class MedicalStoreUserEditComponent implements OnInit {
	@Input({required: true}) control!: UntypedFormGroup;
	@Input('reset') reset$?: Observable<unknown>;
	@Input('reInitialize') reInitialize$?: Observable<unknown>;
	@Input('outerProcess') outerProcess$?: Observable<boolean>;
	@Input() disable?: boolean;
	@Output() listChange: EventEmitter<Map<string, ElementOperationDataInterface>> = new EventEmitter();
	@Output() userDetected: EventEmitter<MedicalStoreUserModel | undefined>        = new EventEmitter();
	@Output() isRemovingAll: EventEmitter<boolean>                                 = new EventEmitter();
	protected readonly update$                               = new Subject<void>();
	protected readonly onUpdate$: Observable<void>;
	protected selectedUserId?: string;
	protected readonly tableController                       = new TablePageController();
	protected readonly updatePermissionState$                = new Subject<ElementEntryState>();
	protected readonly permissionStateUpdated$: Observable<ElementEntryState>;
	protected readonly modelsPrepared$: Observable<ElementEntryState>;
	protected readonly prepareModel$                         = new Subject<MedicalStoreModel>();
	protected readonly loadedMedicalStoreStates              = new Map<string, ElementEntryState>();
	protected loadedMedicalStoreStatesList?: ElementEntryState[];
	private readonly processing$                             = new Subject<boolean>();

	constructor(
		protected readonly medicalStoreService: MedicalStoreService,
		protected readonly destroyRef: DestroyRef,
		protected readonly iconService: IconService,
	) {
		this.onUpdate$ = this.update$.pipe(debounceTime(200));

		this.modelsPrepared$ = this.prepareModel$.pipe(
			mergeMap((store) => {
				const hasStore = this.loadedMedicalStoreStates.get(store.id);
				if(hasStore)
					return of(hasStore);

				return combineLatest([
					of(store),
					store.name.value,
					store.address.value,
					store.institutionskennzeichens.value.pipe(
						mergeMap((models) =>
							combineLatestSafe(
								models?.map(ikNumModel => ikNumModel.number.value),
							),
						),
					),
					store.users.permissions.canUpdate,
					store.users.permissions.canRead,
				]).pipe(map(([store, name, address, institutionskennzeichens, canUpdate, canRead]) => ({
					store,
					name,
					address,
					institutionskennzeichens,
					canUpdate,
					canRead,
				})));
			}),
			mergeMap(storeState => {
				if(storeState instanceof ElementEntryState) {
					return combineLatest([
						of(storeState.data.model),
						storeState.data.model.parent.value,
						storeState.data.model.children.value,
					]);
				}
				const numbers = (storeState.institutionskennzeichens ?? []).filter(notNull);

				this.addMedicalStore(
					storeState.store,
					false,
					false,
					numbers,
					storeState.name,
					storeState.address,
					false,
					storeState.canUpdate,
					storeState.canRead,
				);

				return combineLatest([
					of(storeState.store),
					storeState.store.parent.value,
					storeState.store.children.value,
				]);
			}),
			map((storeState) => {
				const [store, parent, children] = storeState;
				const baseState = this.loadedMedicalStoreStates.get(store.id);

				if(baseState == null)
					throw new Level8Error('store not loaded');

				if(parent != null) {
					const wasProcessed = this.loadedMedicalStoreStates.get(parent.id);
					if(wasProcessed != null)
						wasProcessed.addChild(baseState);
					else
						this.prepareModel$.next(parent);
				}

				if(children != null && children.length > 0) {
					for(const child of children) {
						const childAlreadyLoaded = this.loadedMedicalStoreStates.get(child.id);
						if(childAlreadyLoaded != null) {
							const isChildAdded = baseState.childAlreadyPresent(childAlreadyLoaded);
							if(!isChildAdded)
								this.prepareModel$.next(child);
						} else
							this.prepareModel$.next(child);
					}
				}

				return baseState;
			}),
			catchError((error, observable) => {
				this.processing$.next(false);

				if(error.message.includes('could not find user') === true)
					return observable.pipe(throttle(() => timer(500)));

				return throwError(error);
			}),
		);

		this.permissionStateUpdated$ = this.updatePermissionState$.pipe(
			mergeMap((store) => this.updateOriginalElementPermission$(this.selectedUserId, store.data.model)),
			map((list) => list),
		);

		this.onUpdate$
		    .pipe(takeUntilDestroyed(this.destroyRef))
		    .subscribe(() => {
			    this.emitChange();
		    });

		this.modelsPrepared$.pipe(
			takeUntilDestroyed(this.destroyRef),
			debounceTime(20),
		).subscribe(() => {
			this.updatePermissions();
		});

		this.permissionStateUpdated$.pipe(
			debounceTime(20),
			takeUntilDestroyed(this.destroyRef),
		).subscribe(() => {
			this.update$.next();
		});
	}

	@Input() set user(user: MedicalStoreUserModel | undefined) {
		this.tableController.userId = user?.id;
		this.selectedUserId         = user?.id;
		this.userDetected.emit(user);
		this.updatePermissions();
	}

	ngOnInit(): void {
		this.processing$.next(false);
		if(this.reset$ != null) {
			this.reset$
			    .pipe(
				    takeUntilDestroyed(this.destroyRef),
				    map((value) => {
					    this.resetList();
					    return value;
				    }),
			    )
			    .subscribe();
		}

		if(this.outerProcess$ != null) {
			this.outerProcess$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((processing) => {
				this.processing$.next(processing);
			});
		}

		if(this.reInitialize$ != null) {
			this.reInitialize$.pipe(
				takeUntilDestroyed(this.destroyRef),
				tap(() => {
					this.clear();
				}),
			).subscribe();
		}
	}

	clear(silent = false): void {
		this.loadedMedicalStoreStates.clear();
		if(!silent)
			this.emitChange();

	}

	updatePermissions(): void {
		if(this.selectedUserId != null) {
			const statesI = this.loadedMedicalStoreStates.values();
			for(const state of statesI)
				this.updatePermissionState$.next(state);
		}
	}

	updateOriginalElementPermission$(userId: string | undefined, store: MedicalStoreModel): Observable<ElementEntryState> {
		const state = this.loadedMedicalStoreStates.get(store.id);
		if(state == null)
			return throwError(new Level8Error('store not loaded'));

		return store.users.value.pipe(
			mergeMap((users) => {
				if(users == null || users.length < 1)
					return of([]);


				return combineLatest(users.map((user) => combineLatest([
					of(user),
					of(user.canEdit),
				])));
			}),
			map((users) => {
				if(state.state.touchedBySystem)
					return state;

				for(const [user, canEdit] of users) {
					if(user.user.id === userId) {
						state.checkView(true, true);

						if(canEdit)
							state.checkEdit(true, true);
					}
				}
				state.applyState();
				state.state.touchedBySystem = true;

				if(state.parent != null)
					state.parent.applyInheritanceToChildren();

				return state;
			}),
		);
	}

	addMedicalStore(model: MedicalStoreModel, canView: boolean, canEdit: boolean, ikNumbers?: string[], name?: string, address?: AddressInterface, silent = false, permissionToEdit = false, permissionToView = false): ElementEntryState {
		const found = this.loadedMedicalStoreStates.get(model.id);
		if(found != null)
			return found;

		const element = new ElementEntryState(
			{
				permissionToEdit,
				permissionToView,
				model,
				address,
				name,
				ikNumbers,
			},
			{
				canEdit,
				canView,
				inheritedEdit: false,
				inheritedView: false,
				touchedByUser: false,
				touchedBySystem: false,
			},
			{
				canEdit,
				canView,
				inheritedEdit: false,
				inheritedView: false,
				touchedByUser: false,
				touchedBySystem: false,
			},
		);

		this.loadedMedicalStoreStates.set(model.id, element);
		if(!silent)
			this.update$.next();

		return element;
	}

	resetList(silent = false): void {
		this.loadedMedicalStoreStates.forEach((value) => {
			value.resetState();
		});

		if(!silent)
			this.update$.next();
	}

	generateSelectedMedicalStoresMap(): Map<string, ElementOperationDataInterface> {
		const selectedMap = new Map<string, ElementOperationDataInterface>();
		for(const [key, changeState] of this.loadedMedicalStoreStates) {
			const element = changeState.getChanges();
			if(element.updated)
				selectedMap.set(key, element);

		}

		return selectedMap;
	}

	emitChange(): void {
		if(Array.isArray(
			this.loadedMedicalStoreStatesList))
			this.isRemovingAll.emit(this.hasElementToSave());

		this.listChange.emit(this.generateSelectedMedicalStoresMap());
	}

	hasElementToSave(): boolean {
		for(const item of this.loadedMedicalStoreStates.values()) {
			if(item.state.canEdit || item.state.canView)
				return false;
		}

		return true;
	}

	editChecked(status: boolean, medicalStore: MedicalStoreModel, silent = false): void {
		const elementStatus = this.loadedMedicalStoreStates.get(medicalStore.id);
		if(elementStatus == null) {
			this.prepareModel$.next(medicalStore);
			return;
		}

		elementStatus.checkEdit(status);
		if(!silent)
			this.update$.next();
	}

	viewChecked(status: boolean, medicalStore: MedicalStoreModel, silent = false): void {
		const elementStatus = this.loadedMedicalStoreStates.get(medicalStore.id);
		if(elementStatus == null) {
			this.prepareModel$.next(medicalStore);
			return;
		}

		elementStatus.checkView(status);
		if(!silent)
			this.update$.next();
	}
}
