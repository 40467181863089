import {
	AbstractApiService,
	AbstractService,
	DtoEditFormHelper,
} from '@angular-helpers/frontend-api';
import {HttpErrorResponse} from '@angular/common/http';
import {
	Component,
	ContentChild,
	ElementRef,
	Input,
	signal,
	TemplateRef,
	ViewChild,
} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {
	AnyModel,
	ConfirmDialogAnswer,
	ConfirmDialogConfig,
	DialogService,
	IconService,
} from '@app/main';
import {IconDefinition} from '@fortawesome/fontawesome-common-types';

@Component({
	selector:    'portal-model-editable-box',
	templateUrl: './model-editable-box.component.html',
	styleUrls:   ['./model-editable-box.component.scss'],
})
export class ModelEditableBoxComponent<Model extends AnyModel> {
	@Input({required: true}) headline!: string;
	@Input({required: true}) icon!: IconDefinition;
	@Input() enableContentGrid             = true;
	@Input() saveButtonText                = 'actions.save';
	@Input() cancelButtonText?             = 'actions.cancel';
	@Input() hasHeader                     = true;
	@Input() hasEditButton: boolean | null = true;
	@ContentChild('editModeContent', {static: false}) protected editModeContent?: TemplateRef<unknown>; //todo add Class "EditableEditContentTemplateDirective"
	@ContentChild('showModeContent', {static: false}) protected showModeContent?: TemplateRef<unknown>; //todo addClass "EditableShowContentTemplateDirective"
	@ViewChild('editPopupContent') editPopupContent?: TemplateRef<unknown>;
	protected isSaving         = false;
	protected errorHasOccurred = signal<Error | undefined>(undefined);
	protected control?: UntypedFormGroup;
	private editDialog?: MatDialogRef<unknown, ConfirmDialogAnswer>;

	constructor(
		protected readonly elementRef: ElementRef,
		protected readonly dialogService: DialogService,
		protected readonly iconService: IconService,
	) {
	}

	private _formHelper!: DtoEditFormHelper<Model, AbstractService<AbstractApiService, Model>> | null | undefined;
	get formHelper(): DtoEditFormHelper<Model, AbstractService<AbstractApiService, Model>> | null | undefined {
		return this._formHelper;
	}

	@Input({required: true})
	set formHelper(formHelper: DtoEditFormHelper<Model, AbstractService<AbstractApiService, Model>> | null | undefined) {
		if(this._formHelper === formHelper)
			return;

		this._formHelper = formHelper;
		this.control     = undefined;
		this._formHelper?.control.then(control => {
			this.control = control;
		});
	}

	async save(): Promise<void> {
		if(this.isSaving)
			return;

		if((await this._formHelper?.isInvalid()) === true) {
			this.scrollToFirstInvalidFormControl();
			return;
		}

		this.isSaving = true;
		try {
			await this._formHelper?.save();
			this.closeEditDialog();
			this.errorHasOccurred.set(undefined);
		} catch(error) {
			if(error instanceof Error || error instanceof HttpErrorResponse || error === undefined)
				this.errorHasOccurred.set(error);
			else
				this.errorHasOccurred.set(new Error(`${error}`));
		} finally {
			this.isSaving = false;
		}
	}

	async abortEditing(forceClose = false): Promise<void> {
		if(this._formHelper == null) {
			this.closeEditDialog();
			return;
		}

		if(!forceClose && (await this._formHelper.control).dirty) {
			const confirmDialogData: ConfirmDialogConfig = {
				labelPositiv:  'system.abortChangesDialog.labelPositiv',
				labelNegative: 'system.abortChangesDialog.labelNegative',
				title:         'system.abortChangesDialog.title',
				message:       'system.abortChangesDialog.message',
				icon:          this.iconService.DIALOG_ATTENTION,
			};

			const confirmDialog = this.dialogService.openConfirmDialog(confirmDialogData);
			confirmDialog.afterClosed().subscribe(answer => {
				if(answer === ConfirmDialogAnswer.negative)
					this.abortEditing(true);
			});

			return;
		}

		this.closeEditDialog();
		await this._formHelper.reset();
	}

	openEditDialog(): void {
		if(this.editDialog || !this.editModeContent)
			return;

		this._formHelper?.reset();
		this.editDialog = this.dialogService.openBaseDialog({
			icon:              this.icon,
			content:           this.editModeContent,
			headline:          this.headline,
			acceptText:        this.saveButtonText,
			cancelButtonText:  this.cancelButtonText,
			control:           this.control,
			enableContentGrid: true,
			error:             this.errorHasOccurred,
			onAccept:          this.save.bind(this),
			onCancel:          this.abortEditing.bind(this),
		});

		this.editDialog.backdropClick().subscribe(() => this.abortEditing());
	}

	closeEditDialog(): void {
		this.editDialog?.close();
		this.editDialog = undefined;
	}

	protected scrollToFirstInvalidFormControl(): void {
		const firstElementWithError: HTMLElement | null = this.elementRef.nativeElement.querySelector('.ng-invalid');
		firstElementWithError?.scrollIntoView(
			{
				behavior: 'smooth',
				block:    'center',
				inline:   'center',
			});
	}
}
