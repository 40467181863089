import {
	Pipe,
	PipeTransform,
} from '@angular/core';
import {ElementEntryStateInterface} from '@app/contracts';
import {MedicalStoreModel} from '@contracts/frontend-api';
import {
	Observable,
	of,
	Subject,
} from 'rxjs';
import {
	map,
	mergeMap,
} from 'rxjs/operators';

@Pipe({
	name: 'storeToStateIsChecked',
})
export class StoreToStateIsCheckedPipe implements PipeTransform {
	transform(
		medicalStoreModel: MedicalStoreModel,
		operator: Subject<MedicalStoreModel>,
		library: Map<string, ElementEntryStateInterface>,
		selectedUserId?: string,
		property: 'edit' | 'view' = 'view',
	): Observable<boolean> {
		const state = library.get(medicalStoreModel.id);
		if (state == null) {
			operator.next(medicalStoreModel);

			return medicalStoreModel.users.value.pipe(
				mergeMap((users) => {
					if (users == null)
						return of(false);

					const found = users.find((user) => user.user.id === selectedUserId);
					if (found == null) {
						return medicalStoreModel.parent.value.pipe(
							mergeMap((parent) => {
								if(parent == null)
									return of(false);

								return parent.users.value.pipe(
									map((parentUsers) => {
										if(parentUsers == null || parentUsers.length < 1)
											return false;

										const userFound = parentUsers.find((user) => user.user.id === selectedUserId);
										if(userFound == null)
											return false;

										if(property === 'view')
											return true;

										return userFound.canEdit;
									}),
								);
							}),
						);
					}

					if (property === 'view')
						return of(true);


					return of(found.canEdit);
				}),
			);
		}


		if (property === 'view')
			return of(state.state.canView || state.state.inheritedView);

		return of(state.state.canEdit || state.state.inheritedEdit);
	}
}
