import {
	AsyncPipe,
	NgTemplateOutlet,
} from '@angular/common';
import {
	Component,
	inject,
} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {
	MAT_DIALOG_DATA,
	MatDialogRef,
} from '@angular/material/dialog';
import {
	MatFormField,
	MatLabel,
} from '@angular/material/form-field';
import {MatInput} from '@angular/material/input';
import {
	IconService,
	MainModule,
} from '@app/main';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';
import {TranslateModule} from '@ngx-translate/core';
import {
	BehaviorSubject,
	Observable,
	of,
	Subject,
	timer,
} from 'rxjs';
import {
	debounce,
	map,
	startWith,
	switchMap,
	tap,
} from 'rxjs/operators';
import {CalculationModel} from '../../../../models/calculation/calculation.model';
import {CalculationService} from '../../../../models/calculation/calculation.service';
import {SelectCalculationsDialogEntryComponent} from './select-calculations-dialog-entry/select-calculations-dialog-entry.component';

export interface SelectCalculationsDialogData {
	currentCalculations: CalculationModel[];
}

interface SearchRequest {
	query: string;
	searchNow?: boolean;
}

@Component({
	standalone:  true,
	imports:     [
		MainModule,
		AsyncPipe,
		NgTemplateOutlet,
		FaIconComponent,
		SelectCalculationsDialogEntryComponent,
		MatFormField,
		MatInput,
		MatLabel,
		TranslateModule,
	],
	templateUrl: './select-calculations-dialog.component.html',
	styleUrl:    './select-calculations-dialog.component.scss',
})
export class SelectCalculationsDialogComponent {
	protected readonly dialogData = inject<SelectCalculationsDialogData>(MAT_DIALOG_DATA);
	protected readonly dialogRef = inject(MatDialogRef<this>);
	protected readonly iconService = inject(IconService);
	protected readonly possibleCalculations: Observable<CalculationModel[]>;
	protected readonly currentCalculations$ = new BehaviorSubject<CalculationModel[]>(this.dialogData.currentCalculations);
	protected readonly search$ = new Subject<SearchRequest>();
	protected readonly calculationService = inject(CalculationService);
	protected isLoading = false;
	protected readonly SEARCH_DEBOUNCE_TIME = 1_000;

	constructor() {
		this.possibleCalculations = this.search$.pipe(
			startWith({
				query:     '',
				searchNow: true,
			}),
			tap(() => this.isLoading = true),
			debounce(settings => timer((settings.searchNow ?? false) ? 0 : this.SEARCH_DEBOUNCE_TIME)),
			switchMap(request => {
					if(request.query === '')
						return of(null);

					return this.calculationService.find({
						value:  `%${request.query}%`,
						column: 'identifierExtension',
						comparator: 'ilike',
					}, {
						sortBy: {
							column:    'identifierExtension',
							direction: 'asc',
						},
					});
				},
				// todo replace by global search (name, id, …)
			),
			map(page => page?.data ?? []),
			switchMap(calculations => this.currentCalculations$.pipe(
					map(currentCalculations =>
						calculations.filter(calculation => currentCalculations.includes(calculation) === false),
					),
				),
			),
			tap((page) => {
				this.isLoading = false;
			}),
			takeUntilDestroyed(),
		);
	}

	protected search(query: string, searchNow = false): void {
		this.search$.next({
			query,
			searchNow,
		});
	}

	protected async save(): Promise<void> {
		this.dialogRef.close(this.currentCalculations$.value);
	}

	protected async cancel(): Promise<void> {
		this.dialogRef.close();
	}

	protected removeCalculation(calculation: CalculationModel): void {
		const calculations = this.currentCalculations$.value.filter(c => c !== calculation);
		this.currentCalculations$.next(calculations);
	}

	protected addCalculation(calculation: CalculationModel, index?: number): void {
		const currentCalculations = this.currentCalculations$.value;
		if(currentCalculations.includes(calculation))
			return;

		currentCalculations.splice(index ?? currentCalculations.length, 0, calculation);
		this.currentCalculations$.next(currentCalculations);
	}
}
