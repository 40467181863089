import {
	AbstractPermissionedService,
	DtoInterface,
	ModelId,
} from '@angular-helpers/frontend-api';
import {PageRequestOptions} from '@angular-helpers/frontend-api/lib/models/abstract-model/abstract.api-service';
import {
	Injectable,
	runInInjectionContext,
} from '@angular/core';
import {VersorgungsbereichService} from '@contracts/frontend-api';
import {Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {CalculationsModule} from '../../calculations.module';
import {HourlyRateService} from '../../services/hourly-rate.service';
import {FixedPriceService} from '../fixedPrice/fixed-price.service';
import {MaterialService} from '../material/material.service';
import {ProductService} from '../product/product.service';
import {TaskTimeService} from '../task-time/task-time.service';
import {VariationService} from '../variation/variation.service';
import {
	CalculationApiService,
	CalculationExportFileType,
} from './calculation.api-service';
import {CalculationModel} from './calculation.model';
import {CalculationParser} from './calculation.parser';


@Injectable({
	providedIn: 'root',
})
export class CalculationService extends AbstractPermissionedService<CalculationApiService, CalculationModel> {
	constructor(apiService: CalculationApiService) {
		super(apiService, CalculationsModule.injector);
	}

	get fixedPriceService(): FixedPriceService {
		return this.injector.get(FixedPriceService);
	}

	get productService(): ProductService {
		return this.injector.get(ProductService);
	}

	get variationService(): VariationService {
		return this.injector.get(VariationService);
	}

	get materialService(): MaterialService {
		return this.injector.get(MaterialService);
	}

	get careScopeService(): VersorgungsbereichService {
		return this.injector.get(VersorgungsbereichService);
	}

	get taskTimeService(): TaskTimeService {
		return this.injector.get(TaskTimeService);
	}

	get possibleExtensionService(): CalculationService {
		return this;
	}

	get hourlyRateService(): HourlyRateService {
		return this.injector.get(HourlyRateService);
	}

	generateExport(calculation: CalculationModel, hourlyBillingRate: number, filetype: CalculationExportFileType, extended: boolean): Observable<Blob> {
		return this.apiService.generateExport(calculation, hourlyBillingRate, filetype, extended);
	}

	generateListExport(includedColumns: string[], search: PageRequestOptions['search'], filetype: CalculationExportFileType): Observable<Blob> {
		return this.hourlyRateService.getRates().pipe(
			switchMap(hourlyBillingRates => this.apiService.generateListExport(includedColumns, search, hourlyBillingRates, filetype)),
		);
	}

	protected createNewModel(id: ModelId): CalculationModel {
		return runInInjectionContext(this.injector, () => {
			return new CalculationModel(id, this);
		});
	}

	protected fillModelFromDto(dto: DtoInterface, model: CalculationModel): void {
		return runInInjectionContext(this.injector, () => {
			return CalculationParser.fillModelFromDto(model, dto, this);
		});
	}
}