import {
	Directive,
	Input,
	TemplateRef,
	ViewContainerRef,
} from '@angular/core';

interface LetContext<T> {
	ngLet: T;
}

@Directive({
	selector: '[ngLet]',
})
export class LetDirective<T> {
	private readonly context: LetContext<T | undefined> = {ngLet: undefined};

	constructor(protected viewContainer: ViewContainerRef, protected templateRef: TemplateRef<LetContext<T>>) {
	}

	@Input()
	set ngLet(value: T) {
		this.context.ngLet = value;
		this.updateView();
	}

	updateView(): void {
		this.viewContainer.clear();
		this.viewContainer.createEmbeddedView(this.templateRef, this.context);
	}
}
