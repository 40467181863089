<mat-form-field>
	@if (!control.value?.length) {
		<fa-icon
			[icon]="iconService.SEARCH"
			matPrefix
		/>
	} @else {
		<fa-icon
			[icon]="iconService.CANCEL"
			(click)="control.setValue([]); $event.preventDefault(); $event.stopPropagation();"
			class="clickable"
			matPrefix
		/>
	}
	
	<mat-select
		multiple
		[formControl]="control"
		panelClass="min-content"
	>
		<mat-select-trigger>
			<ng-container *ngLet="control.value?.[0] as firstValue">
				@if (control.value?.length; as amount) {
					{{ amount }} {{ 'common.selected' | translate }}
				}
			</ng-container>
		</mat-select-trigger>
		<mat-option>
			<ngx-mat-select-search [formControl]="filterControl">
				<fa-icon
					ngxMatSelectSearchClear
					[icon]="iconService.CANCEL"
				/>
			</ngx-mat-select-search>
		</mat-option>
		@for (entry of (filteredOptions$ | async) | keyvalue; track entry) {
			<mat-option
				[value]="entry.value"
			>
				<div class="entry">
					<span>{{ getLabel(entry.key) | translate }}</span>
					<span
						class="info"
						*ngIf="getInfo(entry.key) as info"
					>{{ info | translate }}</span>
				</div>
			</mat-option>
		}
	</mat-select>
</mat-form-field>