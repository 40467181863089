import {Injectable} from '@angular/core';
import {environment} from '@app/environment';
import {NewsDidReadGuard} from '@app/main';
import {NewsService} from '@contracts/frontend-api';

@Injectable({
	providedIn: 'root',
})
export class CalculationInfoGuard extends NewsDidReadGuard {
	constructor(
		newsService: NewsService,
	) {
		super(
			newsService.getById(environment.calculationsInfoNewsId),
			environment.calculationInfoAcceptPageFullUrl,
		);
	}
}
