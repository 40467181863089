export interface HourlyBillingRateBracketFixedData {
	name: string;
	pg05?: string;
	pg08?: string;
	pg0923?: string;
	pg17ple?: string;
	pg17scar?: string;
	pg23conf?: string;
	pg23individual?: string;
	pg24leg?: string;
	pg24individual?: string;
	pg26?: string;
	pg31?: string;
	pg37?: string;
	pg38?: string;
}

export const data: HourlyBillingRateBracketFixedData[] = [
	{
		name: 'BIV Kalkulationen',
		pg05:           '72,60 €',
		pg08:           '95,40 €',
		pg0923:         '95,40 €',
		pg17ple:        '72,60 €',
		pg17scar:       '95,40 €',
		pg23conf:       '72,60 €',
		pg23individual: '95,40 €',
		pg24leg:        '95,40 €',
		pg24individual: '109,20 €',
		pg26:           '95,40 €',
		pg31:           '95,40 €',
		pg37:           '72,60 €',
		pg38:           '109,20 €',
	},
	{
		name:           'BARMER',
		pg05:           '54,50 €',
		pg17ple:        '54,50 €',
		pg17scar:       '54,50 €',
		pg23conf:       '54,50 €',
		pg23individual: '61,50 €',
		pg24leg:        '65,40 €',
		pg31:           '64,80 €',
		pg37:           '54,50 €',
	},
	{
		name:           'BKK Landesverband Mitte & Bayern',
		pg05:           '62,40 €',
		pg17ple:        '68,40 €',
		pg17scar:       '68,40 €',
		pg23conf:       '62,40 €',
		pg23individual: '68,40 €',
		pg24leg:        '68,40 €',
		pg37:           '68,40 €',
		pg38:           '115,80 €',
	},
	{
		name:           'BKK Landesverband Süd',
		pg05:           '62,70 €',
		pg17ple:        '68,55 €',
		pg17scar:       '68,55 €',
		pg23conf:       '62,70 €',
		pg23individual: '68,55 €',
		pg24leg:        '68,55 €',
		pg37:           '68,55 €',
		pg38:           '115,84 €',
	},
	{
		name:           'Bundesminiserium der Verteidigung (BMVg)',
		pg05:           '62,40 €',
		pg08:           '68,40 €',
		pg17ple:        '68,40 €',
		pg17scar:       '68,40 €',
		pg23conf:       '62,40 €',
		pg23individual: '68,40 €',
		pg24leg:        '68,40 €',
		pg37:           '68,40 €',
		pg38:           '112,20 €',
	},
	{
		name:           'DAK Gesundheit',
		pg05:           '62,40 €',
		pg17ple:        '68,40 €',
		pg17scar:       '68,40 €',
		pg23conf:       '62,40 €',
		pg23individual: '68,40 €',
		pg24leg:        '68,40 €',
		pg31:           '63,60 €',
	},
	{
		name:           'Deutsche Gesetzliche Unfallversicherung',
		pg05:           'Empfehlung\u00a0GWQ:\n 62,40 €',
		pg23conf:       'Empfehlung\u00a0GWQ:\n 62,40 €',
		pg23individual: 'Empfehlung\u00a0GWQ:\n 68,40 €',
		pg24leg: '69,00 €',
	},
	{
		name:    'Festbeträge',
		pg08:    '62,61 €',
		pg17ple: '45,90 €',
	},
	{
		name:           'Freie Heilfürsorge NRW',
		pg05:           '57,82 €',
		pg17ple:        '57,82 €',
		pg17scar:       '57,82 €',
		pg23conf:       '57,82 €',
		pg23individual: '65,25 €',
		pg24leg:        '68,43 €',
		pg37:           '57,82 €',
	},
	{
		name:           'GWQ ServicePlus AG',
		pg05:           '62,40 €',
		pg17ple:        '64,50 €',
		pg17scar:       '64,50 €',
		pg23conf:       '62,40 €',
		pg23individual: '68,40 €',
		pg24leg:        '68,40 €',
		pg37:           '68,40 €',
	},
	{
		name:           'Heilfürsorge Sachsen',
		pg05:           '54,50 €',
		pg17ple:        '54,50 €',
		pg17scar:       '54,50 €',
		pg23conf:       '54,50 €',
		pg23individual: '61,50 €',
		pg24leg:        '69,00 €',
		pg37:           '54,50 €',
		pg38:           '109,00 €',
	},
	{
		name:           'HEK - Hanseatische Krankenkasse',
		pg05:           '64,00 €',
		pg23conf:       '64,00 €',
		pg23individual: '69,97 €',
		pg24leg:        '69,97 €',
		pg31:           '64,80 €',
	},
	{
		name:           'hkk Krankenkasse',
		pg05:           '56,14 €',
		pg17ple:        '56,14 €',
		pg17scar:       '56,14 €',
		pg23conf:       '56,14 €',
		pg23individual: '63,35 €',
		pg24leg:        '61,50 €',
		pg31:           '64,80 €',
		pg37:           '56,14 €',
	},
	{
		name:           'IKK classic',
		pg05:           '64,93 €',
		pg17ple:        '70,21 €',
		pg17scar:       '70,21 €',
		pg23conf:       '64,93 €',
		pg23individual: '70,21 €',
		pg24leg:        '70,21 €',
		pg37:           '70,21 €',
	},
	{
		name:           'KKH Kaufmännische Krankenkasse',
		pg05:           '64,00 €',
		pg17ple:        '64,50 €',
		pg17scar:       '64,50 €',
		pg23conf:       '64,00 €',
		pg23individual: '69,97 €',
		pg24leg:        '69,97 €',
		pg37:           '69,97 €',
		pg38:           '109,00 €',
	},
	{
		name:           'KNAPPSCHAFT + SVLFG',
		pg05:           '54,50 €',
		pg23conf:       '58,20 €',
		pg23individual: '58,20 €',
		pg37:           '54,50 €',
	},
	{
		name:           'Mobil Krankenkasse',
		pg05:           '66,40 €',
		pg08:           '66,40 €',
		pg17ple:        '66,40 €',
		pg17scar:       '66,40 €',
		pg23conf:       '66,40 €',
		pg23individual: '66,40 €',
		pg24leg:        '70,20 €',
		pg37:           '66,40 €',
		pg38:           '109,00 €',
	},
	{
		name:           'Novitas BKK',
		pg05:           '62,40 €',
		pg23conf:       '54,50 €',
		pg23individual: '68,40 €',
	},
	{
		name:           'spectrumK',
		pg05:           '64,00 €',
		pg17ple:        '68,10 €',
		pg17scar:       '68,10 €',
		pg23conf:       '64,00 €',
		pg23individual: '69,97 €',
		pg24leg:        '69,97 €',
		pg37:           '69,97 €',
		pg38:           '109,00 €',
	},
	{
		name:           'Techniker Krankenkasse',
		pg05:           '54,50 €',
		pg17ple:        '54,50 €',
		pg17scar:       '54,50 €',
		pg23conf:       '54,50 €',
		pg23individual: '61,50 €',
		pg24leg:        '65,40 €',
		pg31:           '64,80 €',
		pg37:           '54,50 €',
	},
];