import {HttpErrorResponse} from '@angular/common/http';
import {
	Component,
	Input,
	Signal,
	TemplateRef,
} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {IconDefinition} from '@fortawesome/fontawesome-svg-core';

@Component({
	selector:    'portal-base-dialog-content',
	templateUrl: './base-dialog-content.component.html',
	styleUrl:    './base-dialog-content.component.scss',
})
export class BaseDialogContentComponent {
	@Input({required: true}) content!: TemplateRef<unknown>;
	@Input({required: true}) headline!: string;
	@Input({required: true}) icon!: IconDefinition;
	@Input({required: true}) acceptText!: string;
	@Input({required: true}) dialogRef!: MatDialogRef<unknown>;
	@Input({transform: (f: boolean | undefined) => f ?? true}) enableContentGrid = true;
	@Input() cancelText?: string;
	@Input() control?: UntypedFormGroup;
	@Input() error?: Signal<Error | undefined>;
	@Input() onAccept?: () => Promise<void>;
	@Input() onCancel?: () => Promise<void>;

	protected errorHasOccurred?: Error;
	protected isSaving = false;


	async accept(): Promise<void> {
		if(this.onAccept == null) {
			this.dialogRef.close();
			return;
		}

		this.isSaving = true;

		try {
			await this.onAccept();
			this.isSaving = false;
		} catch(error) {
			if(!(error instanceof Error) && !(error instanceof HttpErrorResponse))
				throw error;
			else
				this.errorHasOccurred = error;
		}
	}

	async cancel(): Promise<void> {
		if(this.onCancel == null) {
			this.dialogRef.close();
			return;
		}

		try {
			await this.onCancel();
		} catch(error) {
			if(!(error instanceof Error) && !(error instanceof HttpErrorResponse))
				throw error;
			else
				this.errorHasOccurred = error;
		}
	}

	async onErrorResolved(retry: boolean): Promise<void> {
		if(!retry)
			return;

		await this.accept();
	}
}
