import {
	Component,
	Input,
} from '@angular/core';
import {environment} from '@app/environment';
import {
	BasicPropertyType,
	ClientSearchMinimalColumns,
	combineLatestSafe,
	notNull,
	SearchFilter,
	sortModelArray,
	sortStringArray,
} from '@app/main';
import {
	ContractModel,
	ContractSectionModel,
	ExternalContractNumberModel,
	ExternalContractNumberType,
	MasterContractModel,
} from '@contracts/frontend-api';
import {
	combineLatest,
	Observable,
	of,
} from 'rxjs';
import {
	first,
	map,
	mergeMap,
} from 'rxjs/operators';

type AnyContractModel = MasterContractModel | ContractModel | ContractSectionModel;
type ArrayBase<T> = T extends (infer U)[] ? U : never;

@Component({
	selector:  'portal-base-contract-list',
	templateUrl: './base-contract-list.component.html',
	styleUrls: ['./base-contract-list.component.scss'],
})
export class BaseContractListComponent {
	@Input({required: true}) data: AnyContractModel[] | undefined | null;
	@Input() pageSize: number | undefined;
	environment = environment;
	externatContractNumberType = ExternalContractNumberType;
	readonly searchFilter = new CSearchFilter();
	readonly tableHeaders: ClientSearchMinimalColumns<AnyContractModel> = {
		joinedParties:                {
			label:   'baseContract.joinedParties',
			index:   0,
			sortMap: sortModelArray<ArrayBase<BasicPropertyType<AnyContractModel['joinedParties']>>>(party => party.contractingParty.name.firstValue),
		},
		name:                         {
			label: 'baseContract.name',
			index: 1,
		},
		validityStartAt:              {
			label: 'baseContract.validityStartAt',
			index: 2,
		},
		terminatedAt:                 {
			label: 'baseContract.terminatedAt',
			index: 3,
		},
		negotiators:                  {
			isVisible: false,
			label:   'baseContract.negotiators',
			index:   4,
			sortMap: sortStringArray,
		},
		mandateGrantor:               {
			isVisible: false,
			label:   'baseContract.mandateGrantor',
			index:   5,
			sortMap: sortStringArray,
		},
		initialSigner:                {
			isVisible: false,
			label:   'baseContract.initialSigner',
			index:   6,
			sortMap: sortStringArray,
		},
		minimumTermAt:                {
			isVisible: false,
			label: 'baseContract.minimumTermAt',
			index: 7,
		},
		periodOfValidityAt:           {
			label: 'baseContract.periodOfValidityAt',
			index: 8,
		},
		joiningDelay:                 {
			isVisible: false,
			label: 'baseContract.joiningDelay',
			index: 9,
		},
		noticePeriodDelay:            {
			isVisible: false,
			label: 'baseContract.noticePeriodDelay',
			index: 10,
		},
		noticePeriodNegotiationDelay: {
			isVisible: false,
			label: 'baseContract.noticePeriodNegotiationDelay',
			index: 11,
		},
		billingDelay:                 {
			isVisible: false,
			label: 'baseContract.billingDelay',
			index: 12,
		},
		legs:                         {
			label:      'externalContractNumber.modelType.legs',
			index:      13,
			isVisible:  true,
			isSortable: false,
		},
		// todo add
		// status: {
		// 	label: 'baseContract.status',
		// 	index: 13,
		// },
	};


	get columnLink(): string {
		if(this.data == null)
			return '';

		if(this.data.length < 1)
			return '';

		const model = this.data[0];

		switch(true) {
			case model instanceof MasterContractModel:
				return environment.masterContractsFullUrl;

			case model instanceof ContractModel:
				return environment.contractsFullUrl;

			case model instanceof ContractSectionModel:
				return environment.contractSectionsFullUrl;

			default:
				// @ts-expect-error 👷 should never happen - safety first
				throw new Error(`Unexpected class: ${model.constructor.name}`);
		}
	}

	static getJoinedParties$(model: AnyContractModel): Observable<string[] | undefined> {
		let joinedParties$;
		if(model instanceof ContractSectionModel || model instanceof ContractModel)
			joinedParties$ = model.joinedParties.withParent.value;
		else joinedParties$ = model.joinedParties.value;

		return joinedParties$.pipe(
			map((joinedParties) =>
				joinedParties
					?.filter((party) => party.leftAt === null)
					.map((party) => party.contractingParty),
			),
			map((contractingParties) => contractingParties?.map((party) => party.name.value)),
			mergeMap(combineLatestSafe),
			map((contractingPartys) => contractingPartys?.filter((name): name is string => typeof name === 'string')),
		);
	}

	static getExternalContractNumbers$(model: AnyContractModel, filterType: ExternalContractNumberType | null): Observable<ExternalContractNumberModel[] | undefined> {
		let commonExternalContractNumbers$: Observable<ExternalContractNumberModel[] | undefined> = of([]);
		let parentExternalContractNumbers$: Observable<ExternalContractNumberModel[] | undefined> = of([]);
		let childExternalContractNumbers$: Observable<ExternalContractNumberModel[] | undefined> = of([]);

		const getChildEcns = (children: AnyContractModel[] | undefined) => {
			if(children == null || children.length < 1)
				return of([]);

			return combineLatest(children.map((child) => child.externalContractNumbers.value));
		};
		const sortEcns = (legsLists: (ExternalContractNumberModel[] | undefined)[]): ExternalContractNumberModel[] => legsLists.flatMap((legsList) => legsList ?? []);

		if(model instanceof MasterContractModel) {
			parentExternalContractNumbers$ = model.externalContractNumbers.value;

			commonExternalContractNumbers$ = model.contracts.value.pipe(
				mergeMap((contracts) => {
					if(contracts == null)
						return of([]);

					return getChildEcns(contracts);
				}),
				map(sortEcns),
			);

			childExternalContractNumbers$ = model.contracts.value.pipe(
				mergeMap((contracts) => {
					if(contracts == null || contracts.length < 1)
						return of([]);

					return combineLatest(contracts.map((contract) => contract.contractSections.value));
				}),
				mergeMap((contractSections) => {
					const numbers = contractSections
						.filter(notNull).reduce((prev, curr) => [...prev, ...curr], [])
						.map(section => section.externalContractNumbers.value);
					return combineLatestSafe(numbers);
				}),
				map((ecnListsList) => ecnListsList.flatMap((list) => list ?? [])),
			);
		}

		if(model instanceof ContractModel) {
			childExternalContractNumbers$ = model.contractSections.value.pipe(
				mergeMap((contracts) => getChildEcns(contracts)),
				map((ecnData) => sortEcns(ecnData)),
			);
			parentExternalContractNumbers$ = model.externalContractNumbers.withParent.value;
		}

		if(model instanceof ContractSectionModel) {
			commonExternalContractNumbers$ = model.externalContractNumbers.value;
			parentExternalContractNumbers$ = model.externalContractNumbers.withParent.value;
		}

		return combineLatest([commonExternalContractNumbers$, parentExternalContractNumbers$, childExternalContractNumbers$]).pipe(
			map(([common, parent, children]) => {
				const ecnList: ExternalContractNumberModel[] = [];
				const addUnic = (item: ExternalContractNumberModel, list: ExternalContractNumberModel[]) => {
					const found = list.find((added) => added.id === item.id);
					if(found)
						return;

					list.push(item);
				};
				for(const ecn of [...(common ?? []), ...(parent ?? []), ...(children ?? [])]) addUnic(ecn, ecnList);

				return ecnList;
			}),
			mergeMap((ecnList) => {
				if(ecnList.length < 1)
					return of([]);

				return combineLatest(ecnList.map((ecn) => combineLatest([
					of(ecn),
					ecn.type.value,
				])));
			}),
			map(list => list
				.filter(([, type]) => type === filterType)
				.map(([ecn]) => ecn)),
		);
	}

	getJoinedParties$(model: AnyContractModel): Observable<string[] | undefined> {
		return BaseContractListComponent.getJoinedParties$(model);
	}

	getLegs$(model: AnyContractModel): Observable<ExternalContractNumberModel[] | undefined> {
		return BaseContractListComponent.getExternalContractNumbers$(model, ExternalContractNumberType.legs);
	}

	getRelativeDateValue(model: AnyContractModel, valueType: string): Observable<string | null | undefined> {
		let dataField;
		switch(valueType) {
			case 'joiningDelay':
				dataField = model.joiningDelay;
				break;
			case 'billingDelay':
				dataField = model.billingDelay;
				break;
			case 'maximumBackDatingDelay':
				dataField = model.maximumBackDatingDelay;
				break;
			case 'noticePeriodDelay':
				dataField = model.noticePeriodDelay;
				break;
			case 'noticePeriodNegotiationDelay':
				dataField = model.noticePeriodNegotiationDelay;
				break;
			default:
				throw new Error('field not implemented');
		}

		if('withParent' in dataField)
			return dataField.withParent.value;

		return dataField.value;
	}
}

class CSearchFilter extends SearchFilter<AnyContractModel> {
	externalContractNumberType = ExternalContractNumberType;

	protected async getModelValue(field: string, model: AnyContractModel): Promise<unknown> {
		switch(field) {
			case 'joinedParties':
				return BaseContractListComponent.getJoinedParties$(model).pipe(first()).toPromise();

			case 'legs':
				return BaseContractListComponent.getExternalContractNumbers$(model, ExternalContractNumberType.legs)
				                                .pipe(
					                                map(numbers =>
						                                numbers?.map((number) =>
							                                combineLatestSafe([number.number.value, number.description.value]).pipe(
								                                map(([number, description]) => `${number}:${description}`),
							                                ),
						                                ),
					                                ),
					                                mergeMap(x => combineLatestSafe(x)),
					                                first(),
				                                )
				                                .toPromise();

			default:
				return super.getModelValue(field, model);
		}
	}
}
