import {
	inject,
	Pipe,
	PipeTransform,
} from '@angular/core';
import {
	DomSanitizer,
	SafeHtml,
} from '@angular/platform-browser';

@Pipe({
	name:       'bypassSecurityTrustResource',
	standalone: true,
})
export class BypassSecurityTrustResourcePipe implements PipeTransform {
	private readonly sanitizer = inject(DomSanitizer);

	transform(raw: string | null | undefined): SafeHtml | null | undefined {
		if(raw == null)
			return raw;

		return this.sanitizer.bypassSecurityTrustHtml(raw);
	}

}
