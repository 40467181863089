import {
	inject,
	Injectable,
} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {environment} from '@app/environment';
import {
	BehaviorSubject,
	combineLatest,
} from 'rxjs';
import {map} from 'rxjs/operators';
import {SystemPreferencesService} from './system-preferences.service';

declare type Themes = 'defaultTheme' | 'localTheme' | 'devTheme';

@Injectable({
	providedIn: 'root',
})
export class ThemeServiceService {
	static readonly THEME_DEFAULT             = 'defaultTheme';
	static readonly THEME_LOCAL               = 'defaultTheme';
	static readonly THEME_DEV                 = 'defaultTheme';
	static readonly THEMES                    = [ThemeServiceService.THEME_DEFAULT, ThemeServiceService.THEME_DEV, ThemeServiceService.THEME_LOCAL];
	static readonly DARK_MODE                 = 'darkMode';
	static readonly LIGHT_MODE                = 'lightMode';
	static readonly MODES                     = [ThemeServiceService.DARK_MODE, ThemeServiceService.LIGHT_MODE];
	readonly currentTheme$                    = new BehaviorSubject<Themes>(ThemeServiceService.THEME_DEFAULT);
	protected readonly THEME_NODE             = document.getElementsByTagName('html')[0];
	private readonly systemPreferencesService = inject(SystemPreferencesService);
	private readonly systemDarkMode$          = new BehaviorSubject<boolean>(window.matchMedia('(prefers-color-scheme: dark)').matches);
	readonly darkMode$                        = combineLatest([
		this.systemPreferencesService.preferences$, this.systemDarkMode$,
	]).pipe(map(([prefs, system]) => {
		switch(prefs.theme) {
			case 'auto':
				return system;

			case 'dark':
				return true;

			case 'light':
				return false;
		}
	}));

	constructor() {
		this.currentTheme$.pipe(takeUntilDestroyed()).subscribe(theme => {
			const classNames = Array.from(this.THEME_NODE.classList).filter(x => !ThemeServiceService.THEMES.includes(x));
			classNames.push(theme);

			this.THEME_NODE.className = classNames.join(' ');
		});

		this.darkMode$.pipe(takeUntilDestroyed()).subscribe(mode => {
			const classNames = Array.from(this.THEME_NODE.classList).filter(x => !ThemeServiceService.MODES.includes(x));
			classNames.push(mode ? ThemeServiceService.DARK_MODE : ThemeServiceService.LIGHT_MODE);

			this.THEME_NODE.className = classNames.join(' ');
		});

		// init mode
		window.matchMedia('(prefers-color-scheme: dark)')
		      .addEventListener('change', event => this.systemDarkMode$.next(event.matches));


		// init theme
		switch(environment.stage) {
			case 'LOCAL':
				this.currentTheme$.next(ThemeServiceService.THEME_LOCAL);
				break;

			case 'DEVELOPMENT':
				this.currentTheme$.next(ThemeServiceService.THEME_DEV);
				break;

			default:
				this.currentTheme$.next(ThemeServiceService.THEME_DEFAULT);
				break;
		}
	}


	set currentTheme(theme: Themes) {
		if(this.currentTheme$.value === theme)
			return;

		this.currentTheme$.next(theme);
	}
}
