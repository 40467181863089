<portal-base-dialog-content
	[content]="content"
	headline="calculation.dialog.exportList.title"
	[dialogRef]="dialogRef"
	[icon]="iconService.EXPORT"
	cancelText="actions.cancel"
	acceptText="actions.download"
	[onAccept]="generate.bind(this)"
	[enableContentGrid]="false"
/>

<ng-template #content>
	<div class="wrapper">
		<h3>{{ 'calculation.dialog.exportList.calculations.header' | translate }}</h3>
		<mat-form-field>
			<mat-select [(ngModel)]="selectedCalculations">
				@for (option of possibleCalculationOptions; track option) {
					<mat-option [value]="option">{{ 'calculation.dialog.exportList.calculations.options.' + option | translate }}</mat-option>
				}
			</mat-select>
		</mat-form-field>
		
		<mat-form-field *ngIf="selectedCalculations === 'selected'">
			<mat-label>{{ 'calculation.dialog.exportList.calculations.addProductGroups' | translate }}</mat-label>
			<mat-chip-grid #chipList>
				@for (group of selectedProductGroups; track group) {
					<mat-chip
						removable
						(removed)="removeProductGroup(group)"
					>
						{{ group }}
						<button matChipRemove>
							<fa-icon [icon]="iconService.REMOVE"/>
						</button>
					</mat-chip>
				}
			</mat-chip-grid>
			<input
          #input
					[formControl]="selectedCalculationsInput"
					(change)="parseSelectProductGroup(input.value)"
					[matChipInputFor]="chipList"
					(matChipInputTokenEnd)="parseSelectProductGroup($event.value, true)"
					[matChipInputAddOnBlur]="true"
					(blur)="parseSelectProductGroup(input.value, true)"
			/>
			<mat-error *ngIf="selectedProductGroups.length < 1">
				{{ 'input.error.required' | translate }}
			</mat-error>
		</mat-form-field>
		
		<h3>{{ 'calculation.dialog.export.fileType.title' | translate }}</h3>
		<mat-radio-group [value]="fileType">
			@for (fileType of possibleCalculationFileTypes; track fileType) {
				<mat-radio-button [value]="fileType">
					{{ 'calculation.dialog.export.fileType.' + fileType | translate }}
				</mat-radio-button>
			}
		</mat-radio-group>
		
		
		<h3>{{ 'calculation.dialog.exportList.columns.header' | translate }}</h3>
		<div
			id="options"
			cdkDropList
			(cdkDropListDropped)="setColumnListPosition($event.previousIndex, $event.currentIndex)"
		>
			<header>
				<mat-checkbox
					[checked]="allColumnsSelected() == true"
					(change)="selectAllColumns($event.checked)"
					[indeterminate]="allColumnsSelected() === null"
				/>
				{{ 'actions.selectAll' | translate }}
			</header>
			@for (column of POSSIBLE_COLUMNS; track column) {
				<div
					cdkDrag
					cdkDragLockAxis="y"
					class="option"
				>
					<mat-checkbox
						(change)="selectColumn(column, $event.checked)"
						[ngModel]="isSelected(column)"
					>
						{{ 'calculation.dialog.exportList.columns.' + column | translate }}
					</mat-checkbox>
					<fa-icon
						cdkDragHandle
						[icon]="iconService.GRIP_HORIZONTAL"
					/>
				</div>
			}
		</div>
	</div>
</ng-template>