import {
	Component,
	Input,
	OnDestroy,
} from '@angular/core';
import {
	IconService,
	SaveFile,
} from '@app/main';
import {FileModel} from '@contracts/frontend-api';

@Component({
	selector:    'portal-show-file',
	templateUrl: './show-file.component.html',
	styleUrls:   ['./show-file.component.scss'],
})
export class ShowFileComponent implements OnDestroy {
	@Input({required: true}) file?: FileModel | null;
	@Input() label: string | null = 'file.file';
	@Input() showValidityStartAt = false;
	protected saveFile?: SaveFile;

	constructor(
		public iconService: IconService,
	) {
	}

	ngOnDestroy(): void {
		this.saveFile?.cancel();
	}

	showFile(): Promise<void> {
		return this.prepareFile(false);
	}

	downloadFile(): Promise<void> {
		return this.prepareFile(true);
	}

	protected async prepareFile(download: boolean): Promise<void> {
		if(!this.file)
			return;

		if(this.saveFile == null) {
			const content = await this.file.content;
			const fileName = (await this.file.name.firstValue) ?? 'unbekannt';
			const fileExtension = (await this.file.extension.firstValue) ?? '';
			const fileNameExtended = `${fileName}.${fileExtension}`;
			this.saveFile = new SaveFile(download, fileNameExtended, content);
		}

		await this.saveFile.save();
	}
}
