import {AbstractParser} from '@angular-helpers/frontend-api';
import {MaterialModel} from './material.model';
import {MaterialService} from './material.service';

export class MaterialParser extends AbstractParser<MaterialModel, MaterialService> {

	protected sanitizeInput(propertyDto: string, property: string, value: unknown): unknown {
		switch(property) {
			case 'predecessor':
				if(value != null && typeof value !== 'string')
					throw new Error(`Property ${property} must be a string`);

				return this.castToModelOptional(value, this.service);

			default:
				return super.sanitizeInput(propertyDto, property, value);
		}
	}
}