import {ResultPageModel} from '@angular-helpers/frontend-api';
import {
	Component,
	Input,
} from '@angular/core';
import {environment} from '@app/environment';
import {
	IconService,
	MinimalColumns,
	SearchFilter,
} from '@app/main';
import {
	ContractingPartyModel,
	JoinedPartyInterface,
} from '@contracts/frontend-api';

@Component({
	selector:    'portal-base-contract-card-joined-parties',
	templateUrl: './joined-parties.component.html',
	styleUrls:   ['./joined-parties.component.scss'],
})
export class ContractCardJoinedPartiesComponent {
	@Input({required: true}) data: JoinedPartyInterface[] | undefined | null;
	readonly searchFilter = new CSearchFilter();
	readonly tableHeaders: MinimalColumns<JoinedPartyInterface> = {
		name:     {
			label: 'baseContract.joinedParty.name',
			index: 0,
		},
		joinedAt: {
			label: 'baseContract.joinedParty.joinedAt',
			index: 1,
		},
		leftAt:   {
			label: 'baseContract.joinedParty.leftAt',
			index: 2,
		},
	};

	constructor(
		protected readonly iconService: IconService,
	) {
	}

	get hasData(): boolean {
		if(this.data == null)
			return false;

		if(this.data instanceof ResultPageModel)
			return this.data.data.length > 0;

		return this.data.length > 0;
	}

	private static hasJoinedPartyInterface(value: unknown): value is JoinedPartyInterface {
		if(value == null || typeof value != 'object')
			return false;

		return value.hasOwnProperty('contractingParty');
	}

	getColumnLink(entry: JoinedPartyInterface | object): string | undefined {
		if(ContractCardJoinedPartiesComponent.hasJoinedPartyInterface(entry))
			return `${environment.contractingPartyFullUrl}/${entry.contractingParty.id}`;

		return undefined;
	}

	protected isJoinedPartyInterface(value: unknown): value is JoinedPartyInterface {
		if(value != null && typeof value == 'object' && 'contractingParty' in value && value.contractingParty instanceof ContractingPartyModel)
			return true;

		return false;
	}
}


class CSearchFilter extends SearchFilter<JoinedPartyInterface> {
	protected getModelValue(field: string, model: JoinedPartyInterface): Promise<unknown> {
		switch(field) {
			case 'name':
				return model.contractingParty.name.firstValue;

			default:
				return super.getModelValue(field, model);
		}
	}
}
