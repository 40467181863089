<div class="fixedWidth">
	<portal-headline label="calculation.infobox.title"/>
	
	<mat-card>
		<mat-card-content>
			@for (text of ('calculation.infobox.text' | translate); track text) {
				<p [innerText]="text"></p>
			}
		</mat-card-content>
		<mat-card-actions>
			<portal-spinning-button
				class="pushRight"
				[processing]="isSaving"
				(click)="accept()"
			>{{ 'actions.accept' | translate }}
			</portal-spinning-button>
		</mat-card-actions>
	</mat-card>
</div>
