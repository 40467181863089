<portal-table-server-side-searchable
	[headers]="tableHeaders"
	[columnLink]="environment.calculationFullUrl"
	[service]="calculationService"
	[routeHasCustomSearch]="false"
	sortBy="identifierExtension"
	(searchEvent)="searchEvent.emit($event)"
>
	<ng-template
		column="identifierExtension"
    let-model="model"
		[service]="calculationService"
	>
		<portal-skeleton-field [content]="model.identifierExtension.value">
			<ng-template
          let-identifierExtension
					skeletonContent
			>
				{{ identifierExtension | formatIdentifierExtension }}
			</ng-template>
		</portal-skeleton-field>
		<br/>
		<portal-skeleton-field
			class="info"
			[content]="model.productSpecialityNumber.value"
		>
			<ng-template
          let-productSpecialityNumber
					skeletonContent
			>
				{{ productSpecialityNumber | formatProductSpecialityNumber }}
			</ng-template>
		</portal-skeleton-field>
	</ng-template>
	
	<ng-template
		column="name"
    let-model="model"
		[service]="calculationService"
	>
		<portal-skeleton-field
			class="ellipsis"
			[content]="model.name.value"
		/>
	</ng-template>
	
	<!--
		todo update to match changed requirement
		https://git.biv.to/calculations/public/-/issues/140
	-->
	<ng-template
		searchHeader="aidIdentifiers"
    let-column="column"
    let-search="search"
		[service]="calculationService"
	>
		<portal-table-search-multiselect
			[column]="column"
			[possibleValues]="aidIdentifierValues"
			(valueChanged)="search(column.id, column, $event, true)"
		/>
	</ng-template>
	<ng-template
		column="aidIdentifiers"
    let-model="model"
		[service]="calculationService"
	>
		<portal-skeleton-field [content]="model.aidIdentifiers.value">
			<ng-template
          let-aidIdentifiers
					skeletonContent
			>
				@for (aidIdentifier of aidIdentifiers; track aidIdentifier) {
					<portal-double-token *ngIf="aidIdentifier != null">
						<span>{{ aidIdentifier.toString().padStart(2, '0') }}</span>
						<span>{{ aidIdentifier | translatePrefixed: 'calculation.aidIdentifierValues' }}</span>
					</portal-double-token>
				}
			</ng-template>
		</portal-skeleton-field>
	</ng-template>
	
	<ng-template
		column="careScope"
    let-model="model"
		[service]="calculationService"
	>
		<portal-skeleton-field [content]="model.careScope.value"/>
	</ng-template>
	
	<ng-template
		column="fixedPrice"
    let-model="model"
		[service]="calculationService"
	>
		<portal-skeleton-field [content]="getFixedPrice(model)">
			<ng-template
          let-fixedPrice
					skeletonContent
			>
				{{ fixedPrice | currency }}
			</ng-template>
		</portal-skeleton-field>
	</ng-template>
	
	
	<ng-template
		searchHeader="addition"
    let-column="column"
    let-search="search"
		[service]="calculationService"
	>
		<mat-form-field>
			<input
				class="filterAddition"
				matInput
        #x
				[(ngModel)]="column.filter"
				(change)="search(column.id, column, x.value)"
				type="number"
				[min]="0"
				[max]="50"
				[step]="1"
			/>
			<span matSuffix>%</span>
		</mat-form-field>
	</ng-template>
	<ng-template
		column="addition"
    let-model="model"
		[service]="calculationService"
	>
		<portal-skeleton-field [content]="model.addition.value">
			<ng-template
          let-addition
					skeletonContent
			>
				{{ addition | percent }}
			</ng-template>
		</portal-skeleton-field>
	</ng-template>
	
	<ng-template
		column="price"
    let-model="model"
		[service]="calculationService"
	>
		<portal-skeleton-field [content]="model.priceSchema.value">
			<ng-template
          let-schema
					skeletonContent
			>
				<!-- todo make DRY -->
				@switch (schema?.schema) {
					@case (null) {
						<portal-loading/>
					}
					@case ('COST_ESTIMATE') {
						<span>{{ 'calculation.schema.COST_ESTIMATE' | translate }}</span>
					}
					@case ('STANDARD') {
						<portal-skeleton-field [content]="model.calculatePriceStandardSchema()">
							<ng-template
                  let-price
									skeletonContent
							>
								<!-- todo show parts -->
								<span>{{ price | formatCalculationStandardSchema }}</span>
							</ng-template>
						</portal-skeleton-field>
					}
					@case ('STAGGERED') {
						<div class="price-schema-staggered">
							<!-- todo: sort entries -->
							@for (entry of schema.parameters; track entry) {
								<ng-container *ngLet="{from: entry.lowerLimit | shortCurrency, to:entry.upperLimit | shortCurrency} as format">
									<span class="info">
										@if (entry.lowerLimit != null) {
											@if (entry.upperLimit != null) {
												{{ 'calculation.calculationSchema.staggered.range'  | translate: format }}
											} @else {
												{{ 'calculation.calculationSchema.staggered.startingFrom'  | translate: format }}
											}
										} @else {
											{{ 'calculation.calculationSchema.staggered.upTo'  | translate: format }}
										}
									</span>
									<span>
										@if (entry.type === 'EURO') {
											{{ entry.value | shortCurrency }}
										} @else {
											{{ entry.value | percent }}
										}
									</span>
								</ng-container>
							}
						</div>
					}
					@case ('FIXED') {
						<span>{{ schema.parameters.value | shortCurrency }}</span>
					}
					@case ('DISCOUNT') {
						<span>VK - {{ schema.parameters | percent }}</span>
					}
					@default {
						<span [style.background-color]="'red'">MISSING CALCULATION SCHEMA "{{ schema?.type | json }}"</span>
					}
				}</ng-template>
		</portal-skeleton-field>
	</ng-template>
	
	<!-- todo add - backend currently does not support this -->
	<!-- <ng-template -->
	<!-- 	searchHeader="hourlyBillingRateBracket" -->
	<!--   let-column="column" -->
	<!--   let-search="search" -->
	<!-- 	[service]="calculationService" -->
	<!-- > -->
	<!-- 	<portal-table-search-multiselect -->
	<!-- 		[column]="column" -->
	<!-- 		[possibleValues]="hourlyRatesValues" -->
	<!-- 		(valueChanged)="search(column.id, column, $event, true)" -->
	<!-- 	/> -->
	<!-- 	/> -->
	<!-- </ng-template> -->
	
	<ng-template
		column="hourlyBillingRateBracket"
    let-model="model"
		[service]="calculationService"
	>
		<portal-skeleton-field [content]="model.hourlyBillingRateBracket.value">
			<ng-template
          let-hourlyBillRate
					skeletonContent
			>
				<span>{{ hourlyBillRate | translatePrefixed: 'hourlyRates.names' }}</span>
				<br/>
				<portal-skeleton-field [content]="hourlyBillRate | hourlyRate:true">
					<ng-template
              let-rate
							skeletonContent
					>
						<span class="info">{{ rate | shortCurrency }}/h</span>
					</ng-template>
				</portal-skeleton-field>
			</ng-template>
		</portal-skeleton-field>
	</ng-template>
	
	<ng-template
		searchHeader="vatRates"
    let-column="column"
    let-search="search"
		[service]="calculationService"
	>
		<portal-table-search-multiselect
			[column]="column"
			[possibleValues]="vatRatesValues"
			(valueChanged)="search(column.id, column, $event, true)"
		/>
	</ng-template>
	<ng-template
		column="vatRates"
    let-model="model"
		[service]="calculationService"
	>
		<portal-skeleton-field [content]="model.vatRates.value">
			<ng-template
          let-vatRates
					skeletonContent
			>
				@for (vatRate of vatRates | sort; track vatRate; let isLast = $last) {
					<portal-double-token>
						<span>{{ vatRate | translatePrefixed: 'common.vatRateValues' }} %</span>
						<span>{{ vatRate | translatePrefixed: 'common.vatRateNames' }}</span>
					</portal-double-token>
					<ng-container *ngIf="!isLast">
						<br/>
					</ng-container>
				}
			</ng-template>
		</portal-skeleton-field>
	</ng-template>
	
	<ng-template
		searchHeader="unit"
    let-column="column"
    let-search="search"
		[service]="calculationService"
	>
		<portal-table-search-multiselect
			[column]="column"
			[possibleValues]="unitValues"
			(valueChanged)="search(column.id, column, $event, true)"
		/>
	</ng-template>
	<ng-template
		column="unit"
    let-model="model"
		[service]="calculationService"
	>
		<portal-skeleton-field [content]="model.unit.value">
			<ng-template
          let-unit
					skeletonContent
			>
				{{ unit | translatePrefixed: 'material.unit' }}
			</ng-template>
		</portal-skeleton-field>
	</ng-template>
</portal-table-server-side-searchable>