import {
	DtoEditFormHelper,
	ModelInterface,
	ModelNameHelper,
	PermissionedPropertyInterface,
} from '@angular-helpers/frontend-api';
import {HttpErrorResponse} from '@angular/common/http';
import {
	Component,
	HostListener,
	Input,
	TemplateRef,
	ViewChild,
} from '@angular/core';
import {ValidationErrors} from '@angular/forms';
import {
	MatDialogConfig,
	MatDialogRef,
} from '@angular/material/dialog';
import {
	BaseDialogData,
	ConfirmDialogAnswer,
	ConfirmDialogConfig,
	DialogService,
	FileType,
	IconService,
} from '@app/main';
import {
	FileDtoModel,
	FileModel,
	FileService,
} from '@contracts/frontend-api';
import Timeout = NodeJS.Timeout;

interface FileRelationModelInterface extends ModelInterface {
	files: PermissionedPropertyInterface<FileModel[] | undefined>;
}

@Component({
	selector:    'portal-file-create-upload',
	templateUrl: './file-create-upload.component.html',
	styleUrls:   ['./file-create-upload.component.scss'],
})
export class FileCreateUploadComponent {
	private static readonly ERROR_INVALID_FILE_TYPE = 'invalidFileType';
	private static readonly ERROR_INVALID_FILE_SIZE = 'isValidFileSize';
	private static readonly ERROR_INVALID_SOURCE    = 'invalidSource';
	readonly permissions                            = {
		canCreate: false,
	};
	isDragging                                      = false;
	dropError: ValidationErrors | null              = null;
	uploadingFiles: File[]                          = [];
	@Input() fileSizeLimit                          = 1_024 * 1_024 * 50;

	@Input() type!: string;
	@Input() description?: string;
	@Input() acceptedFileTypes = [
		FileType.pdf,
		FileType.xlsx,
		FileType.docx,
		FileType.doc,
	];
	@ViewChild('editModeTemplateFiles') readonly editPopupContent!: TemplateRef<unknown>;
	private errorReset?: Timeout;
	private _relation!: FileRelationModelInterface;

	constructor(
		private readonly fileService: FileService,
		private readonly dialogService: DialogService,
		protected readonly iconService: IconService,
	) {
	}

	get relation(): FileRelationModelInterface {
		return this._relation;
	}

	@Input() set relation(value: FileRelationModelInterface) {
		this._relation = value;
		FileModel.permissionsClass.canCreate({
			         relationId:   value.id,
			         relationType: ModelNameHelper.fromObject(value).asBaseName(),
		         })
		         .then((isPermitted: boolean) => this.permissions.canCreate = isPermitted);
	}

	private static formatBytes(bytes: number, decimals = 2) {
		if(bytes === 0)
			return '0 Bytes';

		const k     = 1_024;
		const dm    = decimals < 0 ? 0 : decimals;
		const sizes = [
			'Bytes',
			'KB',
			'MB',
			'GB',
			'TB',
			'PB',
			'EB',
			'ZB',
			'YB',
		];

		const i = Math.floor(Math.log(bytes) / Math.log(k));

		return `${Number.parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
	}

	@HostListener('dragover', ['$event'])
	@HostListener('dragenter', ['$event'])
	onDragenter(event: DragEvent): void {
		// https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API/Drag_operations#specifying_drop_targets
		event.preventDefault();
		this.resetError();

		if(event.dataTransfer == null)
			return;

		this.dropError = this.getDragEventError(event);
		if(this.dropError != null)
			return;

		this.isDragging = true;
	}

	@HostListener('drop', ['$event']) onDrop(event: DragEvent): void {
		this.isDragging = false;
		event.preventDefault();
		this.resetError();

		this.dropError = this.getDragEventError(event);
		if(this.dropError != null) {
			this.errorReset = setTimeout(() => {
				this.resetError();
			}, 2_500);
			return;
		}

		if(this.getDragEventError(event) != null)
			return;

		if(event.dataTransfer == null)
			return;

		this.addFiles(event.dataTransfer.files);
	}

	addFiles(fileList: FileList | null): void {
		if(fileList == null)
			return;

		const files = Array.from(fileList);

		this.dropError = this.getFilesError(files);
		if(this.dropError != null) {
			this.errorReset = setTimeout(() => {
				this.resetError();
			}, 2_500);
			return;
		}

		for(const file of files)
			this.addFile(file);
	}

	errorToMap(errors: ValidationErrors | null): ReadonlyMap<string, unknown> {
		const values: Map<string, unknown> = new Map();
		if(errors != null) {
			Object.keys(errors).reduce(
				(last, property) => last.set(property, Reflect.get(errors, property)),
				values,
			);
		}

		return values;
	}

	@HostListener('dragleave', ['$event']) onDragleave(event: DragEvent): void {
		this.isDragging = false;
		this.dropError  = null;
	}

	addFile(file: File): void {
		this.uploadingFiles.push(file);
		let fileName      = file.name;
		let fileExtension = file.name;
		let isSaving      = false;
		const lastDot     = fileExtension.lastIndexOf('.');
		if(lastDot > 0) {
			fileName      = fileName.substr(0, lastDot);
			fileExtension = fileExtension.substr(lastDot + 1);
		}

		const fileReader = new FileReader();
		fileReader.readAsDataURL(file);
		fileReader.onloadend = (event) => {
			const creationPromise = this.fileService.create({
				file:            fileReader.result,
				name:            fileName,
				description:     this.description ?? fileName,
				extension:       fileExtension,
				validityStartAt: new Date(),
				isPublishable:   false,
				relationType:    ModelNameHelper.fromObject(this._relation).asBaseName(),
				relationId:      this._relation.id,
				type:            this.type,
			});

			creationPromise.then((fileModel: FileModel) => {
				const formHelper = DtoEditFormHelper.create(FileDtoModel, fileModel, this.fileService);
				isSaving         = false;

				formHelper.control.then((control) => {
					this.uploadingFiles                               = this.uploadingFiles.filter(fileInArray => fileInArray !== file);
					let errorHasOccurred                              = undefined;
					let editDialog: MatDialogRef<unknown> | undefined = undefined;
					const data: BaseDialogData                        = {
						icon:              this.iconService.FILES,
						headline:          'file.edit',
						content:           this.editPopupContent,
						acceptText:        'actions.save',
						cancelButtonText:  'actions.cancel',
						enableContentGrid: true,
						error:             errorHasOccurred,
						control:           control,
					};

					const closeEditDialog                 = () => {
						editDialog?.close();
						editDialog = undefined;
					};
					const scrollToFirstInvalidFormControl = () => {
						const firstElementWithError: HTMLElement | null = this.editPopupContent.elementRef.nativeElement.querySelector('.ng-invalid');
						firstElementWithError?.scrollIntoView(
							{
								behavior: 'smooth',
								block:    'center',
								inline:   'center',
							});
					};

					const save: () => Promise<void> = async () => {
						if(isSaving)
							return;

						const test = await formHelper.isInvalid();
						if((test)) {
							scrollToFirstInvalidFormControl();
							return;
						}

						isSaving = true;
						try {
							await formHelper.save();
							closeEditDialog();
							errorHasOccurred = undefined;
						} catch(error) {
							if(error instanceof Error || error instanceof HttpErrorResponse || error === undefined)
								errorHasOccurred = error;
							else
								errorHasOccurred = new Error(`${error}`);

							throw error;
						} finally {
							isSaving = false;
						}
					};

					const abortEditing = async (forceClose = false): Promise<void> => {
						if(!forceClose && (await formHelper.control).dirty) {
							const confirmDialogData: ConfirmDialogConfig = {
								labelPositiv:  'system.abortChangesDialog.labelPositiv',
								labelNegative: 'system.abortChangesDialog.labelNegative',
								title:         'system.abortChangesDialog.title',
								message:       'system.abortChangesDialog.message',
								icon:          this.iconService.DIALOG_ATTENTION,
							};

							const confirmDialog = this.dialogService.openConfirmDialog(confirmDialogData);
							confirmDialog.afterClosed().subscribe(answer => {
								if(answer === ConfirmDialogAnswer.negative)
									abortEditing(true);
							});

							return;
						}

						closeEditDialog();
						await formHelper.reset();
					};

					data.onAccept = save.bind(this);
					data.onCancel = abortEditing.bind(this);

					const config    = new MatDialogConfig();
					config.minWidth = 'min-content';
					config.data     = data;
					editDialog      = this.dialogService.openBaseDialog(config);
				});

			});
		};
	}

	isObject(value: unknown): value is object {
		if(value == null)
			return false;

		return typeof value === 'object';
	}

	private isValidFileType(fileType: string): boolean {
		return this.acceptedFileTypes.some(acceptedType => acceptedType.toString() === fileType);
	}

	private isValidFileSize(fileSize: number): boolean {
		return fileSize < this.fileSizeLimit;
	}

	private resetError(): void {
		if(this.errorReset != null) {
			clearTimeout(this.errorReset);
			this.errorReset = undefined;
		}
		this.dropError = null;
	}

	private getDragEventError(event: DragEvent): ValidationErrors | null {
		if(event.dataTransfer == null)
			return null;

		if(!event.dataTransfer.types.includes('Files'))
			return {[FileCreateUploadComponent.ERROR_INVALID_SOURCE]: FileCreateUploadComponent.ERROR_INVALID_SOURCE};

		for(const dataTransferItem of Array.from(event.dataTransfer.items)) {
			if(!this.isValidFileType(dataTransferItem.type))
				return {[FileCreateUploadComponent.ERROR_INVALID_FILE_TYPE]: FileCreateUploadComponent.ERROR_INVALID_FILE_TYPE};
		}

		return null;
	}

	private getFilesError(files: (File | null)[]): ValidationErrors | null {
		for(const file of files) {
			if(file == null)
				return {[FileCreateUploadComponent.ERROR_INVALID_SOURCE]: FileCreateUploadComponent.ERROR_INVALID_SOURCE};

			if(!this.isValidFileType(file.type))
				return {[FileCreateUploadComponent.ERROR_INVALID_FILE_TYPE]: FileCreateUploadComponent.ERROR_INVALID_FILE_TYPE};

			if(!this.isValidFileSize(file.size)) {
				return {
					[FileCreateUploadComponent.ERROR_INVALID_FILE_SIZE]: {
						limit: FileCreateUploadComponent.formatBytes(this.fileSizeLimit),
					},
				};
			}
		}

		return null;
	}
}
