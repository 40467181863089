import {registerLocaleData} from '@angular/common';
import {
	HTTP_INTERCEPTORS,
	HttpClient,
} from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import {
	DEFAULT_CURRENCY_CODE,
	ErrorHandler,
	LOCALE_ID,
	NgModule,
} from '@angular/core';
import {MAT_DATE_LOCALE} from '@angular/material/core';
import {
	MAT_DIALOG_DATA,
	MatDialogModule,
	MatDialogRef,
} from '@angular/material/dialog';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {
	BrowserModule,
	Meta,
} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {
	CalculationsAuthInterceptor,
	CalculationsModule,
} from '@app/calculations';
import {HmmParticipationPopupComponent} from '@app/contracts';
import {environment} from '@app/environment';
import {
	DetectOutdatedBrowserService,
	MainModule,
	RequestErrorLoggerInterceptor,
	ThemeServiceService,
	TranslateRecursiveParser,
} from '@app/main';
import {
	ConfigModel,
	PkceAuthModule,
} from '@authentication/angular';
import {AuthenticationModule} from '@authentication/frontend-api';
import {ContractsModule} from '@contracts/frontend-api';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {
	TranslateLoader,
	TranslateModule,
	TranslateParser,
} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import * as sentry from '@sentry/angular';
import {
	LoggerModule,
	NGXLogger,
	NgxLoggerLevel,
} from 'ngx-logger';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';

@NgModule({
	declarations: [
		AppComponent,
	],
	imports:      [
		BrowserModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		MainModule,
		PkceAuthModule.forRoot(),
		LoggerModule.forRoot({level: environment.isOnServer ? NgxLoggerLevel.OFF : NgxLoggerLevel.DEBUG}),
		MatProgressSpinnerModule,
		MatDialogModule,
		ContractsModule.forRoot({
			baseUrl:    environment.contractsApiUrl,
			authConfig: new ConfigModel(
				environment.redirectUrl,
				environment.authServer,
				environment.authClientId,
			),
		}),
		AuthenticationModule.forRoot({
			baseUrl:    environment.authServer,
			authConfig: new ConfigModel(
				environment.redirectUrl,
				environment.authServer,
				environment.authClientId,
			),
		}),
		CalculationsModule.forRoot({
			baseUrl:    environment.calculationsApiUrl,
			authConfig: new ConfigModel(
				environment.redirectUrl,
				environment.authServer,
				environment.authClientId,
			),
		}),
		FontAwesomeModule,
		TranslateModule.forRoot({
			loader:          {
				provide:    TranslateLoader,
				useFactory: (http: HttpClient): TranslateHttpLoader => new TranslateHttpLoader(http),
				deps:       [HttpClient],
			},
			parser:          {
				provide:  TranslateParser,
				useClass: TranslateRecursiveParser,
				deps:     [NGXLogger],
			},
			defaultLanguage: 'de',
		}),
		ContractsModule,
		HmmParticipationPopupComponent,
	],
	providers:    [
		{
			provide:  ErrorHandler,
			useValue: sentry.createErrorHandler(),
		},
		{
			provide:  LOCALE_ID,
			useValue: 'de-de',
		},
		{
			provide:  MAT_DATE_LOCALE,
			useValue: 'de-de',
		},
		{
			provide:  DEFAULT_CURRENCY_CODE,
			useValue: 'EUR',
		},
		{
			provide:  HTTP_INTERCEPTORS,
			useClass: RequestErrorLoggerInterceptor,
			multi: true,
		},
		{
			provide:  HTTP_INTERCEPTORS,
			useClass: CalculationsAuthInterceptor,
			multi:    true,
		},
		{
			provide:  MAT_DIALOG_DATA,
			useValue: {},
		},
		{
			provide:  MatDialogRef,
			useValue: {},
		},
		{
			provide:  MAT_FORM_FIELD_DEFAULT_OPTIONS,
			useValue: {
				subscriptSizing: 'dynamic',
			},
		},
	],
	bootstrap:    [AppComponent],
})
export class AppModule {
	constructor(
		meta: Meta,
		detectOutdatedBrowserService: DetectOutdatedBrowserService,
		themeServiceService: ThemeServiceService,
	) {
		if(environment.stage === 'PRODUCTION')
			meta.removeTag('robots');

		registerLocaleData(localeDe);

		detectOutdatedBrowserService.track();
	}
}
