import {ComponentType} from '@angular/cdk/overlay';
import {Injectable} from '@angular/core';
import {
	MatDialog,
	MatDialogConfig,
	MatDialogRef,
} from '@angular/material/dialog';
import {
	BaseDialogComponent,
	BaseDialogData,
} from '../components/base-dialog/base-dialog.component';
import {
	ConfirmDialogAnswer,
	ConfirmDialogComponent,
	ConfirmDialogConfig,
} from '../components/confirm-dialog/confirm-dialog.component';
import {DeleteModelDialogComponent} from '../components/delete-model-dialog/delete-model-dialog.component';

@Injectable({
	providedIn: 'root',
})
export class DialogService {
	constructor(
		protected readonly dialog: MatDialog,
	) {
	}

	openConfirmDialog(data: ConfirmDialogConfig): MatDialogRef<ConfirmDialogComponent, ConfirmDialogAnswer> {
		return this.dialog.open(ConfirmDialogComponent, {
			maxWidth: '90vw',
			autoFocus:    false,
			disableClose: true,
			data,
		});
	}

	getResult(data: ConfirmDialogConfig): Promise<ConfirmDialogAnswer> {
		return this.openConfirmDialog(data)
		           .afterClosed()
		           .toPromise()
		           .then((answer) => answer ?? ConfirmDialogAnswer.unknown);
	}

	openDeleteDialog(modelName: string, modelType: string, deleteCallback?: () => Promise<unknown>): MatDialogRef<DeleteModelDialogComponent, boolean> {
		return this.dialog.open(DeleteModelDialogComponent, {
			data:      {
				modelType,
				modelName,
				deleteCallback,
			},
			autoFocus: false,
		});
	}

	openBaseDialog(dialogConfig: MatDialogConfig<BaseDialogData> | BaseDialogData): MatDialogRef<BaseDialogComponent> {
		if(dialogConfig instanceof MatDialogConfig)
			return this.openCustomDialog(BaseDialogComponent, dialogConfig.data ?? {}, dialogConfig);

		return this.openCustomDialog(BaseDialogComponent, dialogConfig, new MatDialogConfig());
	}

	openCustomDialog<T, D extends object>(component: ComponentType<T>, data: D, config?: Omit<Partial<MatDialogConfig<D>>, 'data'>): MatDialogRef<T> {
		const matDialogConfig: MatDialogConfig = config ?? new MatDialogConfig<D>();
		matDialogConfig.data                   = data;

		// add default values
		matDialogConfig.autoFocus ??= false;
		matDialogConfig.disableClose ??= true;
		matDialogConfig.minWidth ??= '66vw';
		matDialogConfig.height ??= 'max-content';
		matDialogConfig.maxHeight ??= '90vh';

		return this.dialog.open(component, matDialogConfig);
	}
}
