<a class="logo" routerLink="/">
	<svg inlineSVG="/assets/img/logo-biv.svg"/>
	<span class="name">{{ 'system.systemName' | translate }}</span>
</a>


<div class="info pushLeft pushRight">
	@if (hasMessageHint()) {
		<a
			class="hover no-link"
			[routerLink]="[]"
			(click)="openStageDialog()">
			<fa-icon [icon]="iconService.ICON_ALERT"></fa-icon>&nbsp;
			{{ 'system.stage.' + environment.stage + '.short' | translate }}
		</a>
	}
</div>


<ng-template #login>
	<div class="login hover">
		<a [routerLink]="environment.loginFullUrl">{{ 'general.login' | translate }}</a>
	</div>
</ng-template>

@if ((token$ | async);as token) {
	<div class="content" #avatarMenu>
		<ng-container *ngLet="(user$ | async) as user">
			<a (click)="toggleMenu()" [routerLink]="[]" class="no-link avatar hover">
				<portal-skeleton-field [content]="user?.name?.value"/>
				<fa-icon class="more" [icon]="ICONS.avatar" size="2x"></fa-icon>
			</a>
			<div [class.visible]="menuShown" class="menu">
				<mat-card>
					<mat-card-header>
						<mat-card-title>
							<portal-skeleton-field [content]="user?.name?.value"/>
						</mat-card-title>
						<mat-card-subtitle>
							<portal-skeleton-field [content]="(configService.developerMode | async) ? user?.id : user?.email?.value"/>
						</mat-card-subtitle>
					</mat-card-header>
					<mat-divider></mat-divider>
					<mat-card-content>
						<mat-selection-list>
							<a
								mat-list-item
								(click)="openPreferences()"
							>
								<fa-icon
									size="2xs"
									matListItemIcon
									[icon]="iconService.PREFERENCES"
								/>
								{{ 'system.preferences' | translate }}
							</a>
							<a
								notExternal
								href="https://auth.biv-ot.org/password/reset"
								mat-list-item
							>
								<fa-icon
									size="2xs"
									matListItemIcon
									[icon]="iconService.PASSWORD"
								/>
								{{ 'general.passwordChange' | translate }}
							</a>
							<a
								mat-list-item
								routerLink="/logout"
							>
								<fa-icon
									size="2xs"
									matListItemIcon
									[icon]="iconService.LOGOUT"
								/>
								{{ 'general.logout' | translate }}
							</a>
						</mat-selection-list>
					</mat-card-content>
				</mat-card>
			</div>
		</ng-container>
	</div>
} @else {
	<div class="login hover">
		<a [routerLink]="environment.loginFullUrl">{{ 'general.login' | translate }}</a>
	</div>
}
