import {
	inject,
	Pipe,
	PipeTransform,
} from '@angular/core';
import {
	Observable,
	of,
} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {HourlyRate} from '../models/common/types';
import {HourlyRateService} from '../services/hourly-rate.service';

@Pipe({
	name:       'hourlyRate',
	standalone: true,
})
export class HourlyRatePipe implements PipeTransform {
	protected readonly hourlyRateService = inject(HourlyRateService);

	transform(value: Observable<HourlyRate | undefined | null> | HourlyRate | undefined | null, perHour = false): Observable<number | undefined | null> {
		if(!(value instanceof Observable))
			value = of(value);

		return value.pipe(
			switchMap(value => {
				if(value == null)
					return of(value);

				if(perHour)
					return this.hourlyRateService.getRate(value);

				return this.hourlyRateService.getRatePerMinute(value);
			}),
		);
	}

}
