import {
	AbstractProperty,
	InheritedCoalesceProperty,
	InheritMergedProperty,
	Level8Error,
} from '@angular-helpers/frontend-api';
import {DatePipe} from '@angular/common';
import {inject} from '@angular/core';

type ValueType = string | null | undefined | string[];

// eslint-disable-next-line @typescript-eslint/ban-types
export class SearchFilter<Model extends Object> {
	protected filter = new Map<string, ValueType>();
	private readonly datePipe = inject(DatePipe);

	getEntry(key: string): ValueType {
		return this.filter.get(key);
	}

	setEntry(key: string, value: ValueType): void {
		this.filter.set(key, value);
	}

	cleanup(): void {
		for(const [key, value] of this.filter) {
			if(typeof value === 'string')
				this.filter.set(key, value.trim());

			if(Array.isArray(value))
				this.filter.set(key, value.map(s => s.trim()));
		}
	}

	shouldFilterArray(needle: string, value: string[] | undefined): boolean {
		if(needle.length === 0)
			return false;

		needle = needle.toLowerCase();
		return value?.every(string => this.shouldFilterString(needle, string)) ?? false;
	}

	shouldFilterString(needle: string, value: string | null | undefined): boolean {
		if(needle.length === 0)
			return false;

		if(value == null)
			return true;

		return value.toLocaleLowerCase().includes(needle.toLocaleLowerCase()) === false;
	}

	shouldFilterDate(needle: string, value: Date | null | undefined): boolean {
		if(needle.length === 0)
			return false;

		return this.datePipe.transform(value)?.includes(needle) === false;
	}

	shouldFilterNull(field: unknown): boolean {
		return field !== null;
	}

	async isFiltered(model: Model): Promise<boolean> {
		const filter = [];
		for(const [property] of this.filter)
			filter.push(this.isFieldFiltered(property, model));

		const results = (await Promise.all(filter));
		return results.reduce((prev, curr) => prev || curr, false) === false;
	}

	protected async isFieldFiltered(field: string, model: Model): Promise<boolean> {
		const needle = this.getEntry(field);
		if(needle === '' || needle === undefined)
			return false;

		const value = await this.getModelValue(field, model);
		if(needle === null)
			return this.shouldFilterNull(value);

		if(value == null)
			return true;

		const needles = Array.isArray(needle) ? needle : [needle];
		for(const needle of needles) {
			if(typeof value == 'string') {
				if(this.shouldFilterString(needle, value))
					return true;

				continue;
			}

			// todo maybe replace by smarter filtering
			if(typeof value == 'number') {
				if(this.shouldFilterString(needle, value.toString()))
					return true;

				continue;
			}

			if(Array.isArray(value)) {
				if(this.shouldFilterArray(needle, value))
					return true;

				continue;
			}

			if(typeof value === 'boolean') {
				if(this.shouldFilterString(needle, value ? '1jy' : '0n '))
					return true;

				continue;
			}

			if(value instanceof Date) {
				if(this.shouldFilterDate(needle, value))
					return true;

				continue;
			}

			throw new Level8Error(`Missing filter function for type ${typeof value}`);
		}

		return false;
	}

	protected async getModelValue(field: string, model: Model): Promise<unknown> {
		if(!Reflect.has(model, field))
			return undefined;

		const value = Reflect.get(model, field);
		if(typeof value === 'object' && value != null) {
			if(value instanceof InheritedCoalesceProperty || value instanceof InheritMergedProperty)
				return value.withParent.firstValue;

			if(value instanceof AbstractProperty)
				return await value.firstValue;
		}

		return value;
	}
}
