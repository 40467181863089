import {
	Component,
	OnInit,
} from '@angular/core';
import {environment} from '@app/environment';
import {IconService} from '@app/main';
import {
	MasterContractModel,
	MasterContractService,
} from '@contracts/frontend-api';

@Component({
	templateUrl: './master-contracts-page-list.component.html',
	styleUrls:   ['./master-contracts-page-list.component.scss'],
})
export class MasterContractsPageListComponent implements OnInit {
	protected masterContracts?: Promise<MasterContractModel[]>;
	protected canCreate = MasterContractModel.permissionsClass.canCreate({});
	protected readonly environment = environment;

	constructor(
		protected readonly masterContractService: MasterContractService,
		protected readonly iconService: IconService,
	) {
	}

	ngOnInit(): void {
		this.masterContracts = this.masterContractService.getAllModels();
	}
}
