import {
	AbstractParser,
	AbstractService,
	ModelId,
} from '@angular-helpers/frontend-api';
import {AnyApiService} from '@angular-helpers/frontend-api/lib/models/abstract-model/abstract.api-service';
import {AnyModel} from '@app/main';
import {
	CalculationAmount,
	CalculationModel,
} from './calculation.model';
import {CalculationService} from './calculation.service';

export class CalculationParser extends AbstractParser<CalculationModel, CalculationService> {
	isAmountDto(value: unknown): value is AmountDto {
		if(value == null || typeof value !== 'object')
			return false;

		// todo remove me - just workaround for backend bug
		if(('amount' in value) && (typeof value.amount === 'string'))
			value.amount = Number.parseFloat(value.amount);

		if(('amount' in value) === false || typeof value.amount !== 'number')
			return false;

		if(('id' in value) === false || typeof value.id !== 'string')
			return false;

		return true;
	}

	protected getModelPropertyName(dtoPropertyName: string): string | undefined {
		switch(dtoPropertyName) {
			/**
			 * workaround for switch aidIdentifier => aidIdentifiers
			 * todo deltete after backend sitched https://git.biv.to/calculations/public/-/issues/140
			 */
			case 'aidIdentifier':
				return 'aidIdentifiers';

			default:
				return super.getModelPropertyName(dtoPropertyName);
		}
	}

	protected sanitizeInput(propertyDto: string, property: string, value: unknown): unknown {
		switch(property) {
			/**
			 * workaround for switch aidIdentifier => aidIdentifiers
			 * todo delete after backend switched https://git.biv.to/calculations/public/-/issues/140
			 */
			case 'aidIdentifiers':
				if(Array.isArray(value) === false)
					value = [value];
				return value;

			case 'characteristic':
				if(typeof value !== 'string' && value != null)
					throw new Error(`Invalid value for property '${property}'. Expected'string' got '${typeof value}'`);

				if(value?.trim() === '')
					return null;

				return value;

			case 'materials':
				return this.castToCalculationAmounts(value, this.service.materialService);

			case 'products':
				return this.castToCalculationAmounts(value, this.service.productService);

			case 'calculations':
				return this.castToCalculationAmounts(value, this.service);

			case 'taskTimes':
				return this.castToCalculationAmounts(value, this.service.taskTimeService);

			default:
				return super.sanitizeInput(propertyDto, property, value);
		}
	}

	protected castToCalculationAmounts<T extends AnyModel>(
		value: unknown,
		service: AbstractService<AnyApiService, T>,
	): CalculationAmount<T>[] {
		if(Array.isArray(value) === false)
			throw new Error(`Invalid value. Expected an array, got ${typeof value}`);

		return value.map(dto => {
			if(this.isAmountDto(dto) === false)
				throw new Error(`Invalid value. Expected an AmountDto, got '${JSON.stringify(dto)}'`);

			return {
				model:  this.castToModel(dto.id, service),
				amount: dto.amount,
			};
		});
	}
}

interface AmountDto {
	id: ModelId;
	amount: number;
}