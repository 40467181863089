import {
	AsyncPipe,
	DatePipe,
} from '@angular/common';
import {
	Component,
	inject,
	OnInit,
} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {
	MatCard,
	MatCardActions,
	MatCardContent,
} from '@angular/material/card';
import {Router} from '@angular/router';
import {environment} from '@app/environment';
import {MainModule} from '@app/main';
import {NewsDidReadService} from '@contracts/frontend-api';
import {TranslateModule} from '@ngx-translate/core';
import {first} from 'rxjs/operators';
import {CalculationInfoGuard} from '../../../main/guards/calculation-info.guard';

@Component({
	standalone:  true,
	imports:     [
		AsyncPipe,
		DatePipe,
		MainModule,
		MatCard,
		MatCardActions,
		MatCardContent,
		TranslateModule,
	],
	templateUrl: './calculation-info-accept-page.component.html',
	styleUrl:    './calculation-info-accept-page.component.scss',
})
export class CalculationInfoAcceptPageComponent implements OnInit {
	protected readonly newsDidReadService = inject(NewsDidReadService);
	protected readonly guard = inject(CalculationInfoGuard);
	protected readonly router = inject(Router);
	protected isSaving = false;

	ngOnInit(): void {
		this.guard.accepted$.pipe(
			first(),
			takeUntilDestroyed(),
		).subscribe(accepted => {
			if(accepted)
				this.hasAccepted();
		});
	}

	async accept(): Promise<void> {
		if(this.isSaving)
			return;

		this.isSaving = true;
		try {
			await this.newsDidReadService.create({
				newsId: environment.calculationsInfoNewsId,
			});

			await this.hasAccepted();
		} finally {
			this.isSaving = false;
		}
	}

	protected async hasAccepted(): Promise<void> {
		await this.router.navigate([environment.calculationFullUrl]);
	}
}
