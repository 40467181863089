<portal-headline
	label="general.modelList"
	[labelArgs]="{model: 'masterContracts'}"
	[menu]="menu"
>
	<mat-menu #menu>
		@if (canCreate | async) {
			<a
				mat-menu-item
				[routerLink]="[environment.masterContractsFullUrl, 'create']"
			>
				<fa-icon matMenuItemIcon [icon]="iconService.CREATE"></fa-icon>
				<span>{{ 'masterContract.createNew' | translate }}</span>
			</a>
		}
	</mat-menu>
</portal-headline>

<mat-card>
	<mat-card-content>
		@if (masterContracts) {
			<portal-base-contract-list
				[pageSize]="25"
				[data]="masterContracts | async"
			/>
		}
	</mat-card-content>
</mat-card>
