import {
	Component,
	inject,
	Signal,
	TemplateRef,
} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {
	MAT_DIALOG_DATA,
	MatDialogRef,
} from '@angular/material/dialog';
import {IconDefinition} from '@fortawesome/fontawesome-svg-core';

export interface BaseDialogData {
	content: TemplateRef<unknown>;
	headline: string;
	icon: IconDefinition;
	cancelButtonText?: string;
	control?: UntypedFormGroup;
	acceptText: string;
	enableContentGrid?: boolean;
	error?: Signal<Error | undefined>;
	onAccept?: () => Promise<void>;
	onCancel?: () => Promise<void>;
}


@Component({
	templateUrl: './base-dialog.component.html',
	styleUrls:   ['./base-dialog.component.scss'],
})
export class BaseDialogComponent {
	protected readonly data = inject<BaseDialogData>(MAT_DIALOG_DATA);
	protected readonly dialogRef = inject(MatDialogRef<this>);
}
