import {Level8Error} from '@angular-helpers/frontend-api';
import {
	Component,
	Input,
	OnInit,
} from '@angular/core';
import {
	FormControl,
	FormGroup,
} from '@angular/forms';
import {environment} from '@app/environment';
import {LabelSelectFieldEntry} from '@app/main';
import {
	IqzCategoryModel,
	IqzCategoryService,
} from '@contracts/frontend-api';

@Component({
	selector:    'portal-medical-store-edit-iqz-categories[control]',
	templateUrl: './medical-store-edit-iqz-categories.component.html',
	styleUrls:   ['./medical-store-edit-iqz-categories.component.scss'],
})
export class MedicalStoreEditIqzCategoriesComponent implements OnInit {
	@Input('control') parent!: FormGroup;
	possibleCategories?: LabelSelectFieldEntry<IqzCategoryModel>[];

	constructor(
		private readonly iqzCategoryService: IqzCategoryService,
	) {
	}

	get control(): FormControl {
		const fieldName = 'iqzCategories';
		const control   = this.parent.get(fieldName);
		if(control instanceof FormControl)
			return control;

		throw new Level8Error(`Unexpected type for field ${fieldName} - expected '${FormControl.name}' got '${typeof control}' (${control})`);
	}

	ngOnInit(): void {
		this.loadPossibleValues().then(possibleValues => this.possibleCategories = possibleValues);
	}

	protected async loadPossibleValues(): Promise<LabelSelectFieldEntry<IqzCategoryModel>[]> {
		const possibleValues = [];

		const models = await this.iqzCategoryService.getAllModels();
		for(const model of models) {
			// exclude "allgemeine Fortbildung"
			if(model.id === environment.iqzCategoryBasicId)
				continue;

			const name = await model.name.firstValue;
			if(name == null)
				continue;

			possibleValues.push({
				label: name,
				value: model,
			});
		}

		return possibleValues;
	}
}
