import {
	AbstractPermissionedService,
	DtoInterface,
	ModelId,
} from '@angular-helpers/frontend-api';
import {
	Injectable,
	Injector,
} from '@angular/core';
import {MaterialApiService} from './material.api-service';
import {MaterialModel} from './material.model';
import {MaterialParser} from './material.parser';


@Injectable({
	providedIn: 'root',
})
export class MaterialService extends AbstractPermissionedService<MaterialApiService, MaterialModel> {
	constructor(apiService: MaterialApiService, injector: Injector) {
		super(apiService, injector);
	}

	protected createNewModel(id: ModelId): MaterialModel {
		return new MaterialModel(id, this);
	}

	protected fillModelFromDto(dto: DtoInterface, model: MaterialModel): void {
		return MaterialParser.fillModelFromDto(model, dto, this);
	}
}