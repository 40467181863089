import {AbstractParser} from '@angular-helpers/frontend-api';
import {TaskTimeModel} from './task-time.model';
import {TaskTimeService} from './task-time.service';

export class TaskTimeParser extends AbstractParser<TaskTimeModel, TaskTimeService> {

	protected sanitizeInput(propertyDto: string, property: string, value: unknown): unknown {
		switch(property) {
			case 'task':
				if(typeof value !== 'string')
					throw new Error(`Invalid value for property '${property}'. Expected 'string' got '${typeof value}'`);

				return this.castToModel(value, this.service.taskService);

			case 'productGroups':
				if(typeof value !== 'string')
					throw new Error(`Invalid value for property '${property}'. Expected 'string' got '${typeof value}'`);

				return this.castToModel(value, this.service.produktgruppeService);

			default:
				return super.sanitizeInput(propertyDto, property, value);
		}
	}
}