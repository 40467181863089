import {
	Component,
	inject,
} from '@angular/core';
import {
	MatCard,
	MatCardContent,
} from '@angular/material/card';
import {RouterLink} from '@angular/router';
import {
	ClientSearchMinimalColumns,
	DialogService,
	MainModule,
	SystemPreferencesDialogComponent,
} from '@app/main';
import {
	data,
	HourlyBillingRateBracketFixedData,
} from './hourly-billing-rate-data';

const REGEX_EXTRACT_EURO_VALUE = /[0-9]+,[0-9]{1,2}\s*€/;

@Component({
	standalone:  true,
	imports:     [
		MatCard,
		MatCardContent,
		RouterLink,
		MainModule,
	],
	templateUrl: './hourly-billing-rate-bracket-page.component.html',
	styleUrl:    './hourly-billing-rate-bracket-page.component.scss',
})
export class HourlyBillingRateBracketPageComponent {
	protected readonly tableHeaders: ClientSearchMinimalColumns<HourlyBillingRateBracketFixedData> = {
		name:           {
			index: 0,
			label: 'hourlyBillingRateBrackesPage.name',
		},
		pg05:           {
			index:   1,
			label:   'hourlyBillingRateBrackesPage.pg05',
			sortMap: async (value: HourlyBillingRateBracketFixedData['pg05']) => value?.match(REGEX_EXTRACT_EURO_VALUE)?.[0] ?? null,
		},
		pg08:           {
			index:   2,
			label:   'hourlyBillingRateBrackesPage.pg08',
			sortMap: async (value: HourlyBillingRateBracketFixedData['pg08']) => value?.match(REGEX_EXTRACT_EURO_VALUE)?.[0] ?? null,
		},
		pg0923:         {
			index:   3,
			label:   'hourlyBillingRateBrackesPage.pg0923',
			sortMap: async (value: HourlyBillingRateBracketFixedData['pg0923']) => value?.match(REGEX_EXTRACT_EURO_VALUE)?.[0] ?? null,
		},
		pg17ple:        {
			index:   4,
			label:   'hourlyBillingRateBrackesPage.pg17ple',
			sortMap: async (value: HourlyBillingRateBracketFixedData['pg17ple']) => value?.match(REGEX_EXTRACT_EURO_VALUE)?.[0] ?? null,
		},
		pg17scar:       {
			index:   5,
			label:   'hourlyBillingRateBrackesPage.pg17scar',
			sortMap: async (value: HourlyBillingRateBracketFixedData['pg17scar']) => value?.match(REGEX_EXTRACT_EURO_VALUE)?.[0] ?? null,
		},
		pg23conf:       {
			index:   6,
			label:   'hourlyBillingRateBrackesPage.pg23conf',
			sortMap: async (value: HourlyBillingRateBracketFixedData['pg23conf']) => value?.match(REGEX_EXTRACT_EURO_VALUE)?.[0] ?? null,
		},
		pg23individual: {
			index:   7,
			label:   'hourlyBillingRateBrackesPage.pg23individual',
			sortMap: async (value: HourlyBillingRateBracketFixedData['pg23individual']) => value?.match(REGEX_EXTRACT_EURO_VALUE)?.[0] ?? null,
		},
		pg24leg:        {
			index:   8,
			label:   'hourlyBillingRateBrackesPage.pg24leg',
			sortMap: async (value: HourlyBillingRateBracketFixedData['pg24leg']) => value?.match(REGEX_EXTRACT_EURO_VALUE)?.[0] ?? null,
		},
		pg24individual: {
			index:   9,
			label:   'hourlyBillingRateBrackesPage.pg24individual',
			sortMap: async (value: HourlyBillingRateBracketFixedData['pg24individual']) => value?.match(REGEX_EXTRACT_EURO_VALUE)?.[0] ?? null,
		},
		pg26:           {
			index:   10,
			label:   'hourlyBillingRateBrackesPage.pg26',
			sortMap: async (value: HourlyBillingRateBracketFixedData['pg26']) => value?.match(REGEX_EXTRACT_EURO_VALUE)?.[0] ?? null,
		},
		pg31:           {
			index:   11,
			label:   'hourlyBillingRateBrackesPage.pg31',
			sortMap: async (value: HourlyBillingRateBracketFixedData['pg31']) => value?.match(REGEX_EXTRACT_EURO_VALUE)?.[0] ?? null,
		},
		pg37:           {
			index:   12,
			label:   'hourlyBillingRateBrackesPage.pg37',
			sortMap: async (value: HourlyBillingRateBracketFixedData['pg37']) => value?.match(REGEX_EXTRACT_EURO_VALUE)?.[0] ?? null,
		},
		pg38:           {
			index:   13,
			label:   'hourlyBillingRateBrackesPage.pg38',
			sortMap: async (value: HourlyBillingRateBracketFixedData['pg38']) => value?.match(REGEX_EXTRACT_EURO_VALUE)?.[0] ?? null,
		},
	};
	protected data = data;
	private readonly dialogService = inject(DialogService);

	protected openPreferences(): void {
		SystemPreferencesDialogComponent.open(this.dialogService);
	}
}
