import {
	Component,
	EventEmitter,
	Input,
	Output,
} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {FormControl} from '@angular/forms';
import {
	FullColumn,
	IconService,
} from '@app/main';

@Component({
	selector:    'portal-table-search-select',
	templateUrl: './table-search-select.component.html',
	styleUrls:   ['./table-search-select.component.scss'],
})
export class TableSearchSelectComponent<T> {
	@Input({required: true}) possibleValues!: Map<string, T>;
	@Output() valueChanged = new EventEmitter<T | undefined>();
	protected control      = new FormControl<T | undefined>(undefined);
	private _column?: FullColumn<unknown & object, T | undefined>;

	constructor(
		protected readonly iconService: IconService,
	) {
		this.control.valueChanges
		    .pipe(takeUntilDestroyed())
		    .subscribe(value => {
			    value ??= undefined;
			    this.valueChanged.next(value);
			    if(this.column) {
				    this.column.onSearch?.(
					    value,
					    {
						    id:        this.column.id,
						    searchNow: true,
					    },
				    );
			    }
		    });
	}

	get column(): FullColumn<unknown & object, T | undefined> | undefined {
		return this._column;
	}

	@Input()
	set column(value: FullColumn<unknown & object, T | undefined>) {
		this._column      = value;
		this.currentValue = value.filter;
	}

	@Input() set currentValue(value: T | undefined) {
		this.control.setValue(
			value,
			{
				onlySelf:  true,
				emitEvent: false,
			},
		);
	}
}
