import {
	Component,
	EventEmitter,
	Input,
	Output,
} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {IconDefinition} from '@fortawesome/fontawesome-common-types';
import {FormHelperService} from '../../helper/form-helper.service';
import {IconService} from '../../services/icon.service';

@Component({
	selector:    'portal-edit-field',
	templateUrl: './edit-field.component.html',
	styleUrls:   ['./edit-field.component.scss'],
})
export class EditFieldComponent {
	@Input() icon?: IconDefinition;
	@Input({required: true}) label!: string;
	@Input({required: true}) validator!: UntypedFormControl;
	@Input() placeholder?: string | null;
	@Input() showAlways = false;
	@Input() suffix?: string;
	@Input({transform: (value: '' | boolean) => value !== false}) fill = false;
	@Output() change    = new EventEmitter();

	constructor(
			public iconService: IconService,
			public formHelperService: FormHelperService,
	) {
	}
}
