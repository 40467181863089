import {Level8Error} from '@angular-helpers/frontend-api';
import {
	Component,
	Input,
} from '@angular/core';
import {
	UntypedFormControl,
	UntypedFormGroup,
} from '@angular/forms';
import {
	combineLatestSafe,
	LabelSelectFieldEntry,
	notNull,
} from '@app/main';
import {
	ProfessionalAssociationModel,
	ProfessionalAssociationService,
} from '@contracts/frontend-api';
import {
	from,
	Observable,
} from 'rxjs';
import {
	filter,
	map,
	mergeMap,
} from 'rxjs/operators';

@Component({
	selector: 'portal-professional-association-edit',
	templateUrl: './professional-association-edit.component.html',
	styleUrls: ['./professional-association-edit.component.scss'],
})
export class ProfessionalAssociationEditComponent {
	@Input({
		required: true,
		alias:    'control',
	}) parent!: UntypedFormGroup;
	readonly possibleValues$ = this.buildPossibleValues$();

	constructor(
			protected professionalAssociationService: ProfessionalAssociationService,
	) {}

	get control(): UntypedFormControl {
		const fieldName = 'professionalAssociation';
		const control   = this.parent.get(fieldName);
		if(control instanceof UntypedFormControl)
			return control;

		throw new Level8Error(`Unexpected type for field ${fieldName} - expected '${UntypedFormControl.name}' got '${typeof control}' (${control})`);
	}

	get currentValue(): LabelSelectFieldEntry<ProfessionalAssociationModel> | undefined {
		const professionalAssociation = this.control.value as ProfessionalAssociationModel | null | undefined;

		if(professionalAssociation == null)
			return undefined;

		if(professionalAssociation.name.currentValue == null)
			return undefined;

		return {
			label: professionalAssociation.name.currentValue,
			value: professionalAssociation,
		};
	}

	protected buildPossibleValues$(): Observable<LabelSelectFieldEntry<ProfessionalAssociationModel>[]> {
		return from(this.professionalAssociationService.getAllModels()).pipe(
			mergeMap(professionalAssociations => combineLatestSafe(professionalAssociations.map(professionalAssociation =>
				professionalAssociation.name.value.pipe(
					filter(notNull),
						map(name => ({
							label: name,
							value: professionalAssociation,
						})),
				)))),
		);
	}
}
