import {Environment} from './environment';

export const environment = new Environment(
	'DEVELOPMENT',
	'https://auth.biv-ot.dev',
	'60e1b7de-aa86-4736-bb94-b8278bfb18e2',
	'/login/callback',
	'https://api.contracts.biv-ot.dev',
	'https://api.calculations.biv-ot.dev',
);
