<portal-headline
	label="contractAccession.join"
	[backlink]="true"
/>

<mat-card>
	<mat-card-content>
		<mat-stepper orientation="vertical" linear="true" #stepper>
			<ng-template matStepperIcon="edit" let-index="index">{{ index + 1 }}</ng-template>
			<ng-template matStepperIcon="done" let-index="index">{{ index + 1 }}</ng-template>
			<ng-template matStepperIcon="error" let-index="index">{{ index + 1 }}</ng-template>

			<mat-step [completed]="ikSelected != null" [editable]="!(isSaving || isSaved)">
				<ng-template matStepLabel>{{ 'contractAccessing.selectIk' | translate }}</ng-template>
				<div class="grid-2">
					<portal-institutionskennzeichen-select
						(change)="ikSelectionChange($event)"
						[label]="undefined"
					>
					</portal-institutionskennzeichen-select>
				</div>
			</mat-step>

			<mat-step [completed]="vbSelected != null || contractingPartySelected != null" [editable]="!(isSaving || isSaved)">
				<ng-template matStepLabel>{{ 'contractAccessing.dimension.select' | translate }}</ng-template>
				<portal-loading [loading]="contractingPartys == null || vbs == null">
					<mat-form-field class="fill">
						<mat-label>{{ 'model.contractingParty' | translate }}</mat-label>
						<mat-select [(ngModel)]="contractingPartySelected" (selectionChange)="selected($event.value)">
							@for (entry of contractingPartys | keyvalue | sort;track entry) {
								<mat-option [value]="entry.value">
									{{ entry.key }}
								</mat-option>
							}
							@if (contractingPartys && contractingPartys.size < 1) {
								<mat-option [value]="null">{{ 'general.noOptions' | translate: {model: 'model.contractingParty'} }}</mat-option>
							}
						</mat-select>
					</mat-form-field>
					<span class="divider"> {{ 'contractAccessing.dimension.divider' | translate }} </span>
					<mat-form-field class="fill">
						<mat-label>{{ 'model.versorgungsbereich' | translate }}</mat-label>
						<mat-select [(ngModel)]="vbSelected" (selectionChange)="selected($event.value)">
							@for (entry of vbs | keyvalue | sort;track entry) {
								<mat-option [value]="entry.value">
									{{ entry.key }}
								</mat-option>
							}
							@if (vbs && vbs.size < 1) {
								<mat-option [value]="null">{{ 'general.noOptions' | translate: {model: 'model.versorgungsbereich'} }}</mat-option>
							}
						</mat-select>
					</mat-form-field>
					<ng-container *ngTemplateOutlet="stepperActions"></ng-container>
				</portal-loading>
			</mat-step>

			<mat-step [completed]="joiningContracts.length > 0" [editable]="!(isSaving || isSaved)">
				<ng-template matStepLabel>{{ 'contractAccessing.selectContracts.title' | translate }}</ng-template>
				<portal-loading [loading]="joinedContracts == null || contractIkRelations == null" [content]="lazyLoad"/>
				<ng-template #lazyLoad>
					@if (vbSelected) {
						<h3>{{ 'contractAccessing.selectContracts.byVb' | translate : {vb: (vbSelected.name.value | async)} }}</h3>
					}
					@if (contractingPartySelected) {
						<h3>{{ 'contractAccessing.selectContracts.byParty' | translate : {party: (contractingPartySelected.name.value | async)} }}</h3>
					}
					<mat-tree [dataSource]="treeDataSource" [treeControl]="treeController">
						<mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
							<button mat-icon-button disabled></button>
							@if (node.contractSection) {
								@if (!ikHasAlreadyJoined(node.contractSection)) {
									<mat-checkbox [checked]="isContractSelected(node.contractSection)" (change)="selectContract(node.contractSection, node.party, $event.checked)" [disabled]="hasRequirementErrors(node)">
										@if (vbSelected) {
											{{ '' + (getNodePartyName$(node) | async) }}
										}
										@if (contractingPartySelected) {
											{{ '' + (getNodeVbName$(node) | async) }}
										}
									</mat-checkbox>
								} @else {
									<mat-checkbox [checked]="true" disabled>
										@if (vbSelected) {
											{{ '' + (getNodePartyName$(node) | async) }}
										}
										@if (contractingPartySelected) {
											{{ '' + (getNodeVbName$(node) | async) }}
										}
									</mat-checkbox>
								}
							}&nbsp;
							@if (node.errors != null) {
								@if (node.errors.length > 0) {
									<fa-icon
                      #tooltip="matTooltip"
											(click)="tooltip.show()"
											class="error-messages"
											matTooltip="{{ getErrorString(node.errors) }}"
											matTooltipPosition="right"
											matTooltipShowDelay="200"
											[icon]="iconService.QUESTION"
									></fa-icon>
								}
							} @else {
								<fa-icon
									[icon]="iconService.LOADING_SPINNER"
									[spin]="true"
								>
								</fa-icon>
							}&nbsp;
						</mat-tree-node>
						<mat-tree-node *matTreeNodeDef="let node; when isExpandable" matTreeNodePadding>
							<button
								mat-icon-button
								[attr.aria-label]="'Toggle ' + node.item" matTreeNodeToggle>
								@if (treeController.isExpanded(node)) {
									<fa-icon [icon]="iconService.ANGLE_DOWN"></fa-icon>
								} @else {
									<fa-icon [icon]="iconService.ANGLE_RIGHT"></fa-icon>
								}
							</button>
							<mat-checkbox
                  #checkParent
									[checked]="allChildrenSelected(node) || allChildrenAreJoined(node)"
									[indeterminate]="someChildrenSelected(node) && !allChildrenSelected(node)"
									(change)="todoItemSelectionToggle(node, $event)"
									[disabled]="allChilrenAreNotSelectable(node)"
							>
								@if (node.contract) {
									{{ assertContract(node.contract).name.value | async }}
								}
							</mat-checkbox>
						</mat-tree-node>
					</mat-tree>
					<div class="stepper-actions">
						<button mat-button matStepperPrevious>{{ 'actions.previous' | translate }}</button>
						<button mat-button color="accent" (click)="selectAllContracts()">{{ 'contractAccessing.selectContracts.selectAll' | translate }}</button>
						<button mat-button color="primary" matStepperNext>{{ 'actions.next' | translate }}</button>
					</div>
				</ng-template>
			</mat-step>

			<mat-step [completed]="isSaved" [editable]="!(isSaving || isSaved)">
				<ng-template matStepLabel>{{ 'contractAccessing.join' | translate }}</ng-template>
				<ng-template matStepContent>
					<div class="joining">

						@if (joiningContracts.length > 1) {
							@if (getEarliestStartDate$(joiningContracts) | async;as earliestStartDate) {
								@if (getLatestStartDate$(joiningContracts) | async;as latestStartDate) {
									@if (earliestStartDate <= latestStartDate) {
										<!-- grid layout matching -->
										<span></span>
										<span></span>
										<span></span>
										<portal-edit-field-date
											label="contractAccessing.accessionStartAllAt"
											[validator]="accessionStartAtControl"
											[earliestDate]="earliestStartDate"
											[latestDate]="latestStartDate"
										/>
									}
								}
							}
						}

						@for (contractEntry of joiningContracts;track contractEntry;let index = $index) {
							@if (getIsContractSaving(contractEntry)) {
								<fa-icon class="processIcon" [icon]="iconService.BOOLEAN_TRUE"></fa-icon>
							} @else {
								<fa-icon class="processIcon" [class.hidden]="!(isSaving || isSaved)" [icon]="iconService.LOADING_SPINNER" [spin]="true"></fa-icon>
							}
							<span>{{ getPartyName$(contractEntry.contractSection) | async }}</span>
							<span>{{ getEntryVbName$(contractEntry) | async }}</span>
							<portal-edit-field-date
								label="contractAccession.accessionStartAt"
								[placeholder]="accessionStartAtControl.value | date"
								[validator]="getJoiningDateControl(contractEntry.contractSection)"
								[earliestDate]="getEarliestStartDate$(contractEntry) | async"
								[latestDate]="getLatestStartDate$(contractEntry) | async"
							/>
						}
					</div>
					@if (!isSaved) {
						<portal-spinning-button color="accent" (click)="joinSelected()" text="contractAccessing.joinSelected" [processing]="isSaving"/>
					}
					@if (isSaved) {
						<a mat-stroked-button color="primary" [routerLink]="environment.contractAccessionFullUrl">{{ 'contractAccessing.backToAccessions' | translate }}</a>
						<span class="successMsg">{{
                    'contractAccessing.successfullyJoinedText.' + (joiningContracts.length > 1 ? 'many' : 'one') | translate: {amount: joiningContracts.length}
							}}</span>
					}
				</ng-template>
			</mat-step>

			<ng-template #stepperActions>
				<div class="stepper-actions">
					<button mat-button matStepperPrevious>{{ 'actions.previous' | translate }}</button>
					<button mat-button color="primary" matStepperNext>{{ 'actions.next' | translate }}</button>
				</div>
			</ng-template>
		</mat-stepper>

		@if (!(isSaving || isSaved)) {
			<a mat-stroked-button [routerLink]="environment.contractAccessionFullUrl">{{ 'actions.abort' | translate }}</a>
		}
	</mat-card-content>
	@if (this.errorOccurred) {
		<portal-form-error [error]="this.errorOccurred"/>
	}
</mat-card>
