import {
	Directive,
	Optional,
	TemplateRef,
} from '@angular/core';
import {CalculationShowSectionComponent} from './calculation-show-section.component';

@Directive({
	selector:   'ng-template[CalculationSectionHeaderTitle]',
	standalone: true,
})
export class CalculationSectionHeaderTitleTemplateDirective {
	constructor(
		public readonly templateRef: TemplateRef<unknown>,
		@Optional() parent?: CalculationShowSectionComponent, // require parent to be a CalculationShowSectionComponent
	) {
		if(parent == null)
			throw new Error(`${this.constructor.name} can only be used inside a CalculationShowSectionComponent`);
	}
}