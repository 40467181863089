import {
	AbstractPermissionedService,
	DtoInterface,
	ModelId,
} from '@angular-helpers/frontend-api';
import {
	Injectable,
	Injector,
} from '@angular/core';
import {ProductService} from '../product/product.service';
import {ProductVariationParser} from './product-variation.parser';
import {VariationApiService} from './variation-api.service';
import {VariationModel} from './variation.model';


@Injectable({
	providedIn: 'root',
})
export class VariationService extends AbstractPermissionedService<VariationApiService, VariationModel> {
	constructor(apiService: VariationApiService, injector: Injector) {
		super(apiService, injector);
	}

	get productService(): ProductService {
		return this.injector.get(ProductService);
	}

	protected createNewModel(id: ModelId): VariationModel {
		return new VariationModel(id, this);
	}

	protected fillModelFromDto(dto: DtoInterface, model: VariationModel): void {
		return ProductVariationParser.fillModelFromDto(model, dto, this);
	}
}