import {ResultPageModel} from '@angular-helpers/frontend-api';
import {
	Component,
	Input,
	OnChanges,
	SimpleChanges,
} from '@angular/core';
import {environment} from '@app/environment';
import {
	BasicPropertyType,
	ClientSearchMinimalColumns,
	combineLatestSafe,
	IconService,
	SearchFilter,
} from '@app/main';
import {
	IqzCategoryModel,
	IqzParticipationModel,
} from '@contracts/frontend-api';
import moment from 'moment';
import {
	combineLatest,
	Observable,
	of,
	ReplaySubject,
} from 'rxjs';
import {
	map,
	mergeMap,
} from 'rxjs/operators';

@Component({
	selector:    'portal-iqz-participation-list',
	templateUrl: './iqz-participation-list.component.html',
	styleUrls:   ['./iqz-participation-list.component.scss'],
})
export class IqzParticipationListComponent implements OnChanges {
	@Input({required: true}) data: IqzParticipationModel[] | ResultPageModel<IqzParticipationModel> | undefined | null;
	@Input() category: IqzCategoryModel | undefined;
	readonly tableHeaders: ClientSearchMinimalColumns<IqzParticipationModel> = {
		trainingCourse:    {
			label:   'model.iqzTrainingCourse',
			index:   0,
			sortMap: (trainingCourse: BasicPropertyType<IqzParticipationModel['iqzTrainingCourse']>) => trainingCourse?.title.firstValue,
		},
		category:          {
			isVisible: false,
			label:     'model.iqzCategory',
			index:     1,
			sortMap: (category: BasicPropertyType<IqzParticipationModel['iqzCategory']>) => category?.name.firstValue,
		},
		points:            {
			label: 'iqzTrainingCourse.points',
			index: 2,
		},
		participationDate: {
			label:     'iqzTrainingCourse.endAt',
			index:     3,
			isVisible: false,
		},
	};

	protected readonly searchFilter = new CSearchFilter();
	protected readonly participations$ = new ReplaySubject<IqzParticipationModel[] | undefined | null>();
	protected readonly participations = this.participations$.asObservable()
	                                        .pipe(
		                                        mergeMap(entries => combineLatestSafe(entries?.map(entry => combineLatest([
			                                        of(entry),
			                                        entry.iqzCategory.value,
		                                        ])))),
		                                        map(entries => entries
			                                        ?.filter(([, category]) => this.category == null || category === this.category)
			                                        .map(([model]) => model),
		                                        ),
	                                        )
	;

	constructor(
		protected readonly iconService: IconService,
	) {
	}

	static getParticipationDate(data: IqzParticipationModel): Observable<Date | undefined> {
		return data.iqzTrainingCourse.value.pipe(
			mergeMap(course => course?.endAt.value ?? of(undefined)),
		);
	}

	static getTrainingCourse$(data: IqzParticipationModel): Observable<string> {
		return data.iqzTrainingCourse.value.pipe(
			mergeMap(course => course?.title.value ?? of(undefined)),
			map(x => x ?? ''),
		);
	}

	static getCategory$(data: IqzParticipationModel): Observable<string> {
		return data.iqzCategory.value.pipe(
			mergeMap(category => category?.name.value ?? of(undefined)),
			map(x => x ?? ''),
		);
	}

	static getPoints$(data: IqzParticipationModel): Observable<number> {
		const latestValidCertificateDate = moment().subtract(environment.IQZ_POINTS_VALID_PERIOD_YEARS, 'years').add(1, 'day').toDate();

		return data.iqzTrainingCourse.value.pipe(
			mergeMap(course => {
				return of(course).pipe(
					mergeMap(course => (course == null) ? of(false) : course.endAt.value.pipe(
						map(endAt => endAt == null || endAt < latestValidCertificateDate),
					)),
					mergeMap((isOutdated): Observable<(number | undefined)[]> => {
						if(isOutdated)
							return of([]);

						return combineLatest([
							course?.iqzTrainingCoursePoints.value ?? of(undefined),
							course?.iqzTrainingCoursePointsExam.value ?? of(undefined),
							course?.iqzWorkshopPoints.value ?? of(undefined),
						]);
					}),
					map(x => x.reduce((curr, last) => (last ?? 0) + (curr ?? 0), 0)),
					map(x => x ?? 0),
				);
			}),
		);
	}

	ngOnChanges(changes: SimpleChanges): void {
		let data = this.data;
		if(data instanceof ResultPageModel)
			data = data.data;

		this.participations$.next(data);
	}

	getParticipationDate(data: IqzParticipationModel): Observable<Date | undefined> {
		return IqzParticipationListComponent.getParticipationDate(data);
	}

	getPoints$(data: IqzParticipationModel): Observable<number> {
		return IqzParticipationListComponent.getPoints$(data);
	}

	getTrainingCourse$(data: IqzParticipationModel): Observable<string> {
		return IqzParticipationListComponent.getTrainingCourse$(data);
	}

	getCategory$(data: IqzParticipationModel): Observable<string> {
		return IqzParticipationListComponent.getCategory$(data);
	}
}

class CSearchFilter extends SearchFilter<IqzParticipationModel> {
	protected async getModelValue(field: string, model: IqzParticipationModel): Promise<unknown> {
		switch(field) {
			case 'participationDate':
				return IqzParticipationListComponent.getParticipationDate(model);

			case 'trainingCourse':
				return IqzParticipationListComponent.getTrainingCourse$(model);

			case 'category':
				return IqzParticipationListComponent.getCategory$(model);

			case 'points':
				return IqzParticipationListComponent.getPoints$(model);

			default:
				return super.getModelValue(field, model);
		}
	}
}
