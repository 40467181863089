@if (model) {
	<portal-headline
		label="model.news" [menu]="menu" [backlink]="true"
	>
		<mat-menu #menu>
			@if (!isMarkedAsRead) {
				<button mat-menu-item (click)="markAsRead()">
					<fa-icon matMenuItemIcon [icon]="iconService.VISIBLE"></fa-icon>
					<span>{{ 'news.markAsRead' | translate }}</span>
				</button>
			}
			@if (isMarkedAsRead) {
				<button mat-menu-item (click)="markAsUnread()">
					<fa-icon matMenuItemIcon [icon]="iconService.HIDDEN"></fa-icon>
					<span>{{ 'news.markAsUnread' | translate }}</span>
				</button>
			}
			@if ((model.permissions.canDelete | async)) {
				<button mat-menu-item (click)="showDeleteDialog()">
					<fa-icon matMenuItemIcon [icon]="iconService.DELETE"></fa-icon>
					<span>{{ 'actions.delete' | translate }}</span>
				</button>
			}
		</mat-menu>
	</portal-headline>
	<div class="flex-container">
		<portal-news-card-master-data [news]="model"/>
	</div>
}
