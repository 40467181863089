import {Level8Error} from '@angular-helpers/frontend-api';
import {
	Component,
	ElementRef,
	Input,
	ViewChild,
} from '@angular/core';
import {
	UntypedFormControl,
	UntypedFormGroup,
} from '@angular/forms';
import {
	FormHelperService,
	IconService,
	ModelHelper,
} from '@app/main';
import {
	EmployeeModel,
	EmployeeService,
	RoleModel,
	RoleService,
} from '@contracts/frontend-api';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
	selector: 'portal-employee-edit-roles',
	templateUrl: './employee-edit-roles.component.html',
	styleUrls: ['./employee-edit-roles.component.scss'],
})
export class EmployeeEditRolesComponent {
	protected static _allRoles?: RoleModel[];
	@ViewChild('input') inputElement!: ElementRef<HTMLInputElement>;
	searchValue = '';
	@Input({
		required: true,
		alias:    'control',
	}) parent!: UntypedFormGroup;

	constructor(public iconService: IconService, public formHelperService: FormHelperService, protected roleService: RoleService) {
	}

	get allRoles(): RoleModel[] {
		if(EmployeeEditRolesComponent._allRoles === undefined) {
			EmployeeEditRolesComponent._allRoles = [];
			this.roleService.getAllModels().then(roles => EmployeeEditRolesComponent._allRoles = roles);
		}

		return EmployeeEditRolesComponent._allRoles;
	}


	get currentRoles(): readonly RoleModel[] {
		return this.control.value;
	}

	set currentRoles(roles: readonly RoleModel[]) {
		this.control.markAsDirty();
		this.control.setValue(roles);
	}

	get control(): UntypedFormControl {
		const fieldName = 'roles';
		const control   = this.parent.get(fieldName);
		if(control instanceof UntypedFormControl)
			return control;

		throw new Level8Error(`Unexpected type for field ${fieldName} - expected '${UntypedFormControl.name}' got '${typeof control}' (${control})`);
	}

	get possibleRoles$(): Observable<RoleModel[]> {
		const searchValue = this.searchValue.toLowerCase();
		const roles$      = this.allRoles.filter(role => !this.currentRoles.includes(role));

		return ModelHelper.getModelPropertyValues(roles$, 'name', 'model').pipe(
			map(roles => roles.filter(role => role.name?.toLowerCase().includes(searchValue))),
			map(roles => roles.map(role => role.model)),
		);
	}

	static async saveRoles(employee: EmployeeModel, control: UntypedFormGroup, employeeService: EmployeeService): Promise<void> {
		const rolesControl = control.get('roles');
		if(!rolesControl)
			throw new Level8Error(`Unexpected form group - missing child 'roles'`);

		const updatedRoles: RoleModel[] = (Array.isArray(rolesControl.value)) ? rolesControl.value : [];

		const originalRoles = await employee.roles.firstValue;

		const removedRoles = originalRoles?.filter(role => !(updatedRoles.includes(role))) ?? [];
		const addedRoles   = updatedRoles.filter(role => (originalRoles?.includes(role)) === false);

		const removePromises = removedRoles.map(role => employeeService.removeRole(employee, role));
		const addPromises    = addedRoles.map(role => employeeService.addRole(employee, role));

		const changedModels = await Promise.all([
			...removePromises,
			...addPromises,
		]);

		rolesControl.markAsPristine();
		rolesControl.markAsUntouched();

		const versionControl = control.get('version');
		if(versionControl !== null)
			versionControl.setValue(versionControl.value + changedModels.length);
	}

	removeRole(removeRole: RoleModel): void {
		this.currentRoles = this.currentRoles.filter(role => role !== removeRole);
	}

	roleSelected(role: RoleModel): void {
		this.resetSearch();

		if(this.currentRoles.includes(
			role))
			return;

		this.currentRoles = [
			...this.currentRoles,
			role,
		];
	}

	private resetSearch(): void {
		this.inputElement.nativeElement.value = this.searchValue = '';
	}
}
