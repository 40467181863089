import {
	AbstractPermissionedService,
	DtoInterface,
	ModelId,
} from '@angular-helpers/frontend-api';
import {
	Injectable,
	Injector,
} from '@angular/core';
import {ProduktgruppeService} from '@contracts/frontend-api';
import {TaskService} from '../task/task.service';
import {TaskTimeApiService} from './task-time.api-service';
import {TaskTimeModel} from './task-time.model';
import {TaskTimeParser} from './task-time.parser';


@Injectable({
	providedIn: 'root',
})
export class TaskTimeService extends AbstractPermissionedService<TaskTimeApiService, TaskTimeModel> {
	constructor(apiService: TaskTimeApiService, injector: Injector) {
		super(apiService, injector);
	}

	get produktgruppeService(): ProduktgruppeService {
		return this.injector.get(ProduktgruppeService);
	}

	get taskService(): TaskService {
		return this.injector.get(TaskService);
	}

	protected createNewModel(id: ModelId): TaskTimeModel {
		return new TaskTimeModel(id, this);
	}

	protected fillModelFromDto(dto: DtoInterface, model: TaskTimeModel): void {
		return TaskTimeParser.fillModelFromDto(model, dto, this);
	}
}