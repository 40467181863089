import {AbstractApiService} from '@angular-helpers/frontend-api';
import {Injectable} from '@angular/core';
import {CalculationsModule} from '../../calculations.module';

@Injectable({
	providedIn: 'root',
})
export class ProductApiService extends AbstractApiService {
	constructor() {
		super(CalculationsModule.injector);
	}

	protected getBaseUrl(): string {
		return '/v1/product';
	}
}