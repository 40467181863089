import {AbstractParser} from '@angular-helpers/frontend-api';
import {ProductModel} from './product.model';
import {ProductService} from './product.service';

export class ProductParser extends AbstractParser<ProductModel, ProductService> {
	protected sanitizeInput(propertyDto: string, property: string, value: unknown): unknown {
		switch(property) {
			case 'variations':
				if(!Array.isArray(value))
					throw new Error(`Property ${property} must be an array`);

				return this.castToModelArray(value, this.service.productVariationService);

			default:
				return super.sanitizeInput(propertyDto, property, value);
		}
	}
}