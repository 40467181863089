import {Level8Error} from '@angular-helpers/frontend-api';
import {
	Component,
	Input,
	OnInit,
} from '@angular/core';
import {
	UntypedFormControl,
	UntypedFormGroup,
} from '@angular/forms';
import {
	LabelSelectFieldEntry,
	ModelHelper,
} from '@app/main';
import {
	ContractingPartyModel,
	ContractingPartyService,
	ContractModel,
	MasterContractModel,
} from '@contracts/frontend-api';
import {
	Observable,
	of,
} from 'rxjs';
import {
	first,
	map,
} from 'rxjs/operators';

@Component({
	selector: 'portal-base-contract-edit-joined-parties',
	templateUrl: './base-contract-edit-joined-parties.component.html',
	styleUrls: ['./base-contract-edit-joined-parties.component.scss'],
})
export class BaseContractEditJoinedPartiesComponent implements OnInit {
	@Input({
		required: true,
		alias:    'control',
	}) parent!: UntypedFormGroup;
	@Input() model?: MasterContractModel | ContractModel; // todo #93
	@Input() parentModel?: MasterContractModel | ContractModel;
	entries?: ContractingPartyModel[];

	constructor(
		private readonly contractingPartyService: ContractingPartyService,
	) {
	}

	get inheritedValues(): Observable<ContractingPartyModel[]> | undefined {
		const getValues = () => {
			if(this.parentModel != null) {
				if(this.parentModel instanceof MasterContractModel)
					return this.parentModel.joinedParties.value;

				return this.parentModel.joinedParties.withParent.value;
			}

			if(this.model == null)
				return undefined;

			if(this.model instanceof MasterContractModel)
				return undefined;

			return this.model.joinedParties.inherited;
		};

		return getValues()?.pipe(
			map(entries => entries ?? []),
			map(entries => entries.map(entry => entry.contractingParty)),
		);
	}

	get options(): Observable<LabelSelectFieldEntry<ContractingPartyModel>[]> | undefined {
		if(this.entries == null)
			return this.entries;


		return ModelHelper.getModelPropertyValues(this.entries, 'name', 'model').pipe(
			map(parties => parties
				.filter(party => party.name != null)
				.map(party => ({
					label: party.name ?? '',
					value: party.model,
				}))),
		);
	}

	get control(): UntypedFormControl {
		const fieldName = 'joinedParties';
		const control   = this.parent.get(fieldName);
		if(control instanceof UntypedFormControl)
			return control;

		throw new Level8Error(`Unexpected type for field ${fieldName} - expected '${UntypedFormControl.name}' got '${typeof control}' (${control})`);
	}

	ngOnInit(): void {
		this.contractingPartyService.getAllModels().then(models => {
			ModelHelper.getModelPropertyValues(models, 'name', 'model').pipe(
				map(entries => entries.sort((a, b) => (a.name ?? '').localeCompare(b.name ?? ''))),
				map(sortedEntries => sortedEntries.map(entry => entry.model)),
				first(),
			).subscribe(entries => this.entries = entries);
		});
	}

	isInherited(entry: ContractingPartyModel): Observable<boolean> {
		if(this.model == null)
			return of(false);

		return this.model.joinedParties.value.pipe(
			map(joinedParties => joinedParties?.some((joinedParty => joinedParty.contractingParty === entry)) ?? false),
		);
	}
}
