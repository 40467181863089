import {PageRequestOptions} from '@angular-helpers/frontend-api/lib/models/abstract-model/abstract.api-service';
import {
	Component,
	EventEmitter,
	inject,
	Input,
	Output,
} from '@angular/core';
import {
	MatCard,
	MatCardAvatar,
	MatCardContent,
	MatCardHeader,
	MatCardTitle,
} from '@angular/material/card';
import {IconService} from '@app/main';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';
import {TranslateModule} from '@ngx-translate/core';
import {CalculationListComponent} from '../../list/calculation-list.component';

@Component({
	selector:    'portal-calculation-card-list',
	standalone:  true,
	imports:     [
		FaIconComponent,
		MatCard,
		MatCardAvatar,
		MatCardContent,
		MatCardHeader,
		MatCardTitle,
		TranslateModule,
		CalculationListComponent,
	],
	templateUrl: './calculation-card-list.component.html',
	styleUrl:    './calculation-card-list.component.scss',
})
export class CalculationCardListComponent {
	@Input() label: string | null  = 'model.calculations';
	@Output() readonly searchEvent = new EventEmitter<PageRequestOptions['search']>;
	protected readonly iconService = inject(IconService);
}
