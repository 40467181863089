import {AsyncPipe} from '@angular/common';
import {
	Component,
	EventEmitter,
	Input,
	Output,
} from '@angular/core';
import {FormatIdentifierExtensionPipe} from '@app/main';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {CalculationModel} from '../../../../../models/calculation/calculation.model';

@Component({
	selector:    'portal-select-calculations-dialog-entry',
	standalone:  true,
	imports:     [
		AsyncPipe,
		FaIconComponent,
		FormatIdentifierExtensionPipe,
	],
	templateUrl: './select-calculations-dialog-entry.component.html',
	styleUrl:    './select-calculations-dialog-entry.component.scss',
})
export class SelectCalculationsDialogEntryComponent {
	@Input({required: true}) calculation!: CalculationModel;
	@Input({required: true}) icon!: IconProp;
	@Output() buttonClick = new EventEmitter<CalculationModel>();
}
