import {
	DestroyRef,
	inject,
} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
	UrlSerializer,
	UrlTree,
} from '@angular/router';
import {
	AuthService,
	combineLatestSafe,
} from '@app/main';
import {
	NewsModel,
	UserService,
} from '@contracts/frontend-api';
import {
	Observable,
	of,
} from 'rxjs';
import {
	map,
	mergeMap,
	tap,
} from 'rxjs/operators';

export abstract class NewsDidReadGuard {
	readonly accepted$: Observable<boolean>;
	protected readonly urlSerializer = inject(UrlSerializer);
	protected skipCheck              = false;

	constructor(
		protected readonly news: NewsModel,
		protected readonly redirectUrl: string,
	) {
		const userService = inject(UserService);

		const authService = inject(AuthService);
		const destroyRef  = inject(DestroyRef);
		this.accepted$    = authService.user$.pipe(
			map(user => user?.getSubject()),
			takeUntilDestroyed(destroyRef),
			mergeMap(userId => {
				if(userId == null)
					return of(null);

				return userService.getNewsDidReadList(userId);
			}),
			mergeMap(page => combineLatestSafe(page?.data.map(entry => entry.news.value))),
			map(results => results == null || results.includes(this.news)),
			tap(accepted => this.skipCheck = this.skipCheck || accepted),
		);
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		if(this.skipCheck)
			return true;

		return this.accepted$.pipe(map(accepted => accepted ? true : this.urlSerializer.parse(this.redirectUrl)));
	}

	canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return this.canActivate(childRoute, state);
	}
}
