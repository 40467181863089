import {
	Pipe,
	PipeTransform,
} from '@angular/core';

@Pipe({
	name:       'formatProductSpecialityNumber',
	standalone: true,
})
export class FormatProductSpecialityNumberPipe implements PipeTransform {
	transform<T extends (string | undefined | null)>(value: T): T;
	transform(value: string | undefined | null): string | undefined | null {
		if(typeof value !== 'string')
			return value;

		const parts = value.match(/(.{2})/g);

		if(parts == null)
			return value;

		return parts.join(' ');
	}

}
