import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

export function notNull<T>(value: T | null | undefined): value is T {
	return value != null;
}

export function notEmpty(value: unknown[] | null | undefined): boolean;
export function notEmpty(value: Observable<unknown[] | null | undefined>): Observable<boolean>;
export function notEmpty(value: Observable<unknown[] | null | undefined> | unknown[] | null | undefined): Observable<boolean> | boolean {
	if(value instanceof Observable)
		return value.pipe(map(x => notEmpty(x)));

	if(value == null)
		return false;

	return value.filter(notNull).length > 0;
}

export function sum(values: (number | undefined)[]): number {
	return values
		.filter(notNull)
		.reduce((a, b) => a + b, 0);
}


export function unique<T>(value: T, index: number, array: T[]): boolean {
	return array.indexOf(value) === index;
}

export function sortAsc(a: number, b: number): number {
	return a - b;
}

export function sortDesc(a: number, b: number): number {
	return b - a;
}

export function deepClone<T>(value: T): T {
	return JSON.parse(JSON.stringify(value));
}


export function isArrayOf<T>(value: unknown, type: T): value is T[] {
	if(!Array.isArray(value))
		return false;

	return value.every(item => typeof item === typeof type);
}