import {
	CommonModule,
	CurrencyPipe,
	DatePipe,
	PercentPipe,
} from '@angular/common';
import {NgModule} from '@angular/core';
import {
	FormsModule,
	ReactiveFormsModule,
} from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCardModule} from '@angular/material/card';
import {MatChipsModule} from '@angular/material/chips';
import {MatRippleModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSelectModule} from '@angular/material/select';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatTableModule} from '@angular/material/table';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatTreeModule} from '@angular/material/tree';
import {RouterModule} from '@angular/router';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {
	TranslateModule,
	TranslatePipe,
} from '@ngx-translate/core';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {BaseDialogContentComponent} from './components/base-dialog/base-dialog-content/base-dialog-content.component';
import {BaseDialogComponent} from './components/base-dialog/base-dialog.component';
import {HeadlineComponent} from './components/component-header/headline.component';
import {ConfirmDialogComponent} from './components/confirm-dialog/confirm-dialog.component';
import {CopyableComponent} from './components/copyable/copyable.component';
import {DeleteModelDialogComponent} from './components/delete-model-dialog/delete-model-dialog.component';
import {EditFieldBooleanComponent} from './components/edit-field-boolean/edit-field-boolean.component';
import {EditFieldDateComponent} from './components/edit-field-date/edit-field-date.component';
import {EditFieldEnumComponent} from './components/edit-field-enum/edit-field-enum.component';
import {EditFieldMultiselectComponent} from './components/edit-field-multiselect/edit-field-multiselect.component';
import {EditFieldSelectComponent} from './components/edit-field-select/edit-field-select.component';
import {EditFieldComponent} from './components/edit-field/edit-field.component';
import {EditRelativeDateComponent} from './components/edit-relative-date/edit-relative-date.component';
import {EditableComponent} from './components/editable/editable.component';
import {
	ErrorFieldDirective,
	FormErrorComponent,
} from './components/form-error/form-error.component';
import {HeaderComponent} from './components/header/header.component';
import {InfoComponent} from './components/info/info.component';
import {InputStatusIconComponent} from './components/input-status-icon/input-status-icon.component';
import {LoadingLazyDirective} from './components/loading/loading-lazy.directive';
import {LoadingComponent} from './components/loading/loading.component';
import {ModelEditableBoxComponent} from './components/model-editable-box/model-editable-box.component';
import {PageIndicatorComponent} from './components/page-indicator/page-indicator.component';
import {BaseRelativeDateSelectComponent} from './components/relative-date/relative-date-select/portal-base-relative-date-select.component';
import {ShowFieldBooleanComponent} from './components/show-field-boolean/show-field-boolean.component';
import {ShowFieldDateIntervallComponent} from './components/show-field-date-intervall/show-field-date-intervall.component';
import {ShowFieldDateTimeComponent} from './components/show-field-date-time/show-field-date-time.component';
import {ShowFieldDateComponent} from './components/show-field-date/show-field-date.component';
import {ShowFieldEnumComponent} from './components/show-field-enum/show-field-enum.component';
import {ShowFieldListComponent} from './components/show-field-list/show-field-list.component';
import {ShowFieldUrlComponent} from './components/show-field-url/show-field-url.component';
import {ShowFieldComponent} from './components/show-field/show-field.component';
import {ShowRelativeDateComponent} from './components/show-relative-date/show-relative-date.component';
import {ShowableComponent} from './components/showable/showable.component';
import {SidenavComponent} from './components/sidenav/sidenav.component';
import {SkeletonContentDirective} from './components/skeleton/skeleton-content.directive';
import {SkeletonFieldComponent} from './components/skeleton/skeleton-field.component';
import {SpinningButtonComponent} from './components/spinning-button/spinning-button.component';
import {TableClientSideSearchableComponent} from './components/table-client-side-searchable/table-client-side-searchable.component';
import {TableServerSideSearchableComponent} from './components/table-server-side-searchable/table-server-side-searchable.component';
import {TableColumnTemplateDirective} from './components/table/table-column-template.directive';
import {TableHeaderTemplateDirective} from './components/table/table-header-template.directive';
import {TableSearchHeaderTemplateDirective} from './components/table/table-search-header-template.directive';
import {TableSearchMultiselectComponent} from './components/table/table-search-multiselect/table-search-multiselect.component';
import {TableSearchSelectComponent} from './components/table/table-search-select/table-search-select.component';
import {TableComponent} from './components/table/table.component';
import {TermsAndConditionsComponent} from './components/terms-and-conditions/terms-and-conditions.component';
import {ExternalLinkDirective} from './directives/external-link.directive';
import {LetDirective} from './directives/let.directive';
import {ObfuscateLinkDirective} from './directives/obfuscate-link.directive';
import {SvgInlineDirective} from './directives/svg-inline.directive';
import {AsArrayPipe} from './pipes/as-array.pipe';
import {FormatExternalContractNumbersPipe} from './pipes/format-external-contract-numbers.pipe';
import {FormatInstitutionskennzeichenPipe} from './pipes/format-institutionskennzeichen.pipe';
import {AddressPipe} from './pipes/medicalStoreTable/address.pipe';
import {CanEditStorePermissionPipe} from './pipes/medicalStoreTable/can-edit-store-permission.pipe';
import {CanViewStorePermissionPipe} from './pipes/medicalStoreTable/can-view-store-permission.pipe';
import {HasInheritedPermissionPipe} from './pipes/medicalStoreTable/has-inherited-permission.pipe';
import {StoreIksPipe} from './pipes/medicalStoreTable/store-iks.pipe';
import {StoreToStateIsCheckedPipe} from './pipes/medicalStoreTable/store-is-checked.pipe';
import {StoreToStatePipe} from './pipes/medicalStoreTable/store-to-state.pipe';
import {ObjectPipe} from './pipes/object.pipe';
import {PhonePipe} from './pipes/phone.pipe';
import {SectionPipe} from './pipes/section.pipe';
import {ShortCurrencyPipe} from './pipes/short-currency.pipe';
import {SortCustomPipe} from './pipes/sort-custom.pipe';
import {SortPipe} from './pipes/sort.pipe';
import {TimesPipe} from './pipes/times.pipe';
import {TranslateRelativeDatePipe} from './pipes/translate-relative-date.pipe';
import {WebsitePipe} from './pipes/website.pipe';
import {NavigationService} from './services/navigation.service';


@NgModule({
	declarations: [
		SidenavComponent,
		HeaderComponent,
		ExternalLinkDirective,
		LetDirective,
		PhonePipe,
		TimesPipe,
		EditFieldComponent,
		ShowFieldComponent,
		ModelEditableBoxComponent,
		ShowFieldBooleanComponent,
		EditFieldBooleanComponent,
		ConfirmDialogComponent,
		SpinningButtonComponent,
		DeleteModelDialogComponent,
		HeaderComponent,
		ShowFieldUrlComponent,
		ShowFieldDateComponent,
		ShowFieldDateTimeComponent,
		EditFieldDateComponent,
		ObjectPipe,
		EditableComponent,
		ShowableComponent,
		PageIndicatorComponent,
		EditFieldSelectComponent,
		ShowFieldListComponent,
		TableClientSideSearchableComponent,
		TableColumnTemplateDirective,
		LoadingComponent,
		LoadingLazyDirective,
		SortPipe,
		SortCustomPipe,
		InputStatusIconComponent,
		AsArrayPipe,
		EditFieldMultiselectComponent,
		BaseRelativeDateSelectComponent,
		TranslateRelativeDatePipe,
		EditRelativeDateComponent,
		ShowRelativeDateComponent,
		SvgInlineDirective,
		HeadlineComponent,
		SkeletonFieldComponent,
		SkeletonContentDirective,
		InfoComponent,
		WebsitePipe,
		ObfuscateLinkDirective,
		FormErrorComponent,
		TermsAndConditionsComponent,
		TableServerSideSearchableComponent,
		CopyableComponent,
		FormatInstitutionskennzeichenPipe,
		FormatExternalContractNumbersPipe,
		SectionPipe,
		TableHeaderTemplateDirective,
		TableSearchHeaderTemplateDirective,
		TableComponent,
		CanEditStorePermissionPipe,
		CanViewStorePermissionPipe,
		StoreToStateIsCheckedPipe,
		AddressPipe,
		StoreIksPipe,
		HasInheritedPermissionPipe,
		StoreToStatePipe,
		ErrorFieldDirective,
		BaseDialogComponent,
		ShowFieldDateIntervallComponent,
		TableSearchMultiselectComponent,
		TableSearchMultiselectComponent,
		TableSearchSelectComponent,
		ShowFieldEnumComponent,
		EditFieldEnumComponent,
		BaseDialogContentComponent,
	],
	imports: [
		CommonModule,
		MatTreeModule,
		RouterModule,
		MatCardModule,
		MatDividerModule,
		MatListModule,
		FontAwesomeModule,
		MatProgressSpinnerModule,
		MatButtonModule,
		MatFormFieldModule,
		ReactiveFormsModule,
		TranslateModule,
		MatInputModule,
		MatTooltipModule,
		MatSlideToggleModule,
		MatDialogModule,
		MatMenuModule,
		MatIconModule,
		MatAutocompleteModule,
		FormsModule,
		MatSelectModule,
		MatDatepickerModule,
		MatChipsModule,
		MatTableModule,
		MatProgressBarModule,
		MatRippleModule,
		MatButtonToggleModule,
		NgxMatSelectSearchModule,
	],
	exports: [
		SidenavComponent,
		HeaderComponent,
		ExternalLinkDirective,
		LetDirective,
		PhonePipe,
		EditFieldComponent,
		ShowFieldComponent,
		ModelEditableBoxComponent,
		ShowFieldBooleanComponent,
		EditFieldBooleanComponent,
		DeleteModelDialogComponent,
		HeaderComponent,
		ShowFieldUrlComponent,
		ShowFieldDateComponent,
		ShowFieldDateTimeComponent,
		EditFieldDateComponent,
		ObjectPipe,
		EditableComponent,
		ShowableComponent,
		EditFieldSelectComponent,
		ShowFieldListComponent,
		TableClientSideSearchableComponent,
		TableColumnTemplateDirective,
		TableHeaderTemplateDirective,
		TableSearchHeaderTemplateDirective,
		LoadingComponent,
		LoadingLazyDirective,
		SortPipe,
		SortCustomPipe,
		SpinningButtonComponent,
		InputStatusIconComponent,
		AsArrayPipe,
		EditFieldMultiselectComponent,
		BaseRelativeDateSelectComponent,
		TranslateRelativeDatePipe,
		ShowRelativeDateComponent,
		EditRelativeDateComponent,
		HeadlineComponent,
		SkeletonFieldComponent,
		SkeletonContentDirective,
		WebsitePipe,
		SvgInlineDirective,
		ObfuscateLinkDirective,
		FormErrorComponent,
		TermsAndConditionsComponent,
		CopyableComponent,
		FormatInstitutionskennzeichenPipe,
		FormatExternalContractNumbersPipe,
		SectionPipe,
		TableComponent,
		TableServerSideSearchableComponent,
		CanEditStorePermissionPipe,
		CanViewStorePermissionPipe,
		StoreToStateIsCheckedPipe,
		AddressPipe,
		StoreIksPipe,
		HasInheritedPermissionPipe,
		StoreToStatePipe,
		ErrorFieldDirective,
		ShowFieldDateIntervallComponent,
		TableSearchMultiselectComponent,
		TableSearchSelectComponent,
		ShowFieldEnumComponent,
		EditFieldEnumComponent,
		BaseDialogContentComponent,
	],
	providers:    [
		PhonePipe,
		WebsitePipe,
		FormatInstitutionskennzeichenPipe,
		FormatExternalContractNumbersPipe,
		SectionPipe,
		TranslatePipe,
		DatePipe,
		CurrencyPipe,
		ShortCurrencyPipe,
		PercentPipe,
	],
})
export class MainModule {
	// noinspection JSUnusedLocalSymbols
	constructor(
		private readonly navigationService: NavigationService, // start navigation service
	) {
	}
}
