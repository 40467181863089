import {
	AbstractPermissionedService,
	DtoInterface,
	ModelId,
} from '@angular-helpers/frontend-api';
import {
	Injectable,
	Injector,
} from '@angular/core';
import {TaskApiService} from './task.api-service';
import {TaskModel} from './task.model';
import {TaskParser} from './task.parser';


@Injectable({
	providedIn: 'root',
})
export class TaskService extends AbstractPermissionedService<TaskApiService, TaskModel> {
	constructor(apiService: TaskApiService, injector: Injector) {
		super(apiService, injector);
	}

	get taskService(): TaskService {
		return this.injector.get(TaskService);
	}

	protected createNewModel(id: ModelId): TaskModel {
		return new TaskModel(id, this);
	}

	protected fillModelFromDto(dto: DtoInterface, model: TaskModel): void {
		return TaskParser.fillModelFromDto(model, dto, this);
	}
}