import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
} from '@angular/core';
import {
	FormControl,
	UntypedFormControl,
} from '@angular/forms';
import {MatSelectChange} from '@angular/material/select';
import {
	EditableComponent,
	FormHelperService,
	IconService,
} from '@app/main';
import {TranslateService} from '@ngx-translate/core';
import {
	combineLatest,
	Observable,
	ReplaySubject,
} from 'rxjs';
import {
	map,
	startWith,
} from 'rxjs/operators';

export interface ValueSelectFieldEntry {
	value: string;
	filter?: ((searchValue: string) => boolean);
}

export interface LabelSelectFieldEntry<T> {
	label: string;
	translateLabel?: boolean;
	value: T;
	filter?: ((searchValue: string) => boolean);
}

export type SelectFieldEntry<T> = ValueSelectFieldEntry | LabelSelectFieldEntry<T>;

@Component({
	selector:    'portal-edit-field-select',
	templateUrl: './edit-field-select.component.html',
	styleUrls:   ['./edit-field-select.component.scss'],
})
export class EditFieldSelectComponent<T> implements OnInit, OnChanges {
	@Input({required: true}) label: EditableComponent['label'];
	@Input({required: true}) control?: UntypedFormControl;
	@Input() initialEntry?: SelectFieldEntry<T>;
	@Input() nullable = true;
	@Input() inherited?: SelectFieldEntry<T> | null;
	@Input({required: true}) options?: SelectFieldEntry<T>[] | null;
	@Input() filter   = true;
	@Output() change  = new EventEmitter<MatSelectChange>();

	protected readonly filterControl = new FormControl('');
	protected readonly options$ = new ReplaySubject<SelectFieldEntry<T>[] | null>(1);
	protected readonly filteredOptions$: Observable<SelectFieldEntry<T>[] | null>;

	constructor(
		protected readonly formHelperService: FormHelperService,
		protected readonly translateService: TranslateService,
		protected readonly iconService: IconService,
	) {
		this.filteredOptions$ = combineLatest([
			this.filterControl.valueChanges.pipe(
				startWith(null),
				map(start => {
					if(start != null)
						return start;

					return this.filterControl.value ?? '';
				}),
			),
			this.options$.pipe(map(options => {
				return options?.sort((a, b) => {
					const startA = ('label' in a) ? a.label : a.value;
					const startB = ('label' in b) ? b.label : b.value;

					return startA.localeCompare(startB);
				});
			})),
		]).pipe(
			map(([input, options]) => {
				if(options == null)
					return null;

				if(!this.filter)
					return options;

				const defaultFilter = (value: string, entry: SelectFieldEntry<T>) => {
					const compare = ('label' in entry) ? entry.label.toLowerCase() : entry.value;
					return compare.toLowerCase().includes(value.toLowerCase());
				};

				return options.filter(option => option.filter?.(input) ?? defaultFilter(input, option));
			}),
		);
	}

	protected get initialEntryData(): SelectFieldEntry<T> | undefined {
		if(this.initialEntry == null)
			return this.initialEntry;

		if('label' in this.initialEntry)
			return this.initialEntry;

		return {
			label:  this.initialEntry.value,
			value: this.initialEntry.value,
			filter: this.initialEntry.filter,
		};
	}

	ngOnInit(): void {
		if(this.options)
			this.options$.next(this.options);
	}

	get placeholder(): string | undefined {
		if(this.inherited != null && ('label' in this.inherited))
			return this.inherited.label;

		if(this.control == null)
			return this.control;

		if(this.control.value === null)
			return this.translateService.instant('general.nullOption');

		return undefined;
	}

	get isEmpty(): boolean {
		return (this.options?.length ?? 0) < 1;
	}

	ngOnChanges(changes: SimpleChanges): void {
		if('options' in changes && this.options != null)
			this.options$.next(changes.options.currentValue);
	}

	isLabeledEntry(entry: SelectFieldEntry<T>): entry is LabelSelectFieldEntry<T> {
		return 'label' in entry;
	}

	public onSelected(event: MatSelectChange): void {
		this.change.emit(event);
	}
}
