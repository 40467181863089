<portal-headline
	label="general.modelList"
	[labelArgs]="{model: 'professionalAssociations'}"
/>

<mat-card>
	<mat-card-content>
		<portal-professional-association-list
			[pageSize]="50"
			[data]="professionalAssociations | async"
		/>
	</mat-card-content>
</mat-card>
