import {
	AbstractPermissionedService,
	DtoInterface,
	ModelId,
} from '@angular-helpers/frontend-api';
import {
	Injectable,
	Injector,
} from '@angular/core';
import {VariationService} from '../variation/variation.service';
import {ProductApiService} from './product.api-service';
import {ProductModel} from './product.model';
import {ProductParser} from './product.parser';


@Injectable({
	providedIn: 'root',
})
export class ProductService extends AbstractPermissionedService<ProductApiService, ProductModel> {
	constructor(apiService: ProductApiService, injector: Injector) {
		super(apiService, injector);
	}

	get productVariationService(): VariationService {
		return this.injector.get(VariationService);
	}

	protected createNewModel(id: ModelId): ProductModel {
		return new ProductModel(id, this);
	}

	protected fillModelFromDto(dto: DtoInterface, model: ProductModel): void {
		return ProductParser.fillModelFromDto(model, dto, this);
	}
}