import {Injectable} from '@angular/core';
import {environment} from '@app/environment';
import {NewsService} from '@contracts/frontend-api';
import {NewsDidReadGuard} from './news-did-read.guard';

@Injectable({
	providedIn: 'root',
})
export class TermsAndConditionsAcceptedGuard extends NewsDidReadGuard {
	constructor(
		newsService: NewsService,
	) {
		super(
			newsService.getById(environment.termsAndConditionsNewsId),
			environment.acceptTermsAndConditionsFullUrl,
		);
	}
}
