import {Component} from '@angular/core';
import {environment} from '@app/environment';
import {AbstractShowComponent} from '@app/main';
import {
	MedicalStoreModel,
	MedicalStoreService,
	PreQualificationCertificateDtoInterface,
	PreQualificationCertificateModel,
} from '@contracts/frontend-api';
import {
	Observable,
	Subscription,
} from 'rxjs';
import {
	map,
	take,
} from 'rxjs/operators';

@Component({
	templateUrl: './medical-store-show-page.component.html',
	styleUrls:   ['./medical-store-show-page.component.scss'],
})
export class MedicalStoreShowPageComponent extends AbstractShowComponent<MedicalStoreModel, MedicalStoreService> {
	public isChildFacility = false;
	preQualificationCertificates?: PreQualificationCertificateModel[];
	readonly environment   = environment;
	readonly permissions   = {
		create: {
			employees:                   false,
			institutionskennzeichens:    false,
			branchOffice:                false,
			preQualificationCertificate: false,
			iqzParticipation: false,
		},
		delete: {
			self: false,
		},
	};
	private medicalStoreIkSubscription?: Subscription;

	constructor(
		medicalStoreService: MedicalStoreService,
	) {
		super(medicalStoreService);
	}

	get pqcPrefillData$(): Observable<Partial<PreQualificationCertificateDtoInterface>> {
		return this.getModel().institutionskennzeichens.value.pipe(
			map(iks => iks?.find(() => true)), // get first entry
			map(ik => ({institutionskennzeichen: ik})),
		);
	}

	checkMedicalStoreUserPermission(): void {
		if(this.model == null)
			return;

		this.model.parent.value
		    .pipe(take(1))
		    .subscribe(parent => {
			    this.isChildFacility = (parent != null);
		    });
	}

	protected async onNewModel(medicalStoreModel: MedicalStoreModel, oldModel?: MedicalStoreModel): Promise<void> {
		this.getPermissions(medicalStoreModel);
		await this.loadPQC();
	}

	protected getModelName(): Promise<string> {
		return this.getModel().name.firstValue.then(value => value ?? '');
	}

	private getPermissions(medicalStoreModel: MedicalStoreModel): void {
		medicalStoreModel.permissions.canDelete.then(permitted => this.permissions.delete.self = permitted);

		medicalStoreModel.employees.permissions.canCreate
		                 .then(permitted => this.permissions.create.employees = permitted);

		medicalStoreModel.children.permissions.canCreate
		                 .then(permitted => medicalStoreModel.parent.firstValue
		                                                     .then(parent => this.permissions.create.branchOffice = parent == null && permitted));

		medicalStoreModel.institutionskennzeichens.permissions.canCreate
		                 .then(permitted => this.permissions.create.institutionskennzeichens = permitted);

		medicalStoreModel.institutionskennzeichens.firstValue.then(async institutionskennzeichens => {
			                 for(const institutionskennzeichen of (institutionskennzeichens ?? [])) {
				                 if(await institutionskennzeichen.preQualificationCertificates.permissions.canCreate)
					                 return true;
			                 }

			                 return false;
		                 })
		                 .then(permitted => this.permissions.create.preQualificationCertificate = permitted);

		medicalStoreModel.iqzParticipations.permissions.canCreate
		                 .then(permitted => this.permissions.create.iqzParticipation = permitted);

		this.checkMedicalStoreUserPermission();
	}

	private async loadPQC(): Promise<void> {
		if(this.medicalStoreIkSubscription)
			this.medicalStoreIkSubscription.unsubscribe();

		this.medicalStoreIkSubscription =
			this.getModel().institutionskennzeichens.value
			    .pipe(map(async iks =>
				    (await Promise.all(iks?.map(ik => ik.preQualificationCertificates.firstValue) ?? []))
					    .filter((value): value is PreQualificationCertificateModel[] => value != null)
					    .reduce((last, curr) => [
						    ...last,
						    ...curr,
					    ], [] as PreQualificationCertificateModel[])))
			    .subscribe(valuePromise => valuePromise.then(value => this.preQualificationCertificates = value));
	}
}
