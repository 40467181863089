<portal-base-dialog-content
	[content]="content"
	headline="system.preferences"
	[icon]="iconService.PREFERENCES"
	[dialogRef]="dialogRef"
	cancelText="actions.cancel"
	acceptText="actions.save"
	[onAccept]="save.bind(this)"
	[onCancel]="cancel.bind(this)"
	[enableContentGrid]="false"
/>

<ng-template #content>
	<h2>{{ 'hourlyRates.preferences.title' | translate }}</h2>
	<div class="grid-2">
		<portal-edit-field-number
			label="hourlyRates.names.RATE_04"
			[validator]="control.controls.hourlyRates.controls.RATE_04"
			suffix="€/h"
			[stepSize]="0.01"
			[resetValue]="DEFAULT_VALUES.RATE_04"
			resetTooltip="preferences.resetHourlyRate"
		/>
		<portal-edit-field-number
			label="hourlyRates.names.RATE_01"
			[validator]="control.controls.hourlyRates.controls.RATE_01"
			suffix="€/h"
			[stepSize]="0.01"
			[resetValue]="DEFAULT_VALUES.RATE_01"
			resetTooltip="preferences.resetHourlyRate"
		/>
		<portal-edit-field-number
			label="hourlyRates.names.RATE_02"
			[validator]="control.controls.hourlyRates.controls.RATE_02"
			suffix="€/h"
			[stepSize]="0.01"
			[resetValue]="DEFAULT_VALUES.RATE_02"
			resetTooltip="preferences.resetHourlyRate"
		/>
		<portal-edit-field-number
			label="hourlyRates.names.RATE_03"
			[validator]="control.controls.hourlyRates.controls.RATE_03"
			suffix="€/h"
			[stepSize]="0.01"
			[resetValue]="DEFAULT_VALUES.RATE_03"
			resetTooltip="preferences.resetHourlyRate"
		/>
		
		<h2>{{ 'preferences.theme.title' | translate }}</h2>
		
		<portal-edit-field-select
			label="preferences.theme.theme"
			[options]="layoutOptions"
			[control]="control.controls.theme"
			[nullable]="false"
		/>
	</div>
</ng-template>