import {
	FrontendApiModule,
	Level8Error,
	ModelNameHelper,
} from '@angular-helpers/frontend-api';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {
	Injector,
	ModuleWithProviders,
	NgModule,
	Optional,
	Provider,
	SkipSelf,
} from '@angular/core';
import {
	CalculationsConfig,
	ConfigInterface,
} from './calculations-config';
import * as allModelsNamespace from './index';
import {CalculationsAuthInterceptor} from './interceptors/calculations-auth.interceptor';
import {CalculationApiService} from './models/calculation/calculation.api-service';
import {CalculationService} from './models/calculation/calculation.service';


@NgModule({
	imports:      [],
	declarations: [],
	exports:      [],
	providers:    [],
})
export class CalculationsModule {
	constructor(
		injector: Injector,
		@Optional() @SkipSelf() parentModule?: CalculationsModule,
	) {
		if(parentModule)
			throw new Level8Error(`${this.constructor.name} is already loaded. Import it in the AppModule only`);

		CalculationsModule._injector = FrontendApiModule.buildInjector(CalculationsConfig.config, injector);
		ModelNameHelper.registerNamespace(allModelsNamespace);
	}

	protected static _injector: Injector;

	static get injector(): Injector {
		return this._injector;
	}

	static forRoot(config: ConfigInterface): ModuleWithProviders<CalculationsModule> {
		CalculationsConfig.config = config;

		const classes = [
			CalculationService,
			CalculationApiService,
		];

		const providers: Provider[] = [];
		for(const providerClass of classes) {
			providers.push({
				provide:  providerClass.name,
				useClass: providerClass,
			});
			providers.push(providerClass);
		}

		providers.push({
			provide:  HTTP_INTERCEPTORS,
			useClass: CalculationsAuthInterceptor,
			multi:    true,
		});

		return {
			ngModule: CalculationsModule,
			providers,
		};
	}
}
