import {Component} from '@angular/core';

@Component({
	selector:    'portal-double-token',
	standalone:  true,
	imports:     [],
	templateUrl: './double-token.component.html',
	styleUrl:    './double-token.component.scss',
})
export class DoubleTokenComponent {

}
