<ng-template #popup>
	@for (content of ('medicalStore.hmmParticipationPopup.content' | translate); track content) {
		<p>{{ content }}</p>
	}
	@if (medicalStores != null && medicalStores.length > 0) {
		<div class="table">
			<span></span>
			<span>{{ 'medicalStore.hmmParticipationValues.none' | translate }}</span>
			<span>{{ 'medicalStore.hmmParticipationValues.ot' | translate }}</span>
			<span>{{ 'medicalStore.hmmParticipationValues.otAndRehab' | translate }}</span>
			@if (medicalStores.length > 1) {
				<span></span>
				<button mat-stroked-button (click)="setAll(hmmParticipation.none)">Alle</button>
				<button mat-stroked-button (click)="setAll(hmmParticipation.ot)">Alle</button>
				<button mat-stroked-button (click)="setAll(hmmParticipation.otAndRehab)">Alle</button>
			}
			@for (medicalStore of medicalStores; track medicalStore.id) {
				@if ((getControl(medicalStore) | async); as control) {
					<mat-radio-group [formControl]="control">
						<mat-label>
							{{ medicalStore.name.value | async }}
							<br/>
							<span *ngIf="(medicalStore.address.value | async) as address" class="small">
								{{ address.street }}
								{{ address.houseNumber }},
								{{ address.town }}
								{{ address.zip }}
							</span>
						</mat-label>
						<mat-radio-button [value]="hmmParticipation.none"/>
						<mat-radio-button [value]="hmmParticipation.ot"/>
						<mat-radio-button [value]="hmmParticipation.otAndRehab"/>
					</mat-radio-group>
				} @else {
					<portal-loading/>
				}
			}
		</div>
	} @else {
		<portal-loading/>
	}
</ng-template>