import {AuthInterceptor} from '@angular-helpers/frontend-api';
import {Injectable} from '@angular/core';
import {CalculationsModule} from '../calculations.module';

@Injectable()
export class CalculationsAuthInterceptor extends AuthInterceptor {
	constructor() {
		super(CalculationsModule.injector);
	}
}
