<mat-card>
	@if (label != null) {
		<mat-card-header class="logo">
			<fa-icon
				[icon]="iconService.CALCULATION_HANDBOOK"
				mat-card-avatar
			/>
			<mat-card-title>{{ label | translate }}</mat-card-title>
		</mat-card-header>
	}
	
	<mat-card-content>
		<portal-calculation-list (searchEvent)="searchEvent.emit($event)"/>
	</mat-card-content>
</mat-card>
