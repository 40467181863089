import {
	AbstractProperty,
	InheritedCoalesceProperty,
	InheritMergedProperty,
	Level8Error,
} from '@angular-helpers/frontend-api';
import {
	Component,
	inject,
	Input,
	TemplateRef,
} from '@angular/core';
import {
	IconService,
	MinimalColumn,
	SearchFilter,
	SearchOptions,
} from '@app/main';
import {AbstractTableComponent} from '../table/abstract-table/abstract-table.component';

export interface ClientSearchMinimalColumn<T extends object, RawType = unknown, SortType = unknown> extends MinimalColumn<T, RawType> {
	sortMap?: (value: RawType) => Promise<SortType> | SortType;
	sortCompare?: (a: SortType, b: SortType) => number;
	sortByField?: keyof T;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ClientSearchMinimalColumns<DataType extends object> = Record<string, ClientSearchMinimalColumn<DataType, any> | undefined>;

export function sortStringArray(values: string[] | undefined): string | undefined {
	if(values == null)
		return values;

	return values.sort().join('\n');
}

export function sortModelArray<Model>(map: (prop: Model) => Promise<string | undefined>): (models: (Model[] | undefined)) => Promise<undefined | string> {
	return async (models: Model[] | undefined) => {
		if(models == null)
			return models;

		const valuePromises = await Promise.all(
			models.map(model => map(model)),
		);


		return valuePromises.sort().join('\n');
	};
}

@Component({
	selector:    'portal-table-client-side-searchable',
	templateUrl: './table-client-side-searchable.component.html',
	styleUrls:   ['./table-client-side-searchable.component.scss'],
})
export class TableClientSideSearchableComponent<TableEntry extends object> extends AbstractTableComponent<TableEntry> {
	@Input() customMenu?: TemplateRef<unknown>;
	@Input() searchFilter = new SearchFilter<TableEntry>();
	@Input() columnLink: string | ((entry: TableEntry) => string | undefined) | undefined;
	@Input() withMenu = true;
	@Input() prepareSearch?: (value: unknown, filter: TableClientSideSearchableComponent<TableEntry>['searchFilter'], searchOptions: SearchOptions) => unknown | undefined;
	protected filteredModels?: TableEntry[];
	protected searching: Promise<void> | null = null;
	protected readonly iconService = inject(IconService);

	protected _pageSize = TableClientSideSearchableComponent.DEFAULT_PAGE_SIZE;
	private _models?: TableEntry[];

	get pageSize(): number | undefined {
		return this._pageSize;
	}

	@Input()
	set pageSize(value: number | undefined) {
		this._pageSize = value ?? TableClientSideSearchableComponent.DEFAULT_PAGE_SIZE;
	}

	private _headers!: ClientSearchMinimalColumns<TableEntry>;

	get headers(): ClientSearchMinimalColumns<TableEntry> {
		return this._headers;
	}

	@Input({required: true})
	set headers(value: ClientSearchMinimalColumns<TableEntry>) {
		this._headers = value;
		this.tableHeaders = this.parseHeaders(value);
	}

	get models(): TableEntry[] | undefined {
		return this._models;
	}

	@Input({required: true})
	set data(value: TableEntry[] | undefined | null) { // todo remove null
		this.setModels(value ?? undefined);
	}

	get columns(): readonly string[] {
		return Object.keys(this._headers);
	}

	get hasNextPage(): boolean {
		if(this.pageSize == null)
			return false;

		const expectedSize = (this.displayPage + 1) * this.pageSize;

		if(this.filteredModels != null)
			return this.filteredModels.length > this.pageSize;

		if(this.models == null)
			return false;

		return this.models.length >= expectedSize;
	}

	get lastPage(): number | undefined {
		if(this.pageSize == null)
			return undefined;

		if(this.filteredModels == null)
			return undefined;

		return Math.ceil(this.filteredModels.length / this.pageSize) - 1;
	}

	protected get filteredPagedModels(): TableClientSideSearchableComponent<TableEntry>['filteredModels'] {
		if(this.pageSize != null)
			return this.filteredModels?.slice(0, this.pageSize);

		return this.filteredModels;
	}

	protected async refreshFilters(): Promise<void> {
		// todo check if refresh is needed
		this.searchFilter.cleanup();
		this.filteredModels = undefined;

		const models = this.models;
		if(models == null)
			return;

		let filteredModels = await Promise.all(models.map(model => this.searchFilter.isFiltered(model)))
		                                  .then((results) => models.filter((_, index) => results[index]));

		if(this.sortBy != null) {
			const sortBy = this.sortBy;
			const ascending = this.sortAscending ?? true;
			const sortByValues = await Promise.all(filteredModels.map(async model => {
				if(!(sortBy in this.headers))
					throw new Level8Error(`Cannot sort by '${sortBy}'. Field missing in headers.`);

				const index = this.headers[sortBy]?.sortByField ?? sortBy;

				// @ts-expect-error I don't see the problem here 🤷‍
				let value: unknown = (index in model) ? model[index] : undefined;

				if(value instanceof InheritMergedProperty)
					value = await value.withParent.firstValue;
				else if(value instanceof InheritedCoalesceProperty)
					value = await value.withParent.firstValue;
				else if(value instanceof AbstractProperty)
					value = await value.firstValue;

				return {
					value,
					model,
				};
			}));

			const sortMap = this.headers[sortBy]?.sortMap;
			if(sortMap != null) {
				await Promise.all(sortByValues.map(async entry => {
					entry.value = await sortMap(entry.value);
				}));
			}

			filteredModels = sortByValues
				.sort((l, r) => {
					const leftValue = ascending ? l.value : r.value;
					const rightValue = ascending ? r.value : l.value;

					const compareFnc = this.headers[sortBy]?.sortCompare;
					if(compareFnc != null)
						return compareFnc(leftValue, rightValue);

					if(leftValue === rightValue)
						return 0;

					if(leftValue == null)
						return 1;

					if(rightValue == null)
						return -1;

					if(typeof leftValue === 'string' && typeof rightValue === 'string')
						return leftValue.localeCompare(rightValue);

					if(leftValue instanceof Date && rightValue instanceof Date)
						return leftValue.getTime() - rightValue.getTime();

					if(typeof leftValue === 'object' || typeof rightValue === 'object')
						throw new Error(`Invalid search value type - expected comparable got ${typeof leftValue} or ${typeof rightValue}`);

					// eslint-disable-next-line eqeqeq
					if(leftValue == rightValue)
						return 0;

					if(leftValue > rightValue)
						return 1;

					return -1;
				}).map(value => value.model);
		}

		if(this.pageSize != null)
			filteredModels = filteredModels.slice(this.displayPage * this.pageSize);

		this.filteredModels = filteredModels;
	}

	protected _prepareSearch(value: unknown, searchOptions: SearchOptions): unknown {
		if(this.prepareSearch != null)
			return this.prepareSearch(value, this.searchFilter, searchOptions);

		return value;
	}

	protected async executeSearch(value: unknown, searchOptions: SearchOptions): Promise<void> {
		const header = this.tableHeaders[searchOptions.id];
		if(header != null)
			header.filter = searchOptions.rawSearchValue ?? value;

		if(!(typeof value === 'string' || value == null || Array.isArray(value)))
			throw new Level8Error(`Invalid search value type - expected string? got ${typeof value}`);

		this.searchFilter.setEntry(searchOptions.id, value);
		this.displayPage = 0;

		while(this.searching)
			await this.searching;

		this.searching = this.refreshFilters();
		await this.searching;
		this.searching = null;
	}

	protected async goToPage(page: number): Promise<void> {
		this.displayPage = page;
		await this.refreshFilters();
	}

	protected async reload(): Promise<void> {
		await super.reload();
		await this.refreshFilters();
	}

	protected setModels(value: TableEntry[] | undefined): Promise<void> {
		this._models = value;
		return this.refreshFilters();
	}
}
