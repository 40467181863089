import {FormValidators} from '@angular-helpers/frontend-api';
import {KeyValuePipe} from '@angular/common';
import {
	Component,
	inject,
} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {
	FormControl,
	FormGroup,
	ReactiveFormsModule,
	Validators,
} from '@angular/forms';
import {
	MatDialogConfig,
	MatDialogRef,
} from '@angular/material/dialog';
import {
	MatError,
	MatFormField,
	MatLabel,
	MatSuffix,
} from '@angular/material/form-field';
import {MatInput} from '@angular/material/input';
import {
	DialogService,
	EditFieldNumberComponent,
	FormHelperService,
	IconService,
	LabelSelectFieldEntry,
	MainModule,
	SystemPreferences,
	SystemPreferencesService,
} from '@app/main';
import {ContractValidators} from '@contracts/frontend-api';
import {TranslateModule} from '@ngx-translate/core';

@Component({
	selector:    'portal-system-preferences-dialog',
	standalone:  true,
	imports:     [
		MainModule,
		TranslateModule,
		MatFormField,
		MatInput,
		MatLabel,
		MatError,
		ReactiveFormsModule,
		KeyValuePipe,
		MatSuffix,
		EditFieldNumberComponent,
	],
	templateUrl: './system-preferences-dialog.component.html',
	styleUrl:    './system-preferences-dialog.component.scss',
})
export class SystemPreferencesDialogComponent {
	protected readonly formHelperService                              = inject(FormHelperService);
	protected readonly dialogRef                                      = inject(MatDialogRef<this>);
	protected readonly iconService                                    = inject(IconService);
	protected readonly preferencesService                             = inject(SystemPreferencesService);
	protected readonly DEFAULT_VALUES = SystemPreferencesService.DEFAULT_VALUES.hourlyRates;
	protected readonly control                                        = new FormGroup({
		hourlyRates: new FormGroup({
			// eslint-disable-next-line @typescript-eslint/naming-convention
			RATE_01: new FormControl<SystemPreferences['hourlyRates']['RATE_01']>(0, {
				validators:  [
					Validators.required,
					ContractValidators.isNumber(2),
				],
				nonNullable: true,
			}),
			// eslint-disable-next-line @typescript-eslint/naming-convention
			RATE_02: new FormControl<SystemPreferences['hourlyRates']['RATE_02']>(0, {
				validators:  [
					Validators.required,
					ContractValidators.isNumber(2),
				],
				nonNullable: true,
			}),
			// eslint-disable-next-line @typescript-eslint/naming-convention
			RATE_03: new FormControl<SystemPreferences['hourlyRates']['RATE_03']>(0, {
				validators:  [
					Validators.required,
					ContractValidators.isNumber(2),
				],
				nonNullable: true,
			}),
			// eslint-disable-next-line @typescript-eslint/naming-convention
			RATE_04: new FormControl<SystemPreferences['hourlyRates']['RATE_04']>(0, {
				validators:  [
					Validators.required,
					ContractValidators.isNumber(2),
				],
				nonNullable: true,
			}),
		}),
		theme:       new FormControl<SystemPreferences['theme']>('auto', {
			validators:  [
				Validators.required,
				FormValidators.inArray(['auto', 'dark', 'light']),
			],
			nonNullable: true,
		}),
	});
	protected readonly layoutOptions: LabelSelectFieldEntry<string>[] = [
		{
			value:          'auto',
			label:          'preferences.theme.auto',
			translateLabel: true,
		}, {
			value:          'dark',
			label:          'preferences.theme.dark',
			translateLabel: true,
		},
		{
			value:          'light',
			label:          'preferences.theme.light',
			translateLabel: true,
		},
	];
	protected initialDarkModeSetting?: SystemPreferences['theme'];


	constructor() {
		this.preferencesService.preferences$.pipe(
			takeUntilDestroyed(),
		).subscribe(preferences => {
			this.initialDarkModeSetting ??= preferences.theme;
			this.control.patchValue(preferences, {emitEvent: false});
		});

		// instand update theme
		this.control.controls.theme.valueChanges.pipe(takeUntilDestroyed()).subscribe(theme => {
			this.setDarkMode(theme);
		});
	};

	setDarkMode(theme: SystemPreferences['theme']): Promise<void> {
		return this.preferencesService.set({theme});
	}

	protected async save(): Promise<void> {
		await this.preferencesService.set(this.control.getRawValue());
		this.dialogRef.close();
	}

	protected async cancel(): Promise<void> {
		if(this.initialDarkModeSetting !== undefined)
			await this.setDarkMode(this.initialDarkModeSetting);

		this.dialogRef.close();
	}

	static open(dialogService: DialogService): MatDialogRef<SystemPreferencesDialogComponent> {
		const config = new MatDialogConfig<void>();
		config.autoFocus = false;
		config.minWidth = '25rem';

		return dialogService.openCustomDialog(SystemPreferencesDialogComponent, {}, config);
	}
}
