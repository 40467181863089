<portal-table
	[headers]="tableHeaders"
	[data]="filteredPagedModels"
	[baseLink]="baseLink"
	[getRowLink]="getBaseLinkFunction"
	[(currentPage)]="displayPage"
	[hasNextPage]="hasNextPage"
	[lastPage]="lastPage"
	[isLoading]="searching !== null || filteredModels == null"
	[withMenu]="withMenu"
	[customMenu]="customMenu"
	[sortBy]="sortBy"
	(sortByChange)="sortBy=$event;reload()"
	[sortAscending]="sortAscending"
	(sortAscendingChange)="sortAscending=$event;reload()"
/>
