<portal-base-dialog-content
	[content]="content"
	headline="calculation.dialog.export.title"
	[dialogRef]="dialogRef"
	[icon]="iconService.EXPORT"
	cancelText="actions.cancel"
	acceptText="actions.download"
	[onAccept]="save.bind(this)"
	[enableContentGrid]="true"
/>

<ng-template #content>
	<form [formGroup]="formGroup">
		<mat-label>
			{{ 'calculation.dialog.export.fileType.title' | translate }}
		</mat-label>
		<mat-radio-group formControlName="fileType">
			@for (fileType of possibleCalculationFileTypes; track fileType) {
				<mat-radio-button [value]="fileType">
					{{ 'calculation.dialog.export.fileType.' + fileType | translate }}
				</mat-radio-button>
			}
		</mat-radio-group>
		
		
		<mat-label>
			{{ 'calculation.dialog.export.extended.title' | translate }}
		</mat-label>
		<mat-radio-group formControlName="extended">
			<mat-radio-button [value]="false">
				{{ 'calculation.dialog.export.extended.false' | translate }}
			</mat-radio-button>
			<mat-radio-button [value]="true">
				{{ 'calculation.dialog.export.extended.true' | translate }}
			</mat-radio-button>
		</mat-radio-group>
	</form>
</ng-template>