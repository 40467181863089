import {
	inject,
	Injectable,
} from '@angular/core';
import {
	SystemPreferences,
	SystemPreferencesService,
} from '@app/main';
import {
	Observable,
	of,
} from 'rxjs';
import {
	map,
	switchMap,
} from 'rxjs/operators';
import {CalculationModel} from '../models/calculation/calculation.model';
import {HourlyRate} from '../models/common/types';

@Injectable({
	providedIn: 'root',
})
export class HourlyRateService {
	private readonly systemPreferencesService = inject(SystemPreferencesService);

	getRatePerMinute(calculation: HourlyRate): Observable<number>;
	getRatePerMinute(calculation: CalculationModel): Observable<number | undefined>;
	getRatePerMinute(input: CalculationModel | HourlyRate): Observable<number | undefined>;
	getRatePerMinute(input: CalculationModel | HourlyRate): Observable<number | undefined> {
		return this.getRate(input).pipe(
			map(rate => {
				if(rate === undefined)
					return undefined;

				return rate / 60;
			}),
		);
	}

	getRate(calculation: HourlyRate): Observable<number>;
	getRate(calculation: CalculationModel): Observable<number | undefined>;
	getRate(input: CalculationModel | HourlyRate): Observable<number | undefined>;
	getRate(input: CalculationModel | HourlyRate): Observable<number | undefined> {
		return this.systemPreferencesService.preferences$.pipe(
			switchMap(preferences => {
				if(!(input instanceof CalculationModel))
					return of(preferences.hourlyRates[input]);

				return input.hourlyBillingRateBracket.value.pipe(
					map(rate => {
						if(rate === undefined)
							return undefined;

						return preferences.hourlyRates[rate];
					}),
				);
			}),
		);
	}

	getRates(): Observable<SystemPreferences['hourlyRates']> {
		return this.systemPreferencesService.preferences$.pipe(
			map(preferences => preferences.hourlyRates),
		);
	}
}
