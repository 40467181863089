import {
	BasicPermissionedProperty,
	VersionedModel,
} from '@angular-helpers/frontend-api';
import {TaskTimeModel} from '../task-time/task-time.model';
import {TaskService} from './task.service';


export const TASK_TYPES = [
	'PATIENTENZEITEN',
	'MAS_UND_PLANUNGSZEITEN',
	'FERTIGUNGSZEITEN',
	'ANPROBE_KORREKTURZEITEN',
	'VERWALTUNGSZEITEN',
] as const;
export type TaskType = typeof TASK_TYPES[number];

export const TASK_SCOPES = [
	'AUFTRAGSANNAHME',
	'ZUSTANDSERHEBUNG',
	'KUNDENERFASSUNG',
	'VERSORGUNGSVORSCHLAG',
	'AUFTRAGSANGEBOT',
	'BESCHAFFUNG',
	'HERSTELLUNG',
	'ANPROBE',
	'FERTIGSTELLUNG',
	'AUSLIEFERUNG',
	'ABRECHNUNG',
	'ANAMNESE',
	'ANPASSUNG',
	'HERSTELLUNG',
	'TESTPROTHESE_UBERTRAGUNG',
	'PROTHESE_SCHAFTTECHNIK',
	'MODELL',
	'TESTSCHAFT_HERSTELLUNG',
	'TESTPROTHESE_HERSTELLUNG',
	'PROTHESE_HERSTELLUNG',
	'TESTPROTHESE_ANPROBE',
	'PROTHESENGEBRAUCHSSCHULUNG',
	'LINER_UND_LINERVERSCHLUSSSYSTE',
	'TESTSCHAFT_ANPROBE',
	'PROTHESE_ANPROBE',
	'PASSFORMGARANTIE',
	'AENDERUNG_1',
	'AENDERUNG_2',
	'REPORTED_OUTCOME',
	'STIMULATION',
	'TEST_1',
	'TEST_2',
	'AUSLIEFERUNG_EINWEISUNG',
	'AUFKLARUNG_WARTUNGSINERVALLEN',
	'SERVICE_1',
	'SERVICE_2',
	'SERVICE_3',
	'SERVICE_4',
] as const;
export type TaskScope = typeof TASK_SCOPES[number];


export class TaskModel extends VersionedModel<TaskService> {
	readonly name     = new BasicPermissionedProperty<string>('name', this);
	readonly type     = new BasicPermissionedProperty<TaskType>('type', this);
	readonly scope    = new BasicPermissionedProperty<TaskScope>('scope', this);
	readonly position = new BasicPermissionedProperty<number>('position', this);
	readonly comment  = new BasicPermissionedProperty<string>('comment', this);
	readonly times    = new BasicPermissionedProperty<TaskTimeModel[]>('times', this);
}