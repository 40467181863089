import {
	Component,
	OnInit,
} from '@angular/core';
import {environment} from '@app/environment';
import {
	ProfessionalAssociationModel,
	ProfessionalAssociationService,
} from '@contracts/frontend-api';

@Component({
	selector:    'portal-page-professional-association',
	templateUrl: './professional-association-page-list.component.html',
	styleUrls:   ['./professional-association-page-list.component.scss'],
})
export class ProfessionalAssociationPageListComponent implements OnInit {
	professionalAssociations?: Promise<ProfessionalAssociationModel[]>;
	readonly environment = environment;

	constructor(
		private readonly professionalAssociationService: ProfessionalAssociationService,
	) { }

	ngOnInit(): void {
		this.professionalAssociations = this.professionalAssociationService.getAllModels();
	}

}
