import {Level8Error} from '@angular-helpers/frontend-api';
import {ConfigModel} from '@authentication/angular';
import {CalculationsModule} from './calculations.module';

export interface ConfigInterface {
	baseUrl: string;
	authConfig: ConfigModel;
	authToken?: string;
}

export class CalculationsConfig {
	static _config: ConfigInterface | undefined;

	static get config(): ConfigInterface {
		if(this._config === undefined) {
			const msg = `${CalculationsModule.name} has not been initialized. Did you forget to call "${CalculationsModule.name}.forRoot()" in the AppModule?`;
			throw new Level8Error(msg);
		}

		return this._config;
	}

	static set config(value: ConfigInterface) {
		this._config = value;
	}
}
