<portal-base-dialog-content
	[content]="content"
	headline="calculation.dialog.select.title"
	[dialogRef]="dialogRef"
	[icon]="iconService.CALCULATION_HANDBOOK"
	cancelText="actions.cancel"
	acceptText="actions.ok"
	[onAccept]="save.bind(this)"
	[onCancel]="cancel.bind(this)"
	[enableContentGrid]="false"
/>

<ng-template #content>
	<div class="wrapper">
		<div class="current-calculations">
			<h2>{{ 'calculation.dialog.select.currentCalculations' | translate }}</h2>
			@for (calculation of currentCalculations$.value; track calculation.id) {
				<portal-select-calculations-dialog-entry
					[calculation]="calculation"
					[icon]="iconService.REMOVE"
					(buttonClick)="removeCalculation(calculation)"
				/>
			}
		</div>
		
		<!-- todo add drag and drop -->
		<div class="possible-calculations">
			<h2>{{ 'calculation.dialog.select.possibleCalculations' | translate }}</h2>
			<div class="list">
				<portal-loading [loading]="isLoading">
					@for (calculation of possibleCalculations | async; track calculation.id) {
						<portal-select-calculations-dialog-entry
							[calculation]="calculation"
							[icon]="iconService.ADD"
							(buttonClick)="addCalculation(calculation)"
						/>
					} @empty {
						@if (searchField.value) {
							<p class="hint">{{ 'calculation.dialog.select.noResultsHint' | translate }}</p>
						} @else {
							<p class="hint">{{ 'calculation.dialog.select.searchHint' | translate }}</p>
						}
					}
					<!-- 	todo load more when end of list -->
				</portal-loading>
			</div>
			
			<mat-form-field class="last-entry">
				<mat-label>{{ 'calculation.dialog.select.search' | translate }}</mat-label>
				<input
					matInput
          #searchField
					(change)="search(searchField.value, true)"
					(input)="search(searchField.value, false)"
					[placeholder]="'calculation.dialog.select.searchPlaceholder' | translate"
				/>
			</mat-form-field>
		</div>
	</div>
</ng-template>
