import {
	BasicPermissionedProperty,
	VersionedModel,
} from '@angular-helpers/frontend-api';
import {VatRate} from '../common/types';
import {MaterialUnit} from '../material/material.model';
import {VariationModel} from '../variation/variation.model';
import {ProductService} from './product.service';


export const PRODUCT_STATUS = [
	'ACTIVE',
	'INACTIVE',
] as const;
export type ProductStatus = typeof PRODUCT_STATUS[number];


export class ProductModel extends VersionedModel<ProductService> {
	readonly articleNumber = new BasicPermissionedProperty<string>('articleNumber', this);
	readonly ean = new BasicPermissionedProperty<string>('ean', this);
	readonly identifierExtension = new BasicPermissionedProperty<string>('identifierExtension', this);
	readonly name = new BasicPermissionedProperty<string>('name', this);
	readonly manufacturer = new BasicPermissionedProperty<string>('manufacturer', this);
	readonly variations = new BasicPermissionedProperty<VariationModel[]>('variations', this);
	readonly unit = new BasicPermissionedProperty<MaterialUnit>('unit', this);
	readonly priceBuy = new BasicPermissionedProperty<number>('priceBuy', this);
	readonly priceLease = new BasicPermissionedProperty<number>('priceLease', this);
	readonly vatRate = new BasicPermissionedProperty<VatRate>('vatRate', this);
	readonly availability = new BasicPermissionedProperty<ProductStatus>('availability', this);
	readonly occurrenceRate = new BasicPermissionedProperty<number>('occurrenceRate', this);
	readonly centralPharmaceuticalNumber = new BasicPermissionedProperty<string>('centralPharmaceuticalNumber', this);
}