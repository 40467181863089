<portal-double-token>
	<portal-copyable
		class="number"
		*ngLet="number.number.value | async as numberRaw"
		[content]="numberRaw"
	>
		{{ numberRaw | formatExternalContractNumbers: (number.type.value | async) }}
	</portal-copyable>
	<div class="description">
		{{ number.description.value | async }}
	</div>
</portal-double-token>