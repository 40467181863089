<portal-headline
	label="page.joinedParties.title"
/>

<mat-card>
	<mat-card-content>
		<p>Hier finden Sie eine Auflistung der Kostenträger, die einem vom BIV-OT gezeichneten Rahmenvertrag mit einem Kostenträgerzusammenschluss beigetreten sind. Bitte beachten Sie, dass hier der Übersichtlichkeit halber ganze Produktgruppen angegeben werden. Die tatsächlich im Vertrag geregelten Inhalte können hiervon abweichen und unter Umständen nur Teilbereiche der Produktgruppe abdecken. Für genauere Informationen prüfen Sie bitte die jeweiligen Vertragsunterlagen.</p>
	</mat-card-content>
</mat-card>

<mat-card class="scroll">
	<mat-card-content id="parties">
		<portal-table-client-side-searchable
			[headers]="tableHeaders"
			[columnLink]="undefined"
			[data]="data"
			[pageSize]="999"
			sortBy="name"
		/>
	</mat-card-content>
</mat-card>
