import {Level8Error} from '@angular-helpers/frontend-api';
import {
	Component,
	Input,
} from '@angular/core';
import {
	UntypedFormControl,
	UntypedFormGroup,
} from '@angular/forms';
import {LabelSelectFieldEntry} from '@app/main';
import {
	BaseContractDtoModel,
	ContractModel,
	ContractSectionModel,
	MasterContractModel,
} from '@contracts/frontend-api';
import {Observable} from 'rxjs';

@Component({
	selector: 'portal-base-contract-edit-negotiators',
	templateUrl: './base-contract-edit-negotiators.component.html',
	styleUrls: ['./base-contract-edit-negotiators.component.scss'],
})
export class BaseContractEditNegotiatorsComponent {
	@Input({
		required: true,
		alias:    'control',
	}) parent!: UntypedFormGroup;
	@Input() model?: MasterContractModel | ContractModel | ContractSectionModel;
	@Input() parentModel?: MasterContractModel | ContractModel;
	readonly possibleValues = BaseContractDtoModel.NEGOTIATORS
												  .map(negotiator => ({
													  label: negotiator,
													  value: negotiator,
												  }));

	get currentValues(): LabelSelectFieldEntry<string>[] {
		const currentValue: string[] | undefined = this.control.value;
		if(!currentValue)
			return [];

		return currentValue.map(negotiator => ({
			label: negotiator,
			value: negotiator,
		}));
	}

	get inheritedValues(): Observable<string[] | undefined> | undefined {
		if(this.parentModel != null) {
			if(this.parentModel instanceof MasterContractModel)
				return this.parentModel.negotiators.value;
			
			return this.parentModel.negotiators.inherited;
		}
		
		if(this.model == null)
			return undefined;
		
		if(this.model instanceof MasterContractModel)
			return undefined;
		
		return this.model.negotiators.inherited;
	}

	get control(): UntypedFormControl {
		const fieldName = 'negotiators';
		const control   = this.parent.get(fieldName);
		if(control instanceof UntypedFormControl)
			return control;

		throw new Level8Error(`Unexpected type for field ${fieldName} - expected '${UntypedFormControl.name}' got '${typeof control}' (${control})`);
	}
}
