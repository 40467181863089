import {
	DecimalPipe,
	KeyValuePipe,
	NgIf,
} from '@angular/common';
import {
	Component,
	EventEmitter,
	inject,
	Input,
	Output,
} from '@angular/core';
import {
	ReactiveFormsModule,
	UntypedFormControl,
} from '@angular/forms';
import {
	MatError,
	MatFormField,
	MatPrefix,
	MatSuffix,
} from '@angular/material/form-field';
import {MatInput} from '@angular/material/input';
import {MatTooltip} from '@angular/material/tooltip';
import {
	FormHelperService,
	IconService,
	MainModule,
} from '@app/main';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';
import {IconDefinition} from '@fortawesome/fontawesome-common-types';
import {TranslateModule} from '@ngx-translate/core';

@Component({
	selector:    'portal-edit-field-number',
	standalone:  true,
	imports:     [
		MainModule,
		MatFormField,
		TranslateModule,
		KeyValuePipe,
		ReactiveFormsModule,
		MatInput,
		MatError,
		NgIf,
		MatSuffix,
		FaIconComponent,
		MatPrefix,
		MatTooltip,
		DecimalPipe,
	],
	templateUrl: './edit-field-number.component.html',
	styleUrl:    './edit-field-number.component.scss',
})
export class EditFieldNumberComponent {
	@Input() icon?: IconDefinition;
	@Input({required: true}) label: string | null | undefined;
	@Input({required: true}) validator!: UntypedFormControl;
	@Input() placeholder?: string | null;
	@Input() showAlways = false;
	@Input() suffix?: string;
	@Input() stepSize = 0;
	@Input() resetValue?: number;
	@Input() resetTooltip?: string;
	@Output() change = new EventEmitter();
	protected readonly formHelperService = inject(FormHelperService);
	protected readonly iconService = inject(IconService);

	resetToDefault(): void {
		const validator = this.validator;
		validator.setValue(this.resetValue);
		validator.markAsDirty();
		validator.markAsTouched();
		this.change.emit(validator.value);
	}
}
