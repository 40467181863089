import {Component} from '@angular/core';
import {
	MatCard,
	MatCardContent,
} from '@angular/material/card';
import {RouterLink} from '@angular/router';
import {
	ClientSearchMinimalColumns,
	MainModule,
} from '@app/main';
import {
	joinedParties,
	JoinedPartiesData,
} from './joined-parties-data';

@Component({
	standalone:  true,
	imports:     [
		MainModule,
		MatCard,
		MatCardContent,
		RouterLink,
	],
	templateUrl: './joined-parties-page.component.html',
	styleUrl:    './joined-parties-page.component.scss',
})
export class JoinedPartiesPageComponent {
	readonly data = joinedParties;
	protected readonly tableHeaders = {
		name:                    {
			index: 0,
			label: 'page.joinedParties.tableHeaders.name',
		},
		institutionskennzeichen: {
			index:         1,
			label:         'page.joinedParties.tableHeaders.institutionskennzeichen',
			sortMap:       array => array.sort().join(','),
			prepareSearch: (value: string) => value.split(',').map(item => item.trim()),
		},
		contractor:              {
			index: 2,
			label: 'page.joinedParties.tableHeaders.contractor',
		},
		productGroups:           {
			index:         3,
			label:         'page.joinedParties.tableHeaders.productGroups',
			sortMap:       array => array.sort().join(','),
			prepareSearch: (value: string) => value.split(',').map(item => item.trim()),
		},
		terminatedAt:            {
			index: 4,
			label: 'page.joinedParties.tableHeaders.terminatedAt',
		},
		joinedRegions:           {
			index:         5,
			label:         'page.joinedParties.tableHeaders.joinedRegions',
			sortMap:       array => array.sort().join(','),
			prepareSearch: (value: string) => value.split(',').map(item => item.trim()),
		},
	} satisfies ClientSearchMinimalColumns<JoinedPartiesData>;

}
