import {PageRequestOptions} from '@angular-helpers/frontend-api/lib/models/abstract-model/abstract.api-service';
import {
	Component,
	inject,
} from '@angular/core';
import {
	MatAccordion,
	MatExpansionPanel,
	MatExpansionPanelHeader,
	MatExpansionPanelTitle,
} from '@angular/material/expansion';
import {
	MatMenu,
	MatMenuItem,
} from '@angular/material/menu';
import {RouterLink} from '@angular/router';
import {
	DialogService,
	IconService,
	MainModule,
	MinimalColumns,
} from '@app/main';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';
import {TranslateModule} from '@ngx-translate/core';
import {CalculationCardListComponent} from '../../../components/calculation/cards/list/calculation-card-list.component';
import {CalculationModel} from '../../../models/calculation/calculation.model';
import {CalculationListExportDialogComponent} from './calculation-list-export-dialog/calculation-list-export-dialog.component';

@Component({
	selector:    'portal-calculations-list-page-component',
	standalone:  true,
	imports: [
		RouterLink,
		MainModule,
		CalculationCardListComponent,
		MatMenu,
		MatAccordion,
		MatExpansionPanel,
		MatExpansionPanelHeader,
		MatExpansionPanelTitle,
		TranslateModule,
		FaIconComponent,
		MatMenuItem,
	],
	templateUrl: './calculation-list-page.component.html',
	styleUrl:    './calculation-list-page.component.scss',
})
export class CalculationListPageComponent {
	protected readonly headers: MinimalColumns<CalculationModel> = {};
	protected readonly iconService = inject(IconService);
	protected readonly dialogService = inject(DialogService);
	protected search?: PageRequestOptions['search'];

	protected showExportDialog(): void {
		CalculationListExportDialogComponent.open(this.dialogService, this.search);
	}
}
