import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {map} from 'rxjs/operators';
import {deepClone} from '../helper/helper';

export interface SystemPreferences {
	hourlyRates: {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		RATE_01: number;
		// eslint-disable-next-line @typescript-eslint/naming-convention
		RATE_02: number;
		// eslint-disable-next-line @typescript-eslint/naming-convention
		RATE_03: number;
		// eslint-disable-next-line @typescript-eslint/naming-convention
		RATE_04: number;
	};
	theme: 'auto' | 'dark' | 'light';
}

@Injectable({
	providedIn: 'root',
})
export class SystemPreferencesService {
	static readonly DEFAULT_VALUES: SystemPreferences = {
		hourlyRates: {
			// eslint-disable-next-line @typescript-eslint/naming-convention
			RATE_01: 72.60,
			// eslint-disable-next-line @typescript-eslint/naming-convention
			RATE_02: 95.40,
			// eslint-disable-next-line @typescript-eslint/naming-convention
			RATE_03: 109.20,
			// eslint-disable-next-line @typescript-eslint/naming-convention
			RATE_04: 72.60,
		},
		theme:       'auto',
	};
	private static readonly LOCAL_STORAGE_KEY                 = 'system-preferences';
	protected readonly preferences                            = new BehaviorSubject<SystemPreferences>(this.loadPreferences());
	readonly preferences$                                     = this.preferences.asObservable().pipe(map(deepClone));

	async set(preferences: Partial<SystemPreferences>): Promise<void> {
		const prefs = {
			...this.preferences.value,
			...preferences,
		};
		this.savePreferences(prefs);
		this.preferences.next(prefs);
	}

	protected loadPreferences(): SystemPreferences {
		// toto change to rest call
		const raw         = localStorage.getItem(SystemPreferencesService.LOCAL_STORAGE_KEY);
		const preferences = (raw === null ? {} : JSON.parse(raw));
		preferences.hourlyRates ??= deepClone(SystemPreferencesService.DEFAULT_VALUES.hourlyRates);
		preferences.theme ??= SystemPreferencesService.DEFAULT_VALUES.theme;

		// todo ensure preferences is correct typed!

		return preferences;
	}

	protected savePreferences(preferences: SystemPreferences): void {
		// toto change to rest call
		localStorage.setItem(SystemPreferencesService.LOCAL_STORAGE_KEY, JSON.stringify(preferences));
	}
}
