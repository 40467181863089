import {
	CdkDrag,
	CdkDragHandle,
	CdkDropList,
	moveItemInArray,
} from '@angular/cdk/drag-drop';
import {
	AsyncPipe,
	CurrencyPipe,
	DecimalPipe,
	JsonPipe,
	KeyValue,
	KeyValuePipe,
	NgIf,
	NgTemplateOutlet,
	PercentPipe,
} from '@angular/common';
import {HttpErrorResponse} from '@angular/common/http';
import {
	Component,
	ElementRef,
	inject,
	Input as RouteInput,
	Pipe,
	PipeTransform,
	ViewChild,
} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {MatButton} from '@angular/material/button';
import {
	MatCard,
	MatCardContent,
	MatCardHeader,
} from '@angular/material/card';
import {
	MatOptgroup,
	MatOption,
	MatRipple,
} from '@angular/material/core';
import {MatDialogConfig} from '@angular/material/dialog';
import {
	MatAccordion,
	MatExpansionPanel,
	MatExpansionPanelHeader,
	MatExpansionPanelTitle,
} from '@angular/material/expansion';
import {
	MatFormField,
	MatLabel,
} from '@angular/material/form-field';
import {
	MatMenu,
	MatMenuItem,
	MatMenuTrigger,
} from '@angular/material/menu';
import {MatTooltip} from '@angular/material/tooltip';
import {Router} from '@angular/router';
import {environment} from '@app/environment';
import {
	AnyModel,
	BypassSecurityTrustResourcePipe,
	combineLatestSafe,
	DialogService,
	DoubleTokenComponent,
	FormatIdentifierExtensionPipe,
	FormatProductSpecialityNumberPipe,
	IconService,
	MainModule,
	ModelHelper,
	notEmpty,
	notNull,
	ShortCurrencyPipe,
	sortAsc,
	SystemPreferencesDialogComponent,
	TranslatePrefixedPipe,
	TrimPipe,
	unique,
} from '@app/main';
import {
	HmvService,
	ProduktartModel,
} from '@contracts/frontend-api';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';
import {faCheckCircle} from '@fortawesome/free-solid-svg-icons';
import {
	TranslateModule,
	TranslatePipe,
} from '@ngx-translate/core';
import {
	BehaviorSubject,
	combineLatest,
	Observable,
	of,
	Subscription,
} from 'rxjs';
import {
	map,
	mergeMap,
	shareReplay,
	switchMap,
} from 'rxjs/operators';
import {
	CalculationAmount,
	CalculationModel,
	CalculationTask,
} from '../../../models/calculation/calculation.model';
import {CalculationService} from '../../../models/calculation/calculation.service';
import {VatRate} from '../../../models/common/types';
import {FixedPriceModel} from '../../../models/fixedPrice/fixed-price.model';
import {
	MaterialExtendedUnit,
	MaterialModel,
} from '../../../models/material/material.model';
import {ProductModel} from '../../../models/product/product.model';
import {
	TASK_TYPES,
	TaskModel,
	TaskScope,
	TaskType,
} from '../../../models/task/task.model';
import {FormatCalculationStandardSchemaPipe} from '../../../pipes/format-calculation-standart-schema.pipe';
import {HourlyRatePipe} from '../../../pipes/hourly-rate.pipe';
import {HourlyRateService} from '../../../services/hourly-rate.service';
import {CalculationSectionHeaderTemplateDirective} from './calculation-show-section/calculation-section-header-template.directive';
import {CalculationSectionHeaderTitleTemplateDirective} from './calculation-show-section/calculation-section-header-title-template.directive';
import {CalculationShowSectionComponent} from './calculation-show-section/calculation-show-section.component';
import {GenerateCalculationExportDialogComponent} from './generate-calculation-export-dialog/generate-calculation-export-dialog.component';
import {
	SelectCalculationsDialogComponent,
	SelectCalculationsDialogData,
} from './select-calculations-dialog/select-calculations-dialog.component';

interface Amount {
	amount: number;
	unit: MaterialExtendedUnit;
}

@Pipe({
	name:       'VatRatesString',
	standalone: true,
})
class VatRatesStringPipe implements PipeTransform {
	protected readonly translatePipe = inject(TranslatePipe);

	transform(vatRates: (VatRate | 'UNKNOWN')[]): string {
		if(vatRates.length === 0)
			vatRates.push('UNKNOWN');

		const rates = vatRates
			.map(vatRate => this.translatePipe.transform(`common.vatRateValues.${vatRate}`))
			.sort(sortAsc);

		return `+ ${rates.join(' / ')} %`;
	}
}

@Component({
	standalone:  true,
	imports:   [
		FaIconComponent,
		MainModule,
		MatLabel,
		MatMenu,
		MatMenuItem,
		TranslateModule,
		AsyncPipe,
		MatCard,
		MatCardContent,
		MatCardHeader,
		CdkDrag,
		CdkDropList,
		CdkDragHandle,
		MatExpansionPanel,
		MatExpansionPanelHeader,
		MatExpansionPanelTitle,
		MatAccordion,
		CalculationShowSectionComponent,
		MatButton,
		MatRipple,
		JsonPipe,
		ShortCurrencyPipe,
		CalculationSectionHeaderTemplateDirective,
		CalculationSectionHeaderTitleTemplateDirective,
		NgIf,
		NgTemplateOutlet,
		VatRatesStringPipe,
		PercentPipe,
		TranslatePrefixedPipe,
		FormatIdentifierExtensionPipe,
		FormatProductSpecialityNumberPipe,
		KeyValuePipe,
		FormatCalculationStandardSchemaPipe,
		CurrencyPipe,
		TrimPipe,
		DecimalPipe,
		MatTooltip,
		DoubleTokenComponent,
		HourlyRatePipe,
		MatMenuTrigger,
		MatFormField,
		MatOptgroup,
		ReactiveFormsModule,
		MatOption,
		BypassSecurityTrustResourcePipe,
	],
	providers: [
		FormatIdentifierExtensionPipe,
		DecimalPipe,
	],
	templateUrl: './calculation-show-page.component.html',
	styleUrl:  './calculation-show-page.component.scss',
})
export class CalculationShowPageComponent {
	protected readonly iconService = inject(IconService);
	protected readonly hourlyRateService = inject(HourlyRateService);
	protected readonly environment = environment;
	protected readonly calculations$ = new BehaviorSubject<CalculationModel[]>([]);
	protected readonly possibleExtensions$: Observable<CalculationModel[]>;
	protected readonly materials$: Observable<MaterialModel[]>;
	protected readonly products$: Observable<ProductModel[]>;
	protected readonly indications$: Observable<(string | undefined)[]>;
	protected readonly characteristic$: Observable<(string | null | undefined)[]>;
	protected readonly serviceScopes$: Observable<(string | undefined)[]>;
	protected readonly hints$: Observable<(string | undefined)[]>;
	protected readonly tasks$: Observable<TaskModel[]>;
	protected readonly tasksByScope$: Observable<Map<TaskScope, TaskModel[]>>;
	protected readonly tasksByType$: Observable<Map<TaskType, TaskModel[]>>;
	protected readonly tasksGrouped$: Observable<Map<TaskType, TaskModel[]> | Map<TaskScope, TaskModel[]>>;
	protected readonly groupTasksBy$ = new BehaviorSubject<'type' | 'scope'>('type');
	protected readonly includedCalculations$: Observable<CalculationModel[]>;
	protected readonly fixedPrices$: Observable<FixedPriceModel[]>;
	protected readonly additions$: Observable<number[]>;
	protected readonly isMultiView$: Observable<boolean>;
	protected readonly ICON_POSSIBLE_EXTENSION_EXISTS = faCheckCircle;
	protected readonly sections = [
		'serviceScope',
		'calculations',
		'materials',
		'products',
		'addition',
		'tasks',
		'price',
		'fixedPrice',
		'characteristic',
		'possibleExtensions',
		'indication',
		'hint',
	] as const;
	@ViewChild('head')
	protected readonly headerElement?: ElementRef;
	protected readonly hasIndications$: Observable<boolean>;
	protected readonly hasCharacteristics$: Observable<boolean>;
	protected readonly hasServiceScopes$: Observable<boolean>;
	protected readonly hasHint$: Observable<boolean>;
	private readonly calculationService = inject(CalculationService);
	private readonly dialogService = inject(DialogService);
	private readonly hmvService = inject(HmvService);
	private readonly router = inject(Router);
	private errorSubscription?: Subscription;

	constructor() {
		const sortByFields = <Model, Property extends keyof Model>(models: Model[], fields: Property[]) => {
			return ModelHelper.getPropertyValues(models, fields).pipe(
				map(values => models.sort((a, b) => {
					for(const field of fields) {
						const indexA = models.indexOf(a);
						const indexB = models.indexOf(b);
						const valueA = values[indexA][field];
						const valueB = values[indexB][field];
						if(valueA === valueB)
							continue;

						if(valueA == null)
							return 1;

						if(valueB == null)
							return -1;

						if(typeof valueA === 'string' && typeof valueB === 'string') {
							const compareResult = valueB.localeCompare(valueA);
							if(compareResult !== 0)
								return compareResult;

							continue;
						}

						if(typeof valueA === 'number' && typeof valueB === 'number') {
							if(valueA < valueB)
								return 1;

							if(valueA > valueB)
								return -1;
						}

						throw new Error(`Cannot compare values of type ${typeof valueA} and ${typeof valueB}`);
					}

					return 0;
				})),
			);
		};

		this.isMultiView$ = this.calculations$.pipe(
			map(calculations => calculations.length > 1),
		);
		this.fixedPrices$ = this.calculations$.pipe(
			map(calculations => calculations.map(calculation => calculation.fixedPrice.value)),
			mergeMap(x => combineLatestSafe(x)),
			map(prices => prices.filter(notNull)),
		);

		this.additions$ = this.calculations$.pipe(
			map(calculations => calculations.map(calculation => calculation.addition.value)),
			mergeMap(x => combineLatestSafe(x)),
			map(additions => additions.filter(notNull)),
		);

		this.possibleExtensions$ = this.getModels(m => m.possibleExtensions.value, x => x, models => sortByFields(models, ['identifierExtension', 'productSpecialityNumber']));

		this.materials$ = this.getModels(m => m.materials.value, x => x.model, models => sortByFields(models, ['name']));
		this.products$ = this.getModels(m => m.products.value, x => x.model, models => sortByFields(models, ['identifierExtension']));
		this.includedCalculations$ = this.getModels(m => m.calculations.value, x => x.model, models => sortByFields(models, ['identifierExtension', 'productSpecialityNumber', 'aidIdentifiers']));
		this.tasks$ = this.getModels(m => m.taskTimes.value.pipe(
			switchMap(times => combineLatestSafe(times?.map(time => time.model.task.value))),
			map(tasks => tasks?.filter(notNull)),
			shareReplay(1),
		), x => x, models => sortByFields(models, ['position']));
		const groupTaskByField = <T extends keyof TaskModel>(field: T) => {
			return this.tasks$.pipe(
				switchMap(tasks => ModelHelper.getModelPropertyValues(tasks, [field], 'model')),
				map(tasks => {
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					const tasksByScope = new Map<any, TaskModel[]>();

					tasks.forEach(entry => {
						const scope = entry[field];
						if(scope === undefined)
							return;

						let listEntry = tasksByScope.get(scope);
						if(listEntry === undefined) {
							listEntry = [];
							tasksByScope.set(scope, listEntry);
						}

						listEntry.push(entry.model);
					});

					return tasksByScope;
				}),
				shareReplay(1),
			);
		};
		this.tasksByScope$ = groupTaskByField('scope');
		this.tasksByType$ = groupTaskByField('type');
		this.hints$ = this.calculations$.pipe(
			switchMap(calculations => combineLatestSafe(calculations.map(calculation => calculation.hint.value))),
		);
		this.tasksGrouped$ = this.groupTasksBy$.pipe(
			switchMap(field => field === 'type' ? this.tasksByType$ : this.tasksByScope$),
		);
		this.indications$ = this.calculations$.pipe(
			map(calculations => calculations.map(calculation => calculation.identifierExtension.value)),
			mergeMap(identifierExtensions$ => combineLatestSafe(identifierExtensions$)),
			map(indications => indications.map(indication => (indication == null) ? of(indication) : this.hmvService.find(indication.substring(0, 7)))),
			switchMap(indications$ => combineLatestSafe(indications$)),
			map(hmvEntries => hmvEntries.filter((entry): entry is ProduktartModel => entry instanceof ProduktartModel)),
			map(produktarts => produktarts.map(produktart => produktart.indicator.value)),
			switchMap(indications$ => combineLatestSafe(indications$)),
			shareReplay(1),
		);
		this.serviceScopes$ = this.calculations$.pipe(
			map(calculations => calculations.map(calculation => calculation.serviceScope.value)),
			switchMap(serviceScopes$ => combineLatestSafe(serviceScopes$)),
		);
		this.characteristic$ = this.calculations$.pipe(
			map(calculations => calculations.map((calculation, calculationIndex) => calculation.characteristic.value.pipe(
				switchMap(characteristic => {
					if(characteristic != null)
						return of(characteristic);

					return calculation.identifierExtension.value.pipe(
						switchMap(identifierExtension => {
							if(identifierExtension == null)
								return of(identifierExtension);

							if(identifierExtension.length < 7)
								return of(undefined);

							return this.hmvService.find(identifierExtension.substring(0, 7));
						}),
						map(hmvModel => {
							if(hmvModel == null || (hmvModel instanceof ProduktartModel))
								return hmvModel;

							return undefined;
						}),
						switchMap(produktModell => (produktModell == null) ? of(produktModell) : produktModell.description.value),
					);
				}),
			))),
			map(x => x),
			switchMap(characteristics$ => combineLatestSafe(characteristics$)),
			shareReplay(1),
		);

		this.hasIndications$ = this.indications$.pipe(notEmpty);
		this.hasCharacteristics$ = this.characteristic$.pipe(notEmpty);
		this.hasServiceScopes$ = this.serviceScopes$.pipe(notEmpty);
		this.hasHint$ = this.hints$.pipe(notEmpty);
	}

	get headerHeight(): number {
		return this.headerElement?.nativeElement.offsetHeight ?? 0;
	}

	@RouteInput()
	set id(id: unknown) {
		if(typeof id !== 'string')
			throw new Error(`Invalid id: ${id}`);

		const model = this.calculationService.getById(id);
		this.errorSubscription?.unsubscribe();
		this.errorSubscription = model.onError.subscribe((error) => {
			if(error instanceof HttpErrorResponse) {
				this.router.navigate([
					'errors',
					error.status,
				], {skipLocationChange: true});
			}
		});

		this.calculations$.next([model]);
	}

	getHourlyBillRate(calculation: CalculationModel): Observable<number | undefined> {
		return this.hourlyRateService.getRatePerMinute(calculation);
	}

	findModel<T extends AnyModel>(relations: CalculationAmount<T>[], model: T): CalculationAmount<T> | undefined {
		return relations.find(m => m.model === model);
	}

	findTaskTimeModel(relations: CalculationTask[], model: TaskModel): CalculationTask | undefined {
		return relations.find(relation => relation.model.task.currentValue === model);
	}

	setPosition(previousIndex: number, currentIndex: number): void {
		const calculations = this.calculations$.value;
		moveItemInArray(calculations, previousIndex, currentIndex);
		this.calculations$.next(calculations);
	}

	remove(calculation: CalculationModel): void {
		let calculations = this.calculations$.value;
		calculations = calculations.filter(calc => calc !== calculation);
		this.calculations$.next(calculations);
	}

	add(calculation: CalculationModel | CalculationModel[]): void {
		if(!Array.isArray(calculation))
			calculation = [calculation];

		let calculations = this.calculations$.value;
		calculations.push(...calculation);
		calculations = calculations.filter(unique);
		this.calculations$.next(calculations);
	}

	getFactor(amount: number, unit: MaterialModel['unit']): Observable<Amount | undefined> {
		function transform(amount: number, unit: MaterialExtendedUnit | undefined): Amount | undefined {
			const CM_IN_M = 100;
			const MM_IN_CM = 10;
			const CENTIMETER_SQUARED_IN_MILLIMETER_SQUARED = 10 * 10;
			const CENTIMETER_SQUARED_IN_METER_SQUARED = 100 * 100;
			const GRAMS_IN_KILOGRAM = 1_000;
			const MILLILITER_IN_LITER = 1_000;
			const MINIMUM_AMOUNT = 1;

			switch(unit) {
				case undefined:
					return undefined;

				case 'PIECE':
				case 'PAIR':
				case 'ROLL':
				case 'SET':
					break;

				case 'METER':
					if(amount < MINIMUM_AMOUNT)
						return transform(amount * CM_IN_M, 'CENTIMETER');
					break;

				case 'CENTIMETER':
					if(amount < MINIMUM_AMOUNT)
						return transform(amount * MM_IN_CM, 'MILLIMETER');

					if(amount >= CM_IN_M)
						return transform(amount / CM_IN_M, 'METER');
					break;

				case 'MILLIMETER':
					if(amount >= MM_IN_CM)
						return transform(amount / MM_IN_CM, 'CENTIMETER');
					break;

				case 'METER_SQUARED':
					if(amount < MINIMUM_AMOUNT)
						return transform(amount * CENTIMETER_SQUARED_IN_METER_SQUARED, 'CENTIMETER_SQUARED');
					break;

				case 'CENTIMETER_SQUARED':
					if(amount < MINIMUM_AMOUNT)
						return transform(amount * CENTIMETER_SQUARED_IN_MILLIMETER_SQUARED, 'MILLIMETER_SQUARED');

					if(amount >= CENTIMETER_SQUARED_IN_METER_SQUARED)
						return transform(amount / CENTIMETER_SQUARED_IN_METER_SQUARED, 'METER_SQUARED');
					break;

				case 'MILLIMETER_SQUARED':
					if(amount >= CENTIMETER_SQUARED_IN_MILLIMETER_SQUARED)
						return transform(amount / CENTIMETER_SQUARED_IN_MILLIMETER_SQUARED, 'CENTIMETER_SQUARED');
					break;

				case 'KILOGRAM':
					if(amount < MINIMUM_AMOUNT)
						return transform(amount * GRAMS_IN_KILOGRAM, 'GRAM');
					break;

				case 'GRAM':
					if(amount >= GRAMS_IN_KILOGRAM)
						return transform(amount / GRAMS_IN_KILOGRAM, 'KILOGRAM');
					break;

				case 'LITER':
					if(amount < MINIMUM_AMOUNT)
						return transform(amount * MILLILITER_IN_LITER, 'LITER');
					break;

				case 'MILLILITER':
					if(amount >= MILLILITER_IN_LITER)
						return transform(amount / MILLILITER_IN_LITER, 'LITER');

					break;
			}

			return {
				amount,
				unit,
			};
		}

		return unit.value.pipe(
			map(unit => transform(amount, unit)),
		);
	}

	protected findIndication(calculation: CalculationModel): Observable<string | undefined> {
		return combineLatest([this.indications$, this.calculations$]).pipe(
			map(([indications, calculations]) => {
				const pos = calculations.indexOf(calculation);
				if(pos < 0)
					return undefined;

				return indications[pos];
			}),
		);
	}

	protected findCharacteristic(calculation: CalculationModel): Observable<string | null | undefined> {
		return combineLatest([this.characteristic$, this.calculations$]).pipe(
			map(([characteristics, calculations]) => {
				const pos = calculations.indexOf(calculation);
				if(pos < 0)
					return undefined;

				return characteristics[pos];
			}),
		);
	}

	protected sortTaskGroups(left: KeyValue<TaskScope | TaskType, TaskModel[]>, right: KeyValue<TaskType | TaskScope, TaskModel[]>): number {
		const posLeft = (TASK_TYPES as (readonly string[])).indexOf(left.key);
		const posRight = (TASK_TYPES as (readonly string[])).indexOf(right.key);

		if(posLeft !== -1 && posRight !== -1)
			return posLeft - posRight;

		return left.key.localeCompare(right.key);
	}

	protected openSelectCalculationsDialog(): void {
		const data: SelectCalculationsDialogData = {
			currentCalculations: [...this.calculations$.value],
		};
		const config = new MatDialogConfig<SelectCalculationsDialogData>();
		config.height = '66vh';

		const dialog = this.dialogService.openCustomDialog(SelectCalculationsDialogComponent, data, config);
		dialog.afterClosed().subscribe(selectedCalculations => {
			if(selectedCalculations == null)
				return;

			if(!Array.isArray(selectedCalculations))
				throw new Error('Selected calculations must be an array');

			if(selectedCalculations.length !== 0 && (selectedCalculations[0] instanceof CalculationModel) === false)
				throw new Error('Selected calculations must be instances of CalculationModel');

			this.calculations$.next(selectedCalculations);
		});
	}

	protected getModels<S, T>(field: (m: CalculationModel) => Observable<T[] | undefined>, mapping: (m: T) => S, sort?: (m: S[]) => Observable<S[]>): Observable<S[]> {
		return this.calculations$.pipe(
			map(models => models.map(field)),
			switchMap(m => combineLatestSafe(m)),
			map(m => m.filter(notNull)),
			map(m => m.reduce(
				(prev, curr) => prev.concat(curr.map(mapping)),
				[] as S[]),
			),
			switchMap(m => sort?.(m) ?? of(m)),
			map(m => {
				const map = new Map<S, { count: number, index: number }>();
				m.forEach((value, index) => {
					const count = (map.get(value)?.count ?? 0) + 1;
					map.set(value, {
						count,
						index,
					});
				});

				return m.sort((a, b) => {
					const entryA = map.get(a);
					if(entryA == null)
						throw new Error(`Didn't find entry "${a}" in original array "${JSON.stringify(map)}"`);

					const entryB = map.get(b);
					if(entryB == null)
						throw new Error(`Didn't find entry "${b}" in original array "${JSON.stringify(map)}"`);

					if(entryA.count !== entryB.count)
						return entryB.count - entryA.count;

					// enforce "sort()" to be stable by sorting by index if value is equal
					return entryB.index - entryA.index;
				});
			}),
			map(m => m.filter(unique)),
		);
	}

	protected openPreferences(): void {
		SystemPreferencesDialogComponent.open(this.dialogService);
	}

	protected showExportDialog(): void {
		const calculations = this.calculations$.value;
		if(calculations.length > 1)
			throw new Error('Cannot export multiple calculations');

		if(calculations.length < 0)
			throw new Error('No calculations available');

		GenerateCalculationExportDialogComponent.open(this.dialogService, calculations[0]);
	}
}

