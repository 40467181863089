<div class="wrapper">
	<header
		[class.stickyHeader]="stickyHeader"
	>
		<span
			class="headerText"
			[class.wide]="headerTemplate == null"
		>
			{{ headline | translate }}
			<ng-container
				*ngIf="headerTitleTemplate"
				[ngTemplateOutlet]="headerTitleTemplate.templateRef"
			/>
		</span>
		<ng-container
			*ngIf="headerTemplate"
			[ngTemplateOutlet]="headerTemplate.templateRef"
		/>
		@if (isCollapsable) {
			<div
				class="clickable collapse-icon"
				(click)="collapsedChange.emit(!collapsed)"
			>
				<fa-icon
					[class.collapsed]="collapsed"
					[icon]="iconService.ANGLE_UP"
				/>
			</div>
		} @else {
			<div class="placeholder"></div>
		}
	</header>
	<section
		[@collapse]="collapsed ? 'closed' : 'open'"
    #ngContents
	>
		<ng-content/>
	</section>
</div>