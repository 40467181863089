import {
	BasicPermissionedProperty,
	VersionedModel,
} from '@angular-helpers/frontend-api';
import {FixedPriceService} from './fixed-price.service';


export class FixedPriceModel extends VersionedModel<FixedPriceService> {
	readonly identifierExtension = new BasicPermissionedProperty<string>('identifierExtension', this);
	readonly name                = new BasicPermissionedProperty<string>('name', this);
	readonly price               = new BasicPermissionedProperty<number>('price', this);
	readonly validStartAt        = new BasicPermissionedProperty<Date>('validStartAt', this);
	readonly validEndAt          = new BasicPermissionedProperty<Date | null>('validEndAt', this);
	readonly description         = new BasicPermissionedProperty<string>('description', this);
	readonly specification       = new BasicPermissionedProperty<string>('specification', this);
	readonly aidIdentifier       = new BasicPermissionedProperty<number>('aidIdentifier', this);
	readonly successor           = new BasicPermissionedProperty<FixedPriceModel | null>('successor', this);
	readonly predecessor         = new BasicPermissionedProperty<FixedPriceModel | null>('predecessor', this);
}