import {
	BasicPermissionedProperty,
	VersionedModel,
} from '@angular-helpers/frontend-api';
import {TaskModel} from '../task/task.model';
import {TaskTimeService} from './task-time.service';

export class TaskTimeModel extends VersionedModel<TaskTimeService> {
	readonly task        = new BasicPermissionedProperty<TaskModel>('task', this);
	readonly name        = new BasicPermissionedProperty<string>('name', this);
	readonly description = new BasicPermissionedProperty<string>('description', this);
	readonly time        = new BasicPermissionedProperty<number>('time', this);
}