import {
	Component,
	ElementRef,
	HostListener,
	ViewChild,
} from '@angular/core';
import {environment} from '@app/environment';
import {SystemPreferencesDialogComponent} from '@app/main';
import {UserService} from '@authentication/frontend-api';
import {faAngleDown} from '@fortawesome/free-solid-svg-icons';
import {TranslateService} from '@ngx-translate/core';
import {map} from 'rxjs/operators';
import {AuthService} from '../../services/auth.service';
import {ConfigService} from '../../services/config.service';
import {DialogService} from '../../services/dialog.service';
import {IconService} from '../../services/icon.service';

@Component({
	selector:    'portal-header',
	templateUrl: './header.component.html',
	styleUrls:   ['./header.component.scss'],
})
export class HeaderComponent {
	@ViewChild('avatarMenu') avatarMenu?: ElementRef;
	readonly ICONS       = {
		avatar: faAngleDown,
	};
	menuShown            = false;
	readonly environment = environment;
	readonly token$      = this.authService.user$;
	readonly user$       = this.token$.pipe(map(token => (token == null) ? null : this.userService.getById(token.getSubject())));

	constructor(
		private readonly authService: AuthService,
		private readonly userService: UserService,
		private readonly translateService: TranslateService,
		private readonly dialogService: DialogService,
		protected readonly iconService: IconService,
		protected readonly configService: ConfigService,
	) {
	}

	@HostListener('document:click', ['$event'])
	globalClickListener(event: MouseEvent): void {
		if(!this.menuShown)
			return;

		if(this.avatarMenu?.nativeElement.contains(event.target) === false)
			this.menuShown = false;
	}

	toggleMenu(): void {
		this.menuShown = !this.menuShown;
	}

	async openStageDialog(): Promise<void> {
		await this.dialogService.getResult({
			labelPositiv: 'actions.ok',
			message:      `system.stage.${environment.stage}.message`,
			title:        `system.stage.${environment.stage}.title`,
		});
	}

	hasMessageHint(): boolean {
		const msg = this.translateService.instant(`system.stage.${environment.stage}`);
		return typeof msg != 'string';
	}

	openPreferences(): void {
		SystemPreferencesDialogComponent.open(this.dialogService);
		this.menuShown = false;
	}
}
