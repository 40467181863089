import {
	Component,
	Input,
} from '@angular/core';
import {environment} from '@app/environment';
import {
	BasicPropertyType,
	ClientSearchMinimalColumns,
	MinimalColumns,
	SearchFilter,
} from '@app/main';
import {
	ContractAccessionModel,
	ContractAccessionService,
	ExternalContractNumberModel,
	ExternalContractNumberType,
} from '@contracts/frontend-api';
import {
	Observable,
	of,
} from 'rxjs';
import {
	first,
	map,
	mergeMap,
} from 'rxjs/operators';
import {BaseContractListComponent} from '../../base-contract/list/base-contract-list.component';

@Component({
	selector:    'portal-contract-accession-list',
	templateUrl: './contract-accession-list.component.html',
	styleUrls:   ['./contract-accession-list.component.scss'],
})
export class ContractAccessionListComponent {
	@Input() data: ContractAccessionModel[] | undefined | null;
	environment = environment;
	externalContractNumberType = ExternalContractNumberType;
	readonly searchFilter = new CSearchFilter();
	protected readonly first = first;
	private readonly tableHeaders = {
		institutionskennzeichen: {
			label:         'model.institutionskennzeichen',
			index:         0,
			prepareSearch: (value) => {
				if(typeof value === 'string')
					return value.replace(/\s/g, '');
				return value;
			},
		},
		joinedParties:           {
			label: 'model.contractingParties',
			index: 1,
		},
		masterContract:          {
			label: 'model.masterContract',
			index: 2,
		},
		contract:                {
			label: 'model.contract',
			index: 3,
		},
		contractSection:         {
			label: 'model.contractSection',
			index: 4,
		},
		accessionStartAt:        {
			label: 'contractAccession.accessionStartAt',
			index: 5,
		},
		accessionEndAt:          {
			label:     'contractAccession.accessionEndAt',
			isVisible: false,
			index:     6,
		},
		externalContractNumbers: {
			label:      'externalContractNumber.modelType.legs',
			isSortable: false,
			isVisible:  false,
			isSearchable: false,
			index:      6,
		},
	} satisfies MinimalColumns<ContractAccessionModel>;
	readonly clientSideSearchTableHeaders: ClientSearchMinimalColumns<ContractAccessionModel> = {
		...this.tableHeaders,
		institutionskennzeichen: {
			...this.tableHeaders.institutionskennzeichen,
			prepareSearch: (value) => {
				if(typeof value === 'string')
					return value.replace(/\s/g, '');
				return value;
			},
			sortMap:       (ik: BasicPropertyType<ContractAccessionModel['institutionskennzeichen']>) => ik?.number.firstValue,
		},
		joinedParties:           {
			...this.tableHeaders.joinedParties,
			sortByField: 'contractSection',
			sortMap:     async (contractSection: BasicPropertyType<ContractAccessionModel['contractSection']>) => {
				if(contractSection == null)
					return contractSection;

				const parties = await contractSection.joinedParties.withParent.firstValue;
				const partyNames = await Promise.all(parties.map(party => party.contractingParty.name.firstValue));

				return partyNames.sort().join('\n');
			},
		},
		masterContract:          {
			...this.tableHeaders.masterContract,
			sortByField: 'contractSection',
			sortMap:     async (contractSection: BasicPropertyType<ContractAccessionModel['contractSection']>) => {
				if(contractSection == null)
					return contractSection;

				const contract = await contractSection.contract.firstValue;
				const masterContract = await contract?.masterContract.firstValue;
				return masterContract?.name.firstValue;
			},
		},
		contract:                {
			...this.tableHeaders.contract,
			sortByField: 'contractSection',
			sortMap:     async (contractSection: BasicPropertyType<ContractAccessionModel['contractSection']>) => (await contractSection?.contract.firstValue)?.name.firstValue,
		},
		contractSection:         {
			...this.tableHeaders.contractSection,
			sortByField: 'contractSection',
			sortMap:     async (contractSection: BasicPropertyType<ContractAccessionModel['contractSection']>) => contractSection?.name.firstValue,
		},
	};
	readonly serverSideSearchHeaders = {
		institutionskennzeichen: {
			...this.tableHeaders.institutionskennzeichen,
			sortBy: 'institutionskennzeichen.number',
		},
		joinedParties:           {
			...this.tableHeaders.joinedParties,
			sortBy: 'joinedParty.name',
		},
		masterContract:          {
			...this.tableHeaders.masterContract,
			sortBy: ['masterContract.name', 'contract.name', 'contractSection.name'],
		},
		contract:                {
			...this.tableHeaders.contract,
			sortBy: ['contract.name', 'contractSection.name'],
		},
		contractSection:         {
			...this.tableHeaders.contractSection,
			sortBy: 'contractSection.name',
		},
	};

	constructor(
		protected readonly contractAccessionService: ContractAccessionService,
	) {
	}

	static getMasterContract$(model: ContractAccessionModel): Observable<string> {
		return model.contractSection.value.pipe(
			mergeMap(contractSection => contractSection?.parent.value ?? of(undefined)),
			mergeMap(contract => contract?.parent.value ?? of(undefined)),
			mergeMap(masterContract => masterContract?.name.value ?? of(undefined)),
			map(name => name ?? ''),
		);
	}

	static getContract$(model: ContractAccessionModel): Observable<string> {
		return model.contractSection.value.pipe(
			mergeMap(contractSection => contractSection?.parent.value ?? of(undefined)),
			mergeMap(contract => contract?.name.value ?? of(undefined)),
			map(name => name ?? ''),
		);
	}

	static getContractSection$(model: ContractAccessionModel): Observable<string> {
		return model.contractSection.value.pipe(
			mergeMap(contractSection => contractSection?.name.value ?? of(undefined)),
			map(name => name ?? ''),
		);
	}

	static getInstitutionskennzeichen$(model: ContractAccessionModel): Observable<string> {
		return model.institutionskennzeichen.value.pipe(
			mergeMap(ik => ik?.number.value ?? of(undefined)),
			map(name => name ?? ''),
		);
	}

	static getJoinedParties$(model: ContractAccessionModel): Observable<string[] | undefined> {
		return model.contractSection.value.pipe(
			mergeMap(contractSection => (contractSection) == null ? of(contractSection) : BaseContractListComponent.getJoinedParties$(
				contractSection)),
		);
	}

	static getExternalContractNumbers$(model: ContractAccessionModel): Observable<ExternalContractNumberModel[] | undefined> {
		return model.contractSection.value.pipe(
			mergeMap(contractSection => (contractSection) == null ? of(contractSection) : contractSection.externalContractNumbers.withParent.value),
		);
	}

	getJoinedParties$(model: ContractAccessionModel): Observable<string[] | undefined> {
		return ContractAccessionListComponent.getJoinedParties$(model);
	}

	getMasterContract$(model: ContractAccessionModel): Observable<string> {
		return ContractAccessionListComponent.getMasterContract$(model);
	}

	getContract$(model: ContractAccessionModel): Observable<string> {
		return ContractAccessionListComponent.getContract$(model);
	}

	getContractSection$(model: ContractAccessionModel): Observable<string> {
		return ContractAccessionListComponent.getContractSection$(model);
	}

	getInstitutionskennzeichen$(model: ContractAccessionModel): Observable<string> {
		return ContractAccessionListComponent.getInstitutionskennzeichen$(model);
	}

	getExternalContractNumbers$(model: ContractAccessionModel): Observable<ExternalContractNumberModel[] | undefined> {
		return ContractAccessionListComponent.getExternalContractNumbers$(model);
	}
}

class CSearchFilter extends SearchFilter<ContractAccessionModel> {
	protected async getModelValue(field: string, model: ContractAccessionModel): Promise<unknown> {
		switch(field) {
			case 'masterContract':
				return ContractAccessionListComponent.getMasterContract$(model).pipe(first()).toPromise();

			case 'contractSection':
				return ContractAccessionListComponent.getContractSection$(model).pipe(first()).toPromise();

			case 'contract':
				return ContractAccessionListComponent.getContract$(model).pipe(first()).toPromise();

			case 'joinedParties':
				return ContractAccessionListComponent.getJoinedParties$(model).pipe(first()).toPromise();

			case 'institutionskennzeichen':
				return ContractAccessionListComponent.getInstitutionskennzeichen$(model).pipe(first()).toPromise();

			case 'externalContractNumbers':
				return ContractAccessionListComponent.getExternalContractNumbers$(model).pipe(first()).toPromise();

			default:
				return super.getModelValue(field, model);
		}
	}
}
