import {
	AbstractApiService,
	AbstractService,
	ResultPageModel,
} from '@angular-helpers/frontend-api';
import {
	Directive,
	Input,
	TemplateRef,
} from '@angular/core';
import {AnyModel} from '../../helper/any';
import {
	Column,
	TableComponent,
} from './table.component';

export interface FullColumn<ModelType extends object, RawType> extends Column<ModelType, RawType> {
	id: string;
}

export interface TableSearchHeaderData<ModelType extends object, RawModel> {
	column: FullColumn<ModelType, RawModel>;
	search: TableComponent<ModelType>['search'];
}

@Directive({
	selector: 'ng-template[searchHeader][data],ng-template[searchHeader][service]',
})
export class TableSearchHeaderTemplateDirective<ModelType extends object, RawModel> {
	@Input({required: true}) searchHeader!: string;
	@Input() data?: ModelType[] | ResultPageModel<ModelType> | undefined | null;
	@Input() service?: AbstractService<AbstractApiService, ModelType & AnyModel>;

	constructor(public readonly template: TemplateRef<TableSearchHeaderData<ModelType, RawModel>>) {
	}

	static ngTemplateContextGuard<ModelType extends object, RawModel>(dir: TableSearchHeaderTemplateDirective<ModelType, RawModel>, ctx: unknown): ctx is TableSearchHeaderData<ModelType, RawModel> {
		return true;
	}
}
