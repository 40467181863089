import {
	Component,
	OnInit,
} from '@angular/core';
import {environment} from '@app/environment';
import {
	LandesinnungModel,
	LandesinnungService,
} from '@contracts/frontend-api';

@Component({
	selector:    'portal-page-landesinnung',
	templateUrl: './landesinnung-page-list.component.html',
	styleUrls:   ['./landesinnung-page-list.component.scss'],
})
export class LandesinnungPageListComponent implements OnInit {
	landesinnungs?: Promise<LandesinnungModel[]>;
	readonly environment = environment;

	constructor(
		private readonly landesinnungService: LandesinnungService,
	) { }

	ngOnInit(): void {
		this.landesinnungs = this.landesinnungService.getAllModels();
	}

}
