import {
	BasicPermissionedProperty,
	VersionedModel,
} from '@angular-helpers/frontend-api';
import {VatRate} from '../common/types';
import {MaterialUnit} from '../material/material.model';
import {
	ProductModel,
	ProductStatus,
} from '../product/product.model';
import {VariationService} from './variation.service';


export class VariationModel extends VersionedModel<VariationService> {
	readonly identifierExtension         = new BasicPermissionedProperty<string>('identifierExtension', this);
	readonly name                        = new BasicPermissionedProperty<string>('name', this);
	readonly manufacturer                = new BasicPermissionedProperty<string>('manufacturer', this);
	readonly unit                        = new BasicPermissionedProperty<MaterialUnit>('unit', this);
	readonly price                       = new BasicPermissionedProperty<number>('price', this);
	readonly vatRate                     = new BasicPermissionedProperty<VatRate | null>('vatRate', this);
	readonly availability                = new BasicPermissionedProperty<ProductStatus>('availability', this);
	readonly occurrenceRate              = new BasicPermissionedProperty<number | null>('occurrenceRate', this);
	readonly weight                      = new BasicPermissionedProperty<number>('weight', this);
	readonly product                     = new BasicPermissionedProperty<ProductModel>('product', this);
	readonly ean                         = new BasicPermissionedProperty<string>('ean', this);
	readonly articleNumber               = new BasicPermissionedProperty<string | null>('articleNumber', this);
	readonly centralPharmaceuticalNumber = new BasicPermissionedProperty<string | null>('centralPharmaceuticalNumber', this);
	readonly isLeasing                   = new BasicPermissionedProperty<boolean | null>('isLeasing', this);
}