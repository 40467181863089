import {
	Pipe,
	PipeTransform,
} from '@angular/core';

@Pipe({
	name:       'trim',
	standalone: true,
})
export class TrimPipe implements PipeTransform {
	transform<T extends string | null | undefined>(value: T): T {
		if(typeof value !== 'string')
			return value;

		// @ts-expect-error linter dump
		return value.trim();
	}
}
